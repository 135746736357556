import React from 'react'
import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useLandingStatistics } from 'hooks/productApiHooks'


const AboutUsSection5 = () => {

  const stats = useLandingStatistics()

  return <Stack sx={styles.sectionWrapper}>
    <Grid sx={styles.iconsGrid}>
      <GridItem bigText={stats?.response?.AnnualizedReturns} smallText='Annualized Returns' index={0} />
      <GridItem bigText={stats?.response?.AnnualizedExcessReturns} smallText='Annualized Excess Returns' index={1} />
      <GridItem bigText={stats?.response?.AnnualizedVolatility} smallText='Annualized Volatility' index={2} />
      <GridItem bigText={stats?.response?.AnnualizedExcessVolatility} smallText='Annualized Excess Volatility' index={3} />
      <GridItem bigText={stats?.response?.TrackingError} smallText='Tracking Error' index={4} />
      <GridItem bigText={stats?.response?.InformationRatio} smallText='Information Ratio' index={5} />
      <GridItem bigText={stats?.response?.SharpeRatio} smallText='Sharpe Ratio' index={6} />
      <GridItem bigText={stats?.response?.RecoveryRatio} smallText='Recovery Ratio' index={7} />
    </Grid>
  </Stack>
}


const GridItem = ({ bigText, smallText, index = 0 }) => {

  const theme = useTheme()
  const isAtLeastLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isAtLeastMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isAtLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))

  const hasRightBorder = index => {
    if (isAtLeastLarge) return index % 4 !== 3
    if (isAtLeastMedium) return index % 3 !== 2
    if (isAtLeastSmall) return index % 2 !== 1
    return false
  }

  const getRightBorderStyle = index => ({
    borderRight: hasRightBorder(index) ? '2px solid' : 'none',
    borderRightColor: 'colors.main',
  })


  return <Stack
    sx={{ ...styles.gridItem, ...getRightBorderStyle(index) }}
  >
    <Text sx={styles.largeText}>{bigText || '-'}</Text>
    <Text sx={styles.gridText}>{smallText}</Text>
  </Stack>
}


const styles = {
  sectionWrapper: {
    rowGap: '3.2rem',
  },
  iconsGrid: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: '1fr 1fr',
      md: '1fr 1fr 1fr',
      lg: '1fr 1fr 1fr 1fr',
    },
    rowGap: '45px',
    width: '100%',
  },
  gridItem: {
    alignItems: 'center',
    paddingTop: '5px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  gridText: {
    fontSize: '1.215rem',
  },
  largeText: {
    fontSize: '3rem',
  },
}

export default AboutUsSection5
