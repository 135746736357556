import React from 'react'
import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import MyImage from 'components/MyImage'
import aboutUsLaptops from 'assets/img/aboutUsLaptops.jpg'


const AboutUsSection1 = () => <Grid sx={styles.sectionWrapper} id='machines'>
  <Stack sx={styles.textWrapper}>
    <Text sx={styles.title}>
      We Build Machines That <br />
      <b>Beat The Market</b>
    </Text>
    <Text sx={styles.description}>
      Financial theory says you cannot beat the market. After
      a decade of innovation, validation, and testing with
      institutional asset managers globally and beating the
      market, year after year. We have concluded that machines
      can not only beat the market, but they can also beat them
      by a significant margin. And now we want you to test our
      claim and join us in this intelligence revolution.
    </Text>
  </Stack>
  <MyImage src={aboutUsLaptops} sx={styles.image} />
</Grid>


const styles = {
  sectionWrapper: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    columnGap: '5%',
    rowGap: '60px',
  },
  textWrapper: {
    gap: '40px',
  },
  title: {
    fontSize: '3.5rem',
    fontWeight: '400',
    lineHeight: '1.4',
    color: 'colors.lightGrey18',
  },
  description: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey17',
    fontWeight: '400',
    paddingLeft: '1.5em',
    borderLeft: 'solid 2.5px',
    borderLeftColor: 'colors.primaryMain',
  },
  image: {
    height: {
      xs: '400px',
      md: 'calc()',
    },
    maxWidth: '100%',
    alignSelf: 'center',
  },
}

export default AboutUsSection1
