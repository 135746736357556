
import { Button, Grid } from '@mui/material'
import MyLink from 'components/MyLink'
import React from 'react'

const SeeAllPricesLink = ({ ...rest }) => <Grid
  sx={styles.seeAllFeaturesWrapper}
  container
  {...rest}
>
  <MyLink to='#features' sx={styles.seeAllFeaturesLink}>
    <Button sx={styles.seeAllFeaturesButton}>
      See all Features +
    </Button>
  </MyLink>
</Grid>


const styles = {
  seeAllFeaturesWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  seeAllFeaturesLink: {

  },
  seeAllFeaturesButton: {
    textTransform: 'none',
    fontSize: '1.125rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
}

export default SeeAllPricesLink
