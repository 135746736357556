import React from 'react'
import { Button, Grid } from '@mui/material'
import { NavLink } from 'react-router-dom'
import Text from 'components/Text'
import ToolsNavigation from 'screens/ToolsSlider/components/ToolsNavigation/ToolsNavigation'
import HamburgerMenu from 'components/menu/HamburgerMenu'


export default function ToolsHeader() {
  return <Grid sx={styles.menuRow} container>
    <Button
      variant='outlined'
      color='secondary'
      sx={styles.button}
      component={NavLink}
      to='/get-started'
    >
      <Text>Go Back</Text>
    </Button>
    <Grid sx={styles.bulletsWrapper} container>
      <ToolsNavigation />
    </Grid>
    <HamburgerMenu />
  </Grid>
}


const styles = {
  menuRow: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    width: '100%',
    padding: '1%',
  },
  bulletsWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    textTransform: 'none',
  },
}
