import { createSelector } from 'reselect'


export const defaultArticleFilter = 'All'


export const filtersSelector = createSelector(
  state => state.articles?.allArticles,
  values => {

    const allArticles = values?.response
    const uniqueCategories = getUniqueCategories(allArticles)?.sort()

    const filters = [defaultArticleFilter, ...(uniqueCategories || [])]

    return filters
  }
)


/**
 * From each article having a list of categories, computes 
 * a unique list of categories of all articles.
 */
const getUniqueCategories = articles => {

  const matrixOfCategories = articles?.map(article => article.categories)
  const uniqueCategories = new Set()

  matrixOfCategories?.forEach(categoriesOfAnArticle =>
    categoriesOfAnArticle?.forEach(
      oneCategory => uniqueCategories.add(oneCategory)
    )
  )

  return [...uniqueCategories] || []
}
