import { myAxios, printError } from 'utils/apiUtils'
import appConfig from 'appConfig'
import { apiStatuses } from 'utils/apiStatuses'
import axios from 'axios'
import { defaultBillingFrequency, isValidBillingFrequency } from 'utils/payment/billingFrequencies'
import { defaultNrModels, isValidNrModels } from 'utils/payment/possibleNrModels'


const FETCH_SUBSCRIPTION_PRICES_START = 'payment/FETCH_SUBSCRIPTION_PRICES_START'
const FETCH_SUBSCRIPTION_PRICES_SUCCESS = 'payment/FETCH_SUBSCRIPTION_PRICES_SUCCESS'
const FETCH_SUBSCRIPTION_PRICES_ERROR = 'payment/FETCH_SUBSCRIPTION_PRICES_ERROR'
const SET_SELECTED_BILLING_FREQUENCY = 'payment/SET_SELECTED_BILLING_FREQUENCY'
const SET_SELECTED_NR_MODELS = 'payment/SET_SELECTED_NR_MODELS'
const REDIRECT_TO_CHECKOUT_START = 'payment/REDIRECT_TO_CHECKOUT_START'
const REDIRECT_TO_CHECKOUT_SUCCESS = 'payment/REDIRECT_TO_CHECKOUT_SUCCESS'
const REDIRECT_TO_CHECKOUT_ERROR = 'payment/REDIRECT_TO_CHECKOUT_ERROR'
// const AAAAAAAAAAAAAAAAAAA = 'payment/AAAAAAAAAAAAAAAAAAA'



const initialState = {
  selectedBillingFrequency: defaultBillingFrequency,
  selectedNrModels: defaultNrModels,
  subscriptionPrices: apiStatuses.getInitial(),
  redirectToCheckout: apiStatuses.getInitial(),
}

const paymentReducer = (state = initialState, action) => {
  const payload = action?.payload

  switch (action?.type) {
    case FETCH_SUBSCRIPTION_PRICES_START: return { ...state, subscriptionPrices: apiStatuses.getStarted() }
    case FETCH_SUBSCRIPTION_PRICES_SUCCESS: return { ...state, subscriptionPrices: apiStatuses.getSuccess(payload) }
    case FETCH_SUBSCRIPTION_PRICES_ERROR: return { ...state, subscriptionPrices: apiStatuses.getError(payload) }

    case REDIRECT_TO_CHECKOUT_START: return { ...state, redirectToCheckout: apiStatuses.getStarted() }
    case REDIRECT_TO_CHECKOUT_SUCCESS: return { ...state, redirectToCheckout: apiStatuses.getSuccess() }
    case REDIRECT_TO_CHECKOUT_ERROR: return { ...state, redirectToCheckout: apiStatuses.getError(payload) }

    case SET_SELECTED_BILLING_FREQUENCY: return { ...state, selectedBillingFrequency: payload }
    case SET_SELECTED_NR_MODELS: return { ...state, selectedNrModels: payload }
    default: return state
  }
}


export const redirectToCheckoutStart = () => ({ type: REDIRECT_TO_CHECKOUT_START })
export const redirectToCheckoutSuccess = () => ({ type: REDIRECT_TO_CHECKOUT_SUCCESS })
export const redirectToCheckoutError = error => dispatch => {
  printError('redirectToCheckoutError()', error, dispatch)
  dispatch(({ type: REDIRECT_TO_CHECKOUT_ERROR, payload: error }))
}



export const setSelectedBillingFrequency = billingFrequency => dispatch => {
  if (!isValidBillingFrequency(billingFrequency)) {
    console.error(`Error at setSelectedBillingFrequency(): invalid billingFrequency '${JSON.stringify(billingFrequency)}'.`)
    return
  }
  dispatch({ type: SET_SELECTED_BILLING_FREQUENCY, payload: billingFrequency })
}


export const setSelectedNrModels = nrModels => dispatch => {
  if (!isValidNrModels(nrModels)) {
    console.error(`Error at setSelectedNrModels(): invalid nrModels '${JSON.stringify(nrModels)}'.`)
    return
  }
  dispatch({ type: SET_SELECTED_NR_MODELS, payload: nrModels })
}


export const fetchSubscriptionPrices = () => dispatch => {

  dispatch({ type: FETCH_SUBSCRIPTION_PRICES_START })

  myAxios({
    axiosCallerFn: () => axios.get(appConfig.apiBaseUrl + '/stripe/subscription-price'),
    onSuccess: response => dispatch({
      type: FETCH_SUBSCRIPTION_PRICES_SUCCESS,
      payload: response?.data
    }),
    onError: error => {
      dispatch({ type: FETCH_SUBSCRIPTION_PRICES_ERROR, payload: error })
      printError('fetchSubscriptionPrices()', error, dispatch)
    },
    dispatch,
  })

}
// export const aaaaaaaaaaaaaaa = () => ({ type: AAAAAAAAAAAAAAAAAAA })
// export const aaaaaaaaaaaaaaa = () => ({ type: AAAAAAAAAAAAAAAAAAA })



export default paymentReducer
