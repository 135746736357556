import { Grid, Stack } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import { useParams } from 'react-router'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import Text from 'components/Text'
import { colors } from 'theme'
import { useNavigate } from 'react-router-dom';
import { useArticleDetail } from 'hooks/productApiHooks'
import Loading from 'components/Loading'


const ArticleDetailScreen = () => {

  const { articleId } = useParams()
  const articleDetail = useArticleDetail(articleId)

  const contentElement = <span dangerouslySetInnerHTML={{
    __html: articleDetail?.response?.content
  }} />

  const titleElement = <span dangerouslySetInnerHTML={{
    __html: articleDetail?.response?.title
  }} />


  return <Stack sx={styles.pageWrapper}>
    <Header />
    <Stack sx={styles.sectionsWrapper}>
      {articleDetail?.isSuccess
        ? <>
          <Grid sx={styles.titleAndDate}>
            <Text component='h1' sx={styles.title}>{titleElement}</Text>
            <Text sx={styles.date}>{articleDetail?.response?.date}</Text>
          </Grid>
          <Grid item sx={styles.content}>{contentElement}</Grid>
        </>
        : <Loading />
      }
    </Stack>
    <LandingFooter />
  </Stack>
}


const styles = {
  content: {
    '& p': {
      fontSize: '1.3rem',
      textAlign: 'justify',
      fontWeight: '300',
    },
    '& img': {
      maxWidth: '100%',
      margin: '3rem auto',
      display: 'block',
      backgroundColor: 'colors.main',
      borderRadius: '5px',
    },
    '& a': {
      color: colors.primaryMain,
    },
  },
  titleAndDate: {

  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '3rem',
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  date: {
    textAlign: 'center',
  },
  pageWrapper: {
    gap: '2rem',

    marginLeft: 'auto',
    backgroundColor: 'colors.darkGrey9',
    marginRight: 'auto',
  },
  sectionsWrapper: {
    gap: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '6rem',

    width: {
      xs: 'min(920px, 95%)',
      sm: 'min(920px, 90%)',
      lg: 'min(920px, 85%)',
    },
  },
}

export default ArticleDetailScreen
