import React, { useState } from 'react'
import styles from 'screens/ResearchSlider/components/ResearchNavigation/ResearchNavigation.module.css'
import HorizontalScrollCircle from 'screens/GetStarted/components/Pagination/HorizontalScrollCircle'
import HorizontalScrollDivider from 'screens/GetStarted/components/Pagination/HorizontalScrollDivider'
import researchSections from 'screens/ResearchSlider/researchSlidesConfig'
import { useLocation } from 'react-router'


const ResearchNavigation = () => {

  const { hash } = useLocation()
  const [isLabelVisible, setIsLabelVisible] = useState(false)

  const isActive = (section, index) =>
    hash ? (section.id === hash?.substring(1)) : index === 0


  const dotProps = researchSections?.map((section, index) => ({
    isActive: isActive(section, index),
    linkTo: `/research/#${section.id}`,
    label: section.label,
    setIsLabelVisible,
  }))


  return <div className={styles.container} >
    <HorizontalScrollCircle {...dotProps[0]} />
    <HorizontalScrollCircle {...dotProps[1]} />
    <HorizontalScrollCircle {...dotProps[2]} />
    <HorizontalScrollDivider sectionTitle='News' {...{ isLabelVisible }} />
    <HorizontalScrollCircle {...dotProps[3]} />
    <HorizontalScrollCircle {...dotProps[4]} />
    <HorizontalScrollCircle {...dotProps[5]} />
    <HorizontalScrollCircle {...dotProps[6]} />
  </div>
}

export default ResearchNavigation
