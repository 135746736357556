import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'


const scrollDelayMs = 710

/** 
 * Scrolls to the slide with the next sectionId 
 * (or previous) when mouseWheel event is detected.
 * 
 * When a scroll is complete, does not scroll again for a 
 * certain amount of time. Otherwise the hash (#anchor) 
 * in the URL could change multiple times during a 
 * single scroll and the browser would not follow.
 */
const MouseWheeSliderLogic = ({ sectionIds, sliderId }) => {

  const { hash } = useLocation()
  const scrollTimestamp = useRef()
  const [scrollEventPixels, setScrollEventPixels] = useState(0)

  const maybeIndex = sectionIds?.indexOf(hash?.substring(1))
  const sectionIndex = maybeIndex === -1 ? 0 : maybeIndex


  useEffect(() => {
    const sliderCopy = document.getElementById(sliderId)
    sliderCopy?.addEventListener('wheel', handleScroll)
    // Cleanup when the component unmounts:
    return () => sliderCopy?.
      removeEventListener('wheel', handleScroll)
  }, [])


  const handleScroll = event => {
    event.preventDefault()
    setScrollEventPixels(event?.deltaY)
    // TODO: also on MOBILE!
  }


  useEffect(() => {
    if (scrollEventPixels) { // If there is some amount to scroll
      if ( // If time-limit is passed
        !scrollTimestamp.current ||
        Date.now() - scrollTimestamp.current > scrollDelayMs
      ) {
        // Strore current timestamp for next time-limit check:
        scrollTimestamp.current = Date.now()
        const goToIndex = sectionIndex + (scrollEventPixels > 0 ? 1 : -1)

        // Validate goToIndex: 
        if (goToIndex >= 0 && goToIndex < sectionIds?.length) {
          window.location.replace(`#${sectionIds[goToIndex]}`)
        }
      }
    }
    setScrollEventPixels(0) // Reset amount to scroll
  }, [scrollEventPixels])


  return null
}


export default MouseWheeSliderLogic
