import { Grid, Stack } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import InsightsArticles from 'screens/Insights/InsightsArticles'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import InsightsTopSection from 'screens/Insights/InsightsTopSection'


const InsightsScreen = () => {

  return <Stack sx={styles.pageWrapper}>
    <Header />

    <Stack sx={styles.sectionsStack}>

      <InsightsTopSection />
      <InsightsArticles />
    </Stack>

    <LandingFooter />
  </Stack>

}


const styles = {
  pageWrapper: {
    backgroundColor: 'colors.darkGrey9',
  },
  sectionsStack: {
    gap: '1rem',
    paddingTop: '5rem',
    paddingBottom: '5rem',
    padding: {
      xs: '20px 0',
      sm: '30px 0',
      md: '50px 0',
      lg: '60px 0',
    }
  },
}

export default InsightsScreen
