import { billingFrequencies } from 'utils/payment/billingFrequencies'
import { possibleNrModels } from 'utils/payment/possibleNrModels'
import { subscriptionTypes } from 'utils/payment/subscriptionTypes'


const calculateType2Price = state => {
  const allPrices = state.payment?.subscriptionPrices?.response
  const pricesOfType = allPrices?.find(somePrices => somePrices?.Key === 2)

  // Monthly
  if (state.payment?.selectedBillingFrequency === billingFrequencies.monthly) {
    return {
      [possibleNrModels.oneModel]: pricesOfType?.Price1ProductMonthly,
      [possibleNrModels.threeModels]: pricesOfType?.Price3Products,
      [possibleNrModels.tenModels]: pricesOfType?.Price10Products,
    }[state.payment?.selectedNrModels]
  }

  // Yearly 
  return {
    [possibleNrModels.oneModel]: pricesOfType?.Price1ProductYearly,
    [possibleNrModels.threeModels]: pricesOfType?.Price3ProductsYearly,
    [possibleNrModels.tenModels]: pricesOfType?.Price10ProductsYearly,
  }[state.payment?.selectedNrModels]
}


const calculateType3Price = state => {

  const allPrices = state.payment?.subscriptionPrices?.response
  const pricesOfType = allPrices?.find(somePrices => somePrices?.Key === 3)

  // Monthly
  return {
    [possibleNrModels.oneModel]: pricesOfType?.Price1ProductMonthly,
    [possibleNrModels.threeModels]: pricesOfType?.Price3Products,
    [possibleNrModels.tenModels]: pricesOfType?.Price10Products,
  }[state.payment?.selectedNrModels]

  // this subscription does NOT have Yearly billing, only Monthly
}


export const calculatePrices = state => {
  return {
    [subscriptionTypes.type2]: calculateType2Price(state) || '',
    [subscriptionTypes.type3]: calculateType3Price(state) || '',
    [subscriptionTypes.type4]: '',
  }
}

