import React from 'react'
import ReturnsTemplate from 'screens/Market/ProductsTable/templates/ReturnsTemplate'
import RisksTemplate from 'screens/Market/ProductsTable/templates/RisksTemplate'
import ReturnBenchmarksTemplate from 'screens/Market/ProductsTable/templates/ReturnBenchmarksTemplate'
import RiskBenchmarksTemplate from 'screens/Market/ProductsTable/templates/RiskBenchmarksTemplate'
import { useSelector } from 'react-redux'
import { Templates } from 'utils/productUtils'
import MonthlyReturnPerformanceTemplate from 'screens/Market/ProductsTable/templates/MonthlyReturnPerformanceTemplate'
import MonthlyReturnPerformanceBenchmarkTemplate from 'screens/Market/ProductsTable/templates/MonthlyReturnPerformanceBenchmarkTemplate'
import MonthlyExcessReturnPerformanceTemplate from 'screens/Market/ProductsTable/templates/MonthlyExcessReturnPerformanceTemplate'
import ActionRowAboveProductsTable from 'screens/Market/ProductsTable/ActionRowAboveProductsTable'
import { Stack } from '@mui/material'

/** Assumes the products are given in the correct 
 * format for the current selectedTemplate. */
const ProductsTable = ({ products }) => {

  const selectedTemplate = useSelector(state => state.market.selectedTemplate)

  const templateComponents = {
    [Templates.returns]: <ReturnsTemplate {...{ products }} />,
    [Templates.returnsBenchmarks]: <ReturnBenchmarksTemplate {...{ products }} />,
    [Templates.risks]: <RisksTemplate {...{ products }} />,
    [Templates.riskBenchmarks]: <RiskBenchmarksTemplate {...{ products }} />,
    [Templates.monthlyReturnPerformance]: <MonthlyReturnPerformanceTemplate {...{ products }} />,
    [Templates.monthlyReturnPerformanceBenchmark]: <MonthlyReturnPerformanceBenchmarkTemplate {...{ products }} />,
    [Templates.monthlyExcessReturnPerformance]: <MonthlyExcessReturnPerformanceTemplate {...{ products }} />,

    // THESE TEMPLATES ARE COMMENTED BECAUSE 
    // THEY ARE NOT READY YET:
    // [Templates.mptStats]: <MPTStatsTemplate {...{ products }} />,
  }

  return <Stack>
    <ActionRowAboveProductsTable />
    {templateComponents[selectedTemplate]}
  </Stack>
}


export default ProductsTable
