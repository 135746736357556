import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import TableHeaderIcon from 'screens/Market/TableHeaderIcon'
import { Grid } from '@mui/material'


const CheckedAttribute = ({ text, desc, ...rest }) => <Grid
  sx={styles.checkBoxAndText}
  container
>
  <CheckBoxIcon sx={styles.checkBoxIcon} />
  <TableHeaderIcon
    title={text}
    desc={desc}
    isSmall
    sx={{ ...styles.checkboxLabel, ...(rest.sx) }}
  />
</Grid>


const styles = {
  checkBoxAndText: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',

    gap: '0.5rem',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  checkBoxIcon: {
    color: 'colors.almostPrimary1',
    fontSize: '1.75rem',
  },
  checkboxLabel: {
    fontSize: '1rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    lineHeight: '1.3em',
    marginTop: '0.2em',
  },
}

export default CheckedAttribute
