
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { redirectToCheckoutError, redirectToCheckoutStart, redirectToCheckoutSuccess } from 'store/paymentReducer'
import useStripePriceIds from 'utils/payment/useStripePriceIds'
import { isValidSubscriptionType } from 'utils/payment/subscriptionTypes'
import { useUserPermissions } from 'hooks/productApiHooks'
import appConfig from 'appConfig'




/**
 * The status of the relevant Stripe API call is stored 
 * in redux under state.payment.redirectToCheckout. 
 * 
 * @returns A function that is meant to be called onClick 
 * when the user presses the Buy button (with 1 valid 
 * parameter: subscriptionType). 
 */
const useRedicectToStripe = () => {

  const dispatch = useDispatch()
  const [stripeObject, setStripeObject] = useState()

  const stripePriceIds = useStripePriceIds()
  const userPermissions = useUserPermissions()

  const email = userPermissions?.response?.UserEmail

  useEffect(() => {
    loadStripe(appConfig.stripePublishableKey)
      .then(stripeObject => setStripeObject(stripeObject))
  }, [])



  const handlePayButtonClick = async (subscriptionType) => {

    if (!isValidSubscriptionType(subscriptionType)) {
      console.log('Error at handlePayButtonClick(): invalid subscriptionType: ', subscriptionType)
      return
    }

    const stripePriceId = stripePriceIds?.[subscriptionType]
    console.log('stripePriceId', stripePriceId)

    if (!stripePriceId) {
      console.log('Error at handlePayButtonClick(): stripePriceId cannot be empty')
      return
    }

    dispatch(redirectToCheckoutStart())

    try {

      const options = {
        lineItems: [{
          price: stripePriceId,
          quantity: 1,
        }],
        mode: 'subscription',
        successUrl: 'https://alphablock.org/thank-you',
        cancelUrl: 'https://alphablock.org/payment-canceled',
        customerEmail: email,
      }

      const stripe = await stripeObject

      stripe.redirectToCheckout(options)
        .then(
          response => {

            /** If this method fails, the result object 
             * will contain a localized error message 
             * in the response.message field. */

            if (response) {
              dispatch(redirectToCheckoutError(response))
            } else {
              dispatch(redirectToCheckoutSuccess())
            }
          }
        )
        .catch(
          error => dispatch(redirectToCheckoutError(error))
        )

    } catch (error) {
      dispatch(redirectToCheckoutError(error))
    }
  }

  return handlePayButtonClick
}




export default useRedicectToStripe
