import React, { useEffect, useState } from 'react'
import { Button, Grid, Stack, Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Loading from 'components/Loading'
import TableHead from '@mui/material/TableHead';
import { prettyNumber, } from 'utils/numberUtils'
import PortfolioMetrics from 'screens/ProductDetail/PortfolioMetrics'
import Text from 'components/Text'

const sections = [
  'Top 10 allocations',
  'Top 10 contributors',
  'Performance metrics',
]


const ProductsSlide4 = ({ productGuid }) => {

  const [selectedSection, setSelectedSection] = useState(sections[0])

  const runningComponents = useSelector(state =>
    state.market.runningComponents?.[productGuid]
  )

  const renderSelectedSection = () => {
    if (selectedSection === sections[0])
      return <TopProportionTable {...{ runningComponents }} />
    if (selectedSection === sections[1])
      return <TopPlTable {...{ runningComponents }} />
    if (selectedSection === sections[2])
      return <PortfolioMetrics {...{ productGuid }} />
  }

  return <Paper sx={styles.slideWrapper} >
    <Stack gap='20px'>
      <Text sx={styles.title}>Select table</Text>
      <Grid sx={styles.chartButtons} container>
        {sections.map((section, index) =>
          <Button
            sx={styles.chartButton}
            key={index}
            variant='contained'
            color={section === selectedSection ? 'primary' : 'secondary'}
            onClick={() => setSelectedSection(section)}
          >
            {section}
          </Button>
        )}
      </Grid>
      {renderSelectedSection()}
    </Stack>
  </Paper>
}


const TopProportionTable = ({ runningComponents }) => {

  const [rows, setRows] = useState()

  useEffect(() => {
    setRows(
      runningComponents?.length &&
      getTop10Proportion(runningComponents)
    )
  }, [runningComponents])

  return <>
    <Stack sx={styles.statisticsWrapper} direction='column'>
      <TableContainer>
        <Table sx={styles.table} aria-label='simple table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='center'>Proportion (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index} sx={styles.tableRow}>
                <TableCell>{row.Name}</TableCell>
                <TableCell align='center'>{prettyNumber(row.Proportion) || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    <Loading isHidden={rows?.length} />
  </>
}


const TopPlTable = ({ runningComponents }) => {

  const [rows, setRows] = useState()

  useEffect(() => {
    setRows(
      runningComponents?.length &&
      getTop10PL(runningComponents)
    )
  }, [runningComponents])

  return <>
    <Stack sx={styles.statisticsWrapper} direction='column'>
      <TableContainer>
        <Table sx={styles.table} aria-label='simple table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='center'>P&L (%)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={index} sx={styles.tableRow}>
                <TableCell>{row.Name}</TableCell>
                <TableCell align='center'>{prettyNumber(row.PL) || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    <Loading isHidden={rows?.length} />
  </>
}


const styles = {
  slideWrapper: {
    gap: '20px',
    width: 'min(850px, 95%)',
    backgroundColor: 'colors.darkGrey2',
    padding: '3%',
    minHeight: '59.4vh',
  },
  chartButtons: {
    display: 'grid',
    gridTemplateColumns:
      'repeat( auto-fill, minmax(min(210px, 100%), 1fr) )',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '10px',
    gap: '24px',
  },
  chartButton: {
    textTransform: 'uppercase',
    fontSize: '1.125rem',
    fontWeight: '600',
  },
  sectionWrapper: {
    width: 'min(600px, 95%)',
  },
  statisticsWrapper: {
    margin: '40px 0',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.5rem',
    fontWeight: '600',
  },
  table: {
    borderBottom: '1px solid',
    borderBottomColor: 'colors.lightGrey9',
    '& .MuiTableCell-head.MuiTableCell-root': {
      borderBottom: '1px solid',
      borderBottomColor: 'colors.lightGrey9',
      borderTop: '1px solid',
      borderTopColor: 'colors.lightGrey9',
      fontWeight: '700',
      color: 'colors.main',
      fontSize: '0.84375rem',
      textTransform: 'uppercase',
    },
    '& .MuiTableCell-root': {
      padding: '0.5em 0.3em',
      fontSize: '1.125rem',
      fontWeight: '400',
      wordBreak: 'break-word',
      color: 'colors.darkGrey8',
      border: 'none',
      '&:first-of-type': { paddingLeft: 0, },
      '&:last-child': { paddingRight: 0, },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },

}


const getTop10Proportion = runningComponents =>
  runningComponents?.map(
    component => ({
      Name: component.Name,
      Proportion: component.Proportion,
    })
  )?.sort?.(
    (item1, item2) =>
      parseFloat(item2.Proportion) -
      parseFloat(item1.Proportion)
  )?.slice(0, 10)


const getTop10PL = runningComponents =>
  runningComponents?.map(
    component => ({
      Name: component.Name,
      PL: component.PL,
    })
  )?.sort?.(
    (item1, item2) =>
      parseFloat(item2.PL) -
      parseFloat(item1.PL)
  )?.slice(0, 10)


export default ProductsSlide4
