import axios from 'axios'
import { myAxios, printError } from 'utils/apiUtils'
import appConfig from 'appConfig'
import { clearMyPortfolios, clearUserSpecificMarketData } from 'store/marketReducer'
import { apiStatuses } from 'utils/apiStatuses'
import { setIsMenuOpen, setIsSidebarOpen } from 'store/modalsReducer'

const apiBaseUrl = appConfig.apiBaseUrl

const LOGIN_START = 'user/login/LOGIN_START'
const LOGIN_SUCCESS = 'user/login/LOGIN_SUCCESS'
const LOGIN_ERROR = 'user/login/LOGIN_ERROR'
const LOGOUT = 'user/logout'
const RENEW_TOKEN = 'user/RENEW_TOKEN'

const SIM_GEN_START = 'user/simGeneration/SIM_GEN_START'
const SIM_GEN_CANCEL = 'user/simGeneration/SIM_GEN_CANCEL'
const SIM_GEN_FINALIZE = 'user/simGeneration/SIM_GEN_FINALIZE'
const SIM_GEN_SET_SELECTED = 'user/simGeneration/SIM_GEN_SET_SELECTED'

const PORTF_GEN_START = 'user/portfGeneration/PORTF_GEN_START'
const PORTF_GEN_CANCEL = 'user/portfGeneration/PORTF_GEN_CANCEL'
const PORTF_GEN_FINALIZE = 'user/portfGeneration/PORTF_GEN_FINALIZE'
const PORTF_GEN_SET_SELECTED = 'user/portfGeneration/PORTF_GEN_SET_SELECTED'
const PORTF_GEN_API_START = 'user/portfGeneration/PORTF_GEN_API_START'
const PORTF_GEN_API_SUCCESS = 'user/portfGeneration/PORTF_GEN_API_SUCCESS'
const PORTF_GEN_API_ERROR = 'user/portfGeneration/PORTF_GEN_API_ERROR'

const PORTF_MAPPING_START = 'user/portfMapping/PORTF_MAPPING_START'
const PORTF_MAPPING_CANCEL = 'user/portfMapping/PORTF_MAPPING_CANCEL'
const PORTF_MAPPING_FINALIZE = 'user/portfMapping/PORTF_MAPPING_FINALIZE'
const PORTF_MAPPING_SET_SELECTED = 'user/portfMapping/PORTF_MAPPING_SET_SELECTED'
const PORTF_MAPPING_API_START = 'user/portfMapping/PORTF_MAPPING_API_START'
const PORTF_MAPPING_API_SUCCESS = 'user/portfMapping/PORTF_MAPPING_API_SUCCESS'
const PORTF_MAPPING_API_ERROR = 'user/portfMapping/PORTF_MAPPING_API_ERROR'

const RESET_ALL_SELECTIONS = 'user/RESET_ALL_SELECTIONS'
const CLEAR_PERMISSIONS = 'user/CLEAR_PERMISSIONS'

const REGISTRATION_START = 'user/registration/REGISTRATION_START'
const REGISTRATION_SUCCESS = 'user/registration/REGISTRATION_SUCCESS'
const REGISTRATION_ERROR = 'user/registration/REGISTRATION_ERROR'

const FORGOT_PASSWORD_START = 'user/forgotP/FORGOT_PASSWORD_START'
const FORGOT_PASSWORD_SUCCESS = 'user/forgotP/FORGOT_PASSWORD_SUCCESS'
const FORGOT_PASSWORD_ERROR = 'user/forgotP/FORGOT_PASSWORD_ERROR'

const FETCH_PERMISSIONS_START = 'user/permissions/FETCH_PERMISSIONS_START'
const FETCH_PERMISSIONS_SUCCESS = 'user/permissions/FETCH_PERMISSIONS_SUCCESS'
const FETCH_PERMISSIONS_ERROR = 'user/permissions/FETCH_PERMISSIONS_ERROR'


const SUBSCRIBE_TO_NEWSLETTER_START = 'user/newsletter/SUBSCRIBE_TO_NEWSLETTER_START'
const SUBSCRIBE_TO_NEWSLETTER_SUCCESS = 'user/newsletter/SUBSCRIBE_TO_NEWSLETTER_SUCCESS'
const SUBSCRIBE_TO_NEWSLETTER_ERROR = 'user/newsletter/SUBSCRIBE_TO_NEWSLETTER_ERROR'


const initialSelectionState = {
  isSelectingProduct: false,
  currentSelectedProduct: null,
  finalSelectedProduct: null,
}

const selectionStates = {
  getInitial: () => ({ ...initialSelectionState }),

  getStarted: () => ({
    ...initialSelectionState,
    isSelectingProduct: true,
  }),

  getSelected: currentSelectedProduct => ({
    ...initialSelectionState,
    isSelectingProduct: true,
    currentSelectedProduct,
  }),

  getFinalized: finalSelectedProduct => ({
    ...initialSelectionState,
    finalSelectedProduct,
  }),
}



const initialState = {
  token: null,
  loginStatus: {
    isLoading: false,
    isError: false,
    errorCode: null,
  },
  registration: apiStatuses.getInitial(),
  forgotPassword: apiStatuses.getInitial(),
  subscribeToNewsletter: apiStatuses.getInitial(),
  portfGenApiStatus: apiStatuses.getInitial(),
  portfMappingApiStatus: apiStatuses.getInitial(),
  permissionsApi: apiStatuses.getInitial(),

  simGeneration: selectionStates.getInitial(),
  portfGeneration: selectionStates.getInitial(),
  portfMapping: selectionStates.getInitial(),

}

const userReducer = (state = initialState, action) => {
  const payload = action?.payload

  switch (action?.type) {
    case LOGIN_START: return {
      ...state,
      token: null,
      loginStatus: {
        isLoading: true,
        isError: false,
        errorCode: null,
      },
    }
    case LOGIN_SUCCESS: return {
      ...state,
      token: payload,
      loginStatus: {
        isLoading: false,
        isError: false,
        errorCode: null,
      },
    }
    case LOGIN_ERROR: return {
      ...state,
      token: null,
      loginStatus: {
        isLoading: false,
        isError: true,
        errorCode: payload,
      },
    }


    case LOGOUT: return {
      ...state,
      token: null,
      loginStatus: {
        isLoading: false,
        isError: false,
        errorCode: null,
      },
      portfGenApiStatus: apiStatuses.getInitial(),
      simGeneration: selectionStates.getInitial(),
      portfGeneration: selectionStates.getInitial(),
      permissionsApi: apiStatuses.getInitial(),
    }
    case RENEW_TOKEN: return {
      ...state, token: payload,
    }


    case SIM_GEN_START: return { ...state, simGeneration: selectionStates.getStarted(), }
    case SIM_GEN_CANCEL: return { ...state, simGeneration: selectionStates.getInitial(), }
    case SIM_GEN_SET_SELECTED: return { ...state, simGeneration: selectionStates.getSelected(payload), }
    case SIM_GEN_FINALIZE: return {
      ...state, simGeneration:
        selectionStates.getFinalized(state.simGeneration?.currentSelectedProduct),
    }


    case PORTF_GEN_START: return { ...state, portfGeneration: selectionStates.getStarted(), }
    case PORTF_GEN_CANCEL: return { ...state, portfGeneration: selectionStates.getInitial(), }
    case PORTF_GEN_SET_SELECTED: return { ...state, portfGeneration: selectionStates.getSelected(payload), }
    case PORTF_GEN_FINALIZE: return {
      ...state, portfGeneration: selectionStates.getFinalized(state.portfGeneration?.currentSelectedProduct
      ),
    }


    case PORTF_GEN_API_START: return {
      ...state,
      portfGeneration: selectionStates.getInitial(),
      portfGenApiStatus: apiStatuses.getStarted(),
    }
    case PORTF_GEN_API_SUCCESS: return { ...state, portfGenApiStatus: apiStatuses.getSuccess(payload), }
    case PORTF_GEN_API_ERROR: return { ...state, portfGenApiStatus: apiStatuses.getError(payload), }


    case PORTF_MAPPING_START: return { ...state, portfMapping: selectionStates.getStarted(), }
    case PORTF_MAPPING_CANCEL: return { ...state, portfMapping: selectionStates.getInitial(), }
    case PORTF_MAPPING_SET_SELECTED: return { ...state, portfMapping: selectionStates.getSelected(payload), }
    case PORTF_MAPPING_FINALIZE: return {
      ...state, portfMapping: selectionStates.getFinalized(state.portfMapping?.currentSelectedProduct
      ),
    }


    case PORTF_MAPPING_API_SUCCESS: return { ...state, portfMappingApiStatus: apiStatuses.getSuccess(), }
    case PORTF_MAPPING_API_ERROR: return { ...state, portfMappingApiStatus: apiStatuses.getError(payload), }
    case PORTF_MAPPING_API_START: return {
      ...state, portfMapping: {
        ...state.portfMapping,
        finalSelectedProduct: null,
      },
      portfMappingApiStatus: apiStatuses.getStarted(),
    }


    case RESET_ALL_SELECTIONS: return {
      ...state,
      simGeneration: selectionStates.getInitial(),
      portfGeneration: selectionStates.getInitial(),
      portfMapping: selectionStates.getInitial(),
    }


    case REGISTRATION_START: return { ...state, registration: apiStatuses.getStarted(), }
    case REGISTRATION_ERROR: return { ...state, registration: apiStatuses.getError(payload?.errorCode), }
    case REGISTRATION_SUCCESS: return { ...state, registration: apiStatuses.getSuccess(), }

    case FORGOT_PASSWORD_START: return { ...state, forgotPassword: apiStatuses.getStarted(), }
    case FORGOT_PASSWORD_SUCCESS: return { ...state, forgotPassword: apiStatuses.getSuccess(payload), }
    case FORGOT_PASSWORD_ERROR: return { ...state, forgotPassword: apiStatuses.getError(payload), }

    case SUBSCRIBE_TO_NEWSLETTER_START: return { ...state, subscribeToNewsletter: apiStatuses.getStarted(), }
    case SUBSCRIBE_TO_NEWSLETTER_SUCCESS: return { ...state, subscribeToNewsletter: apiStatuses.getSuccess(payload), }
    case SUBSCRIBE_TO_NEWSLETTER_ERROR: return { ...state, subscribeToNewsletter: apiStatuses.getError(payload), }

    case FETCH_PERMISSIONS_START: return { ...state, permissionsApi: apiStatuses.getStarted(), }
    case FETCH_PERMISSIONS_ERROR: return { ...state, permissionsApi: apiStatuses.getError(payload?.errorCode), }
    case FETCH_PERMISSIONS_SUCCESS: return { ...state, permissionsApi: apiStatuses.getSuccess(payload), }
    case CLEAR_PERMISSIONS: return { ...state, permissionsApi: apiStatuses.getInitial(), }


    default: return state
  }
}



export const subscribeToNewsletter = email => dispatch => {

  if (!email) {
    console.error('Error at subscribeToNewsletter(): email cannot be empty. ')
    return
  }

  dispatch({ type: SUBSCRIBE_TO_NEWSLETTER_START })

  myAxios({
    axiosCallerFn: () => axios.get(apiBaseUrl + '/signup/newsletter', {
      params: { email: email || '', },
    }),
    onSuccess: () => dispatch({ type: SUBSCRIBE_TO_NEWSLETTER_SUCCESS }),
    onError: error => {
      dispatch({ type: SUBSCRIBE_TO_NEWSLETTER_ERROR, payload: error?.response?.data || null })
      printError('subscribeToNewsletter()', error, dispatch)
    },
    dispatch,
  })
}



export const resetAllSelections = () => ({ type: RESET_ALL_SELECTIONS })
export const clearUserPermissions = () => ({ type: CLEAR_PERMISSIONS })


export const fetchUserPermissions = token => dispatch => {
  dispatch({ type: FETCH_PERMISSIONS_START })

  myAxios({
    axiosCallerFn: axiosToken => axios.get(apiBaseUrl + '/portfolio/user-permissions', {
      headers: { "Authorization": `Bearer ${axiosToken}` },
    }),
    onSuccess: response => dispatch({
      type: FETCH_PERMISSIONS_SUCCESS,
      payload: response?.data
    }),
    onError: error => {
      dispatch({ type: FETCH_PERMISSIONS_START, error })
      printError('fetchUserPermissions()', error, dispatch)
    },
    token,
    dispatch,
  })
}


export const renewToken = ({ token, onSuccess, onError }) => dispatch => {

  axios.post(apiBaseUrl + `/signin/renew-token?token=${token}`)
    .then(response => {
      const newToken = response?.data

      if (!newToken) throw new Error('Failed to renew token. ')

      dispatch({ type: RENEW_TOKEN, payload: newToken })
      onSuccess(newToken)

    })
    .catch(error => {

      printError('renewToken()', error, dispatch)
      dispatch(logout())
      onError(error)

    })

}


export const registration = ({ password, confirmPassword, email }) => dispatch => {

  dispatch({ type: REGISTRATION_START })

  axios.post(apiBaseUrl + '/signup/registration', {
    UserPassword: password,
    ConfirmPassword: confirmPassword,
    Email: email,
    CompanyName: '',
    CompanyAddress: '',
    FirstName: '-',
    LastName: '-',
    Telephone: '',
    AssetsUnderManagement: '',
    AssetsCoverage: '',
    AddressCountry: '',
    AddressCounty: '',
    AddressCity: '',
    AddressStreet1: '',
    AddressStreet2: '',
    AddressPostcode: '',
    Newsletter: false,
  })
    .then(response => {
      const message = response?.data

      if (message === 'Registration success. Please confirm') {
        dispatch({ type: REGISTRATION_SUCCESS })
        return
      }

      const getErrorCode = () => {
        if (message === 'The specified string is not in the form required for an e-mail address.')
          return 'invalid-email-syntax'

        if (message?.includes?.(
          'duplicate key value violates unique constraint'
        )) return 'email-already-registered'

        return null
      }

      dispatch({
        type: REGISTRATION_ERROR, payload: {
          errorCode: getErrorCode()
        }
      })
    })
    .catch(error => {
      dispatch({ type: REGISTRATION_ERROR })
      printError('registration()', error)
    })
}

export const sendForgotPassword = email => dispatch => {

  if (!email) {
    console.error('Error at sendForgotPassword(): email cannot be empty. ')
    return
  }

  dispatch({ type: FORGOT_PASSWORD_START })

  myAxios({
    axiosCallerFn: () => axios.post(apiBaseUrl + '/signin/forgot-password', null, {
      params: { emailAddress: email || '', },
    }),
    onSuccess: response => {
      const message = response?.data

      if (message === 'The new password has been sent to the provided email.') {
        dispatch({ type: FORGOT_PASSWORD_SUCCESS })
        return
      }

      const errorMessage = message === 'Email not found.'
        ? 'email-not-found'
        : message

      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: errorMessage
      })

    },
    onError: error => {
      dispatch({ type: FORGOT_PASSWORD_ERROR, payload: error?.response?.data || null })
      printError('sendForgotPassword()', error, dispatch)
    },
    dispatch,
  })
}


export const simGenStart = () => ({ type: SIM_GEN_START })
export const simGenCancel = () => ({ type: SIM_GEN_CANCEL })
export const simGenFinalize = () => ({ type: SIM_GEN_FINALIZE })
export const simGenSetSelected = product => ({ type: SIM_GEN_SET_SELECTED, payload: product })
export const portfGenStart = () => ({ type: PORTF_GEN_START })
export const portfGenCancel = () => ({ type: PORTF_GEN_CANCEL })
export const portfGenSetSelected = product => ({ type: PORTF_GEN_SET_SELECTED, payload: product })
export const portfGenFinalize = () => dispatch => {
  dispatch(({ type: PORTF_GEN_FINALIZE }))
}

export const portfMappingStart = () => ({ type: PORTF_MAPPING_START })
export const portfMappingCancel = () => ({ type: PORTF_MAPPING_CANCEL })
export const portfMappingSetSelected = product => ({ type: PORTF_MAPPING_SET_SELECTED, payload: product })
export const portfMappingFinalize = () => dispatch => {
  dispatch(({ type: PORTF_MAPPING_FINALIZE }))
}



export const callPortfolioGenerationApi = ({ productGuid, token }) => dispatch => {
  dispatch({ type: PORTF_GEN_API_START })

  myAxios({
    axiosCallerFn: axiosToken => axios.post(apiBaseUrl + '/portfolio/generate', null, {
      params: { portfolioTypeGuid: productGuid, },
      headers: { "Authorization": `Bearer ${axiosToken}` },
    }),
    onSuccess: response => {
      console.log('Waiting 15 seconds before re-fetching my portfolios...')
      const delay = 15000 // 15 seconds

      setTimeout(() => {
        /** By clearing myPortfolios, I assume that the useMyPortfolios custom 
         * hook will refetch the data, including the new portfolio that has 
         * just been generated. The API typically needs 10 seconds to notice 
         * the new portfolio and include it in its' response. Therefore I inserted 
         * a delay before calling it. */
        dispatch({ type: PORTF_GEN_API_SUCCESS, payload: response.data })
        dispatch(clearMyPortfolios())
      }, delay);
    },
    onError: error => {
      dispatch({ type: PORTF_GEN_API_ERROR, payload: error?.response?.data || null })
      printError('callPortfolioGenerationApi()', error, dispatch)
    },
    token,
    dispatch,
  })
}




export const callPortfolioMappingApi = ({ productGuid, token }) => dispatch => {
  dispatch({ type: PORTF_MAPPING_API_START })

  myAxios({
    axiosCallerFn: axiosToken => axios.get(apiBaseUrl + `/portfolio/map?productGuid=${productGuid}`, {
      headers: { "Authorization": `Bearer ${axiosToken}` },
    }),
    onSuccess: response => {
      dispatch({
        type: PORTF_MAPPING_API_SUCCESS,
        payload: response?.data
      })

      /** By clearing myPortfolios, I assume that the 
       * useMyPortfolios custom hook will refetch the data.  */
      dispatch(clearMyPortfolios())
      dispatch(clearUserPermissions())
    },
    onError: error => {
      dispatch({ type: PORTF_MAPPING_API_ERROR, error })
      printError('callPortfolioMappingApi()', error, dispatch)
    },
    token,
    dispatch,
  })
}



export const login = (email, password) => dispatch => {

  dispatch({ type: LOGIN_START })

  axios.post(apiBaseUrl + '/signin/login', {
    UserPassword: password,
    UserName: email,
    RememberMe: true,
  })
    .then(response => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data,
      })
      dispatch(clearUserSpecificMarketData())
    })
    .catch(error => {
      dispatch({
        type: LOGIN_ERROR,
        payload: error?.response?.data || null,
      })
      printError('login()', error)
    })
}

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT })
  dispatch(clearUserSpecificMarketData())
  dispatch(setIsMenuOpen(false))
  dispatch(setIsSidebarOpen(false))

}


export default userReducer
