import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import NotFound from 'screens/NotFound'
import LandingScreen from 'screens/Landing/LandingScreen/LandingScreen'
import GetStartedScreen from 'screens/GetStarted/GetStartedScreen'
import ProductsScreen from 'screens/ProductsSlider/ProductsSliderScreen'
import ToolsScreen from 'screens/ToolsSlider/ToolsSliderScreen'
import MarketScreen from 'screens/Market/MarketScreen'
import ProductScreen from 'screens/ProductDetail/ProductDetailScreen'
import ResearchSliderScreen from 'screens/ResearchSlider/ResearchSliderScreen'

import { useLocation } from 'react-router'
import AboutUsScreen from 'screens/AboutUs/AboutUsScreen'
import SimulationsScreen from 'screens/Market/SimulationsScreen'
import MyPortfoliosScreen from 'screens/Market/MyPortfoliosScreen'
import InsightsScreen from 'screens/Insights/InsightsScreen'
import ArticleDetailScreen from 'screens/Insights/ArticleDetailScreen'
import PricingScreen from 'screens/Pricing/PricingScreen'
import DisclaimerScreen from 'screens/ToolsSlider/DisclaimerScreen'
import PrivacyPolicyScreen from 'screens/PrivacyPolicyScreen'
import ThankYouScreen from 'screens/Pricing/ThankYouScreen'
import PaymentCanceled from 'screens/Pricing/PaymentCanceledScreen'
import ContactScreen from 'screens/ToolsSlider/ContactScreen'
import FaqScreen from 'screens/FaqScreen'
import LoginScreen from 'screens/Authentication/LoginScreen'
import RegistrationScreen from 'screens/Authentication/RegistrationScreen'
import ForgotPasswordScreen from 'screens/Market/ForgotPasswordScreen'


function Router() {

  // Clicking on a link does not scroll to the top.
  // Correct for this weird behavior of react-router:
  const { pathname } = useLocation()
  useEffect(() => window.scrollTo(0, 0), [pathname])

  return <Routes>
    <Route exact path='/' element={<LandingScreen />} />

    <Route exact path='/get-started' element={<GetStartedScreen />} />
    <Route path='/research' element={<ResearchSliderScreen />} />
    <Route path='/products/:productGuid' element={<ProductsScreen />} />
    <Route path='/tools' element={<ToolsScreen />} />

    <Route path='/about-us' element={<AboutUsScreen />} />
    <Route path='/contact' element={<ContactScreen />} />
    <Route path='/faq' element={<FaqScreen />} />
    <Route path='/disclaimer' element={<DisclaimerScreen />} />
    <Route path='/privacy-policy' element={<PrivacyPolicyScreen />} />
    <Route path='/insights' element={<InsightsScreen />} />
    <Route path='/insights/:articleId' element={<ArticleDetailScreen />} />

    <Route path='/product/:productGuid' element={<ProductScreen />} />

    <Route path='/market' element={<MarketScreen />} />
    <Route path='/simulations' element={<SimulationsScreen />} />
    <Route path='/simulations/:productGuid' element={<SimulationsScreen />} />
    <Route path='/portfolios' element={<MyPortfoliosScreen />} />

    <Route path='/pricing' element={<PricingScreen />} />
    <Route path='/thank-you' element={<ThankYouScreen />} />
    <Route path='/payment-canceled' element={<PaymentCanceled />} />

    <Route path='/login' element={<LoginScreen />} />
    <Route path='/registration' element={<RegistrationScreen />} />
    <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
    <Route element={<NotFound />} />
  </Routes >

}

export default Router
