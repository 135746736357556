import React from 'react'
import Text from 'components/Text'
import styles from 'screens/GetStarted/components/ToolsCard/ToolsCard.module.css'

export default function ToolsCard({ imageUrl, label }) {
  return <div className={styles.card}>
    <div className={styles.label}>
      <Text size='16px' fontWeight='medium' mr='20px'>
        {label}
      </Text>
    </div>
    <img alt='tralio logo' src={imageUrl} style={{ maxWidth: '100%' }} height='auto' />
  </div>
}
