import { Grid, Stack } from '@mui/material'
import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Loading from 'components/Loading'
import TableHead from '@mui/material/TableHead'
import { useState } from 'react'


import PropTypes from 'prop-types'
import TablePagination from '@mui/material/TablePagination'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import MyLink from 'components/MyLink'




const MyTable = ({
  headCells,
  rows,
  initialSortBy,
  disableSort = false,
  tableStyle,
  paginationStyle,
  highlightEventRows,
  rowsPerPageOptions = [15, 50, 100],
  emptyRowHeight = '32px',
}) => {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState(initialSortBy || headCells?.[0]?.id)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0])


  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }


  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const processedRows = rows?.slice()
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)


  const paginationProps = {
    rowsPerPageOptions: rowsPerPageOptions,
    component: "div",
    count: rows?.length || 0,
    rowsPerPage: rowsPerPage,
    page: page,
    onPageChange: (_, newPage) => setPage(newPage),
    onRowsPerPageChange: handleChangeRowsPerPage,
    sx: styles.pagination,
  }


  const tableHeadProps = {
    order: order,
    orderBy: orderBy,
    headCells: headCells,
    onRequestSort: handleRequestSort,
    rowCount: rows?.length || 0,
    disableSort,
  }


  const isLoading = Boolean(!rows?.length)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0

  const highlightedRowStyle = highlightEventRows && styles.highlightedRow

  return <Stack>
    <TableContainer >
      <Table sx={{ ...styles.table, ...tableStyle }} size='medium'>
        <EnhancedTableHead {...tableHeadProps} />
        <TableBody sx={styles.tableBody}>
          {isLoading &&
            <TableRow>
              <TableCell colSpan={headCells?.length}>
                <Loading />
              </TableCell>
            </TableRow>
          }
          {processedRows?.map((row, index) =>
            <TableRow
              sx={{ ...highlightedRowStyle, ...styles.tableRow }}
              tabIndex={-1}
              key={index}
            >
              <MyCells {...{ headCells, row }} />
            </TableRow>
          )}
          {emptyRows > 0 && (
            <TableRow style={{

              height: `calc(${emptyRowHeight}*${emptyRows})`,

            }} >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    <Grid sx={{ ...styles.paginationRow, ...paginationStyle }} container>
      <Paper sx={styles.paginationWrapper}>
        <TablePagination {...paginationProps} />
      </Paper>
    </Grid>
  </Stack>
}



const MyCells = ({ headCells, row }) => {

  const getAlignment = columnId => headCells?.find(
    someColumn => someColumn?.id === columnId
  )?.align

  return <>
    {headCells?.map(headCell => {

      const cellInner = headCell?.formatter?.(row?.[headCell?.id])
        || row?.[headCell?.id] || '-'

      return <TableCell
        align={getAlignment(headCell?.id)}
        key={Math.random()}
      >
        {row?.href
          ? <MyLink to={row?.href}>{cellInner}</MyLink>
          : cellInner
        }
      </TableCell>
    }
    )}
  </>
}



const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
  disableSort

}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const headStyle = {
    '& .MuiTableSortLabel-icon': {
      display: {
        xs: 'none',
        xl: disableSort ? 'none' : 'inherit',
      }
    }
  }

  return <TableHead sx={headStyle}>
    <TableRow>
      {headCells?.map(headCell => (
        <TableCell
          key={headCell?.id}
          align={headCell?.align}
          sortDirection={orderBy === headCell?.id ? order : false}
        >
          <TableSortLabel
            active={orderBy === headCell?.id}
            direction={orderBy === headCell?.id ? order : 'asc'}
            onClick={disableSort ? null : createSortHandler(headCell?.id)}
          >
            {headCell?.label}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}



const styles = {
  table: {
    minWidth: 'unset',
    '& .MuiTableCell-head.MuiTableCell-root': {
      color: 'colors.lightGrey1',
      fontSize: '0.84375rem',
      fontWeight: '400',
      fontFamily: 'Montserrat',
      borderBottom: '1px solid',
      borderBottomColor: 'colors.lightGrey9',
      borderTop: '1px solid',
      borderTopColor: 'colors.lightGrey9',
      marginBottom: '30px',
      padding: '0.9em 0.2em',
    },
    '& .MuiTableCell-root': {
      border: 'none',
      fontSize: '0.84375rem',
      fontFamily: 'Montserrat',
      color: 'colors.main',
      fontWeight: '600',
      padding: '0.5em 0.2em',
      wordBreak: 'break-word',
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },
  highlightedRow: {
    '&.MuiTableRow-root:nth-of-type(2n)': {
      backgroundColor: 'colors.lightGrey4',
    },
  },

  paginationRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',

  },
  paginationWrapper: {
    paddingRight: '12px',
    marginTop: '10px',

  },
  pagination: {
    paddingRight: '24px',
    '& *': { fontSize: '1rem', },
  },
}


const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1
  if (b[orderBy] > a[orderBy]) return 1
  return 0
}

const getComparator = (order, orderBy) => order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy)


export default MyTable

