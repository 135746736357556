import React, { useState } from 'react'
import styles from 'screens/ToolsSlider/components/ToolsNavigation/ToolsNavigation.module.css'
import HorizontalScrollCircle from 'screens/GetStarted/components/Pagination/HorizontalScrollCircle'
import HorizontalScrollDivider from 'screens/GetStarted/components/Pagination/HorizontalScrollDivider'
import { useLocation } from 'react-router'
import toolsSections from 'screens/ToolsSlider/sections/toolsSections'


const ToolsNavigation = () => {

  const { hash } = useLocation()
  const [isLabelVisible, setIsLabelVisible] = useState(false)

  const isActive = (section, index) =>
    hash ? (section.id === hash?.substring(1)) : index === 0


  const dotProps = toolsSections?.map((section, index) => ({
    isActive: isActive(section, index),
    linkTo: `/tools#${section.id}`,
    label: section.label,
    setIsLabelVisible,
  }))


  return <div className={styles.container} >
    <HorizontalScrollCircle {...dotProps[0]} />
    <HorizontalScrollCircle {...dotProps[1]} />
    <HorizontalScrollDivider sectionTitle='Tool1' {...{ isLabelVisible }} />
    <HorizontalScrollCircle {...dotProps[2]} />
    <HorizontalScrollCircle {...dotProps[3]} />
    <HorizontalScrollDivider sectionTitle='Tool2' {...{ isLabelVisible }} />
    <HorizontalScrollCircle {...dotProps[4]} />
    <HorizontalScrollCircle {...dotProps[5]} />
  </div>
}

export default ToolsNavigation
