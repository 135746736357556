
import { Grid } from '@mui/material'
import Text from 'components/Text'
import ProductValuesChart from 'screens/ProductDetail/ProductValuesChart'
import PortfolioMetrics from 'screens/ProductDetail/PortfolioMetrics'
import HistoricalPerformanceTable from 'screens/ProductDetail/HistoricalPerformanceTable'
import RunningComponents from 'screens/ProductDetail/RunningComponents'
import ProductDrawdownTables from 'screens/ProductDetail/ProductDrawdownTables'
import ProductDrawdownChart from 'screens/ProductDetail/ProductDrawdownChart'
import ProductPerformance from 'screens/ProductDetail/ProductPerformance'
import ProductComparatives from 'screens/ProductDetail/ProductComparatives'
import ProductSimulationsChart from 'screens/ProductDetail/ProductSimulationsChart'
import ProductSimulationsTable from 'screens/ProductDetail/ProductSimulationsTable'
import SimulationsDateFilter from 'screens/ProductDetail/SimulationsDateFilter'
import { usePerformance, useRunningComponents, useDrawdowns, usePerfMonthlySummary, useComparatives, useSimulations, useMyPortfolios, useRebalanceInfo, useBasicRestrictions, } from 'hooks/productApiHooks'
import RebalanceTable from 'screens/ProductDetail/RebalanceTable'
import { useSelector } from 'react-redux'
import ProductDataDownload from 'screens/ProductDetail/ProductDataDownload'
import { LoginForm } from 'screens/Authentication/LoginScreen'
import ProductFactsheetTable from 'screens/ProductDetail/ProductFactsheetTable'
import FactsheetBrickGraph from 'screens/ProductDetail/FactsheetGraphsSection'


export const OverviewTab = ({ productGuid }) => {
  useRebalanceInfo(productGuid)

  const basicRestrictions = useSelector(
    state => state.market.basicRestrictions?.[productGuid]
  )

  return <>
    <Text sx={styles.sectionTitle}>
      Performance
    </Text>
    <Grid sx={styles.valueChartAndStats}>
      <ProductValuesChart {...{ productGuid }} />
      <PortfolioMetrics {...{ productGuid }} />
    </Grid>

    {basicRestrictions?.IsTabRebalanceVisible && <>
      <Text sx={styles.sectionTitle}>Rebalance Instructions</Text>
      <Text sx={styles.sectionDescription}>
        Exceptional & Rich Indices rebalance (readjust) weights once in 12 months. Exceptional & Rich Styles rebalance (readjust) weights once a month, while Exceptional & Rich Strategies enter and exit components monthly or quarterly based on their respective classification. A rebalance can happen because of a corporate action, a pre-set maximum value constraint, or as part of a component's systematic periodical review. Below you will see the rebalance list with the buy or sell action with a respective quantity. All quantities assume fractional units connected to the inception portfolio value of 1 unit. The fractional units change as the component and portfolio value change in time.
      </Text>
      <RebalanceTable {...{ productGuid }} />
    </>}

  </>
}

export const FactsheetTab = ({ productGuid }) => {
  useRunningComponents(productGuid)


  return <>
    <Text sx={styles.sectionTitle}>Factsheet</Text>
    <Text sx={styles.sectionDescription}>
      Exceptional & Rich Indices, Styles, and Strategies are designed to
      outperform their respective benchmarks. The methodology is based on
      a universal framework that considers markets as natural dynamic
      systems driven by the statistical forces of reversion and diversion
      which create unique statistical factors that deliver risk-weighted
      outperformance across assets, regions, styles, and instruments.
    </Text>

    <ProductFactsheetTable {...{ productGuid }} />

    <Text sx={styles.sectionTitle}>Factor ( VCG - Value Core Growth )</Text>
    <FactsheetBrickGraph
      {...{ productGuid, descriptionSx: styles.sectionDescription }}
    />
  </>
}
export const PerformancesTab = ({ productGuid }) => {
  usePerfMonthlySummary(productGuid)
  usePerformance(productGuid)

  return <>
    <Text sx={styles.sectionTitle}>Performance Metrics</Text>
    <HistoricalPerformanceTable {...{ productGuid }} />

    <Text sx={styles.sectionTitle}>Performance</Text>
    <Text sx={styles.sectionDescription}>
      The table illustrates the relative and absolute performance
      across various parameters*. For example the Performance(%) of
      Portfolio since various points in time, Current Portfolio Cash(%)
      and Average Cash (%) in the Portfolio, Average Holding Period
      (Days), Average number of Trades per year, Current Portfolio
      Value of the funds invested at the start of the Portfolio,
      Annualized (%) Returns and Annualized Standard Deviation (%).
    </Text>
    <ProductPerformance {...{ productGuid }} />
  </>
}

export const RunningComponentsTab = ({ productGuid }) => {
  useRunningComponents(productGuid)
  return <>
    <Text sx={styles.sectionTitle}>Running Components</Text>
    <Text sx={styles.sectionDescription}>
      These are the current running components of the Portfolio. The
      table fields include Date (the selection date of the stock),
      Symbol, Symbol Name, Current Price, Entry Price, P&L(%),
      Proportion(%), Holding Period (Days) and the Current Ranking
      of the stocks in the selection universe.
    </Text>
    <RunningComponents  {...{ productGuid }} />
  </>
}

export const ComparativesTab = ({ productGuid }) => {
  useComparatives(productGuid)
  return <>
    <Text sx={styles.sectionTitle}>Sector Composition</Text>
    <Text sx={styles.sectionDescription}>
      The pie chart below illustrates the sector
      composition for the portfolio.
    </Text>
    <ProductComparatives {...{ productGuid }} />
  </>
}

export const SimulationsTab = ({ productGuid }) => {
  useSimulations(productGuid)
  const myPortfolios = useMyPortfolios()
  /** TODO: this function is duplicated throughout the 
   * project. Maybe refactor it to a separate file? */
  const getIsThisAPortfolio = () => {
    if (myPortfolios?.isLoading) return false
    if (!myPortfolios?.data?.length) return false
    return !!(myPortfolios?.data?.find(
      ownedPortfolio => productGuid
        && productGuid === ownedPortfolio?.RProductGuid
    ))
  }

  if (getIsThisAPortfolio()) return null

  return <>
    <Text sx={styles.sectionTitle}>Simulations</Text>
    <Text sx={styles.sectionDescription}>
      In the chart below we have put all the portfolios with
      different starting points together to illustrate the
      general progression of the models and how our unique
      approach is robust and agnostic to the portfolio's
      starting time. Our Exceptional & Rich methodology
      delivers risk weighted excess returns and has
      limited starting point bias.
    </Text>
    <SimulationsDateFilter {...{ productGuid }} />
    <ProductSimulationsChart {...{ productGuid }} />
    <ProductSimulationsTable {...{ productGuid }} />
  </>
}

export const RiskTab = ({ productGuid }) => {
  useDrawdowns(productGuid)
  return <>
    <Text sx={styles.sectionTitle}>Drawdown Analysis</Text>
    <Text sx={styles.sectionDescription}>
      <b>Drawdown (Benchmark and Portfolio): </b>
      A daily time series plot illustrating
      drawdowns of more than 10% from peak equity.
    </Text>
    <ProductDrawdownChart {...{ productGuid }} />
    <ProductDrawdownTables  {...{ productGuid }} />
  </>
}

export const DataTab = ({ productGuid }) => {


  const isLogged = useSelector(state => Boolean(state.user.token))
  if (!isLogged) return <LoginForm />
  return <>
    <Text sx={styles.sectionTitle}>Data</Text>
    <Text sx={styles.sectionDescription}>
      This section carries the documentation on the historical
      values of the model portfolio, the components, the
      classifications, drawdown analysis, and the respective
      benchmark value.
    </Text>
    <ProductDataDownload {...{ productGuid }} />
  </>
}



const styles = {

  sectionTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '2rem',
    margin: '70px 0 15px 0',
  },
  valueChartAndStats: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: '6fr 4fr',
    },
    rowGap: '40px',
    columnGap: '5%',
    marginTop: '24px',
  },
  sectionDescription: {
    color: 'colors.lightGrey16',
    fontSize: '1.5rem',
    fontWeight: '400',
    marginBottom: '20px',

  },
}


export const tabs = Object.freeze({
  overview: { name: 'Overview', component: OverviewTab, },
  factsheet: { name: 'Factsheet', component: FactsheetTab, },
  performances: { name: 'Performances', component: PerformancesTab, },
  runningComponents: { name: 'Running Components', component: RunningComponentsTab, },
  comparatives: { name: 'Comparatives', component: ComparativesTab, },
  simulations: { name: 'Simulations', component: SimulationsTab, },
  risk: { name: 'Risk', component: RiskTab, },
  data: { name: 'Data', component: DataTab, },
})


export const defaultTab = Object.keys(tabs)[0]
