
export const billingFrequencies = Object.freeze({
  monthly: 'Monthly',
  yearly: 'Yearly',
})
export const defaultBillingFrequency = Object.values(billingFrequencies)?.[0]

export const isValidBillingFrequency = value =>
  Boolean(Object.values(billingFrequencies)?.includes(value))


