const researchSections = [
  { id: 'section1', label: 'SectionA' },
  { id: 'section2', label: 'SectionB' },
  { id: 'section3', label: 'SectionC' },
  { id: 'section4', label: 'SectionD' },
  { id: 'section5', label: 'SectionE' },
  { id: 'section6', label: 'SectionF' },
  { id: 'section7', label: 'SectionG' },
]

export default researchSections
