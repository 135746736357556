import { ReactComponent as YourSvg } from 'assets/img/logo.svg'
import React from 'react'
import { Grid } from '@mui/material'
import { NavLink } from 'react-router-dom'
import HamburgerMenu from 'components/menu/HamburgerMenu'

export default function Header({ backgroundColor }) {
  return <Grid
    container
    sx={{
      ...styles.headerWrapper,
      ...backgroundColor && { backgroundColor },
    }}
  >
    <NavLink to='/'>
      <div style={{ width: '180px' }}>
        <YourSvg />
      </div>
    </NavLink>
    <HamburgerMenu />
  </Grid >
}


const styles = {
  headerWrapper: {
    padding: '24px 0 0 24px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}