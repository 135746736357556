import React from 'react'
import { Button } from '@mui/material'
import toolsSection2 from 'assets/img/toolsSection2.png'
import Text from 'components/Text'
import styles from 'screens/ToolsSlider/sections/Section4/Section4.module.css'

export default function Section2() {
  return <div className={styles.container}>
    <div className={styles.contentContainer}>
      <img src={toolsSection2} style={{ maxWidth: '100%', height: 'auto' }} alt='' />
      <div className={styles.quote}>
        <Text size='2.5rem'>
          “
        </Text>
        <Text size='1.125rem' mt='10%'>
          I was inquisitive at first, but when AlphaBlock team
          took my mandate, benchmarked it and overlayed it with
          their different smart beta approaches, there were
          little left for validation. I can use the AlphaBlock
          machines for improved decisions for my deep value
          fund. I had no idea; I was leaving so much excess
          return of the table
        </Text>
        <Text size='1.25rem' mt='20px' fontWeight='bold'>
          Robin Sharma
        </Text>

        <Text size='1.25rem'>
          Deep Value India Fund
        </Text>
      </div>
      <div className={styles.btnContainer}>
        <a href='https://github.com/alphablockorg' style={stylesJson.regularLink}>
          <Button
            variant='contained'
            fullWidth
            sx={stylesJson.button}
          >
            <Text size='1.5rem' color='black' >
              GITHUB
            </Text>
          </Button>
        </a>

      </div>
    </div>
  </div>
}

const stylesJson = {
  regularLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  button: {
    height: 70
  },
}


