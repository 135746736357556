import React, { useEffect, useState } from 'react'
import Header from 'components/Header'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import Text from 'components/Text'
import { Grid, TextField, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { sendForgotPassword } from 'store/userReducer'
import { NavLink, useNavigate } from 'react-router-dom'

const ForgotPasswordScreen = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isLogged = Boolean(useSelector(state => state.user.token))

  const {
    response,
    isLoading,
    isSuccess,
    isError,
    errorCode,
  } = useSelector(state => state.user.forgotPassword)

  const [email, setEmail] = useState('')

  // TODO: show error message if submits with empty email
  const [wasSubmitPressed, setWasSubmitPressed] = useState(false)

  useEffect(() => {
    /** TODO: refactor these login-related redirects 
     * into an elevated router logic. */
    if (isLogged) navigate('/market')
  }, [isLogged])


  const handleSubmit = () => {
    setWasSubmitPressed(true)
    dispatch(sendForgotPassword(email))
  }

  const getMainErrorMessage = () => {
    if (!isError) return null

    if (errorCode === 'email-not-found')
      return 'Email address not found. Please try another one.'

    return 'An error occurred, please try again later.'
  }


  const forgotPasswordForm = <>
    <Text sx={styles.title}>Forgot password</Text>

    <Text sx={styles.welcomeText}>
      Please type in your email address to get started.
    </Text>
    <TextField
      label='Email'
      variant='filled'
      type='email'
      value={email}
      error={isError}
      helperText={getMainErrorMessage()}
      onChange={event => setEmail(event.target?.value)}
      sx={styles.input}
    />
    <Button
      variant='contained'
      sx={styles.button}
      onClick={handleSubmit}
      disabled={isLoading}
      type='submit'
    >
      {isLoading ? 'Loading...' : 'Reset Password'}
    </Button>

    <hr width='100%' />
    <Text sx={styles.title}>Ready to log in?</Text>
    <Text sx={styles.welcomeText}>
      You can continue to log in to manage your account finances
    </Text>
    <Button
      variant='outlined'
      sx={styles.button}
      to='/login'
      component={NavLink}
    >
      Log In
    </Button>
  </>

  const successMessage = <>

    <Text sx={styles.title}>Please check your emails.</Text>
    <Text center>
      We have sent a password reset message to {email}.
      Please open the email to see the next step.
    </Text>

    <Button
      variant='contained'
      sx={styles.button}
      component={NavLink}
      to='/login'
    >
      Log In
    </Button>
  </>

  return <>
    <Grid>
      <Header />
      <Grid container sx={styles.loginWrapper}>
        <Grid container sx={styles.loginInner}>
          {isSuccess ? successMessage : forgotPasswordForm}
        </Grid>
      </Grid>
    </Grid>
    <LandingFooter />
  </>
}

const styles = {
  loginWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '5%',
  },
  loginInner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '33px',
    width: 'min(100%, 650px)',
  },
  title: {
    fontSize: '2rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    width: '100%',
    textAlign: 'center',
  },
  welcomeText: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    color: 'colors.lightGrey11',
  },
  input: {
    width: '100%',
  },
  button: {
    fontSize: '1.5rem',
    width: '100%',
    textTransform: 'none',
  },
  link: {
    color: 'colors.main',
    opacity: '0.2',
  },
}

export default ForgotPasswordScreen
