import React from 'react'
import researchSection5_1 from 'assets/img/researchSection5_1.png'
import HorizontalSlide from 'components/HorizontalSlide'
import MyImage from 'components/MyImage'
import MyLink from 'components/MyLink'


const Section5 = () => <HorizontalSlide
  sx={{ height: '80%', }}

>
  <MyLink to='https://www.youtube.com/channel/UC0lQvPB2M0uCcqsFv_a-OZg'>
    <MyImage src={researchSection5_1} />
  </MyLink>

</HorizontalSlide>


export default Section5

