import React from 'react'
import { Stack } from '@mui/material'
import Text from 'components/Text'
import aboutUsNotebook from 'assets/img/aboutUsNotebook.jpg'

const AboutUsSection2 = () => {
  return <Stack sx={styles.sectionWrapper} id='our-mission'>
    <Text sx={styles.title}>
      Our Mission <br />
      <b>Democratize intelligent investing for everyone. </b>
    </Text>
    <Text sx={styles.description}>
      We want to lead the DIY asset management revolution and
      give every investor and asset owner, whether big or small,
      an opportunity to generate alpha i.e., beat any market
      at any time.
    </Text>
    <img style={styles.image} src={aboutUsNotebook} />
  </Stack>
}

const styles = {
  sectionWrapper: {
    rowGap: '3.5rem',
  },
  title: {
    fontSize: '3.5rem',
    fontWeight: '400',
    lineHeight: '1.4',
    color: 'colors.lightGrey18',
  },
  description: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey17',
    fontWeight: '400',
  },
  image: {
    width: 'min(100%, 1210px)',
    margin: 'auto',
  },
}

export default AboutUsSection2
