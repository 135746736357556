import React from 'react'
import { Button, Grid, Paper, Stack } from '@mui/material'
import MyImage from 'components/MyImage'
import Text from 'components/Text'
import MyLink from 'components/MyLink'
import useFilteredArticles from 'screens/Insights/useFilteredArticles'


const InsightsArticles = () => {

  const {
    limitedArticles,
    isThereMore,
    handleSeeMore
  } = useFilteredArticles()

  return <Stack sx={styles.articlesAndSeeMoreBtn}>
    <Stack sx={styles.articlesGrid}>
      {limitedArticles?.map((article, index) =>
        <React.Fragment key={index}>
          <ArticleThumbnail {...{ article }} />
        </React.Fragment>
      )}
    </Stack>
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        onClick={handleSeeMore}
        disabled={!isThereMore}
        variant='outlined'
        sx={styles.seeMoreButton}
      >
        See more
      </Button>
    </Grid>
  </Stack>
}


const ArticleThumbnail = ({ article }) => {

  const titleElement = <span dangerouslySetInnerHTML={{
    __html: article?.title
  }} />

  return <Grid
    item
    sx={styles.articleWrapper}
    component={MyLink}
    to={`/insights/${article.slug}`}
  >
    <Paper sx={styles.articleInner} elevation={7}>
      <Grid sx={styles.imageWrapper}>
        <MyImage src={article?.imgSrc} sx={styles.articleImage} />
      </Grid>
      <Stack sx={styles.articleInfo}>
        <Text sx={styles.title}>{titleElement}</Text>
        <Text sx={styles.description}>
          {article?.description || titleElement}
        </Text>
        <Text sx={styles.date}>{article?.date}</Text>
      </Stack>
    </Paper>
  </Grid>
}

const styles = {
  articlesAndSeeMoreBtn: {
    marginBottom: '4rem',
  },
  seeMoreButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textTransform: 'none',
    fontSize: '1.25rem',
    fontWeight: '500',
    padding: '0.4em 1.5em',
    width: 'min(100%, 300px)',
  },
  articlesGrid: {
    display: 'grid',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3rem',
    marginBottom: '6rem',
    rowGap: '40px',
    gridTemplateColumns: {
      sx: '1fr',
      sm: '1fr 1fr',
      lg: '1fr 1fr 1fr',
    },
    columnGap: {
      xs: '4%',
      sm: '4%',
      lg: '3%',
    },
    width: {
      xs: 'min(1218px, 95%)',
      sm: 'min(1218px, 90%)',
      lg: 'min(1218px, 85%)',
    },
  },
  articleWrapper: {

  },
  articleInner: {
    height: '100%',
    backgroundColor: 'black',
    backgroundImage: 'none',
  },
  articleInfo: {
    padding: '5% 7% 5% 7%',
    rowGap: '0.7rem',
  },

  imageWrapper: {
    /** I chose this padding to match 
     * a specific aspect ratio: */
    padding: '35.3%',
    position: 'relative',
  },
  articleImage: {
    width: '100%',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
  title: {
    color: 'colors.lightGrey23',
    fontSize: '1.5rem',
    fontWeight: '600',
  },
  description: {
    color: 'colors.lightGrey24',
    fontSize: '1.125rem',
    fontWeight: '300',
    maxHeight: '6.1em',
    overflow: 'hidden',
  },
  date: {
    color: 'colors.main',
  },

}

export default InsightsArticles
