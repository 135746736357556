import { Grid, } from '@mui/material'

const Arrow = ({ sx }) => <Grid
  container
  direction="row"
  justifyContent="flex-start"
  alignItems="center"
  sx={sx}
>
  <Grid sx={styles.arrowLine} />
  <Grid sx={styles.arrowHead} />
</Grid>


const styles = {
  arrowLine: {
    height: '2px',
    width: '50px',
    borderRadius: '1px',
    backgroundColor: 'primary.main',
  },
  arrowHead: {
    height: '10px',
    width: '10px',
    marginLeft: '-9px',
    borderRadius: '2px',
    borderTop: 'solid 3px',
    borderRight: 'solid 3px',
    transform: 'rotate(45deg)',
    borderColor: 'primary.main',
  },
}

export default Arrow
