import React, { useState } from 'react'
import GetStartedResearch from 'screens/GetStarted/GetStartedResearch'
import GetStartedProducts from 'screens/GetStarted/GetStartedProducts'
import GetStartedTools from 'screens/GetStarted/GetStartedTools'
import Pagination from 'screens/GetStarted/components/Pagination/Pagination'
import Header from 'components/Header'
import { Grid, Stack } from '@mui/material'
import ScrollAnchorLogic from 'screens/GetStarted/components/Pagination/ScrollAnchorLogic'
import LandingFooter from 'screens/Landing/components/LandingFooter'

const idList = ['research', 'products', 'tools']

export default function GetStartedScreen() {

  const [activeId, setActiveId] = useState(idList[0])

  return <>
    <Grid sx={styles.headerWrapper}>
      <Header backgroundColor='colors.darkGrey6' />
    </Grid>
    <Stack>
      <GetStartedResearch />
      <GetStartedProducts />
      <GetStartedTools />
      <ScrollAnchorLogic {...{ idList, setActiveId }} />
    </Stack>
    <Pagination  {...{ activeId, setActiveId }} />
    <LandingFooter />
  </>
}


const styles = {
  headerWrapper: {
    backgroundColor: 'colors.darkGrey6',
  },
}
