import { Grid, Paper, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import ApartmentIcon from '@mui/icons-material/Apartment';
import EmailIcon from '@mui/icons-material/Email';
import { colors } from 'theme'


const ContactScreen = () => <PageWithSidebar hasBackground>
  <Stack sx={styles.welcomeGrid}>

    <Stack sx={styles.contactSectionStack}>

      <Stack sx={styles.sectionTitleAndDescription}>
        <Text sx={styles.sectionTitle}>
          Contact
        </Text>
        <Text sx={styles.sectionDescription}>
          Please get in touch and our support
          team will answer all your questions.
        </Text>
      </Stack>
      <Stack component={Paper} elevation={5} sx={styles.contactRowsStack}>
        <Grid container sx={styles.contactRow}>
          <Grid item>
            <EmailIcon sx={styles.icon} />
          </Grid>
          <Grid item><Text sx={styles.welcomeText}>
            <b>Email:</b>
          </Text></Grid>
          <Grid item ml='1rem'>
            <Text sx={styles.welcomeText}>
              <a
                href='mailto:contact@alphablock.org'
                style={styles.emailLink}
              >
                contact@alphablock.org
              </a>
            </Text>
          </Grid>
        </Grid>

        <Grid container sx={styles.contactRow}>
          <Grid item>
            <ApartmentIcon sx={styles.icon} />
          </Grid>
          <Grid item>
            <Text sx={styles.welcomeText}><b>Registered Office:</b></Text>
          </Grid>
          <Grid item ml='1rem'>
            8 Ambleside Ave, Toronto, Ontario M8Z 2H6, Canada
          </Grid>
        </Grid>
        <Text sx={styles.welcomeText}>
        </Text>
      </Stack>
    </Stack>
  </Stack>
</PageWithSidebar>


const styles = {
  emailLink: {
    color: colors.primaryMain,
    wordBreak: 'break-word',
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
  },
  sectionDescription: {
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey26',
    marginBottom: '1.2rem',
  },
  sectionTitleAndDescription: {
    width: 'min(100%, 700px)',
    margin: 'auto',
    gap: '24px',
  },
  contactSectionStack: {
    width: '100%',
    rowGap: '1rem',
  },
  contactRowsStack: {
    width: 'min(100%, 806px)',
    margin: 'auto',
    borderRadius: '10px',
    padding: '3% 2% 0% 2% ',
    gap: '2rem',
    boxShadow: 'none',
  },
  contactRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '0.3rem',
  },
  icon: {
    fontSize: '3rem',
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '200',
    width: 'min(100%, 600px)',
    color: 'colors.lightGrey7',
    '& b': {
      fontWeight: '600',
    },
  },
  welcomeGrid: {
    margin: '4rem 10% 8rem 10%',
    alignItems: 'center',
    gap: '6rem',
  },
}


export default ContactScreen
