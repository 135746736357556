import moment from 'moment'


/**
 * @returns For example: '07-Dec 21'
 */
export const prettyDateShort = date => {
  if (!date) return
  const dateObject = new Date(date)
  const month = dateObject.toLocaleString('default', { month: 'short' })
  const year = dateObject.toLocaleString('default', { year: '2-digit' })
  const day = dateObject.toLocaleString('default', { day: '2-digit' })

  return `${day}-${month} ${year}`
}


/**
 * @returns  For example 'Dec 7, 2021'
 */
export const prettyDate = unixTimestamp =>
  unixTimestamp ? moment(unixTimestamp).format('ll') : ''



/**
 * @returns  For example 'December 2021'
 */
export const prettyMonth = baseDate => {

  if (!baseDate) return ''
  const dateObject = new Date(baseDate)
  const month = dateObject.toLocaleString('default', { month: 'long' })
  const year = dateObject.toLocaleString('default', { year: 'numeric' })

  return `${month} ${year}`
}




export const timeAgo = unixTimestamp =>
  unixTimestamp ? moment(unixTimestamp).fromNow() : ''


/**
 * @param {*} input The number of milliseconds since 0001-JAN-01 00:00
 * @returns Unix timestamp (the number of milliseconds since 1970-JAN-01 00:00)
 */
export const toUnix = input => {
  if (!input && input !== 0) return null
  return -62135596800000 + ((input * 1000) * 30)
}


/**
 * @param {*} baseDate string or any other valid input for the Date() constructor
 * @returns Unix timestamp (the number of milliseconds since 1970-JAN-01 00:00) 
 */
export const stringToUnixTimestamp = baseDate => {
  const unixTimestamp = (new Date(baseDate)).getTime()
  return (!unixTimestamp && unixTimestamp !== 0) ? null : unixTimestamp
}


export const getYear = baseDate => {
  const dateObject = new Date(baseDate)
  if (!isValidDateObject) return null
  return dateObject?.getFullYear()
}


export const isValidDateObject = date => {
  if (!date && date !== 0) return false
  const newDate = new Date(date)
  return !isNaN((newDate)?.getTime())
}


export const addYears = (baseDate, years) => {
  const dateObject = new Date(baseDate)
  if (!isValidDateObject) return null
  dateObject.setFullYear(dateObject.getFullYear() + years)
  return dateObject
}

