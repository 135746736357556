import { Button, Grid, IconButton, Stack } from '@mui/material'
import Text from 'components/Text'
import React, { useState } from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

const allQuestionCategories = {
  'Alpha': {
    'What is Alpha?': 'Alpha is the excess return of the portfolio above the return of the benchmark.',
    'What is Beta?': 'Beta is the market returns and you can get Beta by buying the benchmark [Index Fund] for zero or low fees.',
    'Who delivers alpha?': '1 out of 10 managers delivers alpha over a running 5-year period. That number is less than 1 for a period greater than 10 years. The number drops to 0.1 for a 30-year running period.',
  },
  'Indices and Benchmarks': {
    'What are Indices?': 'Indices [Indexes] is a method to build a benchmark.',
    'Why are benchmarks important?': 'A benchmark is a reference used to measure the return and risk of an asset, region, sector, or style.',
    'Why a new index?': 'The current indexing methods are error-prone, amplify market risk, do not offer the diversification, and are concentrated.',
  },
  'Styles': {
    'What is a style?': 'Value and growth are two popular Investing styles.',
    'What is wrong with these investment styles?': 'These investment styles are not secular, hard to anticipate, and take turns coming in and out of fashion. This creates either an extreme risk or sustained underperformance.',
    'Why a new style?': 'Investing styles are statistical before they are fundamental. This means investing styles exhibit persistence and reversion as a rule before they express their fundamental investing characteristics. Hence, studying styles for their statistical character is more important than studying their fundamental content.',
  },
  'Strategies': {
    'What is a strategy?': 'Strategy is a specific structure of managing money like Market Neutral, Merger Arbitrage, Distressed debt, fund of funds, convertible arbitrage, event-driven, global macro, short only, multi-strategy, fixed income arbitrage, etc.',
    'Do the strategies work all the time?': 'No strategy works all the time.',
    'Why a new strategy?': 'Every strategy goes through periods of persistence and reversion, which means every strategy has a time when it works and a time when it fails [does not work]. This is why focussing on a new common statistical framework for strategies that allows a tactical understanding of when to work and not work on a certain strategy is insightful and can improve performance, reduce risk and offer a new asset allocation approach.',
  },
  'Modern Finance': {
    'What is modern finance?': 'Modern finance is based on linear regression, which means looking at cause and effect statically. The premise that small-size companies deliver better returns is based on a linear test between size and returns.',
    'Why is modern finance becoming redundant?': 'Markets and nature do not work in a linear relation. Sometimes cause becomes effect. This is why Modern finance has failed to explain the behavior of markets. Modern finance is becoming redundant because finance practitioners don’t look at Science and consider Physics as dangerous to your wealth.',
    'How is Science changing modern finance?': 'Physics is about temporal architecture and structures. The subject can allow us to look at the information in a different way, where it works, fails, and operates probabilistically. This allows us a redefine finance using Science and explain many of its shortcomings.',
  },
  'Robo Advisor': {
    'What is a Robo advisor?': 'Robo advisory is a company that matches your preferences with available investable instruments.',
    'Why did Robo advisors not create alpha?': 'The Robo advisor\'s job is not to create alpha.',
    'What is the future of Robo advisory?': 'Many Robo advisors are now merging with the banks.',
  },
  'ETFs': {
    'What is an ETF?': 'ETF is an exchange-traded fund that allows you to buy an index, a benchmark, a sector, a theme as a basket.',
    'Why did ETFs not create alpha?': 'ETFs are primarily designed to offer Beta, not alpha. A few who claim to deliver Alpha, fail to do it but still charge an annual management expense ratio.',
    'What is the future of ETFs?': 'ETFs tomorrow will not charge an upfront fee but only a fee on alpha.',
  },
  // 'CCCCCCCCCCCCCC': {
  //   'QQQQQQQQQQQQQQQQQQQQQQQQQQQQQ': 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  //   'QQQQQQQQQQQQQQQQQQQQQQQQQQQQQ': 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  //   'QQQQQQQQQQQQQQQQQQQQQQQQQQQQQ': 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  //   'QQQQQQQQQQQQQQQQQQQQQQQQQQQQQ': 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  //   'QQQQQQQQQQQQQQQQQQQQQQQQQQQQQ': 'AAAAAAAAAAAAAAAAAAAAAAAAAA',
  // },
}


const FaqSection = () => <Stack sx={styles.sectionWrapper}>
  <Stack sx={styles.sectionTitleAndDescription}>
    <Text sx={styles.sectionTitle}>
      Frequently asked questions
    </Text>
  </Stack>

  <Stack sx={styles.accordions}>
    {
      Object.keys(allQuestionCategories)?.map(
        (questionCategory, index) => <QuestionCategory
          title={questionCategory}
          questions={allQuestionCategories?.[questionCategory]}
          key={index}
        />
      )
    }
  </Stack>
</Stack>



const QuestionCategory = ({ title, questions }) => {
  const [isOpen, setIsOpen] = useState(false)

  return <Stack
    sx={styles.accordionWrapper}
    p={isOpen ? '2% 4% 3% 4%' : '2% 4% 2% 4%'}
  >
    <Grid sx={styles.categoryAndIcon}>
      <Text sx={styles.category}>
        {title}
      </Text>
      <IconButton
        sx={styles.icon}
        color='primary'
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen
          ? <RemoveRoundedIcon sx={styles.icon} />
          : <AddRoundedIcon sx={styles.icon} />
        }
      </IconButton>
    </Grid>
    {isOpen && <Stack sx={styles.questions}>
      {Object.keys(questions)?.map((oneQuestion, index) =>
        <QuestionAndAnswer
          question={oneQuestion}
          answer={questions?.[oneQuestion]}
          key={index}
        />
      )}
    </Stack>
    }
  </Stack >
}


const QuestionAndAnswer = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)

  return <Stack sx={styles.questionAndAnswer}>
    <Text
      sx={styles.question}
      onClick={() => setIsOpen(!isOpen)}
      className='preventTextSelection'
    >{question}</Text>
    {isOpen &&
      <Text sx={styles.answer}>{answer}</Text>
    }
  </Stack>
}


const styles = {
  accordionWrapper: {
    backgroundColor: 'colors.darkGrey2',
    gap: '8px',
    borderRadius: '10px',
  },
  categoryAndIcon: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  category: {
    fontSize: '1.5rem',
    fontWeight: '500',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
  },
  iconButton: {

  },
  icon: {
    fontSize: '2.5rem',
  },
  questions: {
    gap: '16px',

  },
  questionAndAnswer: {

  },
  question: {
    color: 'colors.lightGrey26',
    fontSize: '1.125rem',
    fontWeight: '400',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  answer: {
    fontSize: '1.125rem',
    fontWeight: '400',

  },
  accordions: {
    gap: '24px',
    marginTop: '24px',
  },
  sectionWrapper: {
    width: 'min(100%, 806px)',
    margin: 'auto',
    gap: '24px',
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
  },
  sectionDescription: {
    textAlign: 'center',

    fontSize: '1.125rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey26',
  },
  sectionTitleAndDescription: {
    width: 'min(100%, 700px)',
    margin: 'auto',

    gap: '24px',
  },
}

export default FaqSection

