import { Button, Grid, Paper, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import DownloadIcon from '@mui/icons-material/Download'
import { myAxios, printError } from 'utils/apiUtils'
import axios from 'axios'
import appConfig from 'appConfig'
import { useDispatch, useSelector } from 'react-redux'
import slugify from 'slugify'
import { prettyDate } from 'utils/dateUtils'

const apiBaseUrl = appConfig.apiBaseUrl

const cards = Object.freeze({
  entryAndExits: {
    name: 'Entry and Exits',
    endpoint: '/portfolio/download-portfolio-entry-exits',
    description: 'This section contains documentation on historical Entries, Exits, and Re-adjustments. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
  monthlyPerformance: {
    name: 'Monthly Performance',
    endpoint: '/portfolio/download-portfolio-monthly',
    description: 'This section contains documentation on the historical monthly returns of the model portfolio and its respective benchmark. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
  drawdownAnalysis: {
    name: 'Drawdown Analysis',
    endpoint: '/portfolio/download-portfolio-drawdown-analysis',
    description: 'This section contains documentation on the historical drawdown comparison of the model portfolio and its respective benchmark. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
  dailyPortfolioValues: {
    name: 'Daily Portfolio Values',
    endpoint: '/portfolio/download-portfolio-values',
    description: 'This section contains documentation on the historical end-of-day model portfolio value and its benchmark. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
  portfolioComponents: {
    name: 'Portfolio Components',
    endpoint: '/portfolio/download-portfolio-components',
    description: 'This section contains documentation on all the historical components, their exits, entries, and re-adjustments. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
  componentClassifications: {
    name: 'Component Classifications',
    endpoint: '/portfolio/download-portfolio-classifications',
    description: 'This section contains documentation on the classifications of model portfolio components. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
  downloadAll: {
    name: 'Download All',
    endpoint: '/portfolio/download-portfolio-all',
    description: 'This section contains documentation of the historical end-of-day model portfolio value and its benchmark. By clicking download you are accepting AlphaBlock’s terms and conditions.',
  },
})


const ProductDataDownload = ({ productGuid }) => {
  return <Grid sx={styles.grid}>
    <DownloadCard {...{ productGuid }} card={cards.entryAndExits} />
    <DownloadCard {...{ productGuid }} card={cards.monthlyPerformance} />
    <DownloadCard {...{ productGuid }} card={cards.drawdownAnalysis} />
    <DownloadCard {...{ productGuid }} card={cards.dailyPortfolioValues} />
    <DownloadCard {...{ productGuid }} card={cards.portfolioComponents} />
    <DownloadCard {...{ productGuid }} card={cards.componentClassifications} />
    <DownloadCard {...{ productGuid }} card={cards.downloadAll} />
  </Grid>
}


const DownloadCard = ({ card, productGuid }) => {

  const dispatch = useDispatch()

  const token = useSelector(state => state.user.token)
  const basicDetails = useSelector(
    state => state.market.basicDetails?.[productGuid]
  )
  const productName = basicDetails?.ProductName


  return <Stack sx={styles.cardItem} elevation={5} component={Paper}>
    <Text sx={styles.title}>{card.name}</Text>
    <Text sx={styles.description}>{card.description}</Text>
    <Grid sx={styles.separatorLine} />

    <Grid sx={styles.downloadButtonWrapper} container >
      <Button
        sx={styles.downloadButton}
        endIcon={<DownloadIcon />}
        color='secondary'
        onClick={() => fetchDownload({
          endpoint: card.endpoint,
          cardName: card.name,
          productGuid,
          token,
          productName,
          dispatch,
        })}
      >
        Download
      </Button>
    </Grid>
  </Stack>
}


const styles = {
  grid: {
    display: 'grid',
    gap: '2rem',
    width: '100%',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
  },
  cardItem: {
    padding: '7%',
    backgroundColor: 'colors.lightGrey25',
    backgroundImage: 'none',
    gap: '1rem',
    borderRadius: '22px',
  },
  title: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '1.5rem',

  },
  description: {
    fontFamily: 'Montserrat',
    fontWeight: '300',
    fontSize: '1.125rem',
    color: 'colors.lightGrey26',

  },
  downloadButton: {
    fontFamily: 'Montserrat',
    fontWeight: '300',
    fontSize: '1rem',
    color: 'colors.lightGrey27',
    textTransform: 'none',
    borderBottom: '1px solid',
    borderRadius: '4px 4px 0 0',
    borderColor: 'colors.primaryMain',
  },
  downloadButtonWrapper: {
    width: '100%',
    justifyContent: 'flex-end',
  },
  separatorLine: {
    width: '100%',
    height: '1px',
    backgroundColor: 'colors.darkGrey10',
  },
}


const fetchDownload = ({ endpoint, productGuid, token, productName, cardName, dispatch }) => {

  if (!endpoint) { console.log('Error at fetchDownload(): endpoint cannot be empty. '); return }
  if (!productGuid) { console.log('Error at fetchDownload(): productGuid cannot be empty. '); return }
  if (!token) { console.log('Error at fetchDownload(): token cannot be empty. '); return }


  myAxios({
    axiosCallerFn: axiosToken =>
      axios.post(apiBaseUrl + endpoint, null, {
        params: { productGuid, },
        headers: { "Authorization": `Bearer ${axiosToken}` },
      }),
    onSuccess: response => {

      if (!response?.data) throw 'response.data is empty'

      const unixTimestamp = (new Date()).getTime()
      const dateSlug = slugify(prettyDate(unixTimestamp))
      const productNameSlug = slugify(productName) || 'Product'
      const cardNameSlug = slugify(cardName || 'Download')
      const filename = `${cardNameSlug}_${dateSlug}_${productNameSlug}.csv`

      downloadCSV({ filename, content: response?.data, })
    },
    onError: error => {
      printError('fetchDownload()', error, dispatch)
    },
    token,
    dispatch,
  })

}


const downloadCSV = ({ filename, content }) => {

  const finalContent = 'data:text/csv;charset=utf-8,' + content
  const encodedUri = encodeURI(finalContent)
  const link = document.createElement('a')

  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()

}


export default ProductDataDownload
