import React from 'react'
import { Alert, Snackbar } from '@mui/material'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Text from 'components/Text'
import { clearSnackbar } from 'store/snackbarReducer'
import Grow from '@mui/material/Grow'

const MySnackbar = () => {

  const dispatch = useDispatch()
  const message = useSelector(state => state.snackbar?.message)

  if (!message) return null

  return <Snackbar
    open={Boolean(message)}
    autoHideDuration={6000}
    TransitionComponent={Grow}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    onClose={() => dispatch(clearSnackbar())}
  >
    <Alert
      onClose={() => dispatch(clearSnackbar())}
      severity="error"
      sx={styles.alert}
    >
      An error has occurred, please try again later.
      <Text size='0.8rem'>{message?.toString?.()}</Text>
    </Alert>
  </Snackbar>


}

const styles = {
  alert: {
    width: '100%',
    wordBreak: 'break-word',
  },
}

export default MySnackbar