import React from 'react'
import { Grid, TextField, Button } from '@mui/material'
import Text from 'components/Text'
import { ReactComponent as Logo } from 'assets/img/logo.svg'
import { NavLink } from 'react-router-dom'
import MyLink from 'components/MyLink'
import SocialIconsRow from 'screens/GetStarted/components/SocialIconsRow'
import { useDefaultProducts } from 'hooks/productApiHooks'
import SubscribeToNewsletterForm from 'screens/Landing/components/SubscribeToNewsletterForm'


const year = (new Date()).getFullYear()

export default function LandingFooter() {

  const defaultProducts = useDefaultProducts({ preventFetching: false }) // responsable for fetching API

  const footerProducts = defaultProducts?.response?.map(
    product => ({
      title: product?.productName,
      href: `/product/${product?.ProductGuid}`,
    })
  )


  return <Grid sx={styles.section}>
    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={styles.footerRowSubscribe}
    >

      <Grid item sx={{ width: '180px' }}>
        <NavLink style={styles.navLink} to='/'><Logo /></NavLink>
      </Grid>
      <Grid item>
        <SubscribeToNewsletterForm />
      </Grid>
    </Grid>
    <Grid item sx={styles.footerColumns}>

      <Grid item sx={styles.footerColumn}>
        <MyLink to='/get-started#research'>
          <Text sx={styles.columnTitle}>Research</Text>
        </MyLink>
        <Grid sx={styles.colMenuItems}>

          <MyLink to='/insights/2022-6-21-mechanisms-of-psychology'>
            <Text sx={styles.colMenuItem} >Mechanisms of Psychology</Text>
          </MyLink>
          <MyLink to='/insights/2022-6-22-the-snowball-effect'>
            <Text sx={styles.colMenuItem} >The Snowball Effect</Text>
          </MyLink>
          <MyLink to='/insights/2017-3-26-human-ai'>
            <Text sx={styles.colMenuItem} >Human AI</Text>
          </MyLink>
          <MyLink to='/insights/the-intelligent-web'>
            <Text sx={styles.colMenuItem} >The Intelligent Web</Text>
          </MyLink>
        </Grid>
      </Grid>

      <Grid item sx={styles.footerColumn}>
        <MyLink to='/get-started#products'>
          <Text sx={styles.columnTitle}>Products</Text>
        </MyLink>
        <Grid sx={styles.colMenuItems}>
          {
            footerProducts?.map((product, index) => <MyLink
              to={product?.href} sx={styles.colMenuItem} key={index}
            >
              <Text sx={styles.colMenuItem}>{product?.title}</Text>
            </MyLink>
            )}
        </Grid>
      </Grid>

      <Grid item sx={styles.footerColumn}>
        <MyLink to='/get-started#tools'>
          <Text sx={styles.columnTitle}>Tools</Text>
        </MyLink>

        <Grid sx={styles.colMenuItems}>
          <MyLink to='https://github.com/alphablockorg' sx={styles.colMenuItem}>
            <Text sx={styles.colMenuItem}>GitHub</Text>
          </MyLink>
          <MyLink to='/market' sx={styles.colMenuItem}>
            <Text sx={styles.colMenuItem}>Market</Text>
          </MyLink>

          <MyLink to='/about-us#machines' sx={styles.colMenuItem}>
            <Text sx={styles.colMenuItem}>Machines</Text>
          </MyLink>
          <MyLink to='/insights' sx={styles.colMenuItem}>
            <Text sx={styles.colMenuItem}>Insights</Text>
          </MyLink>
        </Grid>
      </Grid>

      <Grid item sx={styles.footerColumn}>
        <MyLink to='/about-us'>
          <Text sx={styles.columnTitle}>About Us</Text>
        </MyLink>

        <Grid sx={styles.colMenuItems}>
          <MyLink to='/about-us#our-mission' sx={styles.colMenuItem}>
            <Text sx={styles.colMenuItem}>Mission</Text>
          </MyLink>
          <MyLink to='/about-us#our-success' sx={styles.colMenuItem}>
            <Text sx={styles.colMenuItem}>Nasdaq</Text>
          </MyLink>
          {/* <Grid item><Text sx={styles.colMenuItem} {...inactive}>History</Text></Grid> */}
          <MyLink to='/about-us#our-method' sx={styles.colMenuItem} >
            <Text sx={styles.colMenuItem} >Method</Text>
          </MyLink>
        </Grid>
      </Grid>

      <Grid item sx={styles.footerColumn}>
        <MyLink to='/insights'>
          <Text sx={styles.columnTitle}>Insights</Text>
        </MyLink>

        <Grid sx={styles.colMenuItems}>
          <MyLink to='/insights/2022-5-2-910-fail-logic' sx={styles.colMenuItem} >
            <Text sx={styles.colMenuItem}>9/10 Fail Logic</Text>
          </MyLink>
          <MyLink to='/insights/2017-3-6-post-example' sx={styles.colMenuItem} >
            <Text sx={styles.colMenuItem}>The Black Swan</Text>
          </MyLink>
          <MyLink to='/insights/2022-5-2-the-conceptual-age' sx={styles.colMenuItem} >
            <Text sx={styles.colMenuItem}>The Conceptual Age</Text>
          </MyLink>
          <MyLink to='/insights/2022-6-21-web3-alts' sx={styles.colMenuItem} >
            <Text sx={styles.colMenuItem}>Web3 Alts</Text>
          </MyLink>
        </Grid>
      </Grid>
    </Grid>

    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={styles.footerRowSocialLinks}
    >
      <MyLink to='/faq' sx={styles.footerRowSocialLink}>FAQs</MyLink>
      <MyLink to='https://github.com/alphablockorg' sx={styles.footerRowSocialLink}>GitHub</MyLink>
      <MyLink to='/insights' sx={styles.footerRowSocialLink}>Insights</MyLink>
      <MyLink to='/privacy-policy' sx={styles.footerRowSocialLink}>Privacy</MyLink>
      <MyLink to='/disclaimer' sx={styles.footerRowSocialLink}>Disclaimer</MyLink>
      <MyLink to='/contact' sx={styles.footerRowSocialLink}>Contact</MyLink>
      <MyLink to='/pricing' sx={styles.footerRowSocialLink}>Pricing</MyLink>
      <Grid item><SocialIconsRow /></Grid>
    </Grid>

    <Grid
      container
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={styles.footerRowCopyright}
    >
      <Grid item>
        <Text sx={styles.footerRowCopyrightText}>© {year} AlphaBlock Technologies Inc. All rights reserved</Text>
      </Grid>
      <Grid item sx={styles.footerRowCopyrightButtons}>
        <Button variant='contained' sx={styles.footerRowCopyrightButton} disabled>Google Play</Button>
        <Button variant='contained' sx={styles.footerRowCopyrightButton} disabled>Apple Store</Button>
      </Grid>
    </Grid>
  </Grid>
}


const styles = {
  section: {
    backgroundColor: 'colors.darkGrey4',
    padding: {
      md: '81px 10% 36px 10%',
      xs: '81px 5% 36px 5%',
    },
  },
  footerRowSubscribe: {
    marginBottom: 6,
    paddingLeft: 0,
    gap: '16px',
    justifyContent: {
      md: 'space-between',
      xs: 'center',
    }
  },
  footerColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(min(190px, 100%), 1fr) )',
    columnGap: 3,
    rowGap: 5,
  },
  footerColumn: {
    justifySelf: {
      lg: 'left',
      xs: 'center',
    }
  },
  columnTitle: {
    fontSize: '1.5rem',
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: '500',
    lineHeight: '1.875rem',
    letterSpacing: '0.04em',
    fontStyle: 'normal',
    marginBottom: '0.6em',
  },
  colMenuItems: {
    borderLeft: '2px solid',
    borderLeftColor: 'colors.lightGrey14',
    paddingLeft: '12px',
  },
  colMenuItem: {
    fontSize: '1.125rem',
    fontFamily: "'Source Sans Pro', sans-serif",
    color: 'colors.lightGrey14',
    marginBottom: '0.8em',
    fontWeight: '500',
  },
  footerRowSocialLinks: {
    gap: '1.5em',
    marginTop: '32px',
    paddingTop: '20px',
    borderTop: 'solid 1px #E3E3E3',
    marginLeft: 0,
    fontSize: '1.125rem',
  },
  footerRowSocialLink: {
    textTransform: 'uppercase',
    fontWeight: '500',
    paddingTop: 0,
    paddingLeft: 0,
    fontSize: '1.125rem',
    '& p': {
      fontSize: '1.125rem',
    },
  },
  footerRowSocialIcons: {

  },
  footerRowSocialIcon: {

  },
  footerRowCopyright: {
    marginTop: '38px',
    gap: '16px',
  },
  footerRowCopyrightText: {
    color: 'colors.lightGrey14',
    fontSize: '1.125rem',
  },
  footerRowCopyrightButtons: {

  },
  footerRowCopyrightButton: {
    fontSize: '1.125rem',
    borderRadius: '16px',
    padding: '0.5em 1.3em',
    fontFamily: "'Roboto', sans-serif",
    textTransform: 'none',
    backgroundColor: 'colors.lightGrey5',
    color: 'colors.opposite',
    '&:hover': { backgroundColor: 'colors.main', },
    '&:not(:first-of-type)': { marginLeft: '20px', },
    fontWeight: '500',
  },
}
