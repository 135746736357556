import React from 'react'
import researchSection6_1 from 'assets/img/researchSection6_1.jpeg'
import HorizontalSlide from 'components/HorizontalSlide'
import MyImage from 'components/MyImage'
import { Stack } from '@mui/material'
import Text from 'components/Text'
import MyNavLink from 'components/MyNavLink'


const Section6 = () => <HorizontalSlide
  sx={{ height: '100%', }}
>
  <Stack
    sx={styles.imageAndText}
    component={MyNavLink}
    to='/insights/2022-5-2-the-conceptual-age'
  >
    <MyImage src={researchSection6_1} />
    <Text size='2rem' sx={styles.imageCaption}>
      The Conceptual Age
    </Text>
  </Stack>
</HorizontalSlide>


const styles = {
  imageAndText: {

    height: '100%',
    padding: '5% 5% 7% 5%',
  },
  imageCaption: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.5em',
    textAlign: 'center',
    fontWeight: 'bold',
    // fontFamily: 'Montserrat',

    width: 'min(1000px, 90%)',
  },
}


export default Section6

