import { Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import ProductDrawdownChart from 'screens/ProductDetail/ProductDrawdownChart'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'

const ProductsSlide3 = ({ productGuid }) => {


  const defaultProductDetails = useSelector(
    state => state.otherStats.defaultProductDetail?.[productGuid]
  )
  const response = defaultProductDetails?.response

  if (!defaultProductDetails?.isSuccess) return <Loading />

  return <Stack sx={styles.slideWrapper}>
    <Text sx={styles.sectionTitle}>Drawdown Analysis</Text>
    <Text sx={styles.sectionDescription}>
      The historical drawdown chart for the
      {` ${response?.productName} vs. the ${response?.BenchmarkName} `}
      recording all weekly drops of more than 10%.
    </Text>
    <ProductDrawdownChart {...{ productGuid }} />
  </Stack>
}

const styles = {

  slideWrapper: {
    gap: '20px',
    width: 'min(1600px, 95%)',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '2rem',
    margin: '90px 0 15px 0',
  },
  sectionDescription: {
    color: 'colors.lightGrey16',
    fontSize: '1.5rem',
    fontWeight: '400',
    marginBottom: '20px',

  },

}

export default ProductsSlide3
