import React from 'react'
import { Grid } from '@mui/material'
import Text from 'components/Text'
import ToolsCard from 'screens/GetStarted/components/ToolsCard/ToolsCard'
import ReadMoreButton from 'screens/GetStarted/components/ReadMoreButton/ReadMoreButton'
import background from 'assets/img/tools1.png'
import background2 from 'assets/img/tools2.png'
import background3 from 'assets/img/tools3.png'
import background4 from 'assets/img/tools4.png'

const cardData = [
  {
    id: '1',
    label: 'E&R U.S. 500',
    imageUrl: background
  },
  {
    id: '2',
    label: 'E&R U.S. 100',
    imageUrl: background2
  },
  {
    id: '3',
    label: 'Fama & F',
    imageUrl: background3
  },
  {
    id: '4',
    label: 'Sentiment',
    imageUrl: background4
  }
]


export default function GetStartedTools() {
  return <Grid container sx={styles.sectionWrapper} id='tools'>
    <Grid item sx={styles.sectionInner}>
      <Text size='3rem' mb='50px' >
        Sandbox  -  Test our Algorithms
      </Text>
      <div style={{ maxWidth: 600 }}>
        <Text size='24px'>
          The replication crisis is a methodological crisis which makes
          it impossible to replicate or reproduce. This crisis affects
          both social sciences and natural sciences. Our sand box allows
          Asset Owners, Data Scientists and Data Owners to replicate our
          algorithmic processes and extend the body of work for global
          impact across financial and non-financial domains.

        </Text>
      </div>
      <Grid mt='50px'>
        <ReadMoreButton href='/tools' />
      </Grid>
      <Grid mt='50px' container ml='50px'  >
        {cardData.map((card) => {
          return <Grid key={card.id} mr='50px'>
            <ToolsCard imageUrl={card.imageUrl} label={card.label} />
          </Grid>
        })}
      </Grid>
    </Grid>
  </Grid>
}


const styles = {
  sectionWrapper: {
    minHeight: '100vh',
    padding: '30px 10% 80px 10%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'colors.darkGrey6',
  },
  sectionInner: {

  },
}
