import TableHeaderIcon from 'screens/Market/TableHeaderIcon'
import { prettyDateShort } from 'utils/dateUtils'
import { prettyNumber, prettyPercent } from 'utils/numberUtils'
import MyTable from 'components/MyTable'
import PortfTableActionCell from 'screens/Market/ProductsTable/PortfTableActionCell'
import usePortfTableMetadata from 'screens/Market/ProductsTable/usePortfTableMetadata'
import portfTableProps from 'screens/Market/ProductsTable/portfTableProps'


const ReturnsTemplate = ({ products }) => {

  const { isSelectingProduct } = usePortfTableMetadata()

  const rows = products?.map((portfolio, index) => ({
    RName: <PortfTableActionCell productGuid={portfolio.RProductGuid} label={portfolio.RName} />,
    id: index,
    productGuid: portfolio.RProductGuid,
    href: !isSelectingProduct && `/product/${portfolio.RProductGuid}`,
    RDate: portfolio.RDate,
    RReturn: portfolio.RReturn,
    RExcessReturn: portfolio.RExcessReturn,
    RVolatility: portfolio.RVolatility,
    RExcessVolatility: portfolio.RExcessVolatility,
    RTrackingError: portfolio.RTrackingError,
    RInformationRatio: portfolio.RInformationRatio,
  }))

  const headCells = [
    { align: 'left', formatter: v => v, id: 'RName', label: 'Name', },
    { align: 'center', formatter: prettyDateShort, id: 'RDate', label: 'Date', },
    { align: 'center', formatter: prettyPercent, id: 'RReturn', label: <TableHeaderIcon title='AR' desc={<><b>Annualized Return</b> is the geometric average amount of money earned by an investment each year over a given period.</>} />, },
    { align: 'center', formatter: prettyPercent, id: 'RExcessReturn', label: <TableHeaderIcon title='ER' desc={<><b>Excess Return</b> is the Annualized return of the E&R Portfolio minus the Annualized return of the Benchmark.</>} />, },
    { align: 'center', formatter: prettyPercent, id: 'RVolatility', label: <TableHeaderIcon title='AV' desc={<><b>Annualized Volatility</b> is the Annualized Standard Deviation.</>} />, },
    { align: 'center', formatter: prettyPercent, id: 'RExcessVolatility', label: <TableHeaderIcon title='EV' desc={<><b>Excess Volatility</b> is the Annualized volatility of the E&R Portfolio minus the Annualized Volatility of the Benchmark.</>} />, },
    { align: 'center', formatter: prettyPercent, id: 'RTrackingError', label: <TableHeaderIcon title='TE' desc={<><b>Tracking Error</b> is the Standard Deviation of the Annualized Excess Returns.</>} />, },
    { align: 'center', formatter: prettyNumber, id: 'RInformationRatio', label: <TableHeaderIcon title='IR' desc={<><b>Information Ratio</b> is the Annualized Excess Returns divided by the Tracking Error.</>} />, },
  ]

  if (!products) return <h1 style={{ textAlign: 'center', }}>Loading...</h1>

  return <MyTable
    {...{ headCells, rows }}
    // highlightEventRows
    {...portfTableProps}
  />
}


export default ReturnsTemplate
