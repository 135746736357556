import React from 'react'
import researchSection1 from 'assets/img/researchSection1_1.jpg'
import { Grid } from '@mui/material'
import Text from 'components/Text'
import MyImage from 'components/MyImage'
import HorizontalSlide from 'components/HorizontalSlide'
import MyNavLink from 'components/MyNavLink'

const Section1 = () => <HorizontalSlide>
  <Grid
    sx={styles.columns}
    component={MyNavLink}
    to='/insights/2022-5-2-910-fail-logic'
  >
    <MyImage src={researchSection1} />
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Text size='3rem' fontWeight='bold' mb='48px'>
        9/10 Fail Logic
      </Text>
      <Text size='1.4rem' mb='1.5em'>
        9 out of 10 asset managers don&rsquo;t beat the market. This is driving the &lsquo;Do
        It Yourself &lsquo;(DIY) rush to passive. How what should have been a 50%
        chance to beat the market became a poor hand for the asset managers?
      </Text>
      <Text size='1.4rem'>
        The market is a basket, which includes everything investable. An asset
        manager's job is to look into the basket and make a selection and make a
        smaller basket also called a portfolio, mutual fund, hedge fund, etc. Once
        the selection is done, the asset manager has to assign a weight i.e. how
        much money should be allocated to the certain selection, 1% or 10%,
        etc. Once selection and allocation are done, the asset manager has to
        hold the selection for a certain time, so that he/she can book the profit
        (gain) on the respective selection. For this human skill, an asset manager
        charges a 1 to 3% fee for an implicit understanding that his/her
        respective skill will work and the portfolio will perform better than the
        overall universe, or a similar basket without the skill. Historically, since
        the early 1950s it has been observed that 9 out of 10 asset managers are
        unable to showcase the skill.
      </Text>
    </Grid>
  </Grid>
</HorizontalSlide>


const styles = {
  columns: {
    display: 'grid',
    gridTemplateColumns: '10fr 20fr',
    gap: '5%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default Section1
