import React from 'react'
import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import AboutUsNasdaqChart from 'screens/AboutUs/AboutUsNasdaqChart'


const AboutUsSection4 = () => {
  return <Stack sx={styles.sectionWrapper} id='our-success'>
    <Text sx={styles.title}>
      Our Success  <br />
      <b>NASDAQ Orpheus RMIVG 20 </b>
    </Text>

    <Grid sx={styles.chartWrapper}>
      <AboutUsNasdaqChart />
    </Grid>

    <Text sx={styles.description}>
      Nasdaq Orpheus RMIVG20 was our first validated model listed on
      Nasdaq. Before listing the model in August 2014, Nasdaq
      calculated the model from 2004 to 2014. This backdating of the
      algorithm was to understand how a set of rules can perform back
      in time. During this 10-year test period, the RMIVG20 delivered a
      gain of 321%, an excess of 100% above the Nasdaq composite
      benchmark NQUSB. The model was listed on Nasdaq from 4 August 2014
      till 9 April 2021 and delivered an annualized excess return of
      5.9% above NQUSB.
      <br /><br />
      The following are the key statistics of the Exceptional & Rich
      model portfolios listed in the Market section.
    </Text>
  </Stack>
}

const styles = {
  sectionWrapper: {
    rowGap: '3.5rem',
  },
  title: {
    fontSize: '3.5rem',
    fontWeight: '400',
    lineHeight: '1.4',
    color: 'colors.lightGrey18',
  },
  description: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey17',
    fontWeight: '400',
    paddingLeft: '1.5em',
    borderLeft: 'solid 2.8px',
    borderLeftColor: 'colors.primaryMain',
  },
  chartWrapper: {
    width: 'min(100%, 1210px)',
    margin: 'auto',
  },
}

export default AboutUsSection4
