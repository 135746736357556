import { Button, Grid } from '@mui/material'
import { Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import MyImage from 'components/MyImage'
import aboutUsLaptops from 'assets/img/aboutUsLaptops.jpg'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'


const PricingIntroSection = () => {

  const isLogged = Boolean(useSelector(state => state.user.token))

  const btnProps = {
    createAccount: {
      sx: styles.createAccountBtn,
      variant: 'outlined',
      color: 'secondary',
      component: NavLink,
      to: '/registration',
    },
    login: {
      sx: styles.logInBtn,
      variant: 'contained',
      component: NavLink,
      to: '/login',
    },
  }

  return <Grid sx={styles.sectionWrapper}>
    <Stack sx={styles.textWrapper}>
      <Text sx={styles.title}>
        Join Us Today<br />
        <b>Learn Machine Investing</b>
      </Text>
      <Text sx={styles.description}>
        Machine Investing is the future of investing because humans are emotional, find it hard to stick to a plan, and because Information is not an orange that you can juice. Our Exceptional & Rich Indices is an open methodology that you can use for free to build market-beating portfolios. Exceptional & Rich Styles allow you to recreate the S&P 500, Europe 50, India 100, Canada 60, Commodities, etc., and generate Alpha. If you want to understand our unique factor approach, you can use Exceptional & Rich strategies that are insightful, visual, and actionable for your preferred mandate.
      </Text>
      {!isLogged &&
        <Grid sx={styles.buttonsRow} container>
          <Button {...btnProps.createAccount}>Create Account</Button>
          <Button {...btnProps.login}>Log In</Button>
        </Grid>
      }
    </Stack >
    <MyImage src={aboutUsLaptops} sx={styles.image} />
  </Grid >
}


const styles = {
  buttonsRow: {
    display: 'grid',
    gridTemplateColumns:
      'repeat( auto-fit, minmax(min(12rem, 100%), 1fr) )',
    columnGap: '10%',
    rowGap: '1.5rem',
    width: '100%',
    '& .MuiButton-root': {
      fontSize: '1.5rem',
      textTransform: 'none',
      borderRadius: '10px',
    },
  },
  createAccountBtn: {
    border: '2px solid',
    '&:hover': { border: '2px solid', },
  },
  logInBtn: {

  },
  sectionWrapper: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
    },
    columnGap: '5%',
    rowGap: '60px',
  },
  textWrapper: {
    gap: '40px',
  },
  title: {
    fontSize: '3.5rem',
    fontWeight: '400',
    lineHeight: '1.4',
    color: 'colors.lightGrey18',
  },
  description: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey17',
    fontWeight: '400',
  },
  image: {
    height: {
      xs: '400px',
      md: '100%',
    },
    maxWidth: '100%',
    alignSelf: 'center',
  },

  pageWrapper: {
    backgroundColor: 'colors.darkGrey9',
  },
  sectionsWrapper: {
    gap: '7rem',
    padding: {
      'xs': '40px 5%',
      'md': '60px 8%',
    }
  },
}



export default PricingIntroSection
