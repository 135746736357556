import React, { useRef } from 'react'
import { Grid, Stack } from '@mui/material'
import Section1 from 'screens/ToolsSlider/sections/Section1/Section1'
import Section2 from 'screens/ToolsSlider/sections/Section2/Section2'
import Section3 from 'screens/ToolsSlider/sections/Section3/Section3'
import Section4 from 'screens/ToolsSlider/sections/Section4/Section4'
import Section5 from 'screens/ToolsSlider/sections/Section5/Section5'
import Section6 from 'screens/ToolsSlider/sections/Section6/Section6'
import toolsSections from 'screens/ToolsSlider/sections/toolsSections'
import ToolsHeader from 'screens/ToolsSlider/components/ToolsHeader/ToolsHeader'
import MouseWheeSliderLogic from 'screens/GetStarted/components/Pagination/MouseWheeSliderLogic'


const sectionIds = toolsSections?.map(section => section?.id)
const sliderId = 'toolsSlider'

export default function ToolsSliderScreen() {

  const slider = useRef()

  return <Stack sx={styles.headerAndPage}>
    <ToolsHeader />
    <Grid style={styles.pageInner}>
      <Grid style={styles.slideWrapper} ref={slider} id={sliderId}>
        <Grid item sx={styles.slideItem} id={sectionIds[0]}><Section1 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[1]}><Section2 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[2]}><Section3 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[3]}><Section4 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[4]}><Section5 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[5]}><Section6 /></Grid>
      </Grid>
      <MouseWheeSliderLogic
        {...{ sectionIds, sliderId }}
        sliderRef={slider.current}
      />
    </Grid>
  </Stack>
}



const styles = {
  headerAndPage: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'colors.darkGrey6',
  },
  pageInner: {
    width: '100%',
    height: '100%',
  },
  slideWrapper: {
    height: '100%',
    overflowX: 'hidden',
    display: 'flex',
    scrollBehavior: 'smooth',
  },
  slideItem: {
    minWidth: '100vw',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    scrollBehavior: 'smooth',
  },
}

