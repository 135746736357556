//= ===============REDUX PERSIST STORE SETUP==================
import { createStore, applyMiddleware, combineReducers } from 'redux'
import reduxThunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'
import storage from 'redux-persist/lib/storage'
import marketReducer from 'store/marketReducer'
import userReducer from 'store/userReducer'
import snackbarReducer from 'store/snackbarReducer'
import paymentReducer from 'store/paymentReducer'
import articlesReducer from 'store/articlesReducer'
import modalsReducer from 'store/modalsReducer'
import otherStatsReducer from 'store/otherStatsReducer'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['authState', 'settingsState'],
}


const userPersistConfig = {
  key: 'user',
  storage: storage,
  whitelist: ['token'],
}


const reducers = combineReducers({
  market: marketReducer,
  user: persistReducer(userPersistConfig, userReducer),
  snackbar: snackbarReducer,
  modals: modalsReducer,
  otherStats: otherStatsReducer,
  articles: articlesReducer,
  payment: paymentReducer,
})

const middleware = [reduxThunk]
const persistedReducer = persistReducer(rootPersistConfig, reducers)

const getStore = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(...middleware))
  )
  const persistor = persistStore(store)
  return { store, persistor }
}

export default getStore

