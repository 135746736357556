import React from 'react'
import { Button, Grid } from '@mui/material'

import aboutUsLogoT from 'assets/img/aboutUsLogoT.svg'
import Text from 'components/Text'
import MyImage from 'components/MyImage'
import { NavLink } from 'react-router-dom'

const AboutUsSection7 = () => <Grid sx={styles.sectionWrapper}>

  <Grid
    container sx={styles.callToAction}>
    <Text sx={styles.title}>Let's Talk</Text>
    <Text sx={styles.description}>
      Get access to primers, insights, analytics, podcasts and much more.
    </Text>
    <Button
      sx={styles.button}
      variant='contained'
      component={NavLink}
      to='/contact'
    >Contact Us</Button>
  </Grid>

  <Grid sx={styles.backgroundGrid}>
    <MyImage src={aboutUsLogoT} sx={{ backgroundPosition: 'left' }} />
    <Grid item>
    </Grid>
    <MyImage src={aboutUsLogoT} sx={{ backgroundPosition: 'right' }} />
  </Grid>
</Grid>


const styles = {
  sectionWrapper: {
    width: '100%',
    position: 'relative',
  },
  backgroundGrid: {
    display: 'grid',
    gridTemplateColumns:
      'minmax(33%, auto) 1fr minmax(33%, auto)',
    width: '100%',
    columnGap: '2%',
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: '0',
    pointerEvents: 'none',
  },
  backgroundLogo: {
    height: '100%',
    maxHeight: '100%',
  },
  callToAction: {
    width: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
    position: 'relative',
  },
  title: {
    fontWeight: '700',
    fontSize: '2rem',
    letterSpacing: '0.1em',
    marginBottom: '0.5em',
  },
  description: {
    color: 'colors.lightGrey21',
    fontSize: '1.5rem',
    marginBottom: '3em',
    textAlign: 'center',
    width: {
      xs: 'min(100%, 300px)',
      lg: 'auto',
    },
  },
  button: {
    fontSize: '1.5rem',
    textTransform: 'capitalize',
    weight: '600',
    paddingRight: '2em',
    paddingLeft: '2em',
    marginBottom: '1.5em',
  },
}


export default AboutUsSection7
