import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import React, { useState } from 'react'
import { Button } from '@mui/material'
import ProductValuesChart from 'screens/ProductDetail/ProductValuesChart'
import { addYears } from 'utils/dateUtils'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'


const years = [1, 2, 3, 4, 5, 10, 20]


const ProductsSlide1 = ({ productGuid }) => {


  const [selectedYear, setSelectedYear] = useState(years[0])
  const startDate = addYears(new Date(), -selectedYear)

  const defaultProductDetails = useSelector(
    state => state.otherStats.defaultProductDetail?.[productGuid]
  )

  const response = defaultProductDetails?.response

  const texts = [
    `${response?.ComponentsNo} components`,
    `Benchmark to beat is ${response?.BenchmarkName}`,
    `Average annualized returns are ${response?.statistics?.AnnualizedReturns}`,
    `Average annualized excess returns are ${response?.statistics?.AnnualizedExcessReturns}`,
    `Average holding period is ${response?.AverageHolding} months`,
    `Always invested`,
    `Designed for relative outperformance`,
  ]


  if (!defaultProductDetails?.isSuccess) return <Loading />

  return <Stack sx={styles.slideWrapper}>
    <Text sx={styles.title}>
      {response?.productName}
    </Text>
    <Grid sx={styles.listAndChart}>
      <ul>
        {texts.map((text, index) =>
          <Text sx={styles.listItem} component='li' key={index}>
            {text}
          </Text>
        )}
      </ul>
      <Stack sx={styles.buttonsAndChart}>
        <Grid sx={styles.chartButtons} container>
          {years.map((year, index) =>
            <Button
              sx={styles.chartButton}
              key={index}
              variant='contained'
              color={year === selectedYear ? 'primary' : 'secondary'}
              onClick={() => setSelectedYear(year)}
            >
              {year} Year
            </Button>
          )}
        </Grid>
        <ProductValuesChart {...{ productGuid, startDate }} />
      </Stack>
    </Grid >
  </Stack >
}


const styles = {
  slideWrapper: {
    gap: '20px',
    width: 'min(1600px, 95%)',
  },
  listAndChart: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '5fr 7fr',
    },
    columnGap: '5%',
    width: '100%',
    rowGap: '30px',
  },
  buttonsAndChart: {

  },
  chartButtons: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(min(90px, 100%), 1fr) )',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    padding: '10px',
    marginBottom: '10px',
    gap: '10px',
  },
  chartButton: {
    fontSize: '0.875rem',
    fontWeight: '600',
    textTransform: 'none',
  },
  title: {
    fontSize: '3.55rem',
    fontWeight: '600',
  },
  listItem: {
    fontSize: '2rem',
    lineHeight: '1.2',
    marginBottom: '0.7rem'
  },
}


export default ProductsSlide1
