import { Grid, Stack } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Loading from 'components/Loading'
import TableHead from '@mui/material/TableHead';
import { prettyMonth, stringToUnixTimestamp, toUnix } from 'utils/dateUtils'
import { prettyNumber } from 'utils/numberUtils'
import { firstLastDataPoints } from 'store/productSelector'
import { useState } from 'react'
import { getLessItems } from 'utils/productUtils'

const maxNrYears = 4

const HistoricalPerformanceTable = ({ productGuid }) => {

  const [rows, setRows] = useState()

  const performance = useSelector(state =>

    state.market.performance?.[productGuid]
      ?.Data?.Content?.PortfolioPerformances?.[0]
  )


  const basicDetails = useSelector(state => state.market.basicDetails?.[productGuid])

  const inceptionDateIndex = stringToUnixTimestamp(basicDetails?.ProductStartDate)


  useEffect(() => {
    /** To avoid calculating the table rows at every render, 
     *  they are calculated only once and stored in state. */
    if (performance && inceptionDateIndex) {

      const inceptionMonthPretty = prettyMonth(inceptionDateIndex)
      const allYears = performance?.PortfolioHistoricalComparisonValues
      const sortedYears = getSortedYears(allYears)
      const lessYears = getLessItems(sortedYears, maxNrYears)


      const yearRows = lessYears?.map(
        (yearData, index) => ({
          name: `Performance (%) since ${prettyMonth(yearData.Date)}`,
          portfolio: prettyNumber(yearData.Value),
          benchmark: prettyNumber(yearData.BenchmarkValue),
          key: index,
        })
      )

      const staticRows = [
        {
          name: `Current Portfolio Value (Invested in ${inceptionMonthPretty})`,
          portfolio: prettyNumber(performance?.CurrentValue),
          benchmark: prettyNumber(performance?.BenchmarkCurrentValue),
        },
        {
          name: `Annualized (%) Return (Since ${inceptionMonthPretty})`,
          portfolio: prettyNumber(performance?.IntervalicReturn),
          benchmark: prettyNumber(performance?.BenchmarkIntervalicReturn),
        },
        {
          name: `Annualized Std. Deviation (%)`,
          portfolio: prettyNumber(performance?.IntervalicStandardDeviation),
          benchmark: prettyNumber(performance?.BenchmarkIntervalicStandardDeviation),
        },
        {
          name: `Average Tracking Error (%)`,
          portfolio: prettyNumber(performance?.AverageTrackingError),
          benchmark: null,
        },
        {
          name: `Average Information Ratio (%)`,
          portfolio: prettyNumber(performance?.AverageInformationRatio),
          benchmark: null,
        },
      ]

      const getRows = () => {
        if (!yearRows) return staticRows
        if (!staticRows) return yearRows

        return [...yearRows, ...staticRows]
      }

      setRows(getRows())
    }
  }, [performance, inceptionDateIndex])


  return <Grid item>
    <Stack sx={styles.statisticsWrapper} direction='column'>
      <TableContainer>
        <Table sx={styles.table} aria-label='simple table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Portfolio</TableCell>
              <TableCell>Benchmark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => (
              <TableRow key={row.name} sx={styles.tableRow}>
                <TableCell>{row.name}</TableCell>
                <TableCell align='center'>{row.portfolio || '-'}</TableCell>
                <TableCell align='center'>{row.benchmark || '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
    <Loading isHidden={rows?.length} />
  </Grid>
}


const styles = {
  statisticsWrapper: {

  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
    wordBreak: 'break-word',
  },
  table: {

    borderBottom: '1px solid',
    borderBottomColor: 'colors.lightGrey9',
    '& .MuiTableCell-head.MuiTableCell-root': {
      borderBottom: '1px solid',
      borderBottomColor: 'colors.lightGrey9',
      borderTop: '1px solid',
      borderTopColor: 'colors.lightGrey9',
      fontWeight: '700',
      color: 'colors.main',
      fontSize: '0.84375rem',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '& .MuiTableCell-root': {
      padding: '0.5em 0.3em',
      fontSize: '1.125rem',
      fontWeight: '400',
      wordBreak: 'break-word',
      color: 'colors.darkGrey8',
      border: 'none',
      '&:first-of-type': { paddingLeft: 0, },
      '&:last-child': { paddingRight: 0, },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },
}


const getSortedYears = years => years?.sort((year1, year2) => {
  if (year1.Date > year2.Date) return 1
  if (year1.Date < year2.Date) return -1
  return 0
})


export default HistoricalPerformanceTable
