import { Grid, Button, Stack } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedBillingFrequency, setSelectedNrModels } from 'store/paymentReducer'
import { billingFrequencies } from 'utils/payment/billingFrequencies'
import { possibleNrModels } from 'utils/payment/possibleNrModels'


const PriceSelector = () => <Stack>
  <FrequencySelector />
  <NrModelsSelector />
</Stack>


const FrequencySelector = () => {
  const dispatch = useDispatch()
  const selectedBillingFrequency = useSelector(state => state.payment.selectedBillingFrequency)

  return <Grid container sx={styles.chartTitleWrapper}>
    <Grid item sx={styles.radioButtonGroup}>
      {Object.values(billingFrequencies)?.map((option, index) => <Button
        className={selectedBillingFrequency === option ? 'active' : ''}
        onClick={() => dispatch(setSelectedBillingFrequency(option))}
        key={index}
      >
        {option}
      </Button>)
      }
    </Grid>
  </Grid>
}


const NrModelsSelector = () => {
  const dispatch = useDispatch()
  const selectedNrModels = useSelector(state => state.payment.selectedNrModels)

  return <Grid container sx={styles.chartTitleWrapper}>
    <Grid item sx={styles.radioButtonGroup}>
      {Object.values(possibleNrModels).map((option, index) => <Button
        className={selectedNrModels === option ? 'active' : ''}
        onClick={() => dispatch(setSelectedNrModels(option))}
        key={index}
      >
        {option}
      </Button>)}
    </Grid>
  </Grid>
}


const styles = {
  chartTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '12px',
    columnGap: '20px',
  },
  radioButtonGroup: {
    backgroundColor: 'colors.main',
    borderRadius: '7px',
    width: 'auto',
    padding: '4px',
    margin: '0 0 10px 0',
    '& .MuiButton-root': {
      color: 'colors.opposite',
      textTransform: 'none',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '0.84375rem',
      '&.active': {
        backgroundColor: 'colors.primaryMain',
      },
    },
  },
  chartTitle: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
}

export default PriceSelector
