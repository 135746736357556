
const SET_IS_MENU_OPEN = 'modals/SET_IS_MENU_OPEN'
const SET_IS_SIDEBAR_OPEN = 'modals/SET_IS_SIDEBAR_OPEN'

const initialState = {
  isMenuOpen: false,
  isSidebarOpen: true,
}


const modalsReducer = (state = initialState, action) => {

  const payload = action?.payload

  switch (action?.type) {
    case SET_IS_MENU_OPEN: return { ...state, isMenuOpen: payload }
    case SET_IS_SIDEBAR_OPEN: return { ...state, isSidebarOpen: payload }
    default: return state
  }
}


export const setIsMenuOpen = isOpen => ({
  type: SET_IS_MENU_OPEN, payload: isOpen
})

export const setIsSidebarOpen = isOpen => ({
  type: SET_IS_SIDEBAR_OPEN, payload: isOpen
})


export default modalsReducer
