import { Stack } from '@mui/material'
import React from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import FaqSection from 'screens/Pricing/FaqSection'


const FaqScreen = () => <PageWithSidebar hasBackground>
  <Stack sx={styles.welcomeGrid}>
    <FaqSection />
  </Stack>
</PageWithSidebar>


const styles = {
  welcomeGrid: {
    margin: '4rem 10% 8rem 10%',
    alignItems: 'center',
    gap: '6rem',
  },
}


export default FaqScreen
