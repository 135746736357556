import React from 'react'
import Paper from '@mui/material/Paper'
import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import aboutUsPartnerAIT from 'assets/img/aboutUsPartnerAIT.png'
import aboutUsPartnerCenturys from 'assets/img/aboutUsPartnerCenturys.png'
import aboutUsPartnerMinerva from 'assets/img/aboutUsPartnerMinerva.jpg'
import aboutUsPartnerRH from 'assets/img/aboutUsPartnerRH.jpg'
import AboutUsMan from 'assets/img/AboutUsMan.jpg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const AboutUsSection6 = () => {

  const theme = useTheme()
  const isAtLeastLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isAtLeastMedium = useMediaQuery(theme.breakpoints.up('md'))
  const isAtLeastSmall = useMediaQuery(theme.breakpoints.up('sm'))


  const hasRightBorder = index => {
    if (isAtLeastLarge) return index % 4 !== 3
    if (isAtLeastMedium) return index % 3 !== 2
    if (isAtLeastSmall) return index % 2 !== 1
    return false
  }

  const getRightBorderStyle = index => ({
    borderRight: hasRightBorder(index) ? '2px solid' : 'none',
    borderRightColor: 'colors.main',
  })


  return <Stack sx={styles.sectionWrapper}>
    <Text sx={styles.title}>
      Trusted by <br />
      <b>our Awesome Partners and Clients</b>
    </Text>

    <Text sx={styles.description}>
      Though the MIT Fintech award in 2016 was an incredible validation, true innovation is an arduous journey. We have had the fortune and luxury to work closely with institutional clients and partners over the last two decades. A few of them gave us a place to operate. A few of them were the first to experiment. One added its global brand to our name. They flew us around the world to speak at international conferences. And one sat and pitched alongside us during our first venture capital funding. It did not matter to them that we were small, as they wanted to help. These contributions were priceless. We could not have reached here without their help.
      <br />
      <br />
      Bucharest Stock Exchange, Raiffeisen Asset Management, Uniqua Insurance, BRD Securities - Group Societe General, Thompson Reuters, Banca Transylvania Asset Management Romania, SSIF Broker Cluj, Broms Asset Management, Right Horizons, Cumberland Private Wealth, CastleMoore Inc., Centrys Inc., Nasdaq, MaRS Discovery District, Sal Securities, Investor’s Club, Minerva, Right Horizons, Centrys, Ait etc.
    </Text>
    <Text sx={styles.description}>

    </Text>


    <Grid sx={styles.iconsGrid}>
      <Stack sx={{ ...styles.gridItem, ...getRightBorderStyle(0) }} >
        <Paper sx={{ ...styles.iconWrapper, padding: '0', }} elevation={3}>
          <img style={{ ...styles.icon, maxWidth: '158px', }} src={aboutUsPartnerMinerva} alt='' />
        </Paper>
      </Stack>
      <Stack sx={{ ...styles.gridItem, ...getRightBorderStyle(1) }} >
        <Paper sx={styles.iconWrapper} elevation={3}>
          <img style={styles.icon} src={aboutUsPartnerRH} alt='' />
        </Paper>
      </Stack>
      <Stack sx={{ ...styles.gridItem, ...getRightBorderStyle(2) }} >
        <Paper sx={styles.iconWrapper} elevation={3}>
          <img style={styles.icon} src={aboutUsPartnerCenturys} alt='' />
        </Paper>
      </Stack>
      <Stack sx={{ ...styles.gridItem, ...getRightBorderStyle(3) }} >
        <Paper sx={styles.iconWrapper} elevation={3}>
          <img style={styles.icon} src={aboutUsPartnerAIT} alt='' />
        </Paper>
      </Stack>
    </Grid>

    <Grid sx={styles.imageAndQuote}>
      <Grid sx={styles.quoteImageWrapper}>
        <img style={styles.quoteImage} src={AboutUsMan} alt='' />
      </Grid>
      <Stack sx={styles.quoteWrapper}>
        <Text sx={styles.quoteIcon}>“</Text>
        <Text sx={styles.quoteDescription}>
          The performance in my eyes has been quite good. I am
          happiest with the performance against all and every
          benchmark - especially Ethereum - in a down market -
          when multiple second protocol blockchains are now
          in or coming to operation. If you like crypto, you
          probably like the setup here - and the point is that
          we like crypto in this company.
        </Text>
        <Text sx={styles.authorName}>Hubert Inigo</Text>
        <Text sx={styles.authorPosition}>CEO, Crypto Funds</Text>
      </Stack>
    </Grid>

  </Stack>
}

const styles = {
  sectionWrapper: {
    rowGap: '3.2rem',
  },
  title: {
    fontSize: '3.5rem',
    fontWeight: '400',
    lineHeight: '1.4',
    color: 'colors.lightGrey18',
  },
  description: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey17',
    fontWeight: '400',
    textTransform: 'none',
  },
  iconsGrid: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: '1fr 1fr',
      md: '1fr 1fr 1fr',
      lg: '1fr 1fr 1fr 1fr',
    },
    rowGap: '45px',
    width: '100%',
  },
  gridItem: {
    alignItems: 'center',

    paddingTop: '5px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  gridText: {
    fontSize: '1.215rem',
    marginTop: '2em',
  },
  iconWrapper: {
    backgroundColor: 'colors.main',
    padding: '14px',
    borderRadius: '2px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    // maxWidth: 'min(130px, 100%)',
    maxWidth: '130px',
    // width: '100%',
    // height: '100%',
  },
  imageAndQuote: {
    marginTop: '40px',
    display: 'grid',
    width: '100%',
    columnGap: '0',
    backgroundColor: 'colors.opposite',
    gridTemplateColumns: {
      xs: '1fr',
      lg: 'auto auto',
    },
  },
  quoteWrapper: {
    padding: '3.7%',
  },
  quoteImageWrapper: {
    justifySelf: 'center',
  },
  quoteImage: {
    height: '100%',
    maxWidth: '100%',
  },
  quoteIcon: {
    fontSize: '4.75rem',
    color: 'color.lightGrey20',
  },
  quoteDescription: {
    fontSize: '1.125rem',
    color: 'colors.lightGrey20',
  },
  authorName: {
    fontSize: '1.5rem',
    fontWeight: '600',
    marginTop: '1.5em',
    lineHeight: '1',
  },
  authorPosition: {
    fontSize: '1.125rem',
    textTransform: 'capitalize',
  },
}

export default AboutUsSection6
