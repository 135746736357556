import React from 'react'
import { Button } from '@mui/material'

import toolsSection2 from 'assets/img/toolsSection2.png'
import Text from 'components/Text'
import styles from 'screens/ToolsSlider/sections/Section6/Section6.module.css'

export default function Section2() {
  return <div className={styles.container}>
    <div className={styles.contentContainer}>
      <img src={toolsSection2} style={{ maxWidth: '100%', height: 'auto' }} alt='' />
      <div className={styles.quote}>
        <Text size='2.5rem'>
          “
        </Text>
        <Text size='1.125rem' mt='10%'>
          Knowing that a stock market algorithm could be applied to any data initially seemed doubtful but a proof of concept around the online store data for our thousands of products made me comfortable about the simple but effective way that any dataset can be predicted. I love the novel approach
        </Text>
        <Text size='1.25rem' mt='20px' fontWeight='bold'>
          Simona Ilynka
        </Text>
        <Text size='1.25rem'>
          Magazine Online
        </Text>
      </div>
      <div className={styles.btnContainer}>

        <a href='https://github.com/alphablockorg' style={stylesJson.regularLink}>
          <Button variant='contained' fullWidth sx={stylesJson.button}>
            <Text size='1.5rem' color='black' >
              GITHUB
            </Text>
          </Button>
        </a>

      </div>
    </div>
  </div>
}

const stylesJson = {
  regularLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  button: {
    height: 70
  },
}



