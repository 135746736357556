import React from 'react'
import { Grid } from '@mui/material'


const HorizontalSlide = ({ children, sx, ...rest }) => (
  <Grid
    sx={{ ...sx, ...styles.sectionWrapper }}
    {...rest}
  >
    {children}
  </Grid>
)


const styles = {
  sectionWrapper: {
    width: 'min(1560px, 95%)',
    margin: 'auto',
    // height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
}


export default HorizontalSlide
