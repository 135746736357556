import { Stack, Grid } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import AboutUsSection1 from 'screens/AboutUs/AboutUsSection1'
import AboutUsSection2 from 'screens/AboutUs/AboutUsSection2'
import AboutUsSection3 from 'screens/AboutUs/AboutUsSection3'
import AboutUsSection4 from 'screens/AboutUs/AboutUsSection4'
import AboutUsSection5 from 'screens/AboutUs/AboutUsSection5'
import AboutUsSection6 from 'screens/AboutUs/AboutUsSection6'
import AboutUsSection7 from 'screens/AboutUs/AboutUsSection7'
import LandingFooter from 'screens/Landing/components/LandingFooter'


const AboutUsScreen = () => {

  return <Stack sx={styles.pageWrapper}>
    <Header />
    <Stack sx={styles.sectionsWrapper}>
      <AboutUsSection1 />
      <AboutUsSection2 />
      <AboutUsSection3 />
      <AboutUsSection4 />
      <AboutUsSection5 />
      <AboutUsSection6 />
    </Stack>

    <Grid sx={styles.section7Wrapper}>
      <AboutUsSection7 />
    </Grid>

    <LandingFooter />
  </Stack>
}

const styles = {
  pageWrapper: {
    backgroundColor: 'colors.darkGrey9',
  },
  sectionsWrapper: {
    gap: '7rem',
    padding: {
      'xs': '40px 5%',
      'md': '60px 8%',
    }
  },
  section7Wrapper: {
    padding: {
      'xs': '40px 5%',
      'md': '60px 8%',
    },
    backgroundColor: 'colors.opposite',
  },
}

export default AboutUsScreen
