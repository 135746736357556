import { Grid } from '@mui/material'
import Text from 'components/Text'
import { useState } from 'react'
import Popover from '@mui/material/Popover'

const TableHeaderIcon = ({ title, desc, isSmall, ...rest }) => {

  const [anchorEl, setAnchorEl] = useState(null)

  const iconSize = isSmall ? '0.8em' : '1.2em'

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return <Grid
    sx={{ ...styles.headerCell, cursor: desc ? 'help' : 'initial', }}
    aria-owns={open ? 'mouse-over-popover' : undefined}
    aria-haspopup='true'
    onMouseEnter={handlePopoverOpen}
    onMouseLeave={handlePopoverClose}
  >
    <Text {...rest}>{title}</Text>

    {desc && <Grid
      sx={{
        ...styles.headerIconWrapper,
        width: iconSize,
        height: iconSize,
      }}>
      <Text
        sx={{
          ...styles.headerIconInner,
          fontSize:
            isSmall ? '0.643rem' : '0.843rem',
        }}
      >i</Text>
    </Grid>
    }

    {desc && <Popover
      id='mouse-over-popover'
      sx={styles.headerHover}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Text sx={styles.headerHoverText}>{desc}</Text>
    </Popover>
    }

  </Grid>

}


const styles = {
  headerCell: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gap: '0.5rem',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'help',
  },
  headerIconWrapper: {
    borderRadius: '999px',
    backgroundColor: 'colors.lightGrey3',
  },
  headerIconInner: {
    fontSize: '0.843rem',
    color: 'colors.main',
    textAlign: 'center',
    position: 'relative',
    bottom: '0.1em',
  },
  headerHoverText: {
    p: '3% 5% 4% 5%',
    width: '350px',
    backgroundColor: 'colors.main',
    color: 'colors.opposite',
    borderRadius: '15px',
    fontWeight: '300',
    fontSize: '1rem',
    '& b': {
      fontWeight: '600',
    },
  },
  headerHover: {
    pointerEvents: 'none',
    mt: '10px',
  },
}


export default TableHeaderIcon
