import React, { useRef } from 'react'
import { Grid, Stack } from '@mui/material'

import MouseWheeSliderLogic from 'screens/GetStarted/components/Pagination/MouseWheeSliderLogic'
import ProductsHeader from 'screens/ProductsSlider/components/ProductsHeader/ProductsHeader'
import ProductsSlide1 from 'screens/ProductsSlider/ProductsSlide1'
import productSlidesConfig from 'screens/ProductsSlider/productSlidesConfig'
import ProductsSlide2 from 'screens/ProductsSlider/ProductsSlide2'
import ProductsSlide3 from 'screens/ProductsSlider/ProductsSlide3'
import ProductsSlide4 from 'screens/ProductsSlider/ProductsSlide4'
import ProductsSlide5 from 'screens/ProductsSlider/ProductsSlide5'
import { useBasicDetails, useDefaultProductDetails, useDrawdowns, useMarketProducts, usePerfMonthlySummary, useRunningComponents } from 'hooks/productApiHooks'
import { useParams } from 'react-router'


const sectionIds = productSlidesConfig?.map(section => section?.id)
const sliderId = 'productSlider'

export default function ProductsScreen() {

  const { productGuid } = useParams()
  useDefaultProductDetails(productGuid)
  const slider = useRef()

  usePerfMonthlySummary(productGuid)
  useRunningComponents(productGuid)
  useDrawdowns(productGuid)
  useBasicDetails(productGuid)


  return <Stack sx={styles.headerAndPage}>
    <ProductsHeader {...{ productGuid }} />
    <Grid style={styles.pageInner}>
      <Grid style={styles.slideWrapper} ref={slider} id={sliderId}>
        <Grid item sx={styles.slideItem} id={sectionIds[0]}><ProductsSlide1 {...{ productGuid }} /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[1]}><ProductsSlide2 {...{ productGuid }} /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[2]}><ProductsSlide3 {...{ productGuid }} /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[3]}><ProductsSlide4 {...{ productGuid }} /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[4]}><ProductsSlide5 {...{ productGuid }} /></Grid>
      </Grid>
      <MouseWheeSliderLogic
        {...{ sectionIds, sliderId }}
        sliderRef={slider.current}
      />
    </Grid>
  </Stack>
}




const styles = {
  headerAndPage: {
    width: '100%',
    height: '100vh',
    backgroundColor: 'colors.darkGrey6',
  },
  pageInner: {
    width: '100%',
    height: '100%',
  },
  slideWrapper: {
    height: '100%',
    overflowX: 'hidden',
    display: 'flex',
    scrollBehavior: 'smooth',
  },
  slideItem: {
    minWidth: '100vw',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    scrollBehavior: 'smooth',
  },
}

