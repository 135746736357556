import React from 'react'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMenuOpen } from 'store/modalsReducer'


const HamburgerMenu = () => {

  const dispatch = useDispatch()
  const isMenuOpen = useSelector(state => state.modals.isMenuOpen)
  const isSidebarOpen = useSelector(state => state.modals.isSidebarOpen)


  return <IconButton
    onClick={() => {
      dispatch(setIsMenuOpen(!isMenuOpen))
    }}
    aria-label='menu'
    /**
     * When the Sidebar Drawer is open, the body overflow and its scrollbar are hidden. 
     * It changes the width of the body and therefore moves this HamburgerIcon by 17px.
     * We need to compansate to keep it in place: 
     */
    sx={{ ...styles.menuIcon, right: isSidebarOpen ? '39px' : '22px', }}
  >
    {isMenuOpen
      ? <CloseIcon />
      : <MenuIcon />
    }
  </IconButton>
}


const styles = {
  menuIcon: {
    height: '52px',
    width: '52px',
    position: 'absolute',
    right: '22px',
    top: '22px',
    backgroundColor: 'colors.main',
    color: 'colors.opposite',
    borderRadius: '0',
    zIndex: '5',
    '&:hover': { backgroundColor: 'colors.lightGrey5', },
    '& .MuiSvgIcon-root': { fontSize: '33px', },
  },
}

export default HamburgerMenu
