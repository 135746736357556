import { Grid, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { PieChart, Pie, Tooltip, ResponsiveContainer, Cell } from "recharts"
import Text from 'components/Text'
import { getNColors } from 'utils/generalUtils'
import { prettyPercent } from 'utils/numberUtils'
import { colors } from 'theme'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import SquareIcon from '@mui/icons-material/Square'

const nrColumns = 2

const ProductComparatives = ({ productGuid }) => {

  const comparatives = useSelector(state => state.market.comparatives?.[productGuid])
  const [sectorSums, setSectorSums] = useState()


  useEffect(() => {

    const sums = getSectorSums(comparatives)
    const colors = getNColors(sums?.length)

    const sumsWithColors = sums?.map((sector, index) => ({
      color: colors?.[index % colors?.length],
      ...sector,
    }))

    setSectorSums(sumsWithColors)
  }, [comparatives])

  const pieProps = {
    dataKey: 'portfolio',
    nameKey: 'sectorName',
    isAnimationActive: true,
    data: sectorSums,
    outerRadius: '100%',
    startAngle: 540,
    endAngle: 180,
    minAngle: 1,
  }

  const dataExists = Boolean(sectorSums?.length)

  return <Grid container justifyContent="center">
    <Grid item sx={styles.sectionWrapper}>
      <Stack sx={styles.statisticsWrapper} direction='column'>
        <Text sx={styles.tableTitle}>Proportion in Portfolio</Text>
        <TableContainer sx={styles.tableContainer}>
          <Table sx={styles.table} aria-label='simple table'>
            <TableBody>
              {sectorSums?.map((sector, index) => <TableRow key={index}>
                <TableCell align='left'>
                  <Grid container sx={styles.nameAndIcon}>
                    <SquareIcon sx={{ color: sector.color }} />
                    <Grid item>{sector.sectorName}</Grid>
                  </Grid>
                </TableCell>
                <TableCell align='right' sx={{ wordBreak: 'normal' }}>
                  {prettyPercent(sector.portfolio) || '-'}
                </TableCell>
              </TableRow>)}
              {!dataExists &&
                <TableRow>
                  <TableCell colSpan={nrColumns}>
                    <Text sx={styles.tableTitle}>No data.</Text>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Grid sx={styles.chartWrapper}>
        {dataExists ?
          <ResponsiveContainer>
            <PieChart >
              <Pie {...pieProps}>
                {sectorSums?.map((sector, index) =>
                  <Cell fill={sector?.color} key={index} />
                )}
              </Pie>
              <Tooltip formatter={prettyPercent} />
            </PieChart>
          </ResponsiveContainer>
          :
          <Text sx={styles.tableTitle}>No data.</Text>
        }
      </Grid>
    </Grid>
  </Grid>
}

const styles = {
  sectionWrapper: {
    width: 'min(887px, 100%)',
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(min(260px, 100%), 1fr) )',
    columnGap: '5%',
    rowGap: '30px',
    backgroundColor: 'colors.darkGrey6',
    borderRadius: '14px',
    padding: '4% 4% 3% 3%',
  },
  chartWrapper: {
    height: '405px',
    '& .recharts-tooltip-label': { color: 'colors.lightGrey8', },
    '& .recharts-default-tooltip': { textAlign: 'right', },
    '& .recharts-legend-item-text': { color: `${colors.main} !important`, }
  },
  tableContainer: {
    backgroundColor: 'colors.darkGrey6',
    borderRadius: '14px',
    padding: '3% 7%',
  },
  tableTitle: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
    wordBreak: 'break-word',
    textAlign: 'center',
    textTransform: 'none',
    marginBottom: '0.5em',
  },
  nameAndIcon: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
  table: {
    borderTop: '1px solid',
    borderTopColor: 'colors.lightGrey9',
    '& .MuiTableCell-head.MuiTableCell-root': {
      fontWeight: '700',
      color: 'colors.main',
      fontSize: '0.84375rem',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.125rem',
      fontWeight: '400',
      wordBreak: 'break-word',
      color: 'colors.darkGrey8',
      border: 'none',
      padding: '0.1em',
      '&:first-of-type': { paddingLeft: 0, },
      '&:last-child': { paddingRight: 0, },
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },

}


/**
 * For every unique sector name, returns the sum of 
 * BenchmarkProportion and Portfolio Proportion. Example return:
 * {
 *    {
 *        sectorName: 'Consumer Services',
 *        portfolio: 23.456789,
 *        benchmark: 54.987654, 
 *     },
 *    {
 *        sectorName: 'Technology',
 *        portfolio: 89.456789,
 *        benchmark: 56.987654, 
 *     },
 * }
 */
const getSectorSums = comparatives => {

  /** Associates every sectorName to an object containing its'
   * {portfolio, benchmark} proportion sums. */
  const sectorSumsAssociativeArray = comparatives?.reduce((sums, comparative) => {

    const sectorName = comparative?.Sector
    const portfolioSum = sums[sectorName]?.portfolio
    const benchmarkSum = sums[sectorName]?.benchmark
    const portfolioValue = comparative?.PortfolioProportion
    const benchmarkValue = comparative?.BenchmarkProportion

    return {
      ...sums,
      [sectorName]: {
        sectorName,
        portfolio: isNaN(portfolioSum) ? portfolioValue : portfolioSum + portfolioValue,
        benchmark: isNaN(benchmarkSum) ? benchmarkValue : benchmarkSum + benchmarkValue,
      }
    }
  }, {})
  // Converted to an array, in the final format, ready to be given to the chart.
  const arrayVersion = sectorSumsAssociativeArray &&
    Object.values(sectorSumsAssociativeArray)


  const sorted = arrayVersion?.sort((dataPoint1, dataPoint2) => {
    if (dataPoint1.portfolio < dataPoint2.portfolio) return 1
    if (dataPoint1.portfolio > dataPoint2.portfolio) return -1
    return 0
  })

  return sorted

}


export default ProductComparatives
