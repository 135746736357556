import { billingFrequencies } from "utils/payment/billingFrequencies"
import { possibleNrModels } from "utils/payment/possibleNrModels"
import { subscriptionTypes } from "utils/payment/subscriptionTypes"


const TEST_stripeProductIDs = {
  [subscriptionTypes.type2]: {
    [possibleNrModels.oneModel]: { // stripeProductId is prod_LvJjoJq8FYNTX5
      [billingFrequencies.monthly]: 'price_1LDT5vIiuMmz8ldqpFKBak5N',
      [billingFrequencies.yearly]: 'price_1LDT5vIiuMmz8ldq9Oip2jdB',
    },
    [possibleNrModels.threeModels]: { // stripeProductId is prod_LvMIa26mv4a7e3
      [billingFrequencies.monthly]: 'price_1LDVZnIiuMmz8ldqDT3mfqVN',
      [billingFrequencies.yearly]: 'price_1LDVZnIiuMmz8ldqGMnqHCpZ',
    },
    [possibleNrModels.tenModels]: { // stripeProductId is prod_LvMKXbcRTnbdg2
      [billingFrequencies.monthly]: 'price_1LDVbhIiuMmz8ldq80oa2MKF',
      [billingFrequencies.yearly]: 'price_1LDVbhIiuMmz8ldq36J9meBi',
    },
  },
  [subscriptionTypes.type3]: {
    /** These are all 'monthly' prices actually. This 
     * subscription does NOT have 'yearly' option. */
    [possibleNrModels.oneModel]: { // stripeProductId is prod_LvMSoTsLFhymto
      [billingFrequencies.monthly]: 'price_1LDVk4IiuMmz8ldq6V3EenuW',
      [billingFrequencies.yearly]: 'price_1LDVk4IiuMmz8ldq6V3EenuW',
    },
    [possibleNrModels.threeModels]: { // stripeProductId is prod_LvMU14RszpL0dT
      [billingFrequencies.monthly]: 'price_1LDVlSIiuMmz8ldqJZxlf6df',
      [billingFrequencies.yearly]: 'price_1LDVlSIiuMmz8ldqJZxlf6df',
    },
    [possibleNrModels.tenModels]: { // stripeProductId is prod_LvMUyzmdqHuFNz
      [billingFrequencies.monthly]: 'price_1LDVlzIiuMmz8ldqOShEQJKy',
      [billingFrequencies.yearly]: 'price_1LDVlzIiuMmz8ldqOShEQJKy',
    },
  },
}


const LIVE_stripeProductIDs = {
  [subscriptionTypes.type2]: {
    [possibleNrModels.oneModel]: { // stripeProductId is prod_LvJjoJq8FYNTX5
      [billingFrequencies.monthly]: 'price_1LKQmwIiuMmz8ldqIwLSbseR',
      [billingFrequencies.yearly]: 'price_1LKQmwIiuMmz8ldqb1l1tKtN',
    },
    [possibleNrModels.threeModels]: { // stripeProductId is prod_LvMIa26mv4a7e3
      [billingFrequencies.monthly]: 'price_1LKQnGIiuMmz8ldq8t3yXVGK',
      [billingFrequencies.yearly]: 'price_1LKQnFIiuMmz8ldqaxefrvxO',
    },
    [possibleNrModels.tenModels]: { // stripeProductId is prod_LvMKXbcRTnbdg2
      [billingFrequencies.monthly]: 'price_1LKQmlIiuMmz8ldqogTDk6Kq',
      [billingFrequencies.yearly]: 'price_1LKQmlIiuMmz8ldqQLwImtAK',
    },
  },
  [subscriptionTypes.type3]: {
    /** These are all 'monthly' prices actually. This 
     * subscription does NOT have 'yearly' option. */
    [possibleNrModels.oneModel]: { // stripeProductId is prod_LvMSoTsLFhymto
      [billingFrequencies.monthly]: 'price_1LKQmeIiuMmz8ldqauISBUHT',
      [billingFrequencies.yearly]: 'price_1LKQmeIiuMmz8ldqauISBUHT',
    },
    [possibleNrModels.threeModels]: { // stripeProductId is prod_LvMU14RszpL0dT
      [billingFrequencies.monthly]: 'price_1LKQmYIiuMmz8ldqAyd5QRNq',
      [billingFrequencies.yearly]: 'price_1LKQmYIiuMmz8ldqAyd5QRNq',
    },
    [possibleNrModels.tenModels]: { // stripeProductId is prod_LvMUyzmdqHuFNz
      [billingFrequencies.monthly]: 'price_1LKQmPIiuMmz8ldqSFHrsRI3',
      [billingFrequencies.yearly]: 'price_1LKQmPIiuMmz8ldqSFHrsRI3',
    },
  },
}




const appConfig = {
  // apiBaseUrl: 'http://192.168.2.103:8095/api', // UAT
  apiBaseUrl: 'https://alphablock.org/api',  // PROD

  // cmsApiUrl: 'http://localhost/testCms/wp-admin/admin-ajax.php', // UAT
  cmsApiUrl: 'https://mukulpal.ro/wp-admin/admin-ajax.php', // PROD


  // TEST Stripe publishable key (from TEST Stripe account): 
  // stripePublishableKey: 'pk_test_51KJJxmIiuMmz8ldqyvg6xgCbadbKqPyh7Zucx1cB1ROWx8yRydNDcYm87GDOPITpuO2GHsWK9zSPjKqEEZE0Ssub00DEciIZP1',

  // LIVE Stripe publishable key (from LIVE Stripe account): 
  stripePublishableKey: 'pk_live_51KJJxmIiuMmz8ldq6kKTwNF4ScZECPjhuEncbER058HS8yW1EfUShXzc4PJCH2ydiwJvv0a5ideiXXIkelOYru9E00oPqqNrde',


  // allStripePriceIds: TEST_stripeProductIDs // TEST Stripe
  allStripePriceIds: LIVE_stripeProductIDs // LIVE Stripe


}



export default appConfig
