import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead';
import { prettyDate, } from 'utils/dateUtils'
import { prettyNumber } from 'utils/numberUtils'
import { useState } from 'react'
import Text from 'components/Text'

const initialTableData = {
  portfolios: null,
  benchmarks: null,
}

const nrColumns = 4

const ProductDrawdownTables = ({ productGuid }) => {

  const drawdowns = useSelector(state =>
    state.market.drawdowns?.[productGuid]
  )

  const [tableData, setTableData] = useState(initialTableData)

  useEffect(() => {
    setTableData({
      portfolios: getRows(drawdowns?.drawDownPortfolios),
      benchmarks: getRows(drawdowns?.drawDownBenchmarks),
    })
  }, [drawdowns])
  // <Loading isHidden={tableData?.portfolios?.length} />


  return <>
    <Grid sx={styles.statisticsWrapper} direction='column' container>
      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} aria-label='simple table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell colSpan={nrColumns}>
                <Text sx={styles.tableTitle}>Portfolio Drawdowns (%)</Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Maximum(%)</TableCell>
              <TableCell>Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.portfolios?.map((row, index) => (
              <TableRow key={index} sx={styles.tableRow}>
                <TableCell>{row.StartDate || '-'}</TableCell>
                <TableCell>{row.EndDate || '-'}</TableCell>
                <TableCell>{row.Maximum || '-'}</TableCell>
                <TableCell>{row.Days || '-'}</TableCell>
              </TableRow>
            ))}

            {!tableData?.portfolios?.length &&
              <TableRow>
                <TableCell colSpan={nrColumns}>
                  <Text sx={styles.tableTitle}>No match found.</Text>
                </TableCell>
              </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>


      <TableContainer sx={styles.tableContainer}>
        <Table sx={styles.table} aria-label='simple table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell colSpan={nrColumns}>
                <Text sx={styles.tableTitle}>Benchmark Drawdowns (%)</Text>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Maximum(%)</TableCell>
              <TableCell>Days</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData?.benchmarks?.map((row, index) => (
              <TableRow key={index} sx={styles.tableRow}>
                <TableCell>{row.StartDate || '-'}</TableCell>
                <TableCell>{row.EndDate || '-'}</TableCell>
                <TableCell>{row.Maximum || '-'}</TableCell>
                <TableCell>{row.Days || '-'}</TableCell>
              </TableRow>
            ))}

            {!tableData?.benchmarks?.length &&
              <TableRow>
                <TableCell colSpan={nrColumns}>
                  <Text sx={styles.tableTitle}>No match found.</Text>
                </TableCell>
              </TableRow>
            }

          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </>
}


const styles = {
  statisticsWrapper: {
    margin: '30px 0',
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(min(459px, 100%), 1fr) )',
    columnGap: '5%',
    rowGap: '30px',

  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
    wordBreak: 'break-word',
  },
  tableTitle: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
    wordBreak: 'break-word',
    textAlign: 'center',
    textTransform: 'none',
    marginBottom: '0.5em',
  },
  tableContainer: {
    backgroundColor: 'colors.darkGrey6',
    borderRadius: '14px',
    padding: '3% 7%',
  },
  table: {
    // borderBottom: '1px solid',
    // borderBottomColor: 'colors.lightGrey9',
    '& .MuiTableCell-head.MuiTableCell-root': {
      fontWeight: '700',
      color: 'colors.main',
      fontSize: '0.84375rem',
      textAlign: 'center',
      textTransform: 'uppercase',
    },
    '& .MuiTableCell-root': {
      padding: '0.5em 0.3em',
      fontSize: '1.125rem',
      fontWeight: '400',
      wordBreak: 'break-word',
      color: 'colors.darkGrey8',
      border: 'none',
      textAlign: 'center',
      '&:first-of-type': { paddingLeft: 0, },
      '&:last-child': { paddingRight: 0, },
    },
  },
  tableHead: {
    borderBottom: '1px solid',
    borderBottomColor: 'colors.lightGrey9',
    // borderTop: '1px solid',
    // borderTopColor: 'colors.lightGrey9',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },
}


const getRows = items => items

  ?.map(item => ({
    StartDate: prettyDate(item.StartDate),
    EndDate: prettyDate(item.EndDate),
    Maximum: prettyNumber(item.Maximum),
    Days: item.Days,
  }))

export default ProductDrawdownTables

