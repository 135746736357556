import React from 'react'
import TextField from '@mui/material/TextField'
import { Button, Grid } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSimulations, setSimulationsFilter } from 'store/marketReducer'



const SimulationsDateFilter = ({ productGuid }) => {

  const dispatch = useDispatch()

  const { fromDate, toDate } = useSelector(
    state => state.market.simulationsFilter
  )

  const setFromDate = newValue => dispatch(
    setSimulationsFilter({ fromDate: newValue, toDate, productGuid })
  )
  const setToDate = newValue => dispatch(
    setSimulationsFilter({ fromDate, toDate: newValue, productGuid })
  )

  return <Grid sx={styles.wrapper}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container sx={styles.datePickers}>
        <MobileDatePicker
          {...datePickerProps}
          label="From"
          value={fromDate}
          onChange={setFromDate}
        />
        <MobileDatePicker
          {...datePickerProps}
          label="To"
          value={toDate}
          onChange={setToDate}
        />
        <Button
          variant='contained'
          color='primary'
          sx={styles.loadButton}
          onClick={() => dispatch(fetchSimulations(
            { productGuid, fromDate, toDate }
          ))}
        >
          Load data
        </Button>
      </Grid>
    </LocalizationProvider>
  </Grid>
}

const styles = {
  wrapper: {
    margin: '20px 0',
  },
  datePickers: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    columnGap: '20px',
    rowGap: '10px',
  },
  loadButton: {
    height: '53px',
    fontSize: '1rem',
    textTransform: 'none',
    fontWeight: '600',
  },
}

const datePickerProps = {
  inputFormat: 'dd MMM yyyy',
  showToolbar: false,
  renderInput: params => <TextField {...params} />
  // openTo: 'year',
}

export default SimulationsDateFilter