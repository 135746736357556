import { useEffect, useState } from 'react'

/**
 * @returns the id of the DOM element scrolled into view
 */
const useVisibleId = ({ idList }) => {
  const [activeId, setActiveId] = useState()

  useEffect(() => {
    const idListCopy = idList

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id)
          }
        })
      },
      { rootMargin: `0% -80% -80% 0%` }
    )

    const elements = idListCopy?.map(
      id => document.getElementById(id)
    )

    // Set observers: 
    elements?.forEach(
      element => observer?.observe(element)
    )

    // Cleanup observers when component unmounts
    return () => elements?.forEach(
      element => observer?.unobserve(element)
    )
  }, [idList])

  return activeId
}


export default useVisibleId
