import { createTheme } from '@mui/material/styles'


export const colors = {

  main: '#FFF',
  opposite: '#000',
  primaryMain: '#6AE1AC',
  primaryPale: '#6AE1AC17',
  primaryPale2: '#ffffff22',
  primaryPale3: '#def6eb',



  almostPrimary1: '#219653',
  almostPrimary2: '#30544c',
  almostPrimary3: '#4a6259',
  almostPrimary4: '#556e69',




  lightGrey1: '#F2F2F2',
  lightGrey2: '#FCFCFC',
  lightGrey3: '#7C7C7C',
  lightGrey4: '#777878',
  lightGrey5: '#C4C4C4',
  lightGrey6: '#FBFBFB',
  lightGrey7: '#E3E3E3',
  lightGrey8: '#A2A2A2',
  lightGrey9: '#929292',
  lightGrey10: '#9c9c9c',
  lightGrey11: '#D7D7D7',
  lightGrey12: '#D1D1D1',
  lightGrey13: '#F3F3F3',
  lightGrey14: '#A4A4A4',
  lightGrey15: '#E5E5E5',
  lightGrey16: '#AFAFAF',
  lightGrey17: '#E8E8E8',
  lightGrey18: '#F9F9F9',
  lightGrey19: '#D2D2D2',
  lightGrey20: '#EFEFEF',
  lightGrey21: '#8A8888',
  lightGrey22: '#F4F4F4',
  lightGrey23: '#F1F1F1',
  lightGrey24: '#C7C7C7',
  lightGrey25: '#1b1d1d',
  lightGrey26: '#B7B4C7',
  lightGrey27: '#F5F4FA',
  lightGrey28: '#E6E6E6',
  lightGrey29: '#D6D6D6',
  lightGrey30: '#d6e4ec',






  darkGrey1: '#717272',
  darkGrey2: '#272727',
  darkGrey3: '#191919',
  darkGrey4: '#151515',
  darkGrey5: '#606060',
  darkGrey6: '#1B1C1C',
  darkGrey7: '#242626',
  darkGrey8: '#CCCCCC',
  darkGrey9: '#1b1b1b',
  darkGrey10: '#575757',
  darkGrey11: '#13111a',
  darkGrey12: '#6A6A6A',
  darkGrey13: '#DCDCDC',




  success: '#10FF0B',
  successBackground: '#014B0D',
  error: '#ff6b6b',
  errorBackground: '#4b0101',
  durationBtn: '#6A5858',
  chartLine1: '#BCB4FB',
  chartLine2: '#4ddf15',
  link: '#0084ff',
  linkFancyBlue: '#0085FF',
}

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: colors.primaryMain },
    secondary: { main: colors.main },
    colors: colors,
  },
  typography: {
    fontFamily: "'Source Sans Pro', sans-serif",
    wordBreak: 'break-word',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        columnHeaders: {
          borderTop: 'solid 1px',
          borderBottom: 'solid 1px',
          borderColor: 'colors.lightGrey12',
        },
        columnHeadersInner: {
          marginLeft: '4%',
          marginRight: '4%',
        },
        columnHeaderTitle: {
          fontFamily: 'Montserrat',
          fontSize: '0.843rem',
          fontWeight: '300',
          color: 'colors.lightGrey1',
          textAlign: 'left',
        },
        cell: {
          border: 'none',
          fontFamily: 'Montserrat',
          color: 'colors.main',
          fontSize: '0.843rem',
          fontWeight: '500',
          '&:focus': {
            outline: 'none',
          },
        },
        row: {
          marginLeft: '4%',
          marginRight: '4%',
          '&:hover': {
            backgroundColor: 'colors.primaryPale',
          },
        },
        columnSeparator: {
          display: 'none',
        },
        menuIconButton: {
          display: 'none',
        },
        virtualScroller: {
          borderBottom: 'solid 1px',
          borderBottomColor: 'colors.lightGrey12',
        },
      },
    },
  },
})

