import React, { useState } from 'react'
import { useLocation } from 'react-router'
import styles from 'screens/ProductsSlider/components/ProductsNavigation/ProductsNavigation.module.css'
import HorizontalScrollCircle from 'screens/GetStarted/components/Pagination/HorizontalScrollCircle'
import HorizontalScrollDivider from 'screens/GetStarted/components/Pagination/HorizontalScrollDivider'
import productSlidesConfig from 'screens/ProductsSlider/productSlidesConfig'


const ProductsNavigation = ({ productGuid }) => {

  const { hash } = useLocation()
  const [isLabelVisible, setIsLabelVisible] = useState(false)

  const isActive = (section, index) =>
    hash ? (section.id === hash?.substring(1)) : index === 0


  const dotProps = productSlidesConfig?.map((section, index) => ({
    isActive: isActive(section, index),
    linkTo: `/products/${productGuid}#${section.id}`,
    label: section.label,
    setIsLabelVisible,
  }))


  return <div className={styles.container} >
    <HorizontalScrollCircle {...dotProps[0]} />
    <HorizontalScrollDivider sectionTitle=''  {...{ isLabelVisible }} />
    <HorizontalScrollCircle {...dotProps[1]} />
    <HorizontalScrollCircle {...dotProps[2]} />
    <HorizontalScrollDivider sectionTitle=''  {...{ isLabelVisible }} />
    <HorizontalScrollCircle {...dotProps[3]} />
    <HorizontalScrollCircle {...dotProps[4]} />
  </div>
}


export default ProductsNavigation
