import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import useVisibleId from 'hooks/useVisibleId';

const anchorDelayMs = 610

const ScrollAnchorLogic = ({ idList, setActiveId }) => {

  const anchorClickTimestamp = useRef()

  const activeId = useVisibleId({ idList })
  const { hash } = useLocation()


  useEffect(() => {
    if (hash) {
      anchorClickTimestamp.current = Date.now()
      setActiveId(hash?.substring(1))
    }
  }, [hash]);


  useEffect(() => {
    if (
      !anchorClickTimestamp.current ||
      Date.now() - anchorClickTimestamp.current > anchorDelayMs
    ) setActiveId(activeId)
  }, [activeId]);


  return null

}

export default ScrollAnchorLogic
