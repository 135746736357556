import React from 'react'
import Router from 'routes/Router'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import 'App.css'
import { theme } from 'theme.js'
import MySnackbar from 'components/MySnackbar'
import ModalMenu from 'components/ModalMenu'


export default function App() {

  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <Router />
      <MySnackbar />
      <ModalMenu />
    </BrowserRouter>
  </ThemeProvider>
}
