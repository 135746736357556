import appConfig from "appConfig"
import axios from "axios"
import { apiStatuses } from "utils/apiStatuses"
import { myAxios, printError } from "utils/apiUtils"


const apiBaseUrl = appConfig.apiBaseUrl

const FETCH_LANDING_STATISTICS_START = 'otherStats/FETCH_LANDING_STATISTICS_START'
const FETCH_LANDING_STATISTICS_SUCCESS = 'otherStats/FETCH_LANDING_STATISTICS_SUCCESS'
const FETCH_LANDING_STATISTICS_ERROR = 'otherStats/FETCH_LANDING_STATISTICS_ERROR'

const FETCH_DEFAULT_PRODUCTS_START = 'otherStats/FETCH_DEFAULT_PRODUCTS_START'
const FETCH_DEFAULT_PRODUCTS_SUCCESS = 'otherStats/FETCH_DEFAULT_PRODUCTS_SUCCESS'
const FETCH_DEFAULT_PRODUCTS_ERROR = 'otherStats/FETCH_DEFAULT_PRODUCTS_ERROR'

const FETCH_DEFAULT_PROD_DETAIL_START = 'otherStats/FETCH_DEFAULT_PROD_DETAIL_START'
const FETCH_DEFAULT_PROD_DETAIL_SUCCESS = 'otherStats/FETCH_DEFAULT_PROD_DETAIL_SUCCESS'
const FETCH_DEFAULT_PROD_DETAIL_ERROR = 'otherStats/FETCH_DEFAULT_PROD_DETAIL_ERROR'


const initialState = {
  landingStatistics: apiStatuses.getInitial(),
  defaultProducts: apiStatuses.getInitial(),
  // productGuid-s will be the fields of this object:
  defaultProductDetail: {},
}


const otherStatsReducer = (state = initialState, action) => {
  const payload = action?.payload

  const insertProdDetailInState = dataToInsert => {
    if (!payload?.productGuid) return state

    return {
      ...state,
      defaultProductDetail: {
        ...state.defaultProductDetail,
        [payload.productGuid]: dataToInsert,
      }
    }
  }

  switch (action?.type) {
    case FETCH_LANDING_STATISTICS_START: return { ...state, landingStatistics: apiStatuses.getStarted(), }
    case FETCH_LANDING_STATISTICS_SUCCESS: return { ...state, landingStatistics: apiStatuses.getSuccess(payload), }
    case FETCH_LANDING_STATISTICS_ERROR: return { ...state, landingStatistics: apiStatuses.getError(payload), }

    case FETCH_DEFAULT_PRODUCTS_START: return { ...state, defaultProducts: apiStatuses.getStarted(), }
    case FETCH_DEFAULT_PRODUCTS_SUCCESS: return { ...state, defaultProducts: apiStatuses.getSuccess(payload), }
    case FETCH_DEFAULT_PRODUCTS_ERROR: return { ...state, defaultProducts: apiStatuses.getError(payload), }

    case FETCH_DEFAULT_PROD_DETAIL_START: return insertProdDetailInState(apiStatuses.getStarted(payload))
    case FETCH_DEFAULT_PROD_DETAIL_SUCCESS: return insertProdDetailInState(apiStatuses.getSuccess(payload?.data))
    case FETCH_DEFAULT_PROD_DETAIL_ERROR: return insertProdDetailInState(apiStatuses.getError(payload?.error))

    default: return state
  }
}


/**
 * @param {*} productNumber Is not productGuid, but an actual number, like 1 or 2 or 3. 
 * It is calculated based on the array index of a productGuid from state.defaultProducts. 
 */
export const fetchDefaultProductDetails = productGuid => dispatch => {

  if (!productGuid) {
    console.error('Error at fetchDefaultProductDetails(): productGuid cannot be empty. ')
    return
  }

  dispatch({ type: FETCH_DEFAULT_PROD_DETAIL_START, payload: { productGuid }, })

  myAxios({
    axiosCallerFn: () => axios.get(apiBaseUrl + '/landing/default-product-details', {
      params: {
        productGuid: productGuid || '',
      },
    }),
    onSuccess: response => {
      dispatch({
        type: FETCH_DEFAULT_PROD_DETAIL_SUCCESS,
        payload: {
          productGuid,
          data: response?.data,
        }
      })
    },
    onError: error => {
      dispatch({ type: FETCH_DEFAULT_PROD_DETAIL_ERROR, payload: { error, productGuid } })
      printError('fetchDefaultProductDetails()', error, dispatch)
    },
    dispatch,
  })
}



export const fetchLandingStatistics = () => dispatch => {
  dispatch({ type: FETCH_LANDING_STATISTICS_START })

  myAxios({
    axiosCallerFn: () => axios.get(apiBaseUrl + '/landing/statistics'),
    onSuccess: response => dispatch({
      type: FETCH_LANDING_STATISTICS_SUCCESS,
      payload: response?.data
    }),
    onError: error => {
      dispatch({ type: FETCH_LANDING_STATISTICS_ERROR, payload: error })
      printError('fetchLandingStatistics()', error, dispatch)
    },
    dispatch,
  })
}


export const fetchDefaultProducts = () => dispatch => {
  dispatch({ type: FETCH_DEFAULT_PRODUCTS_START })

  myAxios({
    axiosCallerFn: () => axios.get(apiBaseUrl + '/landing/default-products'),
    onSuccess: response => dispatch({
      type: FETCH_DEFAULT_PRODUCTS_SUCCESS,
      payload: response?.data
    }),
    onError: error => {
      dispatch({ type: FETCH_DEFAULT_PRODUCTS_ERROR, payload: error })
      printError('fetchDefaultProducts()', error, dispatch)
    },
    dispatch,
  })
}


export default otherStatsReducer
