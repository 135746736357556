import React from 'react'
import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Loading from 'components/Loading'
import { useSelector } from 'react-redux'


const ProductsSlide5 = ({ productGuid }) => {

  const defaultProductDetails = useSelector(
    state => state.otherStats.defaultProductDetail?.[productGuid]
  )

  const stats = defaultProductDetails?.response?.statistics

  if (!defaultProductDetails?.isSuccess) return <Loading />


  return <Stack sx={styles.slideWrapper}>
    <Grid sx={styles.iconsGrid}>
      <GridItem largeText={stats?.AnnualizedReturns} smallText='Annualized Return' index={0} />
      <GridItem largeText={stats?.AnnualizedExcessReturns} smallText='Annualized Excess Returns' index={1} />
      <GridItem largeText={stats?.AnnualizedVolatility} smallText='Annualized Volatility' index={2} />
      <GridItem largeText={stats?.AnnualizedExcessVolatility} smallText='Annualized Excess Volatility' index={3} />
      <GridItem largeText={stats?.TrackingError} smallText='Average Tracking Error' index={4} />
      <GridItem largeText={stats?.InformationRatio} smallText='Average Information Ratio' index={5} />
    </Grid>
  </Stack>
}

const GridItem = ({ smallText, largeText, index = 0 }) => {

  const theme = useTheme()
  const isAtLeastLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const hasRightBorder = index => {
    if (isAtLeastLarge) return index % 3 !== 2
    return index % 2 !== 1
  }

  const getRightBorderStyle = index => ({
    borderRight: hasRightBorder(index) ? '2px solid' : 'none',
    borderRightColor: 'colors.main',
  })

  return <Stack
    sx={{ ...styles.gridItem, ...getRightBorderStyle(index) }}
    key={index}
  >
    <Text sx={styles.largeText}>{largeText}</Text>
    <Text sx={styles.gridText}>{smallText}</Text>
  </Stack>
}


const styles = {
  slideWrapper: {
    gap: '20px',
    width: 'min(1600px, 95%)',
  },
  iconsGrid: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr 1fr',
      lg: '1fr 1fr 1fr',
    },
    rowGap: {
      xs: '25px',
      sm: '45px',
      md: '65px',
      lg: '85px',
    },
    width: '100%',
  },
  gridItem: {
    alignItems: 'center',
    paddingTop: '5px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  gridText: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  icon: {
    width: 'min(100%, 150px)',
  },
  largeText: {
    fontSize: '3rem',
  },
}

export default ProductsSlide5
