import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Loading from 'components/Loading'
import Text from 'components/Text'
import { prettyDate, stringToUnixTimestamp, timeAgo } from 'utils/dateUtils'
import { prettyCurrency } from 'utils/numberUtils'

const PortfolioMetrics = ({ productGuid }) => {

  const basicDetails = useSelector(state => state.market.basicDetails?.[productGuid])

  const rows = [
    {
      name: 'Inception Date',
      value: prettyDate(new Date(basicDetails?.ProductStartDate)),
    },
    {
      name: 'Current Date',
      value: prettyDate(Date.now()),
    },
    {
      name: 'Last Rebalance',
      value: prettyDate(new Date(stringToUnixTimestamp(
        basicDetails?.LastRebalanceDate
      ))),
    },
    {
      name: 'Starting value',
      value: prettyCurrency(100),
    },
    {
      name: 'Current value',
      value: prettyCurrency(basicDetails?.CurrentValue),
    },
  ]


  if (!basicDetails) return <Grid item>
    <Stack sx={styles.statisticsWrapper} direction='column'>
      <Loading />
    </Stack>
  </Grid>


  return <Grid item>
    <Stack sx={styles.statisticsWrapper} direction='column'>
      <Text sx={styles.title}>
        Portfolio Metrics
      </Text>
      <TableContainer>
        <Table sx={styles.table} aria-label='simple table'>
          <TableBody>
            {rows?.map(row => (
              <TableRow key={row.name} sx={styles.tableRow}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  </Grid>
}


const styles = {
  statisticsWrapper: {
    backgroundColor: 'colors.darkGrey6',
    borderRadius: '14px',
    padding: '7%',
  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
    wordBreak: 'break-word',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: '0.7em 0.3em',
      fontSize: '0.84375rem',
      fontWeight: '600',
      borderBottom: '1px solid',
      borderBottomColor: 'colors.lightGrey9',
      textTransform: 'capitalize',
      wordBreak: 'break-word',
      '&:first-of-type': { paddingLeft: 0, },
      '&:last-child': { paddingRight: 0, },
    },
  },
  tableRow: {

    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
    '&:last-child td, &:last-child th': { border: 0 }
  },
}


export default PortfolioMetrics
