import React from 'react'
import { Grid, Stack, useMediaQuery } from '@mui/material'
import Text from 'components/Text'
import ReadMoreButton from 'screens/GetStarted/components/ReadMoreButton/ReadMoreButton'
import cloudAndSunIcon from 'assets/img/cloudAndSunIcon.png'
import { useDefaultProducts } from 'hooks/productApiHooks'
import { useTheme } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'


export default function GetStartedProducts() {

  const defaultProducts = useDefaultProducts({ preventFetching: true })
  const firstProductGuid = defaultProducts?.response?.[0]?.ProductGuid

  const href = firstProductGuid
    ? `/products/${firstProductGuid}#product1`
    : ''

  return <Grid container sx={styles.sectionWrapper} id='products'>
    <Grid item sx={styles.sectionInner}>
      <Text size='3rem' mb='50px'>
        Products - Exceptional & Rich
      </Text>
      <div style={{ maxWidth: 600 }}>
        <Text size='24px'>
          Investing should not be standard and poor; it should be
          exceptional and rich. Our flagship product is the U.S.
          top 500 basket, which has a turnover less than 5% a year,
          has a volatility similar and on occasions lower than the
          market and the since inception simulation has generated
          on average an annualised return more than 400 basis points.
        </Text>
      </div>
      <Grid mt='50px'>
        {href ? <ReadMoreButton {...{ href }} /> : 'Loading...'}
      </Grid>
      <Grid container mt='50px' sx={styles.iconsGrid}>
        {defaultProducts?.response?.map((product, index) =>
          <React.Fragment key={index}>
            <GridItem
              smallText={product?.productName}
              href={`/products/${product?.ProductGuid}#product1`}
              index={index}
            />
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  </Grid>
}


const GridItem = ({ smallText, href = '/', index = 0 }) => {

  const theme = useTheme()
  const isAtLeastLarge = useMediaQuery(theme.breakpoints.up('md'))

  const hasRightBorder = index => {
    if (isAtLeastLarge) return index % 3 !== 2
    return false
  }

  const getRightBorderStyle = index => ({
    borderRight: hasRightBorder(index) ? '2px solid' : 'none',
    borderRightColor: 'colors.main',
  })

  return <Stack
    sx={{ ...styles.gridItem, ...getRightBorderStyle(index) }}
    key={index}
    component={NavLink}
    to={href}
  >
    <img alt='tralio logo' src={cloudAndSunIcon} style={styles.gridIcon} height='auto' />
    <Text sx={styles.gridText}>{smallText}</Text>
  </Stack>
}


const styles = {
  sectionWrapper: {
    minHeight: '100vh',
    padding: '30px 10% 80px 10%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'colors.darkGrey7',
    width: '100%',
  },
  sectionInner: {
    width: '100%',
  },
  iconsGrid: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr 1fr',
    },
    rowGap: {
      xs: '25px',
      sm: '45px',
      md: '65px',
      lg: '85px',
    },
    width: 'min(100%, 1300px)',
  },
  gridIcon: {
    maxWidth: 'min(100%, 312px)',
    textAlign: 'center'
  },
  gridText: {
    fontSize: '1.5rem',
    textAlign: 'center',
  },
  gridItem: {
    textDecoration: 'inherit',
    color: 'inherit',
    alignItems: 'center',
    padding: '5px 6% 0 6%',
    '&:last-child': {
      borderRight: 'none',
    },
  },
}
