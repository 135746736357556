import { createSelector } from 'reselect'

export const firstLastDataPoints = createSelector(
  state => state.market?.values,
  values => {

    /**
     * Every productGuid gets associated to its earliest and latest datapoints.
     * For example: dataPoints = {
     *    'af0e20f7-79ee-470e-94c6-260c6d05f967': {
     *        earliest: {...},
     *        latest: {...},
     *    }
     * }
     */
    const dataPoints = values && Object.keys(values)?.reduce((result, productGuid) => {
      const historicalValues = values[productGuid]?.Data?.Content?.PortfolioValues
      result[productGuid] = getFirstLastDataPoints(historicalValues)
      return result
    }, {})

    return dataPoints
  }
)


const getFirstLastDataPoints = historicalValues => {
  // Find the earliest AND the latest dataPoint:
  let earliestDataPoint = null
  let latestDataPoint = null

  historicalValues?.forEach(someDataPoint => {
    if (
      !earliestDataPoint ||
      earliestDataPoint.Index > someDataPoint.Index
    ) earliestDataPoint = someDataPoint

    if (
      !latestDataPoint ||
      latestDataPoint.Index < someDataPoint.Index
    ) latestDataPoint = someDataPoint
  })

  return {
    earliest: earliestDataPoint,
    latest: latestDataPoint,
  }
}