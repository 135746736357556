import { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { fetchValues, fetchPerformance, fetchRunningComponents, fetchDrawdowns, fetchPerfMonthlySummary, fetchComparatives, fetchSimulations, fetchBasicDetails, fetchMyPortfolios, fetchSimulationProducts, fetchMarketProducts, fetchRebalanceInfo, fetchBasicRestrictions, } from 'store/marketReducer'
import { fetchUserPermissions } from "store/userReducer"
import { fetchSubscriptionPrices } from 'store/paymentReducer'
import { fetchDefaultProductDetails, fetchDefaultProducts, fetchLandingStatistics } from "store/otherStatsReducer"
import { fetchAllArticles, fetchArticleDetail } from "store/articlesReducer"


/**
 * WHAT DO THESE HOOKS HAVE IN COMMON?
 * 
 * We fetch product data from the API.
 * If it already isLoading, do not fetch again.
 * If we already have the data, do not fetch again.
 * If the parameters are not ready for the fetching, 
 *    don't fetch yet.
 * If the parameters become available, start fetching.
 * If any parameters are available in redux, use them. 
 */


export const useMarketProducts = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const selectedTemplate = useSelector(state => state.market.selectedTemplate)
  const data = useSelector(state => state.market.marketProducts?.[selectedTemplate])
  const isLoading = useSelector(state => state.market.isLoading?.marketProducts?.[selectedTemplate])
  useEffect(() => {
    if (!isLoading && !data?.length)
      dispatch(fetchMarketProducts(selectedTemplate, token))
  }, [isLoading, selectedTemplate, data, token])
  return data
}

export const useMyPortfolios = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const selectedTemplate = useSelector(state => state.market.selectedTemplate)
  const data = useSelector(state => state.market.myPortfolios?.[selectedTemplate])
  const isLoading = useSelector(state => state.market.isLoading?.myPortfolios?.[selectedTemplate])
  useEffect(() => {
    if (!isLoading && !data && token)
      dispatch(fetchMyPortfolios(selectedTemplate, token))
  }, [isLoading, selectedTemplate, token, data])
  return { data, isLoading }
}

export const useSimulationProducts = () => {
  const dispatch = useDispatch()
  const selectedTemplate = useSelector(state => state.market.selectedTemplate)
  const token = useSelector(state => state.user.token)
  const productGuid = useSelector(state => state.user.simGeneration?.finalSelectedProduct)
  const data = useSelector(state => state.market.simulationProducts?.[selectedTemplate]?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.simulationProducts?.[selectedTemplate]?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data?.length && productGuid && token)
      dispatch(fetchSimulationProducts(productGuid, selectedTemplate, token))
  }, [isLoading, selectedTemplate, token, productGuid, data])
  return { data, isLoading }
}

export const useBasicDetails = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.market.basicDetails?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.basicDetails?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchBasicDetails(productGuid))
  }, [isLoading, data, productGuid])
  return data
}

export const useRebalanceInfo = productGuid => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const basicRestrictions = useSelector(state => state.market.basicRestrictions?.[productGuid])
  const data = useSelector(state => state.market.rebalanceInfo?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.rebalanceInfo?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data && productGuid && token && basicRestrictions?.IsTabRebalanceVisible)
      dispatch(fetchRebalanceInfo(productGuid, token))
  }, [isLoading, data, productGuid, token, basicRestrictions?.IsTabRebalanceVisible])
  return data
}

export const useValues = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state?.market?.values?.[productGuid]?.Data)
  const isLoading = useSelector(state => state?.market?.values?.[productGuid]?.Data)
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchValues(productGuid))
  }, [isLoading, productGuid, data])
  return data
}

export const usePerformance = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.market.performance?.[productGuid]?.Data?.Content?.PortfolioPerformances?.[0])
  const isLoading = useSelector(state => state.market.isLoading?.performance?.[productGuid]?.Data?.Content?.PortfolioPerformances?.[0])
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchPerformance(productGuid))
  }, [isLoading, productGuid, data])
  return data
}

export const useRunningComponents = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.market.runningComponents?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.runningComponents?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchRunningComponents(productGuid))
  }, [isLoading, productGuid, data])
  return data
}

export const useDrawdowns = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.market.drawdowns?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.drawdowns?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data && productGuid) {
      dispatch(fetchDrawdowns(productGuid))
    }
  }, [isLoading, productGuid, data])
  return data
}

export const usePerfMonthlySummary = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.market.perfMonthlySummary?.[productGuid]?.Data?.MonthlyAnnualizedDatas)
  const isLoading = useSelector(state => state.market.isLoading?.perfMonthlySummary?.[productGuid]?.Data?.MonthlyAnnualizedDatas)
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchPerfMonthlySummary(productGuid))
  }, [isLoading, productGuid, data])
  return data
}

export const useComparatives = productGuid => {
  const dispatch = useDispatch()
  const data = useSelector(state => state.market.comparatives?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.comparatives?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchComparatives(productGuid))
  }, [isLoading, productGuid, data])
  return data
}

export const useSimulations = productGuid => {
  const dispatch = useDispatch()
  const { fromDate, toDate } = useSelector(state => state.market.simulationsFilter)
  const data = useSelector(state => state.market.simulations?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.simulations?.[productGuid])
  useEffect(() => {
    if (!isLoading && !data && productGuid)
      dispatch(fetchSimulations({ productGuid, fromDate, toDate }))
  }, [isLoading, productGuid, fromDate, toDate, data])
  return data
}


/** TODO: if we make other similar userAPI hooks, 
 * move them to a separate userApiHooks.js file */
export const useUserPermissions = () => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const data = useSelector(state => state.user.permissionsApi)
  useEffect(() => {
    if (token && !data?.isLoading && !data?.response)
      dispatch(fetchUserPermissions(token))
  }, [data, token])
  return data
}



export const useBasicRestrictions = productGuid => {
  const dispatch = useDispatch()
  const token = useSelector(state => state.user.token)
  const data = useSelector(state => state.market.basicRestrictions?.[productGuid])
  const isLoading = useSelector(state => state.market.isLoading?.basicRestrictions?.[productGuid])
  useEffect(() => {
    if (token && productGuid && !isLoading && !data)
      dispatch(fetchBasicRestrictions(productGuid, token))
  }, [isLoading, data, productGuid, token])
  return data
}



/** TODO: if we make other similar PAYMENT hooks, 
 * move them to a separate paymentApiHooks.js file */
export const useSubscriptionPrices = () => {
  const dispatch = useDispatch()
  const apiData = useSelector(state => state.payment.subscriptionPrices)
  useEffect(() => {
    if (!apiData?.wasCalled)
      dispatch(fetchSubscriptionPrices())
  }, [apiData])
  return apiData
}



/** TODO: if we make other similar otherStats hooks, 
 * move them to a separate otherStatsHooks.js file */
export const useLandingStatistics = () => {
  const dispatch = useDispatch()
  const apiData = useSelector(state => state.otherStats.landingStatistics)
  useEffect(() => {
    if (!apiData?.wasCalled)
      dispatch(fetchLandingStatistics())
  }, [apiData])
  return apiData
}



/** TODO: if we make other similar otherStats hooks, 
 * move them to a separate otherStatsHooks.js file */
export const useDefaultProducts = props => {

  const { preventFetching = false } = props || {}

  const dispatch = useDispatch()
  const apiData = useSelector(state => state.otherStats.defaultProducts)
  useEffect(() => {
    if (!apiData?.wasCalled && !preventFetching)
      dispatch(fetchDefaultProducts())
  }, [apiData, Boolean(preventFetching)])
  return apiData
}





/** TODO: if we make other similar otherStats hooks, 
 * move them to a separate otherStatsHooks.js file */
export const useDefaultProductDetails = productGuid => {
  const dispatch = useDispatch()
  const apiData = useSelector(state => state.otherStats.defaultProductDetail?.[productGuid])
  useEffect(() => {
    if (productGuid && !apiData?.wasCalled)
      dispatch(fetchDefaultProductDetails(productGuid))
  }, [productGuid, apiData?.wasCalled])
  return apiData
}




/** TODO: if we make other similar article hooks, 
 * move them to a separate articleHooks.js file */
export const useAllArticles = () => {
  const dispatch = useDispatch()
  const apiData = useSelector(state => state.articles.allArticles)
  useEffect(() => {
    if (!apiData?.wasCalled)
      dispatch(fetchAllArticles())
  }, [apiData])
  return apiData
}




/** TODO: if we make other similar article hooks, 
 * move them to a separate articleHooks.js file */
export const useArticleDetail = slug => {
  const dispatch = useDispatch()
  const apiData = useSelector(state => state.articles.articleDetail?.[slug])
  useEffect(() => {
    if (slug && !apiData?.wasCalled)
      dispatch(fetchArticleDetail(slug))
  }, [slug, apiData?.wasCalled])
  return apiData
}



