import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import MyTable from 'components/MyTable'
import { prettyNumber } from 'utils/numberUtils'
import { prettyDateShort, stringToUnixTimestamp } from 'utils/dateUtils'
import { getUniqueYears } from 'utils/productUtils'
import Loading from 'components/Loading'


const firstHeadCell = {
  align: 'left',
  formatter: prettyDateShort,
  id: 'unixTimestamp',
  label: 'Date',
}


const ProductSimulationsTable = ({ productGuid }) => {

  const simulations = useSelector(state =>
    state.market.simulations?.[productGuid]
  )

  const [rows, setRows] = useState()
  const [years, setYears] = useState()
  const [headCells, setHeadCells] = useState()


  useEffect(() => {
    setRows(getRows(simulations))
    setYears(getUniqueYears(simulations))
  }, [simulations])


  useEffect(() => {

    const dynamicHeadCells = getDynamicHeadCells(years)

    setHeadCells(dynamicHeadCells && [
      firstHeadCell, ...dynamicHeadCells
    ])

  }, [years])


  const dataExists = Boolean(headCells?.length && rows?.length)

  return dataExists
    ? <MyTable
      disableSort
      initialSortBy={firstHeadCell.id}
      {...{ headCells, rows }}
    />
    : <Loading />
}



const getDynamicHeadCells = years => years?.map(year => ({
  align: 'right',
  formatter: prettyNumber,
  id: year,
  label: `Since ${year}`,
}))



const getRows = simulations => simulations
  ?.map(dataPoint => {

    const years = dataPoint?.Values
      && Object.keys(dataPoint.Values)

    const yearsToValues = years?.reduce(
      (dataPointYears, year) => ({
        ...dataPointYears,
        [year]: dataPoint.Values?.[year]
      }), {})

    return {
      unixTimestamp: stringToUnixTimestamp(dataPoint.Date),
      ...yearsToValues,
    }
  })



export default ProductSimulationsTable

