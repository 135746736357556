import React from 'react'
import { Grid } from '@mui/material'
import ResearchCard from 'screens/GetStarted/components/ResearchCard/ResearchCard'
import Text from 'components/Text'
import ReadMoreButton from 'screens/GetStarted/components/ReadMoreButton/ReadMoreButton'
import background from 'assets/img/researchCard1.png'
import getStartedResearchSnowball from 'assets/img/getStartedResearchSnowball.jpg'
import getStartedResearch2 from 'assets/img/getStartedResearch2.jpg'





const cardData = [
  {
    id: '1',
    title: '9/10 Fail Logic',
    imageUrl: background,
    href: '/insights/2022-5-2-910-fail-logic',
  },
  {
    id: '2',
    title: 'Mechanisms of Psychology',
    imageUrl: getStartedResearch2,
    href: '/insights/2022-6-21-mechanisms-of-psychology',
  },
  {
    id: '3',
    title: 'The Snowball Effect',
    imageUrl: getStartedResearchSnowball,
    href: '/insights/2022-6-22-the-snowball-effect',
  }
]


export default function GetStartedResearch() {
  return <Grid container sx={styles.sectionWrapper} id='research'>
    <Grid item sx={styles.sectionInner}>
      <Text size='3rem' mb='20px'>
        Research - 9/10 Fail Logic
      </Text>
      <div style={{ maxWidth: 600 }}>
        <Text size='24px'>
          9 out of 10 asset managers don&rsquo;t beat the market. This is driving the &lsquo;Do It
          Yourself &lsquo;(DIY) rush to passive. What should have been a 50% chance to beat the
          market became a poor hand for the asset managers. How did this happen? The market is a
          basket, which includes everything investable. An asset manager's job is to look into the
          basket and make a selection and make a smaller basket also called a portfolio&hellip;
        </Text>
      </div>
      <Grid mt='50px'>
        <ReadMoreButton href='/research' />
      </Grid>
      <Grid container ml='50px'  >
        {cardData.map((card) => <ResearchCard
          key={card.id}
          imageUrl={card.imageUrl}
          title={card.title}
          href={card.href}
        />)}
      </Grid>
    </Grid>
  </Grid>
}


const styles = {
  sectionWrapper: {
    backgroundColor: 'colors.darkGrey6',
    minHeight: 'calc(100vh - 94px)',
    padding: '30px 10% 80px 10%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  sectionInner: {

  },
}
