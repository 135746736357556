import React from 'react'
import { useState } from 'react'
import { Grid, Button, Stack } from '@mui/material'
import Text from 'components/Text'
import FactsheetBrickGraph from 'screens/ProductDetail/FactsheetBrickGraph'
import FactsheetPieGraph from 'screens/ProductDetail/FactsheetPieGraph'


const graphTypes = Object.freeze({
  brickGraph: 'Brick graph',
  pieGraph: 'Pie graph',
})


const FactsheetGraphsSection = ({ descriptionSx }) => {

  const [selectedGraphType, setSekectedGraphType] = useState(graphTypes.brickGraph)

  const description = selectedGraphType === graphTypes.brickGraph
    ? 'These are the following primary factors. Value - if ranking is equal to or below 20 i.e. bottom quintile ranking (BQ). Core - if ranking is between 20 and 80 i.e. rest quintiles, ignoring top and bottom quintile (RQ). Growth - if ranking is equal to or above 80 i.e. top quintile (TQ). The factors can be used to generate the following type of portfolios. Value (BQ), Core(RQ), Growth (TQ), Value Growth i.e. top and bottom quintile (TBQ), Value Core Growth (VCG) i.e. all quintiles (AQ). Exceptional & Rich Indices and Styles use VCG factor. While all other factors are part of Exceptional & Rich Strategies.'
    : 'These are the starting weights of the three Pure Factors, which do not change at a group level but continue to change at the component level.'

  const graph = selectedGraphType === graphTypes.brickGraph
    ? <FactsheetBrickGraph /> : <FactsheetPieGraph />

  return <Stack sx={styles.sectionStack}>
    <Grid container>
      <Grid item sx={styles.radioButtonGroup}>
        <Button
          className={selectedGraphType === graphTypes.brickGraph ? 'active' : ''}
          onClick={() => setSekectedGraphType(graphTypes.brickGraph)}
        >
          {graphTypes.brickGraph}
        </Button>
        <Button
          className={selectedGraphType === graphTypes.pieGraph ? 'active' : ''}
          onClick={() => setSekectedGraphType(graphTypes.pieGraph)}
        >
          {graphTypes.pieGraph}
        </Button>
      </Grid>
    </Grid>
    <Text sx={descriptionSx}>{description}</Text>
    {graph}
  </Stack>
}


const styles = {
  sectionStack: {
    gap: '1rem',
  },
  radioButtonGroup: {
    backgroundColor: 'colors.main',
    borderRadius: '7px',
    width: 'auto',
    padding: '4px',
    margin: '0 0 10px 0',
    gap: '1rem',
    '& .MuiButton-root': {
      color: 'colors.opposite',
      textTransform: 'uppercase',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '14.5px',
      padding: '0.1em 0.9em',
      '&.active': {
        backgroundColor: 'colors.primaryMain',
      },
    },
  },
}

export default FactsheetGraphsSection
