
/**
 * Returns the oldest products or the rest, 
 * based on the oldestProductsOnly parameter.
 * 
 * The oldest products are the ones that have an RDate equal 
 * to the earliest RDate of all the products in the array.
 * 
 * The rest of the products are the ones that have an RDate 
 * NOT EQUAL TO the earliest RDate of all the products in 
 * the array.
 * 
 * Sometimes, a product actually consists of its' portfolio and 
 * benchmark instances. In that case, even a single oldest 
 * product will be an array with two elements. 
 */
export const getProducts = ({ allProducts, oldestProductsOnly }) => {
  if (!allProducts?.length) return null

  const earliestDate = getEarliestDate(allProducts)

  const isProductWanted = oldestProductsOnly
    ? product => product?.RDate === earliestDate
    : product => product?.RDate !== earliestDate

  return allProducts.filter(isProductWanted)
}


/**
 * Finds the products that have an RDate NOT EQUAL TO
 * the earliest RDate of all the products in the array.
 * @param {array} products 
 */
export const getNotOldestProducts = products => {
  const earliestDate = getEarliestDate(products)
  if (!earliestDate) return null

  return products.filter(
    product => product?.RDate === earliestDate
  )
}


/**
 * Finds the earliest RDate from the products.
 * @param {array} products 
 */
const getEarliestDate = products => {
  if (!products?.length) return null
  if (products?.length === 1) return products[0]?.RDate

  return products?.reduce((prev, current) =>
    current.RDate < prev ? current.RDate : prev,
    products[0]?.RDate
  )
}



/** 
 * Reduces the number of elements in the array to no more than 
 * maxNrItems. Guarantees to include the first and last elements. 
 * Selects the rest from in between, equally spaced out.
*/
export const getLessItems = (items, maxNrItems) => {

  const nrItems = items?.length

  if (!nrItems) return items
  if (nrItems <= maxNrItems) return items

  const step = (nrItems - 1) / (maxNrItems - 1)

  const reducedArray = Array.from(Array(maxNrItems - 1)).map(
    (_, i) => items[Math.floor(i * step)]
  )

  const lastElement = items[items.length - 1]

  return [...reducedArray, lastElement]
}



/**
 * Gets a unique list of years from the simulation data.
 * Example return: [2018, 2019, 2020, 2021] 
*/
export const getUniqueYears = simulations => simulations?.reduce(
  (years, simulation) => {

    const maybeYears = simulation?.Values
      && Object.keys(simulation.Values)

    /** Include only those years that have some 
     * non-null Value associated with them.  */
    const okYears = maybeYears.filter(
      maybeYear => simulation?.Values?.[maybeYear]
    )
    const setOfYears = new Set([...years, ...okYears])

    return [...setOfYears]

  }, []
)



export const Templates = Object.freeze({
  returns: 'returns',
  returnsBenchmarks: 'returnsBenchmarks',
  risks: 'risks',
  riskBenchmarks: 'riskBenchmarks',
  monthlyReturnPerformance: 'monthlyReturnPerformance',
  monthlyReturnPerformanceBenchmark: 'monthlyReturnPerformanceBenchmark',
  monthlyExcessReturnPerformance: 'monthlyExcessReturnPerformance',

  // THESE TEMPLATES ARE COMMENTED BECAUSE 
  // THEY ARE NOT READY YET:
  // mptStats: 'mptStats',
})

export const isValidTemplate = template =>
  Object.values(Templates)?.includes(template)

export const defaultTemplate =
  Templates?.[Object.keys(Templates)?.[0]]

