import { setSnackbar } from "store/snackbarReducer"
import { renewToken } from "store/userReducer"


export const myAxios = params => {

  const {
    axiosCallerFn,
    onSuccess,
    onError,
    dispatch,
    token = null,
    maxNrRetries = 0,
    maxNrTokenRenewals = 1,
    retryDelayMillis = 5000,
  } = params

  axiosCallerFn(token)

    .then(onSuccess)

    .catch(error => {
      if (
        /** If token is invalid, we have to renew it and 
         * then call the original API endpoint again. */
        error?.response?.status === 401
        && maxNrTokenRenewals > 0 && token && dispatch
      ) {
        dispatch(renewToken({
          token,
          onSuccess: newToken => myAxios({
            ...params,
            token: newToken,
            maxNrTokenRenewals: maxNrTokenRenewals - 1,
          }),
          onError,
        }))

      } else if (maxNrRetries > 0) {

        setTimeout(
          () => myAxios({
            // On other types of errors, try calling again maxNrRetries times.
            ...params,
            maxNrRetries: maxNrRetries - 1
          }),
          retryDelayMillis
        )

      }

      else onError(error)
    })
}



const getErrorMessage = error => {
  const errorStringPart1 = `${error?.response?.status || ''} ${error?.response?.statusText || ''}`

  const errorStringPart2 = error?.response?.data
    ? JSON.stringify(error?.response?.data)
    : error?.message

  return `${errorStringPart1} ${errorStringPart2}`.trim()
}


export const printError = (location, error, dispatch) => {
  const errorString = getErrorMessage(error)
  console.log(`Error at ${location}: `, errorString)

  if (!dispatch) return

  dispatch(setSnackbar(errorString))
}


