import React, { useRef } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Grid, } from '@mui/material'
import { colors } from 'theme'
import { prettyDate, stringToUnixTimestamp } from 'utils/dateUtils'
import { useSelector } from 'react-redux'
import { prettyCurrency } from 'utils/numberUtils'
import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useState } from 'react'
import { getNColors } from 'utils/generalUtils'
import { getUniqueYears } from 'utils/productUtils'

const ProductSimulationsChart = ({ productGuid }) => {

  const simulations = useSelector(
    state => state?.market?.simulations?.[productGuid]
  )
  const [years, setYears] = useState()
  const [yearColors, setYearColors] = useState()
  const [chartData, setChartData] = useState()
  const [shouldResizeChart, setShouldResizeChart] = useState()


  const chartResizerTimeout = useRef()

  // TODO: apply this fix to the other charts as well: 
  useEffect(() => {
    /** The chart has a problem. Initially the Legend overlaps the 
     * XAxis. Then, any resize event does correct the problem. 
     * So we trigger a resize some time later. */
    chartResizerTimeout.current = setTimeout(
      () => setShouldResizeChart(true), 1000
    )

    return () => clearTimeout(chartResizerTimeout.current)
  }, [chartData])


  useEffect(() => {
    const colors = getNColors(years?.length)

    setYearColors(years?.reduce((prev, current, index) => ({
      ...prev,
      [current]: colors?.[index % colors?.length],
    }), {}))

  }, [years])


  useEffect(() => {
    setChartData(simulations && getChartData(simulations))
    setYears(simulations && getUniqueYears(simulations))
  }, [simulations])

  const xAxisProps = {
    dataKey: 'unixTimestamp',
    domain: ['dataMin', 'dataMax'],
    name: 'Time',
    tickFormatter: prettyDate,
    type: 'number',
  }

  if (!chartData) return <Loading sx={styles.chartWrapper} />

  return <Grid sx={{
    ...styles.chartWrapper,
    width: shouldResizeChart ? '100%' : '99%',
  }}>
    <ResponsiveContainer>
      <LineChart data={chartData}>
        <CartesianGrid stroke={colors.primaryPale2} />
        <XAxis {...xAxisProps} />
        <YAxis
          domain={['auto', 'auto']}
          tickFormatter={prettyCurrency}
        />
        <Tooltip
          formatter={prettyCurrency}
          labelFormatter={prettyDate}
        />
        <Legend />

        {years?.map((year, index) => <Line
          type='linear'
          name={`Since ${year}`}
          dataKey={`Values.${year}`}
          stroke={yearColors?.[year]}
          dot={false}
          key={index}
        // style={{ opacity: index === 0 ? '1' : '0.3', }}
        />)}

      </LineChart>
    </ResponsiveContainer>
  </Grid>
}

const styles = {
  chartWrapper: {
    height: '405px',
    backgroundColor: 'colors.darkGrey6',
    padding: '4% 4% 3% 3%',
    borderRadius: '14px',
    marginBottom: '23px',
    '& .recharts-tooltip-label': { color: 'colors.lightGrey8', },
    '& .recharts-default-tooltip': { textAlign: 'right', },
  },
}

const getChartData = simulations =>
  simulations?.map(item => ({
    unixTimestamp: stringToUnixTimestamp(item.Date),
    Date: item.Date,
    Values: item.Values,
  }))


export default ProductSimulationsChart
