import { useSelector } from 'react-redux'
import { useSimulationProducts } from 'hooks/productApiHooks'

/** Determines wether the user is allowed to generate 
 * or to map any products into their portfolios. */
const useAnyAllowedPortfAction = () => {

  const simulationProducts = useSimulationProducts()
  const userPermissions = useSelector(
    state => state.user.permissionsApi?.response
  )

  const allowedGenerationGuids = userPermissions?.UserAllowedToCreate
  const allowedMappingGuids = userPermissions?.UserAllowedToMapp

  // TODO: maybe include the main product guid in the search?
  const isAnyGenerationAllowed = Boolean(simulationProducts?.data?.find(
    existingSimulation => allowedGenerationGuids?.includes(existingSimulation?.RProductGuid)
  ))

  // TODO: maybe include the main product guid in the search?
  const isAnyMappingAllowed = Boolean(simulationProducts?.data?.find(
    existingSimulation => allowedMappingGuids?.includes(existingSimulation?.RProductGuid))
  )

  return { isAnyGenerationAllowed, isAnyMappingAllowed }
}

export default useAnyAllowedPortfAction
