import { Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import ProductPerformance from 'screens/ProductDetail/ProductPerformance'

const ProductsSlide2 = ({ productGuid }) => {


  return <Stack sx={styles.slideWrapper}>
    <Text sx={styles.sectionTitle}>Performance</Text>
    <Text sx={styles.sectionDescription}>
      The table illustrates the relative and absolute performance
      across various parameters*. For example the Performance(%) of
      Portfolio since various points in time, Current Portfolio Cash(%)
      and Average Cash (%) in the Portfolio, Average Holding Period
      (Days), Average number of Trades per year, Current Portfolio
      Value of the funds invested at the start of the Portfolio,
      Annualized (%) Returns and Annualized Standard Deviation (%).
    </Text>
    <ProductPerformance {...{ productGuid }} />
  </Stack>
}

const styles = {

  slideWrapper: {
    gap: '20px',
    width: 'min(1600px, 95%)',
  },
  sectionTitle: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontSize: '2rem',
    margin: '90px 0 15px 0',
  },
  sectionDescription: {
    color: 'colors.lightGrey16',
    fontSize: '1.5rem',
    fontWeight: '400',
    marginBottom: '20px',

  },

}

export default ProductsSlide2
