import TableHeaderIcon from 'screens/Market/TableHeaderIcon'
import { prettyDateShort } from 'utils/dateUtils'
import { prettyNumber, prettyPercent } from 'utils/numberUtils'
import MyTable from 'components/MyTable'
import PortfTableActionCell from 'screens/Market/ProductsTable/PortfTableActionCell'
import usePortfTableMetadata from 'screens/Market/ProductsTable/usePortfTableMetadata'
import portfTableProps from 'screens/Market/ProductsTable/portfTableProps'


const RisksTemplate = ({ products }) => {

  const { isSelectingProduct } = usePortfTableMetadata()


  const rows = products?.map(portfolio => ({
    productGuid: portfolio.RProductGuid,
    href: !isSelectingProduct && `/product/${portfolio.RProductGuid}`,
    RName: <PortfTableActionCell productGuid={portfolio.RProductGuid} label={portfolio.RName} />,

    RDate: portfolio.RDate,
    RVolatility: portfolio.RVolatility,
    RExcessVolatility: portfolio.RExcessVolatility,
    RInformationRatio: portfolio.RInformationRatio,
    RSharpeRatio: portfolio.RSharpeRatio,
    RMaxDrawdown: portfolio.RMaxDrawdown,
    RRecoveryRatio: portfolio.RRecoveryRatio,
  }))


  const headCells = [
    { align: 'left', formatter: v => v, id: 'RName', label: 'Name', },
    { align: 'center', formatter: prettyDateShort, id: 'RDate', label: 'Date', },
    { align: 'center', formatter: prettyPercent, id: 'RVolatility', label: <TableHeaderIcon title='AV' desc={<><b>Annualized Volatility</b> is the Annualized Standard Deviation.</>} />, },
    { align: 'center', formatter: prettyPercent, id: 'RExcessVolatility', label: <TableHeaderIcon title='EV' desc={<><b>Excess Volatility</b> is the Annualized volatility of the E&R Portfolio minus the Annualized Volatility of the Benchmark.</>} />, },
    { align: 'center', formatter: prettyNumber, id: 'RInformationRatio', label: <TableHeaderIcon title='IR' desc={<><b>Information Ratio</b> is the Annualized Excess Returns divided by the Tracking Error.</>} />, },
    { align: 'center', formatter: prettyNumber, id: 'RSharpeRatio', label: <TableHeaderIcon title='SR' desc={<><b>Sharpe Ratio</b> is the [E&R Portfolio Return minus Risk-Free Rate] divided by Standard Deviation of the E&R Portfolio’s Excess Return.</>} />, },
    { align: 'center', formatter: prettyPercent, id: 'RMaxDrawdown', label: <TableHeaderIcon title='MD' desc={<><b>Max Drawdown</b> is the worst fall in the period under study.</>} />, },
    { align: 'center', formatter: prettyNumber, id: 'RRecoveryRatio', label: <TableHeaderIcon title='RR' desc={<><b>Recovery Ratio</b> is the [Number of days taken by the Benchmark] to recover divided by [Number of days taken by the Portfolio to recover] to recover.</>} />, },
  ]


  if (!products) return <h1 style={{ textAlign: 'center', }}>Loading...</h1>

  return <MyTable
    {...{ headCells, rows }}
    // highlightEventRows
    {...portfTableProps}
  />
}


export default RisksTemplate
