const SET_SNACKBAR = 'snackbar/SET_SNACKBAR'
const CLEAR_SNACKBAR = 'snackbar/CLEAR_SNACKBAR'

const initialState = {
  message: null,
}

const snackbarReducer = (state = initialState, action) => {

  const payload = action?.payload

  switch (action?.type) {
    case SET_SNACKBAR: return { ...state, message: payload }
    case CLEAR_SNACKBAR: return initialState
    default: return state
  }
}


export const setSnackbar = message => ({
  type: SET_SNACKBAR, payload: message
})


export const clearSnackbar = () => ({
  type: CLEAR_SNACKBAR
})


export default snackbarReducer
