import { Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { colors } from 'theme'
import MyLink from 'components/MyLink'


const PaymentCanceledScreen = () => <PageWithSidebar hasBackground>
  <Stack sx={styles.welcomeGrid}>
    <Text sx={styles.welcomeTitle}>Payment canceled!</Text>

    <Text sx={styles.welcomeText}>
      The payment was not successful. Please try again later or

      <MyLink
        to='/contact'
        sx={{ color: colors.primaryMain }}
      >
        <b> Contact Us </b>
      </MyLink>

      to get support.
    </Text>

  </Stack>
</PageWithSidebar>


const styles = {
  welcomeTitle: {
    fontSize: '2.25rem',
    textAlign: 'center',
    color: 'colors.lightGrey22',
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '200',
    width: 'min(100%, 600px)',
    color: 'colors.lightGrey7',
    '& b': {
      fontWeight: '600',
    },
  },
  welcomeGrid: {
    margin: '8rem 10% 0 10%',
    alignItems: 'center',
    gap: '24px',
  },
}


export default PaymentCanceledScreen
