import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, Tooltip } from 'recharts'
import { useSelector } from 'react-redux'
import { prettyDate, stringToUnixTimestamp } from 'utils/dateUtils'
import { prettyCurrency } from 'utils/numberUtils'
import { useState } from 'react'
import Text from 'components/Text'
import { colors } from 'theme'


const initialChartData = {
  portfolios: null,
  benchmarks: null,
}


const ProductDrawdownChart = ({ productGuid }) => {

  const [chartData, setChartData] = useState(initialChartData)

  const drawdowns = useSelector(state =>
    state.market.drawdowns?.[productGuid]
  )


  useEffect(() => {
    setChartData({
      portfolios: prettifyDataPoints(drawdowns?.drawDownPortfolioChartDatas),
      benchmarks: prettifyDataPoints(drawdowns?.drawDownBenchmarkChartDatas),
    })
  }, [drawdowns])


  const dataExists = Boolean(
    chartData?.portfolios?.length ||
    chartData?.benchmarks?.length
  )

  if (!dataExists) return (
    <Grid sx={{ ...styles.chartWrapper, height: 'auto', }}>
      <Text size='1.5rem' mb='5px' center>No drawdown found.</Text>
    </Grid >
  )


  return <Grid sx={styles.chartWrapper}>
    <ResponsiveContainer>
      <LineChart >
        <CartesianGrid stroke={colors.primaryPale2} />
        <XAxis
          dataKey='unixTimestamp'
          domain={['dataMin', 'dataMax']}
          name='Time'
          tickFormatter={prettyDate}
          type='number'
        />
        <YAxis
          domain={['auto', '10']}
          tickFormatter={prettyCurrency}
        />
        <Legend />
        <Line
          type='linear'
          name='Portfolio Drawdown Value'
          dataKey='Value'
          stroke={colors.chartLine1}
          dot={false}
          data={chartData.portfolios}
        />
        <Line
          type='linear'
          name='Benchmark Drawdown Value'
          dataKey='Value'
          stroke={colors.chartLine2}
          dot={false}
          data={chartData.benchmarks}
        />
        <Tooltip
          formatter={(value, _, props) =>
            // Format the numbers to have 2 decimals.
            // Try using the chartData because it already
            // has the formatted number. Avoid doing the
            // actual formatting right before rendering.
            props.payload?.pretty?.[props?.dataKey]
            || prettyCurrency(value)
          }
          labelFormatter={(value, name) =>
            name?.[0]?.payload?.pretty?.time
            || prettyDate(value)
          }
        />

      </LineChart>
    </ResponsiveContainer>
  </Grid>
}



const styles = {
  chartWrapper: {
    width: '100%',
    height: '405px',
    backgroundColor: 'colors.darkGrey6',
    padding: '4% 4% 3% 3%',
    borderRadius: '14px',
    '& .recharts-tooltip-label': { color: 'colors.lightGrey8', },
    '& .recharts-default-tooltip': { textAlign: 'right', },
  },
}


const prettifyDataPoints = dataPoints => dataPoints?.map(dataPoint => ({
  unixTimestamp: stringToUnixTimestamp(dataPoint.Date),
  Date: dataPoint.Date,
  Value: dataPoint.Value,
  pretty: {
    Date: prettyDate(dataPoint.Date),
    Value: prettyCurrency(dataPoint.Value),
  },
}))


export default ProductDrawdownChart