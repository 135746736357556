import React, { useEffect } from 'react'
import { Stack, Grid, Button } from '@mui/material'
import { ReactComponent as AlphablockLogo } from 'assets/img/logo.svg'
import SquareIcon from '@mui/icons-material/Square';
import { NavLink } from 'react-router-dom'
import Text from 'components/Text'
import Arrow from 'components/Arrow';
import { simGenCancel, simGenFinalize, logout, portfGenStart, portfGenFinalize, portfGenCancel, callPortfolioGenerationApi, portfMappingStart, portfMappingFinalize, portfMappingCancel, callPortfolioMappingApi } from 'store/userReducer'
import { useDispatch, useSelector } from 'react-redux'
import { simGenStart } from 'store/userReducer'
import { useNavigate } from 'react-router-dom'
import { ScreenTypes } from 'store/marketReducer';
import MyLink from 'components/MyLink';
import useAnyAllowedPortfAction from 'hooks/useAnyAllowedPortfAction';


const spacing = 'min(40px, 16%)'

export const sidebarBreakpoint = 'md'

const Sidebar = () => {

  const dispatch = useDispatch()
  const token = useSelector(state => Boolean(state.user.token))

  return <Stack sx={styles.sidebar}>

    <NavLink to='/'><AlphablockLogo style={styles.alphablockLogo} /></NavLink>
    <SidebarActionButtons />
    <Text sx={styles.sectionTitle}>Portfolio menu</Text>
    <MenuItem to='/market'>Market</MenuItem>
    <MenuItem to='/simulations'>Simulations</MenuItem>
    <MenuItem to='/portfolios'>Portfolios</MenuItem>
    <MenuItem to='/' disabled>History</MenuItem>
    <Text sx={{ ...styles.sectionTitle, ...styles.secondSectionTitle }}>Community</Text>
    <MenuItem to='/' disabled>Account</MenuItem>
    <MenuItem to='/faq' >Support</MenuItem>
    <Button
      color='secondary'
      sx={styles.menuItem}
      startIcon={<SquareIcon />}
      onClick={() => dispatch(logout())}
      disabled={!token}
    >
      Log Out
    </Button>
    <MyLink to='/pricing'>
      <Stack sx={styles.subscribeBox} >
        <Text sx={styles.subscribeTitle}>Subscribe</Text>
        <Text sx={styles.subscribeItems}>
          Basic Services<br />
          Premium Services<br />
          Data Services<br />
        </Text>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={styles.arrowLink}
        >
          <Grid item><Arrow /></Grid>
          <Grid item><Text fontWeight='300'>View</Text></Grid>
        </Grid>
      </Stack>
    </MyLink>

  </Stack>

}


const SidebarActionButtons = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const token = useSelector(state => state.user.token)
  const activeScreen = useSelector(state => state.market.activeScreen)

  const simGeneration = useSelector(state => state.user.simGeneration)
  const portfGeneration = useSelector(state => state.user.portfGeneration)
  const portfMapping = useSelector(state => state.user.portfMapping)


  const { isAnyGenerationAllowed, isAnyMappingAllowed }
    = useAnyAllowedPortfAction() || {}


  useEffect(() => {
    if (portfGeneration?.finalSelectedProduct && token)
      dispatch(callPortfolioGenerationApi({
        productGuid: portfGeneration?.finalSelectedProduct,
        token,
      }))
  }, [portfGeneration?.finalSelectedProduct])


  useEffect(() => {
    if (portfMapping?.finalSelectedProduct && token)
      dispatch(callPortfolioMappingApi({
        productGuid: portfMapping?.finalSelectedProduct,
        token,
      }))
  }, [portfMapping?.finalSelectedProduct])


  const mainButtonProps = {
    sx: styles.loginButton,
    variant: 'contained',
  }

  const cancelButtonProps = {
    sx: {
      ...styles.loginButton,
      marginTop: '-1px',
      marginBottom: '-1px',
    },
    variant: 'outlined',
    color: 'secondary',
  }


  if (!token)
    return <Button {...mainButtonProps} to='/login' component={NavLink}>
      Log in
    </Button>


  const simGenStartButton = <Button
    {...mainButtonProps}
    onClick={() => { navigate(`/market`); dispatch(simGenStart()) }}
  >Generate Simulation</Button>

  const simGenSelectCancelButtons = <Stack sx={styles.buttonStack}>
    <Button
      {...mainButtonProps}
      disabled={!simGeneration.currentSelectedProduct}
      onClick={() => { dispatch(simGenFinalize()); navigate(`/simulations`) }}
    >Select</Button>
    <Button {...cancelButtonProps} onClick={() => dispatch(simGenCancel())}>
      Cancel
    </Button>
  </Stack>

  const portfGenStartButton = <Button
    {...mainButtonProps}
    onClick={() => {
      dispatch(portfGenStart())
    }}
  >Generate Portfolio</Button>

  const portfMappingStartButton = <Button
    {...mainButtonProps}
    onClick={() => {
      dispatch(portfMappingStart())
    }}
  >Map Portfolio</Button>

  const portGenSelectCancelButtons = <Stack sx={styles.buttonStack}>
    <Button
      {...mainButtonProps}
      disabled={!portfGeneration.currentSelectedProduct}
      onClick={() => { dispatch(portfGenFinalize()); navigate(`/portfolios`) }}
    >Select</Button>
    <Button {...cancelButtonProps} onClick={() => dispatch(portfGenCancel())}>
      Cancel
    </Button>
  </Stack>

  const portMappingSelectCancelButtons = <Stack sx={styles.buttonStack}>
    <Button
      {...mainButtonProps}
      disabled={!portfMapping.currentSelectedProduct}
      onClick={() => { dispatch(portfMappingFinalize()); navigate(`/portfolios`) }}
    >Select</Button>
    <Button {...cancelButtonProps} onClick={() => dispatch(portfMappingCancel())}>
      Cancel
    </Button>
  </Stack>



  if (activeScreen === ScreenTypes.market) {
    if (simGeneration.isSelectingProduct) return simGenSelectCancelButtons
    return simGenStartButton
  }

  if (activeScreen === ScreenTypes.simulations) {

    if (portfGeneration.isSelectingProduct) return portGenSelectCancelButtons
    if (portfMapping.isSelectingProduct) return portMappingSelectCancelButtons

    if (!simGeneration.finalSelectedProduct) return simGenStartButton

    if (isAnyGenerationAllowed || isAnyMappingAllowed) return <Stack
      sx={styles.buttonStack}
    >
      {isAnyGenerationAllowed && portfGenStartButton}
      {isAnyMappingAllowed && portfMappingStartButton}
    </Stack>
  }

  return null

}


const MenuItem = ({ to, icon, children, ...rest }) => <Button
  color='secondary'
  startIcon={icon || <SquareIcon />}
  component={NavLink}
  to={to || '#'}
  sx={styles.menuItem}
  {...rest}
>
  {children}
</Button>

const styles = {
  buttonStack: {
    gap: '17px',
  },
  sidebar: {
    width: '100%',
    backgroundColor: 'colors.darkGrey6',
    paddingTop: spacing,
    paddingRight: spacing,
    paddingBottom: spacing,

    gap: {
      xs: '0px',
      [sidebarBreakpoint]: '12px',
    },

  },
  alphablockLogo: {
    width: 'min(180px, 100%)',
    marginBottom: '22px',
    paddingLeft: spacing,
  },
  menuItem: {
    paddingLeft: spacing,
    borderLeft: 'solid 3px transparent',
    justifyContent: 'flex-start',
    fontWeight: '600',
    fontSize: '1.125rem',
    textTransform: 'none',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',

    '&.active': {
      borderLeft: 'solid 3px',
      borderLeftColor: 'primary.main',
      color: 'primary.main',
    },

    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: '2.9rem',
      marginRight: '32px',
    },
  },
  loginButton: {
    marginLeft: spacing,
    // marginTop: '5px',
    // marginBottom: '5px',
    fontWeight: '600',
    fontSize: '1.125rem',
    textTransform: 'capitalize',
    padding: '0.8em',
    borderRadius: '10px',

  },
  sectionTitle: {
    textTransform: 'uppercase',
    fontSize: '1.125rem',
    color: 'colors.lightGrey1',
    paddingLeft: spacing,
    fontWeight: '200',
    marginTop: '20px',

    marginTop: {
      xs: '40px',
      [sidebarBreakpoint]: '20px',
    },
  },
  secondSectionTitle: {
    marginTop: {
      xs: '30px',
      [sidebarBreakpoint]: '80px',
    },
  },
  subscribeBox: {
    backgroundColor: 'colors.darkGrey1',
    '&:hover': {
      backgroundColor: 'colors.lightGrey21',
    },
    padding: '7% 5%',
    marginLeft: spacing,
    gap: '30px',
    marginTop: {
      xs: '35px',
      [sidebarBreakpoint]: '130px',
    },
    '&>*': { textAlign: 'center', }
  },
  subscribeTitle: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '600',
    marginBottom: '0px',
  },
  subscribeItems: {
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: '200',
    color: 'colors.lightGrey2',
  },
  arrowLink: {
    gap: '1em',
    marginTop: '18px',
  },
  navLink: {
    color: 'inherit',
    textDecoration: 'none',

  },

}

export default Sidebar