import { Grid, Stack } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead';
import { prettyDate } from 'utils/dateUtils'


const ProductFactsheetTable = ({ productGuid }) => {

  const basicDetails = useSelector(state => state.market.basicDetails?.[productGuid])
  const runningComponents = useSelector(state => state.market.runningComponents?.[productGuid])

  const rows = {
    'Name': basicDetails?.ProductName,
    'Starting Point': prettyDate(basicDetails?.ProductStartDate),
    'Asset Class, Region': null,
    'Benchmark': null,
    'Holding Period': null,
    'Investment': null,
    'Turnover': null,
    'Execution': null,
    'Available Transaction Cost Per Trade': null,
    'Number of Components': runningComponents?.length,
    'Annual Dividend Payout': null,
    'Portfolio Construction': null,
    'Rebalance Process': null,
    'Rebalance Frequency': null,
    'Sandbox': null,
    'Management Fee': null,
    'Active Share': null,
  }


  return <Grid item>
    <Stack sx={styles.statisticsWrapper} direction='column'>
      <TableContainer>
        <Table sx={styles.table} aria-label='simple table'>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableCell colSpan={2}>Factsheet Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(rows)?.map(key => (
              <TableRow
                key={key}
                sx={styles.tableRow}
              >
                <TableCell className='keyCell' align='right'>{key}</TableCell>
                <TableCell className='valueCell' align='left'>
                  {getValueOrNothing(rows[key])}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  </Grid>
}

const getValueOrNothing = value => {
  if (value === 0) return value
  return value || '-'
}

const styles = {
  statisticsWrapper: {

  },
  table: {
    borderBottom: '1px solid',
    borderBottomColor: 'colors.lightGrey9',
    '& .MuiTableCell-head.MuiTableCell-root': {
      borderBottom: '1px solid',
      borderBottomColor: 'colors.lightGrey9',
      borderTop: '1px solid',
      borderTopColor: 'colors.lightGrey9',
      fontWeight: '700',
      color: 'colors.main',
      fontSize: '0.84375rem',
      textAlign: 'center',
      // textTransform: 'none',
    },
    '& .MuiTableCell-root': {

      width: {
        xs: 'auto',
        md: '50%',
      },

      padding: '0.5em 0.3em',
      fontSize: '1.125rem',
      fontWeight: '400',
      wordBreak: 'break-word',
      color: 'colors.darkGrey8',
      border: 'none',
      '&:first-of-type': { paddingLeft: 0, },
      '&:last-child': { paddingRight: 0, },
      '&.keyCell': {
        paddingRight: '3%',
      },
      '&.valueCell': {
        paddingLeft: '3%',
        fontWeight: '600',
        paddingLeft: '3%',
      },
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },
}


export default ProductFactsheetTable
