import React, { useState } from 'react'
import Text from 'components/Text'
import { Grid } from '@mui/material'
import styles from 'screens/GetStarted/components/Pagination/HorizontalScrollCircle.module.css'


const HorizontalScrollCircle = ({ isActive, disabled, setIsLabelVisible, label, linkTo }) => {
  const [mouseOver, setMouseOver] = useState(false)

  const onMouseEnter = () => {
    setIsLabelVisible(true)
    setMouseOver(true)
  }

  const onMouseLeave = () => {
    setIsLabelVisible(false)
    setMouseOver(false)
  }

  if (disabled)
    return <div className={styles.circleContainer}>
      <div className={`${styles.circle} , ${styles.disabledCircle}`} />
    </div>

  return <a href={linkTo} className={styles.circleContainer}>
    <Grid
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`${styles.circle} , ${isActive && styles.activeCircle}`}
    >
      {isActive && <div className={styles.innerCircle}></div>}
    </Grid>
    {mouseOver && (
      <div style={stylesJson.circleAndText} >
        <Grid sx={stylesJson.verticalLine} />
        <Text size='11px' color='primary.main' sx={{ width: '100px' }} center>
          {label}
        </Text>
      </div>
    )}
  </a>
}

const stylesJson = {
  circleAndText: {
    position: 'absolute',
    bottom: -35,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  verticalLine: {
    width: 2,
    height: 15,
    backgroundColor: 'primary.main',
  },
}

export default HorizontalScrollCircle

