import React from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox, Grid } from '@mui/material'
import usePortfTableMetadata from 'screens/Market/ProductsTable/usePortfTableMetadata'


const PortfTableActionCell = ({ productGuid, label }) => {

  const dispatch = useDispatch()

  const {
    isSelectingProduct,
    currentSelectedProduct,
    setSelectedProduct,
    getIsAllowed,
  } = usePortfTableMetadata(productGuid)

  if (!isSelectingProduct) return label

  return <Grid container sx={styles.wrapperGridRow}>
    <Checkbox
      checked={productGuid === currentSelectedProduct}
      inputProps={{ 'aria-label': 'controlled' }}
      disabled={!getIsAllowed(productGuid)}
      sx={styles.checkbox}
      onChange={event => {
        event.target.checked
          ? dispatch(setSelectedProduct(productGuid))
          : dispatch(setSelectedProduct())
      }}
    />
    <Grid item>{label}</Grid>
  </Grid >

}

const styles = {
  wrapperGridRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkbox: {
    position: 'relative',
    margin: '-20px 0',
  },
}

export default PortfTableActionCell
