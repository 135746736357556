import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'

const MyPortfoliosTopSection = () => <Grid sx={styles.sectionWrapper}>
  <Stack sx={styles.titleAndDescription}>
    <Text sx={styles.pageTitle}>Your Portfolios</Text>
    <Text sx={styles.description}>
      Map [or create] a fresh start model portfolio or a set of portfolios to your login. Monitor it as it first constructs itself, then starts to regularly generate monthly rebalancing instructions, tracks the benchmark, persists, and eventually generates alpha, beating the market, like all its peers. Alpha is not a game of certainty but a game of scientific method. Drop us a line of feedback, questions, and clarifications and let’s work together to build a fully automated machine investing future.
    </Text>
  </Stack>
</Grid>


const styles = {
  sectionWrapper: {
    width: '100%',
    padding: '2% 6% 1% 6%',
  },
  titleAndDescription: {
    gap: '1.5rem',
  },
  pageTitle: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  description: {
    width: 'min(100%, 354px)',
    width: '100%',
    fontSize: '1rem',
    color: 'colors.lightGrey29',
    fontWeight: '300',
    textAlign: 'left',
  },
}


export default MyPortfoliosTopSection
