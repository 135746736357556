import React from 'react'
import { Grid, Button, Stack } from '@mui/material'
import { NavLink } from 'react-router-dom'
import Text from 'components/Text'
import WebsiteShortcutCard from 'screens/Landing/components/WebsiteShortcutCard'
import Header from 'components/Header'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import backgroundImage from 'assets/img/background_1-DARKER.png'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import landingPageLogoFinCrime from 'assets/img/landingPageLogoFinCrime.jpeg'
import landingPageLogoMars from 'assets/img/landingPageLogoMars.png'
import landingPageLogoReal from 'assets/img/landingPageLogoReal.png'
import aboutUsTrustedClient1 from 'assets/img/aboutUsTrustedClient1.png'
import useWebsiteShortcutsContent from 'screens/Landing/LandingScreen/useWebsiteShortcutsContent'
import MyImage from 'components/MyImage'


export default function LandingScreen({ history }) {

  return <Grid>
    {/* section 1 ====================================================== */}
    <Grid container flexDirection='column' sx={styles.sectionOneContainer}>
      <Header />

      {/* centered content-------------*/}
      <Grid container justifyContent='center' flexDirection='column' alignItems='center' flex={1}>
        <Text
          center={true}
          sx={styles.heroText}
        >
          Machines can beat the market.
        </Text>

        <Button
          component={NavLink}
          to='/get-started'
          type='submit'
          variant='contained'
          fullWidth
          sx={{
            ...styles.getStartedBtn,
            letterSpacing: '0.3em',
          }}
        >
          Get Started
        </Button>

        <Text size='1.4rem' sx={{ letterSpacing: '0.335em' }} center>
          Award Winners / Trusted
        </Text>
        <div style={styles.divider}></div>
        <Grid sx={styles.partnerLogos}>
          <img style={{ ...styles.partnerLogo, padding: '8px', }} src={aboutUsTrustedClient1} alt='' />
          <img style={styles.partnerLogo} src={landingPageLogoFinCrime} alt='' />
          <img style={styles.partnerLogo} src={landingPageLogoMars} alt='' />
          <Grid sx={styles.realPartnerLogoWrapper}>
            <MyImage src={landingPageLogoReal} />
          </Grid>

        </Grid>
      </Grid>
    </Grid>
    {/* section 2 ====================================================== */}
    <Grid container flexDirection='column' sx={styles.sectionTwo}>
      <Grid container flexDirection='column' sx={{ maxWidth: '890px', marginBottom: '90px' }}>
        <Text size='2.6rem'>
          Exceptional & Rich Indices, Strategies and Styles
        </Text>
        <Text
          size='1.5rem'
          lineHeight='1.29em'
          mt={{
            lg: '48px',
            md: '40px',
            sm: '32px',
            xs: '24px',
          }}
        >
          Indices are the foundations of modern investing. They drive your pension
          fund, your ETFs and are used as benchmarks by mutual funds. The current
          Indexing method is more than 100 years old, is poorly designed,
          amplifies market risk as it is concentrated in a few stocks or
          components. Exceptional & Rich Indices is a new Indexing approach that
          allows market participants to reduce risk and increase returns without
          concentration. View our Indices, styles and strategies, simulate them,
          build portfolios and see how beating the market was not about skill,
          it was always about innovation.
        </Text>
        {/* btn container--- */}
        <Grid
          mt={{
            lg: '80px',
            md: '60px',
            sm: '40px',
            xs: '35px',
          }}
        >
          <Button
            to='/market'
            component={NavLink}
            color='secondary'
            variant='outlined'
            sx={{
              ...styles.button,
              marginRight: {
                lg: '45px',
                md: '35px',
                sm: '30px',
                xs: '20px',
              },
            }}

          >
            View Market
          </Button>
          <NavLink to='/login' style={styles.buttonWrapperLink}>
            <Button sx={styles.button} type='submit' variant='contained'>
              Log In
            </Button>
          </NavLink>

        </Grid>
      </Grid>
      <WebsiteShortcuts />
    </Grid>
    <LandingFooter />
  </Grid >
}

function WebsiteShortcuts() {

  const websiteShortcuts = useWebsiteShortcutsContent()

  const theme = useTheme()
  const isAtLeastLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isAtLeastMedium = useMediaQuery(theme.breakpoints.up('sm'))

  const getNrColumns = () => {
    if (isAtLeastLarge) return 3
    if (isAtLeastMedium) return 2
    return 1
  }

  const nrColumns = getNrColumns()
  const topOffsetPx = 90

  if (!(websiteShortcuts?.length)) return null

  // Initialize empty arrays nrColumns times. Avoid the fill() 
  // method here, because it does not work in IE.
  const columns = Array.from(Array(nrColumns))?.map(() => [])

  websiteShortcuts.forEach((cardData, index) =>
    columns[index % nrColumns]?.push(
      <WebsiteShortcutCard cardData={cardData} index={index} />
    )
  )

  return <Grid
    container
    sx={styles.websiteShortcutsColumns}
    style={{ gridTemplateColumns: `repeat(${nrColumns}, 1fr)`, }}
  >
    {columns.map((column, colIndex) =>
      <Stack
        key={colIndex}
        gap='32px'
        sx={{ marginTop: (nrColumns - colIndex - 1) * topOffsetPx + 'px', }}
      >
        {column.map((colItem, index) =>
          <Grid item key={index}>{colItem}</Grid>
        )}
      </Stack>
    )}
  </Grid>
}


const styles = {
  button: {
    fontSize: '1.5rem',
    textTransform: 'initial',
    fontFamily: 'Source Sans Pro',
    letterSpacing: '0.105em',
    paddingLeft: {
      lg: '50px',
      md: '40px',
      sm: '30px',
      xs: '20px',
    },
    paddingRight: {
      lg: '50px',
      md: '40px',
      sm: '30px',
      xs: '20px',
    },
  },
  sectionOneContainer: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    minHeight: '100vh',
  },
  heroText: {
    fontSize: '3.5rem',
  },
  getStartedBtn: {
    textTransform: 'initial',
    fontSize: '1.5rem',
    fontFamily: 'Source Sans Pro',
    width: 'min(334px, 90%)',
    textAlign: 'center',
    margin: '80px 0',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: '#fff',
    borderBottomStyle: 'solid',
    width: '100%',
    maxWidth: 853,
    paddingTop: 25,
  },
  partnerLogos: {
    display: 'grid',
    width: 'min(644px, 100%)',
    gridTemplateColumns: {
      xs: '1fr 1fr',
      sm: '1fr 1fr 1fr 1fr',
    },
    columnGap: 'min(101px, 1%)',
    rowGap: '20px',
    marginTop: '51px',
    justifyItems: 'center',
  },
  partnerLogo: {
    backgroundColor: 'white',
    width: '86px',
    height: '86px',
  },
  realPartnerLogoWrapper: {

    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'colors.main',
    width: '86px',
  },
  partnerLogoCenturysWrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: 'colors.main',
    alignItems: 'center',
    justifyContent: 'center',

  },
  sectionTwo: {
    backgroundColor: 'colors.darkGrey6',
    padding: {
      lg: '120px',
      md: '100px',
      sm: '60px',
      xs: '24px',
    },
  },
  buttonWrapperLink: {
    textDecoration: 'none',
  },
  websiteShortcutsColumns: {
    gap: '32px',
    display: 'grid',
    width: '100%',
    marginTop: {
      xs: '60px',
      sm: '30px',
      md: 0,
    },
  },
  websiteShortcutsTitle: {
    fontFamily: 'Montserrat',
    fontSize: '2.625rem',
    fontWeight: '600',
    marginTop: '10px',
  },
}
