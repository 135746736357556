import React, { useState } from 'react'
import Text from 'components/Text'
import styles from 'screens/GetStarted/components/Pagination/PaginationStyles.module.css'



function Circle({ id, activeId, setActiveId, disabled, setVisibleLabels, label }) {
  const [mouseOver, setMouseOver] = useState(false)
  const active = id === activeId

  const onMouseEnter = () => {
    setVisibleLabels(true)
    setMouseOver(true)
  }

  const onMouseLeave = () => {
    setVisibleLabels(false)
    setMouseOver(false)
  }

  if (disabled) {
    return <div className={styles.circleContainer}>
      <div
        onClick={() => setActiveId(id)}
        className={`${styles.circle} , ${styles.disabledCircle}`}
      />
    </div>
  }
  return <a href={`#${id}`} className={styles.circleContainer}>
    {mouseOver && (
      <>
        <Text size='13px' color='primary.main' mr='8px'>
          {label}
        </Text>
        <div style={{
          width: 50,
          height: 2,
          backgroundColor: 'primary.main',
          marginRight: 8
        }}></div>
      </>
    )}

    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      // onClick={() => setActiveId(id)}
      className={`${styles.circle} , ${active && styles.activeCircle}`}
    >
      {active && <div className={styles.innerCircle}></div>}
    </div>
  </a>
}

function Divider({ sectionTitle, visibleLabels }) {
  return <div className={styles.dividerContainer}>
    <div style={{ position: 'absolute', right: 20, bottom: 5 }}>
      <Text size='13px' style={{ opacity: visibleLabels ? 1 : 0 }} className={styles.sectionTitle} mr='10px'>
        {sectionTitle}
      </Text>
    </div>
    <div className={styles.divider}></div>
  </div>
}

export default function Pagination({ activeId, setActiveId, }) {
  const [visibleLabels, setVisibleLabels] = useState(false)

  return <div className={styles.container} >
    <Divider visibleLabels={visibleLabels} sectionTitle='Research' />
    {/* research */}
    <Circle id='research' label='Portfolio of Portfolios' activeId={activeId} setActiveId={setActiveId} setVisibleLabels={setVisibleLabels} />
    <Circle id='research' disabled activeId={activeId} setActiveId={() => { }} />
    <Circle id='research' disabled activeId={activeId} setActiveId={() => { }} />
    <Divider visibleLabels={visibleLabels} sectionTitle='Products' />
    {/* products */}
    <Circle id='products' label='Exceptional & Rich' activeId={activeId} setActiveId={setActiveId} setVisibleLabels={setVisibleLabels} />
    <Circle id='products' disabled activeId={activeId} setActiveId={() => { }} />
    <Circle id='products' disabled activeId={activeId} setActiveId={() => { }} />
    <Divider visibleLabels={visibleLabels} sectionTitle='Tools' />
    {/* tools */}
    <Circle id='tools' label='Test our Algorithms' activeId={activeId} setActiveId={setActiveId} setVisibleLabels={setVisibleLabels} />
    <Circle id='tools' disabled activeId={activeId} setActiveId={() => { }} />
    <Circle id='tools' disabled activeId={activeId} setActiveId={() => { }} />
  </div>
}
