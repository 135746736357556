import axios from "axios"
import { apiStatuses } from "utils/apiStatuses"
import { myAxios, printError } from "utils/apiUtils"
import { defaultArticleFilter } from "store/selectors/articleSelector"
import appConfig from "appConfig"

const SET_SELECTED_FILTER = 'articles/SET_SELECTED_FILTER'

const FETCH_ALL_ARTICLES_START = 'articles/FETCH_ALL_ARTICLES_START'
const FETCH_ALL_ARTICLES_SUCCESS = 'articles/FETCH_ALL_ARTICLES_SUCCESS'
const FETCH_ALL_ARTICLES_ERROR = 'articles/FETCH_ALL_ARTICLES_ERROR'

const FETCH_ARTICLE_DETAIL_START = 'articles/FETCH_ARTICLE_DETAIL_START'
const FETCH_ARTICLE_DETAIL_SUCCESS = 'articles/FETCH_ARTICLE_DETAIL_SUCCESS'
const FETCH_ARTICLE_DETAIL_ERROR = 'articles/FETCH_ARTICLE_DETAIL_ERROR'


const cmsApiUrl = appConfig.cmsApiUrl

const initialState = {
  selectedFilter: defaultArticleFilter,
  allArticles: null,
  articleDetail: {},
}


const articlesReducer = (state = initialState, action) => {

  const payload = action?.payload

  const insertArticleDetail = dataToInsert => {
    if (!payload?.slug) return state

    return {
      ...state,
      articleDetail: {
        ...state.articleDetail,
        [payload.slug]: dataToInsert,
      }
    }
  }

  switch (action?.type) {
    case SET_SELECTED_FILTER: return { ...state, selectedFilter: payload }

    case FETCH_ALL_ARTICLES_START: return { ...state, allArticles: apiStatuses.getStarted(), }
    case FETCH_ALL_ARTICLES_SUCCESS: return { ...state, allArticles: apiStatuses.getSuccess(payload), }
    case FETCH_ALL_ARTICLES_ERROR: return { ...state, allArticles: apiStatuses.getError(payload?.error), }

    case FETCH_ARTICLE_DETAIL_START: return insertArticleDetail(apiStatuses.getStarted())
    case FETCH_ARTICLE_DETAIL_SUCCESS: return insertArticleDetail(apiStatuses.getSuccess(payload?.data))
    case FETCH_ARTICLE_DETAIL_ERROR: return insertArticleDetail(apiStatuses.getError(payload?.error))

    default: return state
  }
}


export const fetchAllArticles = () => dispatch => {

  dispatch({ type: FETCH_ALL_ARTICLES_START })

  const form_data = new FormData;
  form_data.append('action', 'getAllArticles');

  myAxios({
    axiosCallerFn: () => axios.post(cmsApiUrl, form_data),
    onSuccess: response => dispatch({
      type: FETCH_ALL_ARTICLES_SUCCESS,
      payload: response?.data
    }),
    onError: error => {
      dispatch({ type: FETCH_ALL_ARTICLES_ERROR, error })
      printError('fetchAllArticles()', error, dispatch)
    },
    dispatch,
  })
}


export const fetchArticleDetail = slug => dispatch => {

  if (!slug) {
    console.error('Error at fetchArticleDetail(): slug cannot be empty. ')
    return
  }

  dispatch({ type: FETCH_ARTICLE_DETAIL_START, payload: { slug } })

  const form_data = new FormData;
  form_data.append('action', 'getArticle');
  form_data.append('slug', slug);

  myAxios({
    axiosCallerFn: () => axios.post(cmsApiUrl, form_data),
    onSuccess: response => dispatch({
      type: FETCH_ARTICLE_DETAIL_SUCCESS,
      payload: {
        slug,
        data: response?.data,
      }
    }),
    onError: error => {
      dispatch({ type: FETCH_ARTICLE_DETAIL_ERROR, payload: { error, slug } })
      printError('fetchArticleDetail()', error, dispatch)
    },
    dispatch,
  })
}

export const setSelectedFilter = selectedFilter =>
  ({ type: SET_SELECTED_FILTER, payload: selectedFilter })


export default articlesReducer
