import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Text from 'components/Text'
import TableHeaderIcon from 'screens/Market/TableHeaderIcon'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { calculatePrices } from 'utils/payment/calculatePrices'
import { billingFrequencies } from 'utils/payment/billingFrequencies'
import { subscriptionTypes } from 'utils/payment/subscriptionTypes'


const MyRow = ({ text, desc, cell1, cell2, cell3 }) => <TableRow sx={styles.commonRow}>
  <TableCell>
    <TableHeaderIcon
      title={text}
      sx={styles.firstCellInRow}
      isSmall
      desc={desc}
    />
  </TableCell>
  <TableCell sx={styles.commonCell}>{cell1}</TableCell>
  <TableCell sx={styles.commonCell}>{cell2}</TableCell>
  <TableCell sx={styles.commonCell}>{cell3}</TableCell>
</TableRow>


const PricingAllFeatures = () => {


  const calculatedPrices = useSelector(calculatePrices)
  const selectedBillingFrequency = useSelector(state => state.payment.selectedBillingFrequency)


  const frequencyLabel = {
    [billingFrequencies.monthly]: 'month',
    [billingFrequencies.yearly]: 'year',
  }[selectedBillingFrequency] || ''



  return <TableContainer id='features'>
    <Table sx={styles.table}>
      <TableHead sx={styles.tableHead}>
        <TableRow>
          <TableCell sx={styles.headerCell} />
          <HeaderCell
            type='Individual'
            price={`$${calculatedPrices?.[subscriptionTypes.type2]}`}
            frequency={frequencyLabel}
            slash='/'
          />
          <HeaderCell
            type='Institutional'
            price={`$${calculatedPrices?.[subscriptionTypes.type3]}`}
            frequency='month'
            slash='/'
          />
          <HeaderCell type='Advisory' price='Contact us' frequency='for more information' />
        </TableRow>
      </TableHead>
      <TableBody>

        <SectionTitleRow text='Product Type' disableSpacing />
        <MyRow cell1='Free' cell2='Free' cell3='Free' text='Exceptional & Rich Indices' desc='A unique and dynamic method to weigh components in a basket to generate risk-weighted alpha while maintaining low turnover and low tracking error.' />
        <MyRow cell1='—' cell2='—' cell3='—' text='Exceptional & Rich Styles' desc='A staggered approach to construct portfolios to remove starting point bias and improve investability.' />
        <MyRow cell1='—' cell2='—' cell3='—' text='Exceptional & Rich Strategies' desc='Concentrated portfolios based on statistical factors used for tactical allocation, arbitrage, and hedging.' />

        <SectionTitleRow text='Investible Portfolios' />
        <MyRow cell1='1' cell2='Unlimited' cell3='Unlimited' text='Standard Portfolio' desc='Portfolios start on the first trading day of the month.' />
        <MyRow cell1='—' cell2='3' cell3='21' text='Portfolio Creation/ Month' desc='End-of-day portfolios are created on any trading day of the month.' />
        <MyRow cell1='—' cell2='—' cell3='1' text='Mandate Customization' desc='Asset, Region, Sector, and Factor specific unique mandates.' />

        <SectionTitleRow text='Analytics' />
        <MyRow cell1={<Check />} cell2={<Check />} cell3={<Check />} text='Product Simulations' desc='Portfolio simulations for monthly and yearly starting points.' />
        <MyRow cell1={<Check />} cell2={<Check />} cell3={<Check />} text='Monthly Rebalance Instructions' desc='Rebalance instructions for exit, entries, and readjustments.' />
        <MyRow cell1='—' cell2={<Check />} cell3={<Check />} text='Historical Rebalances Data' desc='Historical data for rebalances since model portfolio inception.' />
        <MyRow cell1='—' cell2={<Check />} cell3={<Check />} text='Performance Attribution Data' desc='Asset allocation, stock selection, and interaction' />
        <MyRow cell1='—' cell2={<Check />} cell3={<Check />} text='Historical Simulations Data' desc='Historical simulation data since model portfolio inception.' />
        <MyRow cell1='—' cell2='—' cell3={<Check />} text='Advanced Analytics' desc='Customized Analytics Request' />
        <MyRow cell1='—' cell2='—' cell3={<Check />} text='Alternative Data' desc='Alternative Data for Machine Learning.' />

        <SectionTitleRow text='Research' />
        <MyRow cell1='Free' cell2='Free' cell3='Free' text='Insights' desc='Features, webinars, podcasts, and primers covering everything from investing, and trends to performance.' />
        <MyRow cell1='—' cell2={<Check />} cell3={<Check />} text='Sandbox Support' desc='Assistance with Github Sandbox codebase.' />
        <MyRow cell1='—' cell2='—' cell3={<Check />} text='Exceptional & Rich Research' desc='Academic papers, white papers, and presentations, covering strategic and tactical investing themes across asset classes and individual assets.' />

        <SectionTitleRow text='Licensing' />
        <MyRow cell1='—' cell2='—' cell3={<Check />} text='White Labeling' desc='Banks, Robo Advisors, Brokerage Companies, Financial Planners, Asset Managers, etc.' />
        <MyRow cell1='—' cell2='—' cell3={<Check />} text='APIs' desc='Standardized and customized APIs' />
        <MyRow cell1='—' cell2='—' cell3={<Check />} text='Licensing' desc='Licensing for institutional products.' />

      </TableBody>
    </Table>
  </TableContainer >


}



const HeaderCell = ({ type, price, frequency, slash }) => <TableCell
  sx={styles.headerCell}
>
  <Text sx={styles.subscriptionType}>{type}</Text>
  <Text sx={styles.subscriptionPrice}>
    {price}
    {slash &&
      <span style={styles.subscriptionPriceSlash}>
        {slash}
      </span>
    }
  </Text>
  <Text sx={styles.subscriptionFrequency}>{frequency}</Text>
</TableCell>


const SectionTitleRow = ({ text, disableSpacing = false }) => <>
  {!disableSpacing &&
    <TableRow sx={styles.sectionTitleRow}>
      <TableCell sx={styles.sectionTitleSpacerCell} />
    </TableRow>
  }
  <TableRow sx={styles.sectionTitleRow}>
    <TableCell sx={styles.sectionTitleCell} colSpan='4'>
      {text}
    </TableCell>
  </TableRow>
</>


const Check = () => (
  <Grid container sx={styles.checkBoxIconWrapper}>
    <CheckBoxIcon sx={styles.checkBoxIcon} />
  </Grid>
)


const styles = {
  subscriptionType: {
    fontWeight: '700',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey26',
    fontSize: {
      xs: '0.8rem',
      sm: '1rem',
      md: '1.25rem',
    },
  },
  subscriptionPrice: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '1rem',
      sm: '1.5rem',
      md: '2.0625rem',
    },
  },
  subscriptionFrequency: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '0.8rem',
      sm: '0.9rem',
      md: '1.125rem',
    },
  },
  table: {
    '& .MuiTableCell-head': {
      border: 'none',
    },
    '& .MuiTableCell-root': {
      '&:first-of-type': { paddingLeft: '0', },
      '&:last-child': { paddingRight: '0', },
      wordBreak: 'break-word',
    },
  },
  subscriptionPriceSlash: {
    fontWeight: '400',
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '0.8rem',
      sm: '0.9rem',
      md: '1.125rem',
    },
  },
  sectionTitleRow: {

  },
  sectionTitleCell: {
    fontSize: '1.5rem',
    fontWeight: '700',
    fontFamily: 'Montserrat',
  },
  tableHead: {

  },
  headerCell: {
    '& .MuiTypography-root': {
      textAlign: 'center',
    },
  },
  firstCellInRow: {
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '0.9rem',
      sm: '1rem',
      md: '1.125rem',
    },
  },
  commonCell: {
    textAlign: 'center',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '0.8rem',
      sm: '0.9rem',
      md: '1.125rem',
    },
  },
  sectionTitleSpacerCell: {
    paddingTop: '3rem',
    border: 'none',
  },
  checkBoxIcon: {
    color: 'colors.almostPrimary1',
    fontSize: '2.125rem',
  },
  commonRow: {

  },
  checkBoxIconWrapper: {
    justifyContent: 'center',
  },
}

export default PricingAllFeatures
