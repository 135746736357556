import React from 'react'
import { Typography } from '@mui/material'

import PropTypes from 'prop-types'

const variants = {
  1: 'Source Sans Pro',
  2: 'Montserrat',
}

export default function Text({
  mt,
  mb,
  variant,
  color,
  size,
  center,
  fontWeight,
  lineHeight,
  sx,
  children,
  ...rest
}) {

  return <Typography
    marginTop={mt}
    marginBottom={mb}
    sx={{
      fontFamily: variants[variant],
      color: color,
      fontSize: size,
      textAlign: center ? 'center' : 'left',
      lineHeight: lineHeight,
      fontWeight: fontWeight === 'regular' ? 'normal' : fontWeight,
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Typography>
}

Text.propTypes = {
  lineHeight: PropTypes.string,
  variant: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  sx: PropTypes.object,
  center: PropTypes.bool,
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
