import React from 'react'
import { Grid, Typography, Box } from '@mui/material'

export default function NotFound() {
  return <Grid container component='main' sx={{ height: '100vh' }}>
    <Grid
      item
      xs={12}
      sx={{
        backgroundImage: `
      linear-gradient(to bottom, rgba(255,255, 255, .85), rgba( 0,0, 0, .85)), 
      url(https://images.unsplash.com/photo-1485847791529-09ed2263da0b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80)
      `,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) =>
          t.palette.mode === 'light'
            ? t.palette.grey[50]
            : t.palette.grey[900],
        backgroundSize: 'cover',

        backgroundPosition: 'center',
        filter: 'grayscale(20%)',
      }}
    >
      <Box sx={{ marginTop: '10%' }}>
        <Typography
          variant='h1'
          sx={{ textAlign: 'center', color: ({ palette }) => palette.grey[700] }}
        >
          404
        </Typography>
        <Typography
          sx={{
            fontSize: '1.125rem',
            textAlign: 'center',
            color: ({ palette }) => palette.grey[700],
          }}
        >
          Oops! Page Not Found
        </Typography>
      </Box>
    </Grid>
  </Grid>
}

// https://images.unsplash.com/photo-1485847791529-09ed2263da0b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80
