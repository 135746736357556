import { useEffect, useRef } from 'react'
import { useState } from 'react'

const useChartWidth = chartData => {

  const [shouldResizeChart, setShouldResizeChart] = useState()
  const chartResizerTimeout = useRef()

  useEffect(() => {
    /** The chart has a problem. Initially the Legend overlaps the 
     * XAxis. Then, any resize event does correct the problem. 
     * So we trigger a resize some time later. */
    if (chartData)
      chartResizerTimeout.current = setTimeout(
        () => setShouldResizeChart(true), 1101
      )

    return () => clearTimeout(chartResizerTimeout.current)
  }, [chartData])


  return shouldResizeChart ? '100%' : '99.5%'
}

export default useChartWidth
