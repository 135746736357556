import React from 'react'
import { Grid } from '@mui/material'


import Text from 'components/Text'
import ToolsCard from 'screens/GetStarted/components/ToolsCard/ToolsCard'

import background from 'assets/img/tools1.png'
import background2 from 'assets/img/tools2.png'
import background3 from 'assets/img/tools3.png'
import background4 from 'assets/img/tools4.png'
import styles from 'screens/ToolsSlider/sections/Section3/Section3.module.css'

const cardData = [
  {
    id: '1',
    label: 'E&R U.S. 500',
    imageUrl: background
  },
  {
    id: '2',
    label: 'E&R U.S. 100',
    imageUrl: background2
  },
  {
    id: '3',
    label: 'Fama & F',
    imageUrl: background3
  },
  {
    id: '4',
    label: 'Sentiment',
    imageUrl: background4
  }
]

export default function Section3() {
  return <div className={styles.container}>
    <Grid xs={12} sm={6} item flexDirection='column' p='100px' >
      <Text size='3.5rem'>
        Sand Box
      </Text>
      <Text size='3.5rem' fontWeight='bold' mb='40px'>
        For Asset Managers
      </Text>
      <Text size='1.875rem'>
        Any asset manager i.e., a portfolio manager, hedge fund manager, a wealth manager can request us to customize the code base for a unique mandate which can allow for understanding that unique mandates can not only be benchmarked but also deliver significant risk weighted alpha using a systematic, scientific, and replicable process.
      </Text>
    </Grid>
    <Grid container xs={12} sm={6} item flexDirection='row'   >
      {cardData.map((card) => {
        return <Grid key={card.id} container item xs={6} justifyContent='center' alignItems='center' p='10px' mt={['3', '4'].includes(card.id) && '50px'}>
          <ToolsCard imageUrl={card.imageUrl} label={card.label} />
        </Grid>
      })}
    </Grid>
  </div >
}
