import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'

const SimulationsTopSection = () => <Grid sx={styles.sectionWrapper}>
  <Stack sx={styles.titleAndDescription}>
    <Text sx={styles.pageTitle}>Simulations</Text>
    <Text sx={styles.description}>
      Select a model that you want to simulate. A set of default starting points, pre-2007, post-2008, or a 12-month-old model appears. If the annualized excess returns at 10% and annualized absolute returns at 15% seem surreal and too good to be true, generate a model portfolio.
    </Text>
  </Stack>
</Grid>


const styles = {
  sectionWrapper: {
    width: '100%',
    padding: '2% 6% 1% 6%',
  },
  titleAndDescription: {
    gap: '1.5rem',
  },
  pageTitle: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  description: {
    width: 'min(100%, 354px)',
    width: '100%',
    fontSize: '1rem',
    color: 'colors.lightGrey29',
    fontWeight: '300',
    textAlign: 'left',
  },
}


export default SimulationsTopSection
