import React from 'react'
import researchSection4_1 from 'assets/img/researchSection4_1.png'
import HorizontalSlide from 'components/HorizontalSlide'
import MyImage from 'components/MyImage'
import { Stack } from '@mui/material'
import Text from 'components/Text'


const Section4 = () => <HorizontalSlide
  sx={{ height: '100%', }}
>
  <Stack sx={styles.imageAndText}>
    <MyImage src={researchSection4_1} />
    <Text size='1.5rem' sx={styles.imageCaption}>
      <i>
        Image 4: 30-year annualized performance. Best case, S&P 500 and a performance estimate for
        the rest of the active managers net of fees.
      </i>
    </Text>
  </Stack>
</HorizontalSlide>


const styles = {
  imageAndText: {

    height: '100%',
    padding: '5%',
  },
  imageCaption: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.5em',

    width: 'min(1000px, 90%)',
  },
}


export default Section4

