import PageWithSidebar from 'components/PageWithSidebar'
import { useParams } from 'react-router-dom'
import { Grid, Button, Stack } from '@mui/material'
import StarIcon from '@mui/icons-material/Star'
import Text from 'components/Text'
import ProductValueInfos from 'screens/ProductDetail/ProductValueInfos'
import Loading from 'components/Loading'
import { useBasicDetails, useBasicRestrictions } from 'hooks/productApiHooks'
import { useState } from 'react'
import TabSelector from 'screens/ProductDetail/ProductDetailTabSelector'
import { defaultTab, tabs } from 'screens/ProductDetail/ProductDetailTabs'



const ProductDetailScreen = () => {

  const { productGuid } = useParams()
  const basicDetails = useBasicDetails(productGuid)

  /** This component has the responsibility to fetch 
   * basicRestrictions. Multiple children will use this data. */
  useBasicRestrictions(productGuid)

  const [selectedTab, setSelectedTab] = useState(defaultTab)

  const SelectedTabComponent = tabs?.[selectedTab]?.component

  if (!basicDetails) return <PageWithSidebar><Loading /></PageWithSidebar>

  return <PageWithSidebar>
    <Grid sx={styles.titleRow}>
      <Stack sx={{ alignSelf: 'flex-end', }}>
        <Text sx={styles.pageTitle}>{basicDetails?.ProductName}</Text>
        <Stack direction='row' gap='8px'>
          <Button sx={styles.titleButton} variant='contained'>
            <StarIcon sx={styles.starIcon} fontSize='small' />
          </Button>
          <Button
            sx={{ ...styles.titleButton, pointerEvents: 'none', }}
            variant='contained'
          >
            {basicDetails?.ProductCode}
          </Button>
        </Stack>
      </Stack>
      <ProductValueInfos {...{ productGuid }} />
    </Grid>

    <TabSelector {...{ productGuid, selectedTab, setSelectedTab }} />

    <Stack mt='-30px'>
      {SelectedTabComponent && <SelectedTabComponent {...{ productGuid }} />}
    </Stack>

  </PageWithSidebar >
}

const styles = {
  titleRow: {
    display: 'grid',
    gap: '5%',
    rowGap: '30px',
    marginBottom: '43px',
    gridTemplateColumns: {
      xs: '1fr',
      lg: 'auto max(330px, 40%)',
    },
  },
  titleButton: {
    fontSize: '0.875rem',
    minWidth: 'unset',
    fontWeight: '600',
    padding: '0.5rem 0.5rem',
    height: '1.6rem',
    backgroundColor: 'colors.lightGrey6',
  },
  starIcon: {
    color: 'colors.lightGrey5',
    fontSize: '1rem',
    m: '0 -0.2em',
  },
  pageTitle: {
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '1.5rem',
      sm: '2rem',
      md: '2.3rem',
      lg: '2.625rem',
    },
    fontWeight: '700',
    lineHeight: '1.4',
  },
}

export default ProductDetailScreen
