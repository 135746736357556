import { Stack } from '@mui/material'
import React, { useEffect } from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { ScreenTypes, setActiveScreen, } from 'store/marketReducer'
import { useDispatch } from 'react-redux'
import ProductsTemplateTable from 'screens/Market/ProductsTable/ProductsTable'
import { useMarketProducts } from 'hooks/productApiHooks'
import MarketTopSection from 'screens/Market/MarketTopSection'

const MarketScreen = ({ pageType }) => {

  const dispatch = useDispatch()
  const products = useMarketProducts()

  useEffect(() => {
    dispatch(setActiveScreen(ScreenTypes.market))
  }, [pageType])

  return <PageWithSidebar hasBackground>
    <Stack sx={styles.screenStack}>
      <MarketTopSection />
      <ProductsTemplateTable {...{ products }} />
    </Stack>
  </PageWithSidebar>
}


const styles = {
  screenStack: {
    gap: '1rem',
    width: '100%',
  },
  filterRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '0 2%',
  },
}


export default MarketScreen
