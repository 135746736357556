import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'

const MarketTopSection = () => <Grid sx={styles.sectionWrapper}>
  <Stack sx={styles.titleAndDescription}>
    <Text sx={styles.pageTitle}>Market</Text>
    <Text sx={styles.description}>
      All our Exceptional & Rich Indices, Strategies, and Styles are listed below. Check the returns, risk, calendar performances, and Modern Portfolio Statistics for different starting points. Compare the statistics with the respective benchmarks using the drop-down menu. If you think a two-decade performance is backdated and hence too far back in time, generate simulations.
    </Text>
  </Stack>
</Grid>


const styles = {
  sectionWrapper: {
    width: '100%',
    padding: '2% 6% 1% 6%',
  },
  titleAndDescription: {
    gap: '1.5rem',
  },
  pageTitle: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  description: {
    width: 'min(100%, 354px)',
    width: '100%',
    fontSize: '1rem',
    color: 'colors.lightGrey29',
    fontWeight: '300',
    textAlign: 'left',
  },
}


export default MarketTopSection
