import { useSelector } from 'react-redux'
import { simGenSetSelected, portfGenSetSelected, portfMappingSetSelected } from 'store/userReducer'
import { ScreenTypes } from 'store/marketReducer'


/**
 * @param {*} productGuid optional
 * @returns An object with the following fields: {
 *   isSelectingProduct,
 *   currentSelectedProduct,
 *   setSelectedProduct,
 *   getIsAllowed,
 * }
 */
const usePortfTableMetadata = productGuid => {

  const activeScreen = useSelector(state => state.market.activeScreen)
  const simGeneration = useSelector(state => state.user.simGeneration)
  const portfGeneration = useSelector(state => state.user.portfGeneration)
  const portfMapping = useSelector(state => state.user.portfMapping)
  const userPermissions = useSelector(
    state => state.user.permissionsApi?.response
  )


  const getSelectionInfo = () => {
    if (activeScreen === ScreenTypes.market)
      return {
        ...simGeneration,
        setSelectedProduct: simGenSetSelected,
        getIsAllowed: () => true,
      }

    if (activeScreen === ScreenTypes.simulations) {
      if (portfGeneration.isSelectingProduct) return {
        ...portfGeneration,
        setSelectedProduct: portfGenSetSelected,
        getIsAllowed: productGuid =>
          userPermissions?.UserAllowedToCreate?.includes?.(productGuid),
      }

      if (portfMapping.isSelectingProduct) return {
        ...portfMapping,
        setSelectedProduct: portfMappingSetSelected,
        getIsAllowed: productGuid =>
          userPermissions?.UserAllowedToMapp?.includes?.(productGuid),
      }
    }

    return null
  }

  return getSelectionInfo() || {}

}

export default usePortfTableMetadata
