import { Grid, Button } from '@mui/material'
import React from 'react'
import { colors } from 'theme'
import { useSelector } from 'react-redux'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { getYear, prettyDate, stringToUnixTimestamp } from 'utils/dateUtils'
import { prettyPercent } from 'utils/numberUtils'
import { useState } from 'react'
import Text from 'components/Text'




const ProductPerformance = ({ productGuid }) => {


  const [activeTemplate, setActiveTemplate] = useState(2)

  const performances = useSelector(

    state => state.market.perfMonthlySummary?.[productGuid]
      ?.Data?.MonthlyAnnualizedDatas
  )

  const data = prettifyDataPoints(performances)

  return <>

    <Grid container
      sx={styles.chartTitleWrapper}
    >
      <Grid item sx={styles.radioButtonGroup}>
        <Button
          className={activeTemplate === 2 ? 'active' : ''}
          onClick={() => setActiveTemplate(2)}
        >
          2 Years
        </Button>
        <Button
          className={activeTemplate === 3 ? 'active' : ''}
          onClick={() => setActiveTemplate(3)}
        >
          3 Years
        </Button>
      </Grid>
      <Text sx={styles.chartTitle}>{activeTemplate}YR Annualized Rolling Return - Portfolio vs. Benchmark</Text>
    </Grid>


    <Grid sx={styles.chartWrapper}>

      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid stroke={colors.primaryPale2} />
          <XAxis
            dataKey="unixTimestamp"
            tickFormatter={getYear}
          />
          <YAxis tickFormatter={number => `${number}%`} />
          <Tooltip
            formatter={prettyPercent}
            labelFormatter={prettyDate}
          />
          <Legend />
          <Bar
            dataKey={activeTemplate === 2 ? 'PortfolioAnnualized_II' : 'PortfolioAnnualized_III'}
            fill={colors.chartLine1}
            name={`${activeTemplate}YR Annualized Rolling Return of Portfolio`}
          />
          <Bar
            dataKey={activeTemplate === 2 ? 'BenchmarkAnnualized_II' : 'BenchmarkAnnualized_III'}
            fill={colors.chartLine2}
            name={`${activeTemplate}YR Annualized Rolling Return of Benchmark`}
          />
        </BarChart>
      </ResponsiveContainer >
    </Grid>
  </>
}


const styles = {
  chartTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '12px',
    columnGap: '20px',

  },

  radioButtonGroup: {
    backgroundColor: 'colors.main',
    borderRadius: '7px',
    width: 'auto',
    padding: '4px',
    margin: '0 0 10px 0',
    '& .MuiButton-root': {
      color: 'colors.opposite',
      textTransform: 'none',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '0.84375rem',
      '&.active': {
        backgroundColor: 'colors.primaryMain',
      },
    },
  },
  chartTitle: {
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontWeight: '500',
  },
  chartWrapper: {
    width: '100%',
    height: '405px',
    backgroundColor: 'colors.darkGrey6',
    padding: '4% 4% 3% 3%',
    borderRadius: '14px',
    '& .recharts-tooltip-label': { color: 'colors.lightGrey8', },
    '& .recharts-default-tooltip': { textAlign: 'right', },
    '& path.recharts-rectangle.recharts-tooltip-cursor': {
      fill: colors.primaryPale,
    }
  },
}

const prettifyDataPoints = dataPoints => dataPoints?.map(dataPoint => ({
  unixTimestamp: stringToUnixTimestamp(dataPoint.PortfolioLastDate),
  PortfolioLastDate: dataPoint.PortfolioLastDate,
  PortfolioAnnualized_II: dataPoint.PortfolioAnnualized_II,
  PortfolioAnnualized_III: dataPoint.PortfolioAnnualized_III,
  BenchmarkAnnualized_II: dataPoint.BenchmarkAnnualized_II,
  BenchmarkAnnualized_III: dataPoint.BenchmarkAnnualized_III,
}))



export default ProductPerformance
