import { Button, Grid, Paper, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import MyLink from 'components/MyLink'
import { colors } from 'theme'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import CheckedAttribute from 'screens/Pricing/CheckedAttribute'

const PricingFreeToolsSection = () => {

  const isLogged = Boolean(useSelector(state => state.user.token))

  return <Stack sx={styles.sectionWrapper} component={Paper} elevation={5}>
    <Grid sx={styles.textAndButtonRow} container>
      <Stack sx={styles.textStack}>
        <Text sx={styles.title}>Free Tools</Text>
        <Text sx={styles.description} >
          Start learning about Machine Investing, step by step, using our free analytics and tools.
        </Text>
      </Stack>
      <Button
        sx={styles.getStartedButton}
        variant='contained'
        component={NavLink}
        to={isLogged ? '/market' : '/login'}
      >
        Get started Free
      </Button>
    </Grid>
    <Grid sx={styles.checkboxesRow} container>
      <Grid item><CheckedAttribute text='Exceptional & Rich Indices' desc='A unique and dynamic method to weigh components in a basket to generate risk-weighted alpha while maintaining low turnover and low tracking error.' /></Grid>
      <Grid item><CheckedAttribute text='Exceptional & Rich Styles' desc='A staggered approach to construct portfolios to remove starting point bias and improve investability.' /></Grid>
      <Grid item><CheckedAttribute text='Exceptional & Rich Strategies' desc='Concentrated portfolios based on statistical factors used for tactical allocation, arbitrage, and hedging.' /></Grid>
      <Grid item><CheckedAttribute text='Insights and Analytics' desc='Academic papers, white papers, primers, and features cover everything from asset trends, performance metrics, attribution, and risk analysis.' /></Grid>
    </Grid>
    <Grid container sx={styles.linkWrapper}>
      <MyLink sx={styles.seeAllLink} to='#features'>See all Features  + </MyLink>
    </Grid>
  </Stack>
}


const styles = {
  linkWrapper: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  seeAllLink: {
    color: colors.primaryMain,
    fontSize: '1rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    textAlign: 'center',
  },
  checkboxesRow: {
    columnGap: '1.5rem',
    rowGap: '1rem',
    flexDirection: 'row',
    justifyContent: {
      sx: 'flex-start',
      sm: 'space-around',
    },
    alignItems: 'flex-start',
  },
  sectionWrapper: {
    padding: '3% 5.5%',
    gap: '2rem',
    backgroundColor: 'colors.darkGrey7',
    backgroundImage: 'none',
  },
  textAndButtonRow: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: 'auto max(17rem, 42%)',
    },
    columnGap: '7%',
    rowGap: '2rem',
    borderBottom: 'solid 1px',
    borderBottomColor: 'primary',
    paddingBottom: '2.5rem',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  getStartedButton: {
    textTransform: 'none',
    fontSize: '1.25rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    borderRadius: '10px',
    width: '100%',
    paddingTop: '0.6em',
    paddingBottom: '0.6em',
    color: 'colors.opposite',
  },
  title: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
  },
  description: {
    fontSize: '1.125rem',
    fontFamily: 'Montserrat',
    fontWeight: '300',
    color: 'colors.lightGrey26',
  },
  textStack: {
    gap: '1.5rem',
  },
}

export default PricingFreeToolsSection
