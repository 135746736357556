import { Grid } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

const MyLink = ({ to, children, style, sx, ...rest }) => {

  const activeStyle = {
    ...style,
    ...sx,
    textDecoration: 'none',
    color: style?.color || sx?.color || 'inherit',
    display: 'inline',
  }

  const inactiveStyle = {
    ...style,
    ...sx,
    opacity: '0.3',
    pointerEvents: 'none',
    display: 'inline',
  }


  const isExternalLink =
    to?.startsWith('http://') ||
    to?.startsWith('https://')


  // If the URL has an anchor, use <a>
  // NavLink does not work with anchors (hashes).
  const shouldUseRegularLink = to?.includes('#')
    || isExternalLink


  if (shouldUseRegularLink) return <a
    target={isExternalLink ? '_blank' : ''}
    rel={isExternalLink ? 'noreferrer' : ''}
    href={to}
    style={activeStyle}
    {...rest}
  >
    {children}
  </a>


  return to
    ?
    <NavLink to={to} style={activeStyle} {...rest}>
      {children}
    </NavLink>
    :
    <Grid style={inactiveStyle} {...rest} component='span'>
      {children}
    </Grid>

}


export default MyLink
