import React from 'react'
import { Grid } from '@mui/material'


const Loading = ({ isHidden, ...rest }) => {

  if (isHidden) return null

  return <Grid
    container
    justifyContent='center'
    alignItems='center'
    {...rest}
  >
    <h1>Loading...</h1>
  </Grid >

}

export default Loading
