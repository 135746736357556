import React from 'react'
import researchSection2 from 'assets/img/researchSection2_1.jpg'
import HorizontalSlide from 'components/HorizontalSlide'
import MyImage from 'components/MyImage'


const Section2 = () => <HorizontalSlide sx={{ height: '100%', }}>
  <MyImage src={researchSection2} />
</HorizontalSlide>


export default Section2

