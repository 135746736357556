import React, { useState } from 'react'
import { Grid, TextField, Button, Stack } from '@mui/material'
import Text from 'components/Text'
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToNewsletter } from 'store/userReducer';

const SubscribeToNewsletterForm = () => {

  const dispatch = useDispatch()
  const apiData = useSelector(state => state.user.subscribeToNewsletter)
  const [email, setEmail] = useState('')


  const submit = event => {
    event?.preventDefault?.()
    dispatch(subscribeToNewsletter(email))
  }

  const errorMessage = apiData?.isError && 'An error occurred, please try again later.'

  const textFieldProps = {
    label: 'Your email address',
    variant: 'filled',
    type: 'email',
    required: true,
    sx: styles.input,
    value: email,
    onChange: event => setEmail(event.target.value),
    helperText: errorMessage,
    error: Boolean(errorMessage),
  }

  const buttonProps = {
    variant: 'outlined',
    sx: styles.button,
    type: 'submit',
    disabled: apiData?.isLoading,
  }

  if (apiData?.isSuccess) return <Stack
    sx={styles.responseTextStack}
  >
    <Grid container sx={styles.iconAndTextRow}>
      <Text sx={styles.thankYouTextPart1} center>
        {'Done '}
      </Text>
      <DoneIcon sx={styles.doneIcon} />
    </Grid>
    <Text sx={styles.thankYouText}>
      You are now subscribed to our newsletter. Thank you!
    </Text>
  </Stack>


  return <form onSubmit={submit}>
    <Grid sx={styles.inputAndButton}>
      <TextField {...textFieldProps} />
      <Button {...buttonProps}>
        {apiData?.isLoading ? 'Loading...' : 'subscribe'}
      </Button>
    </Grid>
  </form>
}

const styles = {
  doneIcon: {
    fontSize: '2.2rem',
  },
  thankYouTextPart1: {
    color: 'inherit',
    fontSize: '1.5rem',
  },
  thankYouTextPart2: {
    fontSize: '1.5rem',
  },
  responseTextStack: {
    color: 'colors.primaryMain',
  },
  iconAndTextRow: {
    justifyContent: 'center',
  },
  inputAndButton: {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 369px) auto',
  },
  input: {
    '& .MuiFilledInput-root': {
      background: '#2c2c2c',
      borderRadius: 999,
      fontFamily: "'Montserrat', sans-serif",
      '& :-webkit-autofill': { borderRadius: 0, },
    },
    '& .MuiFilledInput-root:after': { borderBottom: 'none', },
    '& .MuiFilledInput-root:before': { borderBottom: 'none', },
    '& .MuiFilledInput-input': { paddingLeft: 'calc(2em + -3px)', },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': { borderBottom: 'none', },
    '& .MuiInputLabel-root': {
      fontFamily: "'Montserrat', sans-serif",
      marginLeft: '1em',
    }
  },
  button: {
    color: 'colors.main',
    textTransform: 'none',
    borderWidth: 2,
    borderColor: 'primary.main',
    borderRadius: 999,
    fontFamily: "'Montserrat', sans-serif",
    fontSize: '1.125rem',
    marginLeft: 2,
    '&:hover': { borderWidth: 2, },
  },
}

export default SubscribeToNewsletterForm
