import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Grid, } from '@mui/material'
import { colors } from 'theme'
import { prettyDate } from 'utils/dateUtils'
import { prettyCurrency } from 'utils/numberUtils'
import Loading from 'components/Loading'
import { useState } from 'react'
import nasdaqData from 'screens/AboutUs/aboutUsNasdaqData'


const AboutUsNasdaqChart = () => {

  const [chartData, _] = useState(getChartData(nasdaqData))

  if (!chartData) return <Loading sx={styles.chartWrapper} />

  return <Grid sx={styles.chartWrapper}>
    <ResponsiveContainer>
      <LineChart data={chartData}>
        <CartesianGrid stroke={colors.primaryPale2} />
        <XAxis
          dataKey='time'
          domain={['dataMin', 'dataMax']}
          name='Time'
          tickFormatter={prettyDate}
          type='number'
        />
        <YAxis
          domain={['auto', 'auto']}
          tickFormatter={prettyCurrency}
        />
        <Tooltip
          formatter={prettyCurrency}
          labelFormatter={prettyDate}
        />
        <Legend />
        <Line
          type='linear'
          name='RMIVG20T'
          dataKey='value'
          stroke={colors.chartLine1}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  </Grid>
}


const styles = {
  chartWrapper: {
    width: '100%',
    height: '405px',
    backgroundColor: 'colors.darkGrey6',
    padding: '4% 4% 3% 3%',
    borderRadius: '14px',
    '& .recharts-tooltip-label': { color: 'colors.lightGrey8', },
    '& .recharts-default-tooltip': { textAlign: 'right', },
  },
}

const getChartData = dataPointsObject => {
  if (!dataPointsObject) return null

  return Object.keys(dataPointsObject)?.map(dateString => ({
    time: (new Date(dateString)).getTime(),
    value: dataPointsObject[dateString],
  }))
}

export default AboutUsNasdaqChart
