import { useDefaultProducts } from 'hooks/productApiHooks'

const useWebsiteShortcutsContent = () => {

  const defaultProducts = useDefaultProducts({ preventFetching: true })

  const dynamicWebsiteShortcuts = defaultProducts?.response?.map(
    product => ({
      title: product?.productName,
      content: product?.ProductText,
      href: `/products/${product?.ProductGuid}`,
    })
  ) || []


  return [
    ...dynamicWebsiteShortcuts,
    {
      title: '9/10 Fail Logic',
      content: '9 out of 10 asset managers don’t beat the market. This is driving the ‘Do It Yourself ‘(DIY) rush to passive. What should have been a 50% chance to beat the market became a poor hand for the asset managers and how the intelligent future is going to break the game.',
      href: '/insights/2022-5-2-910-fail-logic',
    },
    {
      title: 'Dismantle ESG Label',
      content: 'ESG immeasurability along with a feel-good approach is still connected with a bigger lack of alpha problem in the industry. Full consequence investing, doing the right thing, and adopting a more integrated approach are steps in the right direction.',
      href: '/insights/dismantle-esg-label',
    },
    {
      title: 'The Conceptual Age',
      content: 'Breaking news is not breaking, it\'s after the fact and has a miserable record of timing the market.Even though the information age started with innovation, it has started consuming us and has become an unruly horse.',
      href: '/insights/2022-5-2-the-conceptual-age',
    },
  ]
}

export default useWebsiteShortcutsContent