import React from 'react';
import Text from 'components/Text'


const HorizontalScrollDivider = ({ sectionTitle, isLabelVisible }) => {
  return <div style={styles.dividerContainer}>
    <div style={styles.dividerTextWrapper}>
      <Text sx={{
        ...styles.sectionTitle,
        opacity: isLabelVisible ? 1 : 0,
      }}>
        {sectionTitle}
      </Text>
    </div>
    <div style={styles.divider} />
  </div>
}


const styles = {
  dividerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    marginLeft: '6px',
    marginRight: '6px',
  },
  dividerTextWrapper: {
    position: 'absolute',
    top: -20,
    right: '-50%',
    transform: `translateX(50%)`,
  },
  divider: {
    width: '2px',
    height: '18px',
    backgroundColor: '#fff',
  },
  sectionTitle: {
    fontSize: '10px',
    textAlign: 'center',
    transition: 'ease 1000ms',
    marginRight: '10px',
    width: '100px',
  },
}

export default HorizontalScrollDivider
