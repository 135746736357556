import React from 'react'
import { Grid } from '@mui/material'
import githubIcon from 'assets/img/githubIcon.png'
import linkedInIcon from 'assets/img/linkedInIcon.png'
import twitterIcon from 'assets/img/twitterIcon.png'
import MyLink from 'components/MyLink'



const SocialIconsRow = () => <Grid container gap='1.5rem'>
  <MyLink to='https://github.com/alphablockorg'>
    <img src={githubIcon} alt='GitHub' />
  </MyLink>
  <MyLink to='https://ca.linkedin.com/company/alphablockio'>
    <img src={linkedInIcon} alt='LinkedIn' />
  </MyLink>
  <MyLink to='https://twitter.com/alphablock_org'>
    <img src={twitterIcon} alt='Twitter' />
  </MyLink>
</Grid>


export default SocialIconsRow
