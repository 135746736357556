import React from 'react'
import { Grid, Paper, Stack } from '@mui/material'
import aboutUsScreens from 'assets/img/aboutUsScreens.jpg'
import Text from 'components/Text'

const AboutUsSection3 = () => {
  return <Stack sx={styles.sectionWrapper} id='our-method'>
    <Text sx={styles.title}>
      Our Method  <br />
      <b>Informational Architecture </b>
    </Text>
    <Text sx={styles.description}>
      Information is not an orange to juice, it is a fluid
      which flows from relevance to irrelevance, from effect
      to cause, from predictability to unpredictability.
      Information lives in architectural states.
    </Text>
    <Paper sx={styles.imageAndTextBoxesWrapper} elevation={3}>
      <Stack sx={styles.imageAndTextBoxes}>
        <img style={styles.image} src={aboutUsScreens} />
        <Grid sx={styles.textBoxes}>

          <Stack sx={styles.textBox}>
            <Text sx={styles.boxTitle}>
              Rich Get Richer
            </Text>
            <Text sx={styles.boxDescription}>
              A variable that is trending positively
              continues to trend higher.
            </Text>
          </Stack>

          <Stack sx={styles.textBox}>
            <Text sx={styles.boxTitle}>
              Rich Get Poorer
            </Text>
            <Text sx={styles.boxDescription}>
              A variable that is trending higher,
              reverses and drops lower.
            </Text>
          </Stack>

          <Stack sx={styles.textBox}>
            <Text sx={styles.boxTitle}>
              Poor Get Poorer
            </Text>
            <Text sx={styles.boxDescription}>
              A variable that is trending
              negatively, continues to drop lower.
            </Text>
          </Stack>

          <Stack sx={styles.textBox}>
            <Text sx={styles.boxTitle}>
              Poor Get Richer
            </Text>
            <Text sx={styles.boxDescription}>
              A variable that is trending lower
              reverses and starts rising.
            </Text>
          </Stack>

        </Grid>
      </Stack>
    </Paper>

  </Stack>
}

const styles = {
  sectionWrapper: {
    rowGap: '3.5rem',
  },
  title: {
    fontSize: '3.5rem',
    fontWeight: '400',
    lineHeight: '1.4',
    color: 'colors.lightGrey18',
  },
  description: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey17',
    fontWeight: '400',
  },
  image: {
    width: '100%',
  },
  imageAndTextBoxesWrapper: {
    borderRadius: '0',
  },
  imageAndTextBoxes: {

  },
  textBoxes: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr',
      lg: '1fr 1fr 1fr 1fr',
    }
  },
  textBox: {
    padding: '7% 12% 12% 12% ',
    '&:hover': {
      backgroundColor: 'colors.primaryPale',
    },
  },
  boxTitle: {
    fontSize: '2rem',
    color: 'colors.main',
    fontWeight: '600',
    marginBottom: '1.4em',
  },
  boxDescription: {
    fontSize: '1.5rem',
    color: 'colors.lightGrey19',
    fontWeight: '400',
  },
}

export default AboutUsSection3
