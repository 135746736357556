import { prettyPercent } from 'utils/numberUtils'
import MyTable from 'components/MyTable'
import PortfTableActionCell from 'screens/Market/ProductsTable/PortfTableActionCell'
import usePortfTableMetadata from 'screens/Market/ProductsTable/usePortfTableMetadata'
import portfTableProps from 'screens/Market/ProductsTable/portfTableProps'


const MonthlyReturnPerformanceTemplate = ({ products }) => {

  const { isSelectingProduct } = usePortfTableMetadata()


  const rows = products?.map(portfolio => ({
    productGuid: portfolio.RProductGuid,
    href: !isSelectingProduct && `/product/${portfolio.RProductGuid}`,
    RName: <PortfTableActionCell productGuid={portfolio.RProductGuid} label={portfolio.RName} />,

    RDate: portfolio.RDate,
    RJan: portfolio.RJan,
    RFeb: portfolio.RFeb,
    RMar: portfolio.RMar,
    RApr: portfolio.RApr,
    RMay: portfolio.RMay,
    RJun: portfolio.RJun,
    RJul: portfolio.RJul,
    RAug: portfolio.RAug,
    RSep: portfolio.RSep,
    ROct: portfolio.ROct,
    RNov: portfolio.RNov,
    RDec: portfolio.RDec,
  }))


  const headCells = [
    { align: 'left', formatter: v => v, id: 'RName', label: 'Name', },
    { align: 'center', formatter: v => v, id: 'RDate', label: 'Date', },
    { align: 'center', formatter: prettyPercent, id: 'RJan', label: 'Jan', },
    { align: 'center', formatter: prettyPercent, id: 'RFeb', label: 'Feb', },
    { align: 'center', formatter: prettyPercent, id: 'RMar', label: 'Mar', },
    { align: 'center', formatter: prettyPercent, id: 'RApr', label: 'Apr', },
    { align: 'center', formatter: prettyPercent, id: 'RMay', label: 'May', },
    { align: 'center', formatter: prettyPercent, id: 'RJun', label: 'Jun', },
    { align: 'center', formatter: prettyPercent, id: 'RJul', label: 'Jul', },
    { align: 'center', formatter: prettyPercent, id: 'RAug', label: 'Aug', },
    { align: 'center', formatter: prettyPercent, id: 'RSep', label: 'Sep', },
    { align: 'center', formatter: prettyPercent, id: 'ROct', label: 'Oct', },
    { align: 'center', formatter: prettyPercent, id: 'RNov', label: 'Nov', },
    { align: 'center', formatter: prettyPercent, id: 'RDec', label: 'Dec', },
  ]


  if (!products) return <h1 style={{ textAlign: 'center', }}>Loading...</h1>

  return <MyTable
    {...{ headCells, rows }}
    // highlightEventRows
    {...portfTableProps}
  />
}


export default MonthlyReturnPerformanceTemplate
