import React from 'react'
import { NavLink } from 'react-router-dom'


/**
 * Meant to be used just like NavLink, except this 
 * does not mess up the font color and underline.
 */
const MyNavLink = ({ style, children, ...rest }) => {

  const finalStyle = {
    ...styles.navLink,
    ...style,
  }

  const navLinkProps = { ...rest, style: finalStyle }

  return <NavLink {...navLinkProps}>
    {children}
  </NavLink>
}


const styles = {
  navLink: {
    textDecoration: 'inherit',
    color: 'inherit'
  },
}

export default MyNavLink
