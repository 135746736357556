export const possibleNrModels = Object.freeze({
  oneModel: '1 Model',
  threeModels: '3 Models',
  tenModels: '10 Models',

})

export const defaultNrModels = Object.values(possibleNrModels)?.[0]

export const isValidNrModels = value =>
  Boolean(Object.values(possibleNrModels)?.includes(value))


