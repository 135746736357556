

export const subscriptionTypes = Object.freeze({
  type2: 'type2',
  type3: 'type3',
  type4: 'type4', // no actual price, the user has to 'Contact Us' for this
})

export const isValidSubscriptionType = value =>
  Boolean(Object.values(subscriptionTypes)?.includes(value))

