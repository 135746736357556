
const portfTableProps = {
  disableSort: true,
  rowsPerPageOptions: [10, 50, 100],
  emptyRowHeight: '4.4em',
  paginationStyle: {
    paddingRight: '10px',
    marginBottom: '10px',
  },
  tableStyle: {
    '& .MuiTableCell-root': {
      border: 'none',
      fontSize: '0.84375rem',
      fontFamily: 'Montserrat',
      fontWeight: '500',
      padding: '1.9em 0.2em',

      wordBreak: {
        xs: 'break-word',
        lg: 'initial',
      },
      '&:first-of-type': {
        textAlign: 'left',
        paddingLeft: {
          xs: '1vw',
          sm: '2vw',
          md: '3vw',
          lg: '4vw',
        },
      },
      '&:last-child': {
        paddingRight: {
          xs: '0vw',
          sm: '1vw',
          md: '2vw',
          lg: '4vw',
        },
      },
    },
    borderBottom: '1px solid',
    borderBottomColor: 'colors.lightGrey9',
  }
}

export default portfTableProps