
import React from 'react'
import Text from 'components/Text'
import { Grid, FormHelperText } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'
import MyLink from 'components/MyLink'
import { colors } from 'theme'


const TermsCheckbox = ({
  checked,
  onChange,
  termsErrorMessage,
}) =>
  <Grid
    container
    sx={styles.checkboxAndLabelRow}
  >
    <Checkbox {...{ checked, onChange, }} />
    <Stack>
      <Text sx={styles.loginSetting}>
        {'I agree to the '}
        <MyLink
          to='/disclaimer#terms-and-conditions'
          style={styles.link}
          target='_blank'
          rel='noreferrer'
        >
          Terms Of Service
        </MyLink>
        {' and '}
        <MyLink to='/privacy-policy' style={styles.link}
          target='_blank'
          rel='noreferrer'
        >Privacy Policy</MyLink>
      </Text>
      {termsErrorMessage &&
        <FormHelperText error>{termsErrorMessage}</FormHelperText>
      }
    </Stack>
  </Grid>

const styles = {
  checkboxAndLabelRow: {
    position: 'relative',
    left: '-11px',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  loginSetting: {
    color: 'colors.lightGrey12',
    fontSize: '1.125rem',
  },
  link: {
    color: colors.linkFancyBlue,
  },
}

export default TermsCheckbox
