import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import factsheetBrickValue from 'assets/img/factsheetBrickValue.svg'
import factsheetBrickCore from 'assets/img/factsheetBrickCore.svg'
import factsheetBrickGrowth from 'assets/img/factsheetBrickGrowth.svg'
import factsheetBrickArrow from 'assets/img/factsheetBrickArrow.svg'
import MyImage from 'components/MyImage'

export const factsheetColors = Object.freeze({
  Growth: '#7c7272',
  Core: '#979797',
  Value: '#4d3f3f',
})


const FactsheetBrickGraph = () => <Grid sx={styles.masterGrid}>
  <Stack sx={styles.column1Stack}>
    <LegendItem imgSrc={factsheetBrickGrowth} text='Growth' />
    <LegendItem imgSrc={factsheetBrickCore} text='Core' />
    <LegendItem imgSrc={factsheetBrickValue} text='Value' />
  </Stack>
  <Text sx={styles.column2Top}>80%</Text>
  <Grid sx={styles.column2Mid}>Core</Grid>
  <Text sx={styles.column2Bottom}>20%</Text>
  <Stack sx={styles.column3Stack}>
    <Stack sx={styles.arrowStackInnerGrid} >
      <Text sx={styles.arrowText}>Top Quintile</Text>
      <MyImage src={factsheetBrickArrow} />
      <Text sx={styles.arrowText}>Bottom Quintile</Text>
    </Stack>
  </Stack>
</Grid>


const LegendItem = ({ imgSrc, text }) => <Grid sx={{
  ...styles.legendLineAndText,
  backgroundImage: `url(${imgSrc})`,
}} >
  <Text sx={styles.legendText}>{text}</Text>
</Grid>


const textStyle = {
  fontSize: '1.5rem',
  fontWeight: '600',
  fontFamily: 'Montserrat',
  textAlign: 'center',
}


const styles = {
  masterGrid: {
    display: 'grid',
    gridTemplateColumns: '33fr 33fr 33fr',
    backgroundColor: 'colors.darkGrey6',
    padding: '3%',
    columnGap: '3%',
  },
  column1Stack: {
    gridRow: '2',
    alignItems: 'center',
    paddingRight: '5%',
  },
  column2Top: {
    gridColumn: '2',
    backgroundColor: factsheetColors.Growth,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    paddingTop: '3rem',
    ...textStyle,
  },
  column2Mid: {
    gridColumn: '2',
    backgroundColor: factsheetColors.Core,
    ...textStyle,
    color: '#FFF0',
  },
  column2Bottom: {
    gridColumn: '2',
    backgroundColor: factsheetColors.Value,
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    paddingBottom: '3rem',
    ...textStyle,
  },
  column3Stack: {
    gridColumn: '3',
    gridRow: '1 / 4',
    height: '100%',
  },
  arrowText: {
    fontSize: '1.125rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    textAlign: 'center',

    color: 'colors.darkGrey13',
  },
  legendLineAndText: {
    width: 'min(100%, 224px)',

    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
  },
  legendText: {
    fontSize: '1.5rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    paddingLeft: '5%',

    color: 'colors.darkGrey13',
    paddingBottom: '0.5em',
  },
  arrowStackInnerGrid: {

    display: 'grid',
    gridTemplateRows: 'auto 1fr auto',
    height: '100%',
    justifyItems: 'center',
  },
}


export default FactsheetBrickGraph
