import { Stack } from '@mui/material'
import Text from 'components/Text'
import React, { useEffect } from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { useSelector } from 'react-redux'
import { ScreenTypes, setActiveScreen, } from 'store/marketReducer'
import { useDispatch } from 'react-redux'
import ProductsTemplateTable from 'screens/Market/ProductsTable/ProductsTable'
import { LoginForm } from 'screens/Authentication/LoginScreen'
import { colors } from 'theme'
import MyLink from 'components/MyLink'
import { useSimulationProducts, useUserPermissions } from 'hooks/productApiHooks'
import Loading from 'components/Loading'
import cloudAndSun from 'assets/img/cloudAndSun.png'
import SimulationsTopSection from 'screens/Market/SimulationsTopSection'


const SimulationsScreen = () => {

  const dispatch = useDispatch()
  const isLogged = useSelector(state => Boolean(state.user.token))
  const simulationProducts = useSimulationProducts()

  const userPermissions = useUserPermissions()

  useEffect(() => {
    dispatch(setActiveScreen(ScreenTypes.simulations))
  }, [])

  const getPageContent = () => {
    if (simulationProducts?.isLoading)
      return <Loading />

    if (simulationProducts?.data?.length)
      return <ProductsTemplateTable products={simulationProducts?.data} />

    /** Make sure user permissions are fetched 
     * before showing Simulation products. */
    if (isLogged && !userPermissions.isSuccess)
      return <Loading />

    return <Stack sx={styles.welcomeGrid}>
      <Text sx={styles.welcomeTitle}>Welcome to Simulations</Text>
      <img src={cloudAndSun} style={styles.welcomeImage} />

      <Text sx={styles.welcomeText}>
        To generate your <b>First Simulation</b>, please go to the
        <MyLink to='/market' sx={{ color: colors.primaryMain }}>
          <b> Market page</b>
        </MyLink>,
        select a model portfolio and click the
        <b> Generate Simulation</b> button on the left menu.
      </Text>
    </Stack>
  }


  return <PageWithSidebar hasBackground>
    <Stack sx={styles.contentStack}>
      <SimulationsTopSection />
      {isLogged ? getPageContent() : <LoginForm />}
    </Stack>
  </PageWithSidebar>
}


const styles = {
  pageTitle: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  contentStack: {
    width: '100%',
    gap: '1.2rem',
  },
  filterRow: {
    p: '4%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  welcomeTitle: {
    fontSize: '2.25rem',
    textAlign: 'center',
    color: 'colors.lightGrey22',
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: '200',
    width: 'min(100%, 628px)',
    color: 'colors.lightGrey7',
    '& b': {
      fontWeight: '600',
    },
  },
  welcomeImage: {
    width: 'min(100%, 433px)',
  },
  welcomeGrid: {
    paddingTop: '4%',
    margin: '0 10%',
    alignItems: 'center',
    gap: '24px',
  },
}


export default SimulationsScreen
