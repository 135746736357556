import React, { useEffect, useState } from 'react'
import Header from 'components/Header'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import Text from 'components/Text'
import { Grid, TextField, Button } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import Stack from '@mui/material/Stack'
import { useDispatch, useSelector } from 'react-redux'
import { registration } from 'store/userReducer'
import { NavLink, useNavigate } from 'react-router-dom'
import { colors } from 'theme'
import TermsCheckbox from 'screens/Authentication/TermsCheckbox'

const RegistrationScreen = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isLogged = Boolean(useSelector(state => state.user.token))

  const {
    isLoading,
    isSuccess,
    isError,
    errorCode,
  } = useSelector(state => state.user.registration)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [acceptsTcAndPP, setAcceptsTcAndPP] = useState(false)
  const [wasSubmitPressed, setWasSubmitPressed] = useState(false)

  const getPasswordErrorMessage = () => {
    if (!password) return 'Password cannot be empty. Please type in a password.'
    if (password !== confirmPassword) return 'Passwords do not match. '
  }

  const passwordErrorMessage = getPasswordErrorMessage()
  const isPasswordError = Boolean(passwordErrorMessage)

  useEffect(() => {
    /** TODO: refactor these login-related redirects 
     * into an elevated router logic. */
    if (isLogged) navigate('/market')
  }, [isLogged])


  const handleSubmit = () => {
    setWasSubmitPressed(true)

    if (!isPasswordError && acceptsTcAndPP)
      dispatch(registration({ password, confirmPassword, email }))
  }

  const getMainErrorMessage = () => {
    if (!isError) return null

    if (errorCode === 'email-already-registered')
      return <>This email address is already registered. Please
        {' '}
        <NavLink to='/login' style={{ color: colors.primaryMain }}>
          log in
        </NavLink>
      </>

    if (errorCode === 'invalid-email-syntax')
      return 'Invalid email address. Please type in a valid email address.'

    return 'An error occurred, please try again later.'
  }


  const termsErrorMessage =
    wasSubmitPressed &&
    !acceptsTcAndPP &&
    'You must accept the the Terms Of Service and Privacy Policy to continue. '


  const registrationForm = <>
    <Text sx={styles.title}>Registration</Text>
    <TextField
      label='Email'
      variant='filled'
      type='email'
      value={email}
      error={isError}
      helperText={getMainErrorMessage()}
      onChange={event => setEmail(event.target?.value)}
      sx={styles.input}
    />
    <TextField
      label='Password'
      variant='filled'
      type='password'
      sx={styles.input}
      value={password}

      error={wasSubmitPressed && isPasswordError}
      helperText={wasSubmitPressed && passwordErrorMessage}
      onChange={event => setPassword(event.target?.value)}
    />
    <TextField
      label='Confirm password'
      variant='filled'
      type='password'
      sx={styles.input}
      value={confirmPassword}
      onChange={event => setConfirmPassword(event.target?.value)}
    />
    <Button
      variant='contained'
      sx={styles.button}
      onClick={handleSubmit}
      disabled={isLoading}
      type='submit'
    >
      {isLoading ? 'Loading...' : 'Create Account'}
    </Button>

    <Grid container sx={styles.checkboxesAndLinks}>
      <Stack >
        <TermsCheckbox
          checked={acceptsTcAndPP}
          onChange={event => setAcceptsTcAndPP(event.target.checked)}
          termsErrorMessage={termsErrorMessage}
        />
      </Stack>
    </Grid>
  </>



  const successMessage = <>

    <Text sx={styles.title}>Please check your emails.</Text>
    <Text center>
      Thank you for registering. We have sent a confirmation
      message to {email}. Please confirm your email address
      by clicking on the Confirmation button in the email.
    </Text>

    <Button
      variant='contained'
      sx={styles.button}
      component={NavLink}
      to='/login'
    >
      Done
    </Button>

  </>

  return <>
    <Grid>
      <Header />
      <Grid container sx={styles.loginWrapper}>
        <Grid container sx={styles.loginInner}>
          {isSuccess ? successMessage : registrationForm}



          <Grid sx={styles.horizontalSeparatorWrapper}>
            <Grid sx={styles.horizontalSeparatorLine}></Grid>
            <Text size='2.5rem'>OR</Text>
            <Grid sx={styles.horizontalSeparatorLine}></Grid>
          </Grid>

          <Button
            variant='outlined'
            sx={styles.externalLoginBtn}
            startIcon={<GoogleIcon />}
            disabled
          >
            Sign up with Google
          </Button>
          <Button
            variant='outlined'
            sx={styles.externalLoginBtn}
            startIcon={<FacebookIcon />}
            disabled
          >
            Continue with Facebook
          </Button>
          <hr width='100%' />
          <Text sx={styles.title}>Already have an account?</Text>
          <Text sx={styles.welcomeText}>
            You can continue to log in to manage your account finances
          </Text>

          <Button
            variant='contained'
            sx={styles.button}
            to='/login'
            component={NavLink}
          >
            Log In
          </Button>

        </Grid>
      </Grid>
    </Grid>
    <LandingFooter />
  </>
}

const styles = {
  loginWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '5%',
  },
  loginInner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '33px',
    width: 'min(100%, 650px)',
  },
  loginImage: {
    height: '200px',
    width: '200px',
    backgroundColor: 'colors.lightGrey5',
  },
  title: {
    fontSize: '2rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    width: '100%',
    textAlign: 'center',
  },
  welcomeText: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    color: 'colors.lightGrey11',
  },
  input: {
    width: '100%',
  },
  button: {
    fontSize: '1.5rem',
    width: '100%',
    textTransform: 'none',
  },
  checkboxesAndLinks: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 'inherit',
  },
  horizontalSeparatorWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    width: '80%',
    margin: 'auto',
    gap: '2.1em',
    opacity: '0.2',
  },
  horizontalSeparatorLine: {
    height: 0,
    alignSelf: 'center',
    borderBottom: '2px solid',
    borderBottomColor: 'colors.lightGrey13',
  },
  externalLoginBtn: {
    color: 'colors.lightGrey12',
    borderColor: 'colors.lightGrey12',
    textTransform: 'none',
    fontSize: '2rem',
    width: '100%',
    textAlign: 'left',
    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: '2.2rem',
    },
  },
}



export default RegistrationScreen

