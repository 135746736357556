import { Grid, Button, Stack } from '@mui/material'
import Text from 'components/Text'
import { useState } from 'react'
import { prettyCurrency } from 'utils/numberUtils'
import { useSelector } from 'react-redux'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'


const ProductValueInfos = ({ productGuid, }) => {

  const [selectedValueDuration, setSelectedValueDuration] = useState('day')
  const basicDetails = useSelector(state => state.market.basicDetails?.[productGuid])


  const valueChanges = {
    day: basicDetails?.OneDay,
    month: basicDetails?.OneMonth,
    threeMonth: basicDetails?.ThreeMonths,
    year: basicDetails?.OneYear,
    threeYear: basicDetails?.ThreeYears,
  }

  const isChangePositive = valueChanges[selectedValueDuration] >= 0

  return <Stack sx={styles.productValueInfos}>

    <Grid container sx={styles.productValueAndChange}>
      <Text sx={styles.productValue}>{prettyCurrency(basicDetails?.CurrentValue)}</Text>
      <Text sx={{
        ...styles.productValueChange,
        ...isChangePositive && styles.positiveChange,
      }}
      >
        {isChangePositive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        {prettyCurrency(valueChanges[selectedValueDuration])}%
      </Text>
    </Grid>

    <Grid container sx={styles.durationButtons}>
      {Object.keys(durations)?.map(durationId =>
        <Button
          key={durationId}
          sx={{
            ...styles.durationButton,
            ...durationId === selectedValueDuration && styles.durationButtonSelected,
          }}
          onClick={() => { setSelectedValueDuration(durationId) }}
          variant='contained'
        >
          {durations[durationId]?.buttonLabel}
        </Button>
      )}
    </Grid>

    <Grid container sx={styles.valuePeaksRow}>
      <Grid item>
        <Text sx={styles.valuePeak}>
          Year Lowest:
          {' '}
          <span className='value'>
            {prettyCurrency(basicDetails?.YearLowest)}
          </span>
        </Text>
      </Grid>
      <Grid item sx={styles.valuePeak}>|</Grid>
      <Grid item>
        <Text sx={styles.valuePeak}>
          Year Highest:
          {' '}
          <span className='value'>
            {prettyCurrency(basicDetails?.YearHighest)}
          </span>
        </Text>
      </Grid>
    </Grid>

  </Stack>
}


const styles = {
  titleRow: {
    display: 'grid',
    gap: '5%',
    rowGap: '30px',
    gridTemplateColumns: {
      xs: '1fr',
      lg: 'auto max(330px, 40%)',
    },
  },
  titleButton: {
    fontSize: '0.875rem',
    minWidth: 'unset',
    fontWeight: '600',
    padding: '0.5rem 0.5rem',
    height: '1.6rem',
    backgroundColor: 'colors.lightGrey6',
  },
  starIcon: {
    color: 'colors.lightGrey5',
    fontSize: '1rem',
    m: '0 -0.2em',
  },
  pageTitle: {
    fontFamily: 'Montserrat',
    fontSize: {
      xs: '1.5rem',
      sm: '2rem',
      md: '2.3rem',
      lg: '2.625rem',
    },
    fontWeight: '700',
    lineHeight: '1.4',
  },
  productValue: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    fontWeight: '300',
  },
  productValueChange: {
    fontSize: '0.84375rem',
    borderRadius: '4px',
    padding: '0.15rem 0.6rem',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    color: 'colors.error',
    backgroundColor: 'colors.errorBackground',
    '& svg': {
      margin: '-0.4em -0.1em -0.4em -0.3em',
      marginRight: '0.1rem',
      fontSize: '1.4rem',
      position: 'relative',
      bottom: '0.1em',
    }
  },
  positiveChange: {
    color: 'colors.success',
    backgroundColor: 'colors.successBackground',
  },
  durationButton: {
    fontFamily: 'Montserrat',
    fontSize: '0.63296875rem',
    backgroundColor: 'colors.durationBtn',
    color: 'colors.lightGrey7',
    fontWeight: '500',
    padding: '0.2em 1.1em 0em 1.1em',
    minWidth: 'unset',
  },
  durationButtonSelected: {
    backgroundColor: 'primary.main',
    color: 'colors.opposite',
  },
  durationButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    rowGap: '10px',
    columnGap: '5px',
  },
  productValueInfos: {
    alignSelf: 'flex-end',
    gap: '12px',
    width: {
      xs: 'min(100%, 330px)',
      lg: 'auto',
    },
  },
  productValueAndChange: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '30px',
  },
  valuePeaksRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  valuePeak: {
    fontSize: '0.84375rem',
    fontWeight: '300',
    color: 'colors.lightGrey8',
    fontFamily: 'Montserrat',
    '& .value': {
      color: 'colors.main',
    },
  },
}


const durations = {
  day: { buttonLabel: '1 day', },
  month: { buttonLabel: '1 month', },
  threeMonth: { buttonLabel: '3 month', },
  year: { buttonLabel: '1 year', },
  threeYear: { buttonLabel: '3 year', },
}


export default ProductValueInfos
