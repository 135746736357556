import { Grid } from '@mui/material'
import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { colors } from 'theme'
import { factsheetColors } from 'screens/ProductDetail/FactsheetBrickGraph'


const data = [
  { name: 'Growth', value: 40, color: factsheetColors.Growth, },
  { name: 'Value', value: 40, color: factsheetColors.Value, },
  { name: 'Core', value: 20, color: factsheetColors.Core, },
]

const cells = data.map((entry, index) =>
  <Cell key={index} fill={entry.color} />
)

const pieProps = {
  dataKey: 'value',
  startAngle: 360,
  endAngle: 0,
  data,
  cx: '50%',
  cy: '50%',
  outerRadius: '70%',
}

const FactsheetPieGraph = () => <Grid sx={styles.sectionWrapper}>
  <ResponsiveContainer>
    <PieChart width={600} height={600}>
      <Pie {...pieProps} label={renderOuterLabel}>
        {cells}
      </Pie>
      <Pie {...pieProps} label={renderInnerLabel}>
        {cells}
      </Pie>
    </PieChart>
  </ResponsiveContainer>
</Grid>


const styles = {
  sectionWrapper: {
    backgroundColor: 'colors.darkGrey6',
    width: '100%',
    height: {
      xs: '200px',
      sm: '400px',
      md: '485px',
    },
  },
  label: {
    fontSize: '1.5rem',
    color: colors.lightGrey23,
    padding: '10px',
    fontWeight: '400',
  },
}


const RADIAN = Math.PI / 180

const renderInnerLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return <text
    x={x}
    y={y}
    fill={colors.main}
    textAnchor={x > cx ? 'start' : 'end'}
    dominantBaseline='central'
    style={styles.label}
  >
    {`${(percent * 100).toFixed(0)}%`}
  </text>
}


const renderOuterLabel = ({ x, y, name, cx, }) => <text
  x={x}
  y={y}
  fill={colors.main}
  dominantBaseline='central'
  textAnchor={x > cx ? 'start' : 'end'}
  style={styles.label}
>
  {name}
</text>


export default FactsheetPieGraph
