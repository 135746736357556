import { Drawer, Grid, IconButton, Stack, useMediaQuery } from '@mui/material'
import Sidebar, { sidebarBreakpoint } from 'components/Sidebar'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import HamburgerMenu from 'components/menu/HamburgerMenu'
import { useEffect } from 'react'
import { theme } from 'theme.js'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { setIsSidebarOpen } from 'store/modalsReducer'
import { useLocation } from 'react-router-dom'




const PageWithSidebar = ({ children, hasBackground, }) => {

  const location = useLocation()
  const dispatch = useDispatch()
  const isAtLeastMedium = useMediaQuery(theme.breakpoints.up('md'))

  const isSidebarOpen = useSelector(state => state.modals.isSidebarOpen)

  const closeSidebar = () => dispatch(setIsSidebarOpen(false))
  const openSidebar = () => dispatch(setIsSidebarOpen(true))

  useEffect(() => { if (isSidebarOpen) closeSidebar() }, [location])
  useEffect(() => { if (!isAtLeastMedium) closeSidebar() }, [isAtLeastMedium])

  const getSidebar = () => {
    if (isAtLeastMedium) return <Sidebar />

    if (isSidebarOpen) return <Grid sx={styles.mobileOpenSidebar}>
      <Drawer
        anchor={'left'}
        open={isSidebarOpen}
        onClose={closeSidebar}
      >
        <Box sx={styles.mobileSidebarBox} role='presentation'>
          <Sidebar />
        </Box>
      </Drawer>
    </Grid>

    return <SidebarOpenerIcon onClick={openSidebar} />
  }


  return <Stack>
    <Grid sx={styles.withSidebar}>
      {getSidebar()}
      <Stack sx={styles.hamburgerAndContentStack}>
        <HamburgerMenu />
        <Grid sx={{
          ...styles.contentWrapper,
          backgroundColor: hasBackground && 'colors.darkGrey6',
        }}>
          {children}
        </Grid>
      </Stack>
    </Grid >
    <LandingFooter />
  </Stack >
}


const SidebarOpenerIcon = ({ ...rest }) => <Grid
  sx={styles.sidebarOpenerIcon}
  container
  component={IconButton}
  {...rest}
>
  <ArrowForwardIosIcon />
</Grid>

const styles = {
  mobileSidebarBox: {
    width: '260px',
  },

  mobileOpenSidebar: {
    position: 'absolute',
    top: '0',
    left: '0',
  },
  sidebarOpenerIcon: {
    color: 'colors.opposite',
    height: '52px',
    width: '52px',
    position: 'absolute',
    left: '22px',
    top: '22px',
    backgroundColor: 'colors.main',
    color: 'colors.opposite',
    borderRadius: '0',
    zIndex: '5',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': { backgroundColor: 'colors.lightGrey5', },
    '& .MuiSvgIcon-root': { fontSize: '33px', },
  },

  withSidebar: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      [sidebarBreakpoint]: 'min(250px, 85%) auto',
      lg: 'min(280px, 85%) auto',
      xl: 'min(300px, 85%) auto',
    },
    width: '100%',
    backgroundColor: 'colors.darkGrey2',
  },
  hamburgerAndContentStack: {
    marginTop: '26px',
  },
  contentWrapper: {
    marginLeft: '4%',
    marginRight: '4%',
    marginTop: '72px',
    marginBottom: '4.5rem',
    paddingBottom: '4.5rem',
    height: '100%',
  },
  backButton: {
    width: 'auto',
    textTransform: 'none',
    fontSize: '1rem',
  },
  backButtonWrapper: {
    marginLeft: '4%',
    marginTop: '7px',
  },
}


export default PageWithSidebar
