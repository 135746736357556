import { Stack } from '@mui/material'
import Text from 'components/Text'
import React, { useEffect } from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { useSelector } from 'react-redux'
import { ScreenTypes, setActiveScreen, } from 'store/marketReducer'
import { useDispatch } from 'react-redux'
import ProductsTemplateTable from 'screens/Market/ProductsTable/ProductsTable'
import { LoginForm } from 'screens/Authentication/LoginScreen'
import MyLink from 'components/MyLink'
import { colors } from 'theme'
import Loading from 'components/Loading'
import { useMyPortfolios } from 'hooks/productApiHooks'
import cloudAndSun from 'assets/img/cloudAndSun.png'
import MyPortfoliosTopSection from 'screens/Market/MyPortfoliosTopSection'

const MyPortfoliosScreen = () => {

  const dispatch = useDispatch()
  const myPortfolios = useMyPortfolios()
  const isLogged = useSelector(state => Boolean(state.user.token))
  const isGenerationApiLoading = useSelector(state => state.user.portfGenApiStatus?.isLoading)

  const isAnythingLoading = myPortfolios?.isLoading || isGenerationApiLoading

  useEffect(() => {
    dispatch(setActiveScreen(ScreenTypes.myPortfolios))
  }, [])

  const getPageContent = () => {
    if (isAnythingLoading)
      return <Loading />

    if (myPortfolios?.data?.length)
      return <ProductsTemplateTable products={myPortfolios?.data} />

    return <Stack sx={styles.welcomeGrid}>
      <Text sx={styles.welcomeTitle}>Welcome to Portfolios</Text>
      <img src={cloudAndSun} style={styles.welcomeImage} />

      <Text sx={styles.welcomeText}>
        To generate your <b>First Portfolio</b>, please go to the
        <MyLink to='/market' sx={{ color: colors.primaryMain }}> <b>Market Page</b> </MyLink>
        and generate simulations for various starting points. Once you have generated
        the simulations, you can map one or more of these simulations to your portfolio
        to monitor and review. You can also create a new portfolio based on the previous
        end-of-day closing prices.
      </Text>
      <Text sx={styles.welcomeText}>
        You can see the <b>rebalance instructions</b> in the Overview button when you click
        on a portfolio. You can chose to receive email instructions for portfolio set up,
        rebalancing, portfolio performance and risk analysis for the latest running portfolio.
      </Text>
    </Stack>
  }


  return <PageWithSidebar hasBackground>
    <Stack sx={styles.contentStack}>
      <MyPortfoliosTopSection />
      {isLogged ? getPageContent() : <LoginForm />}
    </Stack>
  </PageWithSidebar>
}


const styles = {
  pageTitle: {
    fontSize: '2rem',
    fontWeight: '700',
  },
  contentStack: {
    width: '100%',
    gap: '1.2rem',
  },
  filterRow: {
    p: '4%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  welcomeTitle: {
    fontSize: '2.25rem',
    textAlign: 'center',
    color: 'colors.lightGrey22',
  },
  welcomeText: {
    fontSize: '1.125rem',
    fontWeight: '200',
    width: 'min(100%, 628px)',
    color: 'colors.lightGrey7',
    '& b': {
      fontWeight: '600',
    },
  },
  welcomeImage: {
    width: 'min(100%, 433px)',
  },
  welcomeGrid: {
    paddingTop: '4%',
    margin: '0 10%',
    alignItems: 'center',
    gap: '24px',
  },
}


export default MyPortfoliosScreen

