
import { getLessItems } from 'utils/productUtils'
export const getNColors = n => {

  if (!n) return null

  const cubeRoot = Math.pow(n + 2, 1 / 3)
  const nrSteps = Math.round(cubeRoot)
  const step = Math.round(255 / (nrSteps - 1))

  const numbers = Array.from(Array(nrSteps)).map((_, i) => Math.round(i * step))
  const triples = cartesian(numbers, numbers, numbers)
  const colors = triples.map(triple => `rgb(${triple[0]}, ${triple[1]}, ${triple[2]})`)

  const noBlackNoWhite = colors.slice(1, colors.length - 1)

  const nColors = getLessItems(noBlackNoWhite, n)
  return nColors
}


const cartesian =
  (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))

