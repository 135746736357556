import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import ClickAwayListener from '@mui/base/ClickAwayListener'
import Button from '@mui/material/Button'
import Text from 'components/Text'
import tralioLogoSemiTransp from 'assets/img/TralioLogoSemiTransparent.svg'
import MyLink from 'components/MyLink'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMenuOpen } from 'store/modalsReducer'
import { logout } from 'store/userReducer'
import SocialIconsRow from 'screens/GetStarted/components/SocialIconsRow'

const ModalMenu = () => {
  const location = useLocation()

  const dispatch = useDispatch()
  const isMenuOpen = useSelector(state => state.modals.isMenuOpen)
  const isLogged = Boolean(useSelector(state => state.user.token))

  const [shouldCloseMenu, setShouldCloseMenu] = useState(false)


  const closeMenu = () => setShouldCloseMenu(true)

  useEffect(() => {
    /** 
     * This closing delay is integrated to avoid the double toggling 
     * (closing and opening) of the modal menu (one from the modal 
     * clickAwayListener and one from the closeIcon). 
     * */
    if (isMenuOpen && shouldCloseMenu) {
      dispatch(setIsMenuOpen(false))
      setShouldCloseMenu(false)
    }
  }, [shouldCloseMenu, isMenuOpen])



  useEffect(() => {
    // Close menu on click:
    if (isMenuOpen) dispatch(setIsMenuOpen(false))
  }, [location])


  const logoutButton = <Text
    sx={{ ...styles.menuItem, cursor: 'pointer' }}
    onClick={() => dispatch(logout())}
  >
    Log Out
  </Text>

  if (!isMenuOpen) return null

  return <ClickAwayListener onClickAway={() => { closeMenu() }} >
    <Grid
      container
      direction='column'
      justifyContent='flex-start'
      alignItems='center'
      sx={styles.menuModalWrapper}
    >
      <Grid
        container
        direction='column'
        justifyContent='space-evenly'
        alignItems='center'
        sx={styles.menuModalInner}
      >
        <MyLink to='/'><Text sx={styles.menuItem}>Home</Text></MyLink>
        <MyLink to='/get-started#research' ><Text sx={styles.menuItem}>Research</Text></MyLink>
        <MyLink to='/get-started#products'><Text sx={styles.menuItem}>Products</Text></MyLink>
        <MyLink to='/get-started#tools'><Text sx={styles.menuItem}>Tools</Text></MyLink>
        <MyLink to='/market'><Text sx={styles.menuItem}>Market</Text></MyLink>
        <MyLink to='/insights'><Text sx={styles.menuItem}>Insights</Text></MyLink>
        <MyLink to='/about-us'><Text sx={styles.menuItem}>About Us</Text></MyLink>
        <MyLink to='/contact'><Text sx={styles.menuItem}>Contact Us</Text></MyLink>
        <MyLink to='/pricing'><Text sx={styles.menuItem}>Pricing</Text></MyLink>
        {isLogged && logoutButton}

        {!isLogged && <Grid
          container
          direction='column'
          justifyContent='flex-start'
          alignItems='center'
          sx={{
            marginTop: '30px',
            gap: '20px',
          }}
        >
          <Text sx={styles.getStartedText}>Let's get started</Text>
          <NavLink to='/login' style={styles.buttonWrapperLink}>
            <Button sx={styles.loginButton} variant='contained'>Log In</Button>
          </NavLink>
        </Grid>
        }

      </Grid>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='center'
        sx={{ height: '100px', }}
      >
        <Grid item><SocialIconsRow /></Grid>
      </Grid>
    </Grid >
  </ClickAwayListener >
}



const styles = {
  menuModalWrapper: {
    position: 'fixed',
    height: '100%',
    width: 'min(800px, 100%)',
    backgroundColor: 'black',
    top: 0,
    right: 0,
    zIndex: '3',
    padding: '100px min(5%, 115px) 0 min(5%, 115px)',
  },
  menuModalInner: {
    backgroundColor: 'colors.darkGrey3',
    padding: '8% 5%',
    backgroundImage: `url('${tralioLogoSemiTransp}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '69%',
    backgroundPosition: 'center',

  },
  menuItem: {
    color: 'colors.lightGrey10',
    textDecoration: 'none',
    fontSize: '1.5rem',
    marginBottom: '0.7em',
    '&:hover': { color: 'primary.main', }
  },
  getStartedText: {
    fontWeight: '100',
    fontSize: '1.2rem',
  },
  buttonWrapperLink: {
    textDecoration: 'none',
  },
  loginButton: {
    fontFamily: 'Montserrat',
    fontSize: '1.3rem',
    padding: '0.3em 3.5em',
    textTransform: 'none',
  },
}

export default ModalMenu
