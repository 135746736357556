import React from 'react'
import { Button } from '@mui/material'
import toolsSection2 from 'assets/img/toolsSection2.png'
import Text from 'components/Text'
import styles from 'screens/ToolsSlider/sections/Section2/Section2.module.css'
import MyLink from 'components/MyLink'

export default function Section2() {
  return <div className={styles.container}>
    <div className={styles.contentContainer}>
      <img src={toolsSection2} style={{ maxWidth: '100%', height: 'auto' }} alt='' />
      <div className={styles.quote}>
        <Text size='2.5rem'>
          “
        </Text>
        <Text size='1.125rem' mt='10%'>
          I have used the Sandbox for E&R U.S. 500 and found
          it extremely insightful and an out of the box approach
          to look at stock market data. I have never seen such
          an investing and alpha creation approach
        </Text>
        <Text size='1.25rem' mt='20px' fontWeight='bold'>
          Phill Scott
        </Text>
        <Text size='1.25rem'>
          UBB Endowment
        </Text>
      </div>
      <div className={styles.btnContainer}>
        <MyLink to='https://github.com/alphablockorg'>
          <Button
            variant='contained'
            fullWidth
            sx={stylesJson.button}
          >
            <Text size='1.5rem' color='black' >
              GITHUB
            </Text>
          </Button>
        </MyLink>
      </div>
    </div>
  </div >
}


const stylesJson = {
  button: {
    height: 70
  },
}
