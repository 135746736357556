import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import MyTable from 'components/MyTable'
import { prettyDateShort } from 'utils/dateUtils'
import { prettyNumber } from 'utils/numberUtils'



const RunningComponents = ({ productGuid }) => {


  const headCells = [
    { align: 'left', formatter: v => v, id: 'Symbol', label: 'Symbol', },
    { align: 'left', formatter: v => v, id: 'Name', label: 'Name', },
    { align: 'right', formatter: prettyDateShort, id: 'Date', label: 'Entry Date', },
    { align: 'right', formatter: prettyNumber, id: 'EntryPrice', label: 'Entry Price', },
    { align: 'right', formatter: prettyNumber, id: 'CurrentPrice', label: 'Current Price', },
    { align: 'right', formatter: prettyNumber, id: 'PL', label: 'P&L (%)', },
    { align: 'right', formatter: prettyNumber, id: 'Proportion', label: 'Proportion (%)', },
    { align: 'right', formatter: v => v, id: 'HoldingPeriod', label: 'Days from Entry', },
  ]



  const [rows, setRows] = useState()

  const runningComponents = useSelector(state =>
    state.market.runningComponents?.[productGuid]
  )

  useEffect(() => {
    /** To avoid calculating the table rows at every render, 
     *  they are calculated only once and stored in state. */
    if (runningComponents) {
      setRows(runningComponents?.map(component => ({
        // Don't do any formatting here (it confuses the sort algorithm).
        Symbol: component.Symbol,
        Name: component.Name,
        Date: component.Date,
        EntryPrice: component.EntryPrice,
        CurrentPrice: component.CurrentPrice,
        PL: component.PL,
        Proportion: component.Proportion,
        HoldingPeriod: component.HoldingPeriod,
      })))
    }
  }, [runningComponents])

  const initialSortBy = 'Date'

  return <MyTable {...{ headCells, rows, initialSortBy }} />
}




const styles = {


}



export default RunningComponents

