import appConfig from 'appConfig'
import { useSelector } from 'react-redux'
import { subscriptionTypes } from 'utils/payment/subscriptionTypes'


/**
 * Decides the stripePriceIds (for each subscriptionType) 
 * based on selectedBillingFrequency and selectedNrModels.
 * 
 * Meant to be used for stripe.redirectToCheckout().
 * 
 * @returns An object with with the stripePrideId of each subscription type.
 * Example: {
 *   [subscriptionTypes.type2]: 'price_1LDT5vIiuMmz8ldqpFKBak5N',
 *   [subscriptionTypes.type3]: 'price_1LDVk4IiuMmz8ldq6V3EenuW',
 *   [subscriptionTypes.type4]: '', // no actual price, the user has to 'Contact Us' for this
 * }
 */
const useStripePriceIds = () => {

  const selectedBillingFrequency = useSelector(
    state => state.payment.selectedBillingFrequency
  )
  const selectedNrModels = useSelector(
    state => state.payment.selectedNrModels
  )

  return {
    [subscriptionTypes.type2]:
      appConfig.allStripePriceIds[subscriptionTypes.type2]
      ?.[selectedNrModels]?.[selectedBillingFrequency] || '',

    [subscriptionTypes.type3]:
      appConfig.allStripePriceIds[subscriptionTypes.type3]
      ?.[selectedNrModels]?.[selectedBillingFrequency] || '',

    [subscriptionTypes.type4]: '', // no actual price, the user has to 'Contact Us' for this
  }
}


export default useStripePriceIds
