import { Button, Grid } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { tabs } from 'screens/ProductDetail/ProductDetailTabs'

const tabKeys = Object.keys(tabs)

const ProductDetailTabSelector = ({ productGuid, selectedTab, setSelectedTab }) => {


  const isLogged = Boolean(useSelector(state => state.user.token))
  const basicRestrictions = useSelector(
    state => state.market.basicRestrictions?.[productGuid]
  )

  const getIsButtonDisabled = tab => {

    // Non-logged users can only see the Overview tab.
    if (!isLogged)
      return tab !== tabs.overview

    if (tab === tabs.simulations)
      return !basicRestrictions?.IsTabSimulationVisible

    if (tab === tabs.data)
      return !basicRestrictions?.IsTabDataVisisble

    return !tab?.component
  }


  const getButton = (tabKey, index) => {
    const tab = tabs?.[tabKey]
    return <Button
      key={index}
      variant='contained'
      sx={styles.button}
      color={selectedTab === tabKey ? 'primary' : 'secondary'}
      disabled={getIsButtonDisabled(tab)}
      onClick={() => setSelectedTab(tabKey)}
    >{tab?.name}</Button>
  }

  return <Grid sx={styles.tabsRow} container>
    {tabKeys?.map(getButton)}
  </Grid>
}

const styles = {
  tabsRow: {
    flexDirection: 'row',
    gap: '0.875rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: '0.875rem',
    paddingBottom: '0.875rem',
    borderBottom: 'solid 1px',
    borderTop: 'solid 1px',
    borderColor: 'colors.lightGrey12',
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: '0.84375rem',
    fontWeight: '700',
    textTransform: 'none',
    borderRadius: '0',
    paddingTop: '0.8em',
    paddingBottom: '0.8em',
  },
}

export default ProductDetailTabSelector
