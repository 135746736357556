import { Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import PageWithSidebar from 'components/PageWithSidebar'
import { colors } from 'theme'
import MyLink from 'components/MyLink'
import cloudAndSun from 'assets/img/cloudAndSun.png'


const ThankYouScreen = () => <PageWithSidebar hasBackground>
  <Stack sx={styles.welcomeGrid}>
    <Text sx={styles.welcomeTitle}>Payment confirmed!</Text>
    <img src={cloudAndSun} style={styles.welcomeImage} />

    <Text sx={styles.welcomeText}>
      Thank you for your purchase, your subscription is
      now active! To get started, please go to the
      <MyLink to='/market' sx={{ color: colors.primaryMain }}>
        <b> Market page</b>
      </MyLink>.
    </Text>

  </Stack>
</PageWithSidebar>


const styles = {
  welcomeTitle: {
    fontSize: '2.25rem',
    textAlign: 'center',
    color: 'colors.lightGrey22',
  },
  welcomeText: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: '200',
    width: 'min(100%, 628px)',
    color: 'colors.lightGrey7',
    '& b': {
      fontWeight: '600',
    },
  },
  welcomeImage: {
    width: 'min(100%, 433px)',
  },
  welcomeGrid: {
    margin: '5rem 10% 0 10%',
    alignItems: 'center',
    gap: '24px',
  },
}


export default ThankYouScreen
