import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import MyTable from 'components/MyTable'
import { prettyDate, stringToUnixTimestamp } from 'utils/dateUtils'
import { Grid, Button, Stack } from '@mui/material'

const rebalanceTypes = Object.freeze({
  current: 'Current Rebalance',
  last: 'Last Rebalance',
})

const RebalanceTable = ({ productGuid }) => {


  const [selectedRebalance, setSelectedRebalance] = useState(rebalanceTypes.current)

  const headCells = [
    { align: 'left', formatter: prettyDate, id: 'date', label: 'Date', },
    { align: 'left', formatter: v => v, id: 'SymbolName', label: 'Symbol', },
    { align: 'left', formatter: v => v, id: 'SymbolDescription', label: 'Name', },
    { align: 'left', formatter: v => v, id: 'Action', label: 'Action', },
    { align: 'left', formatter: v => v, id: 'Quantity', label: 'Quantity', },
  ]

  const [rows, setRows] = useState()

  const rebalanceInfo = useSelector(state =>
    state.market.rebalanceInfo?.[productGuid]
  )


  useEffect(() => {
    /** To avoid calculating the table rows at every render, 
     *  they are calculated only once and stored in state. */
    setRows(rebalanceInfo ? getRows(rebalanceInfo, selectedRebalance) : null)
  }, [rebalanceInfo, selectedRebalance])


  const disabledBtnStyle = { opacity: '0.4', pointerEvents: 'none', }

  return <Stack>
    <Grid container
      sx={styles.chartTitleWrapper}
    >
      <Grid item sx={styles.radioButtonGroup}>
        <Button
          className={selectedRebalance === rebalanceTypes.current ? 'active' : ''}
          onClick={() => setSelectedRebalance(rebalanceTypes.current)}
        >
          {rebalanceTypes.current}
        </Button>
        <Button
          className={selectedRebalance === 'secondLatestRebalance' ? 'active' : ''}
          onClick={() => setSelectedRebalance('secondLatestRebalance')}
          sx={rebalanceInfo?.length < 2 ? disabledBtnStyle : null}
        >
          {rebalanceTypes.last}
        </Button>
      </Grid>
    </Grid>
    <MyTable {...{ headCells, rows, }} />
  </Stack>
}


export default RebalanceTable


const getRows = (rebalanceInfo, selectedRebalance) => {

  const resultRebalance = getSelectedRebalance(rebalanceInfo, selectedRebalance)
  const date = stringToUnixTimestamp(resultRebalance?.DateTime)

  const entryMapper = entries => entries?.map(entry => ({
    date,
    SymbolName: entry?.SymbolName,
    SymbolDescription: entry?.SymbolDescription,
    Action: entry?.Action,
    Quantity: entry?.Quantity,
  })) || []

  const entries = entryMapper(resultRebalance?.Entries)
  const exits = entryMapper(resultRebalance?.Exits)
  const adjustments = entryMapper(resultRebalance?.Adjustments)

  return [...entries, ...exits, ...adjustments,]
}



const getSelectedRebalance = (rebalanceInfo, selectedRebalance) => {

  // Sort rebalances by DateTime in descending order
  const sortedRebalanceInfo = rebalanceInfo?.sort((rebalance1, rebalance2) => {
    if (rebalance1?.DateTime < rebalance2?.DateTime) return 1
    if (rebalance1?.DateTime > rebalance2?.DateTime) return -1
    return 0
  })

  return selectedRebalance === rebalanceTypes.current
    ? sortedRebalanceInfo?.[0]
    : sortedRebalanceInfo?.[1]
}


const styles = {

  chartTitleWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '12px',
    columnGap: '20px',
  },

  radioButtonGroup: {
    backgroundColor: 'colors.main',
    borderRadius: '7px',
    width: 'auto',
    padding: '4px',
    margin: '0 0 10px 0',
    '& .MuiButton-root': {
      color: 'colors.opposite',
      textTransform: 'none',
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '0.84375rem',
      '&.active': {
        backgroundColor: 'colors.primaryMain',
      },
    },
  },
}