import { Grid, Stack } from '@mui/material'
import React, { useRef } from 'react'
import Section1 from 'screens/ResearchSlider/ResearchSlide1'
import Section2 from 'screens/ResearchSlider/ResearchSlide2'
import Section3 from 'screens/ResearchSlider/ResearchSlide3'
import Section4 from 'screens/ResearchSlider/ResearchSlide4'
import Section5 from 'screens/ResearchSlider/ResearchSlide5'
import Section6 from 'screens/ResearchSlider/ResearchSlide6'
import Section7 from 'screens/ResearchSlider/ResearchSlide7'
import ResearchHeader from 'screens/ResearchSlider/components/ResearchHeader/ResearchHeader'
import researchSections from 'screens/ResearchSlider/researchSlidesConfig'
import MouseWheeSliderLogic from 'screens/GetStarted/components/Pagination/MouseWheeSliderLogic'

const sectionIds = researchSections?.map(section => section?.id)
const sliderId = 'researchSlider'

export default function ResearchSliderScreen() {

  const slider = useRef()

  return <Stack sx={styles.headerAndPage}>
    <ResearchHeader />
    <Grid style={styles.pageInner}>
      <Grid style={styles.slideWrapper} ref={slider} id={sliderId}>
        <Grid item sx={styles.slideItem} id={sectionIds[0]}><Section1 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[1]}><Section2 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[2]}><Section3 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[3]}><Section4 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[4]}><Section5 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[5]}><Section6 /></Grid>
        <Grid item sx={styles.slideItem} id={sectionIds[6]}><Section7 /></Grid>
      </Grid>
      <MouseWheeSliderLogic
        {...{ sectionIds, sliderId }}
        sliderRef={slider.current}
      />
    </Grid>
  </Stack>
}


const styles = {
  headerAndPage: {
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    backgroundColor: 'colors.darkGrey6',
    overflowY: 'hidden',
    // position: 'relative',

    position: 'absolute',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
  },
  pageInner: {
    height: 'calc(100% - 91px)',
    // backgroundColor: 'red',
    // height: '100%',


  },
  slideWrapper: {
    height: '100%',
    overflowX: 'hidden',
    display: 'flex',
    scrollBehavior: 'smooth',
  },
  slideItem: {
    minWidth: '100vw',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    scrollBehavior: 'smooth',
  },
}

