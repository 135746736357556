import React from 'react'
import researchSection7_1 from 'assets/img/researchSection7_1.jpeg'
import HorizontalSlide from 'components/HorizontalSlide'
import MyImage from 'components/MyImage'
import { Stack } from '@mui/material'
import Text from 'components/Text'
import MyNavLink from 'components/MyNavLink'


const Section7 = () => <HorizontalSlide
  sx={{ height: '100%', }}
>
  <Stack
    sx={styles.imageAndText}
    component={MyNavLink}
    to='/insights/dismantle-esg-label'
  >
    <MyImage src={researchSection7_1} />
    <Text size='2rem' sx={styles.imageCaption}>
      Dismantle the ESG label
    </Text>
  </Stack>
</HorizontalSlide>


const styles = {
  imageAndText: {
    height: '100%',
    padding: '4% 4% 5% 4%',
  },
  imageCaption: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '1.5em',
    textAlign: 'center',
    fontWeight: 'bold',
    width: 'min(1000px, 90%)',
  },
}


export default Section7

