import React from 'react'
import { Grid, Paper, Stack } from '@mui/material'
import MyImage from 'components/MyImage'

import aboutUsCloudIcon from 'assets/img/aboutUsCloudIcon.png'
import Text from 'components/Text'

const PricingStepsSection = () => <Grid sx={styles.gridWrapper}>
  <Grid sx={styles.gridItemInner}>
    <Stack sx={styles.gridItem}>
      <Paper sx={styles.gridIconWrapper} elevation={3}>
        <MyImage src={aboutUsCloudIcon} />
      </Paper>
      <Text sx={styles.preTitle}>Step 1</Text>
      <Text sx={styles.title}>Select</Text>
      <Text sx={styles.description}>
        Create an account to select any market to access our free tools and services.
      </Text>
    </Stack>
  </Grid>

  <Grid sx={styles.gridItemInner}>
    <Stack sx={styles.gridItem}>
      <Paper sx={styles.gridIconWrapper} elevation={3}>
        <MyImage src={aboutUsCloudIcon} />
      </Paper>
      <Text sx={styles.preTitle}>Step 2</Text>
      <Text sx={styles.title}>Simulate</Text>
      <Text sx={styles.description}>
        Use our open methodology to simulate our products for different starting points.
      </Text>
    </Stack>
  </Grid>

  <Grid sx={styles.gridItemInner}>
    <Stack sx={styles.gridItem}>
      <Paper sx={styles.gridIconWrapper} elevation={3}>
        <MyImage src={aboutUsCloudIcon} />
      </Paper>
      <Text sx={styles.preTitle}>Step 3</Text>
      <Text sx={styles.title}>Execute</Text>
      <Text sx={styles.description}>
        Map [or create] any of our solutions to your account and get instructions to create and maintain your alpha-generating portfolios.
      </Text>
    </Stack>
  </Grid>
</Grid>

const styles = {
  gridWrapper: {
    display: 'grid',
    columnGap: '5%',
    rowGap: '5.5rem',
    gridTemplateColumns: {
      xs: '1fr',
      md: '1fr 1fr 1fr',
    },
  },
  gridItem: {
    gap: '0.625rem',
    alignItems: 'center',
  },
  gridItemInner: {
    width: 'min(100%, 290px)',
    margin: 'auto',
  },
  gridIconWrapper: {
    width: 'min(100%, 124px)',
    height: '124px',
    backgroundColor: 'colors.darkGrey7',
    backgroundImage: 'none',
  },
  preTitle: {
    fontSize: '1rem',
    fontWeight: '500',
    color: 'colors.lightgrey20',
    fontFamily: 'Montserrat',
    textAlign: 'center',
  },
  title: {
    fontSize: '2rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    color: 'colors.lightgrey20',
    textAlign: 'center',
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: '300',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey28',
    textAlign: 'center',
  },
}

export default PricingStepsSection
