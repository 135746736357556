import { Grid, Stack } from '@mui/material'
import Header from 'components/Header'
import React from 'react'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import Text from 'components/Text'
import { colors } from 'theme'
import MyLink from 'components/MyLink'


const DisclaimerScreen = () => {



  return <Stack sx={styles.pageWrapper}>
    <Header />
    <Stack sx={styles.sectionsWrapper}>

      <Text component='h1' sx={styles.title}>Disclaimer</Text>
      <Grid item sx={styles.content}>
        <p>
          Past performance is no guarantee of future results. All results
          shown are based on simulated performance and are without fees
          and expenses typical of managed accounts which would reduce
          performance. Nothing contained on this website is intended to
          constitute legal, tax, securities, financial or investment advice,
          nor an opinion regarding the appropriateness of any investment.
          No part of the website material may be duplicated in any form and/or
          redistributed without prior written consent. All website information,
          current or historical, is believed to be reliable, although its
          accuracy and completeness cannot be guaranteed.
        </p>
      </Grid>

      <Text
        component='h1'
        sx={styles.title}
        mt='1em'
        id='terms-and-conditions'
      >
        Terms and Conditions
      </Text>
      <Grid item sx={styles.content}>

        <h1>Alphablock Technologies Inc.</h1>
        <p>This document has been updated on June 27th 2016.</p>
        <p>
          Welcome to Alphablock! “Alphablock Technologies Inc ” makes this Web site (the “Site”), including all information, documents, communications, files, text, graphics, software, and products available through the Site (collectively, the “Materials”) and all services operated by Alphablock  and third parties through the Site (collectively, the “Services”), available for your use subject to the terms and conditions set forth in this document and any changes to this document that Alphablock  may publish from time to time (collectively, the “Terms of Use”).
        </p>
        <p>
          By accessing or using this Site in any way, including, without limitation, use of any of the Services, downloading of any Materials, or merely browsing the Site, you agree to and are bound by the Terms of Use, constituting the entire agreement between you and Alphablock.
        </p>
        <p>
          Alphablock reserves the right to change the Terms of Use and other guidelines or rules posted on the Site from time to time at its sole discretion, and will provide notice of material changes on the home page of the Site. Your continued use of the Site, or any Materials or Services accessible through it, after such notice has been posted constitutes your acceptance of the changes. Your use of the Site will be subject to the most current version of the Terms of Use, rules, and guidelines posted on the Site at the time of such use. You should periodically check the “Terms of Use” link on the Site’s home page to view the then-current terms.
        </p>
        <p>
          If any of these terms of use/conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining conditions. If you breach any of the Terms of Use, your authorization to use this Site automatically terminates, and any Materials downloaded or printed from the Site in violation of the Terms of Use must be immediately destroyed.
        </p>

        <h2>Area of use</h2>
        <p>
          The Terms of Use are valid and apply to all the Web Sites under Alphablock, sites for which Alphablock is the author/owner/administrator. All these Web sites will be further called individually or collectively Site or Sites.
        </p>

        <h2>Electronic Communications</h2>
        <p>
          When you visit the Site or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email or by posting notices on the Site. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
        </p>

        <h2>Sign up terms</h2>
        <p>
          When you sign up/register on Alphablock sites the information you provide has to be accurate and complete and you have to inform Alphablock if any changes occur. By filling in the sing up form you agree to allow Alphablock to process electronically your personal information.
        </p>

        <h2>Each sign up is valid only for one user.</h2>
        <p>
          When you register on Alphablock sites you can choose a username and a password. You have to provide Alphablock a valid e-mail address for notices, updates and/or billing. By registering your e-mail address, you agree you are entitled use it to send and receive e-mails.
        </p>

        <h2>Price and payment methods</h2>
        <p>Alphablock reserves the right to suspend the delivery of services if the user does not pay the dues on time or does not respect these Terms and conditions. Suspension can happen also in cases of fraud and/or furnishing of incorrect data and/or expired and/or incorrect documents. In case of suspension Alphablock can notify 15 days ahead the intention to interrupt the contracted services.</p>

        <h2>Author rights</h2>
        <p>
          Alphablock is the author of the Sites and has all the right and privileges pertaining to. Without additional information provided, all the Materials including documents, communications, files, text, graphics, software, pictures, designs, icons, audio/video clips are the property of Alphablock or are licensed to be used by Alphablock.
        </p><p>
        </p><h2>License of use</h2>
        <p>
          Alphablock grants you a limited license to use/visit the Site, without the right to modify it. This license does not include any resale or commercial use of this site or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this Site or its contents; any downloading or copying of account information for the benefit of another merchant; or any use of data mining, robots, or similar data gathering and extraction tools.
        </p><p>
        </p><p>
          This Site or any portion of this site may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express written consent of Alphablock. You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Alphablock and our affiliates without express written consent. You may not use any meta tags or any other “hidden text” utilizing Alphablock ’s name or trademarks without the express written consent of Alphablock.
          Any unauthorized use terminates the permission or license granted by Alphablock. You are granted a limited, revocable, and nonexclusive right to create a hyperlink to the home page of the Site so long as the link does not portray Alphablock in a false, misleading, derogatory, or otherwise offensive matter. You may not use any Alphablock logo or other proprietary graphic or trademark as part of the link without express written permission.
        </p>

        <h2>Trade Marks</h2>
        <p>
          Alphablock is a registered trade mark and it is owned by Alphablock Technologies Inc, the legal owner of Alphablock. Usage of the trade mark, of the domain, or of the name Alphablock without Alphablock  prior written consent is prohibited and will be punished according with the Law. Failure to comply will automatically terminate any of the licenses granted as above.
        </p>

        <h2>Disclaimer of Warranties and Limitation of Liability</h2>
        <p>
          This site is provided by Alphablock on an “as is” and “as available” basis. Alphablock makes no representations or warranties of any kind, express or implied, as to the operation of this site or the information, content, materials, or products included on this site. You expressly agree that your use of this site is at your sole risk.
        </p>
        <p>
          To the full extent permissible by applicable law, Alphablock disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. Alphablock does not warrant that this site, its servers, or email sent from Alphablock are free of viruses or other harmful components. Alphablock will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.
        </p>
        <p>
          Certain countries/state laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you might have additional rights.
          The product/services described in the Site do not represent an offer or a quote, being presented only for your information. To receive a quote from Alphablock, please access the corresponding Site section and apply for a quote. Alphablock reserves the right not to answer your request.
        </p>

        <h2>Security</h2>
        <p>
          If you use this Site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password.
        </p>
        <p>
          If you believe that the confidentiality of your account and/or your password has been compromised you are responsible to notify Alphablock as quickly as possible in order that a new set of credentials to be generated for you.
        </p>
        <p>
          Performing un-authorized operations on this Site, or tentatively performing the un-authorized operations, including but not limited to: abusive usage, fraudulent usage, un-authorized access, modify/copy of the Materials, denial of service will be punished according with the Law.
        </p>

        <h2>Reviews, Comments, Communications, and Other content</h2>
        <p>
          Visitors may post reviews, comments, and other content; send e-cards and other communications; and submit suggestions, ideas, comments, questions, or other information, so long as the content is not illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of “spam”.
        </p>
        <p>
          You may not use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of a card or other content. Alphablock reserves the right (but not the obligation) to remove or edit such content, but does not regularly review posted content.
        </p>
        <p>
          If you do post content or submit material, and unless we indicate otherwise, you grant Alphablock and its affiliates a nonexclusive, royalty-free, perpetual, irrevocable, and fully sub licensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media. You grant Alphablock the right to use the name that you submit in user identity connection with such content, if they choose.
        </p>
        <p>
          You represent and warrant that you own or otherwise control all of the rights to the content that you post; that the content is accurate; that use of the content you supply does not violate this policy and will not cause injury to any person or entity; and that you will indemnify Alphablock for all claims resulting from content you supply. Alphablock has the right but not the obligation to monitor and edit or remove any activity or content. Alphablock takes no responsibility and assumes no liability for any content posted by you or any third party.
        </p>
        <p>
          Alphablock does not sell products and/or services to minors. Alphablock might sell or process payments for products/services targeted to minors but purchased by an adult. If you are a minor and you visit our Sites you may do it only under your parent or your legal guardian supervision.
        </p>

        <h2>Copyright Complaints</h2>
        <p>
          Alphablock respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please follow our Notice and Procedure for Making Claims of Copyright Infringement presented below:
        </p>
        <ul>
          <li>An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest;</li>
          <li>A description of the copyrighted work that you claim has been infringed upon;</li>
          <li>A description of where the material that you claim is infringing is located on the site, including the auction ID number, if applicable;</li>
          <li>Your address, telephone number, and email address;</li>
          <li>A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
          <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner’s behalf.</li>
        </ul>

        <h2>Privacy Policy</h2>
        <p>Please visit and review our
          <MyLink to='/privacy-policy' sx={{ color: colors.primaryMain, }}>
            {' Privacy Policy'}
          </MyLink>
          , which also governs your visit to the Site, to understand our practices.</p>

        <h2>Applicable law</h2>
        <p>
          All and any disputes arising out of or in user identity connection with our contract will be settled first amiably in maximum 30 days from starting. If the amiable negotiations fail any such disputes shall be settled by the law and the courts from the territorial jurisdiction of Alphablock ’s office, as the case may be.
        </p>

        <h2>Final amendments</h2>
        <p>
          The information, the data, the graphs and the facts presented in the Alphablock’s reports were obtained from trustworthy sources, but their correctness cannot be guaranteed. Alphablock takes no responsibility of any kind for the accuracy, integrity, potential time gaps or the ability to access without interruption the information published in his reports, or the information sources utilized.
        </p>
        <p>
          Alphablock including other of its clients, can hold in legal terms or can be interested to trade financial instrument that are referred in his reports. The Alphablock can give investment consultancy services to any of the companies mentioned in its reports or can be their beneficiary.
        </p>
        <p>
          According to the current laws Alphablock will accurately transmit the information, but through this it offers no written or verbal guarantees, explicit or implicit of the quality or the profit of the investment made, of the correctness, punctuality and sharpness of the report’s content. Thus, Alphablock takes no obligation for the potential losses due to the use of its reports or advisory, engaged in this Agreement.
        </p>
        <p>
          By accessing Alphablock sites you admit to have read and understood the terms and conditions of Alphablock and that you were informed about the risk that Alphablock services involve.
        </p>

        <h2>Disclaimer</h2>
        <p>
          This document, information, update is not to be construed as an offer to sell or the solicitation of an offer to buy any security in any jurisdiction where such an offer or solicitation would be illegal. No person associated Alphablock is obligated to call or initiate contact with you for the purposes of elaborating or following up on the information contained in this document. The material is based upon information that we consider reliable, but we do not represent that it is accurate or complete, and it should not be relied upon as such. Neither Alphablock, nor any person connected with him, accepts any liability arising from the use of this document. The recipient of this material should rely on their own investigations and take their own professional advice. Opinions expressed are our current opinions as of the date appearing on this material only. While Alphablock endeavor is to update on a reasonable basis the information discussed in this material, there may be regulatory, compliance, or other reasons that prevent him from doing so. Prospective investors and others are cautioned that any forward-looking statements are not predictions and may be subject to change without notice. No part of this material may be duplicated in any form and/or redistributed without prior written consent. In so far as this report includes current or historical information, it is believed to be reliable, although its accuracy and completeness cannot be guaranteed.
        </p>

        <h2>Contact details</h2>
        <p>Alphablock Technologies Inc</p>
        <p>Email: contact@alphablock.org</p>
      </Grid>
    </Stack>
    <LandingFooter />
  </Stack>
}


const styles = {
  content: {
    '& h2': {
      margin: '1.5em 0 -0.4em 0',
    },
    '& img': {
      maxWidth: '100%',
      margin: '3rem auto',
      display: 'block',
    },
    '& a': {
      color: colors.primaryMain,
    },
  },
  titleAndDate: {

  },
  title: {
    fontFamily: 'Montserrat',
    fontSize: '3rem',
    fontWeight: '600',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  date: {
    textAlign: 'center',
  },
  pageWrapper: {
    gap: '2rem',

    marginLeft: 'auto',
    backgroundColor: 'colors.darkGrey9',
    marginRight: 'auto',
  },
  sectionsWrapper: {
    gap: '2rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '6rem',

    width: {
      xs: 'min(1200px, 95%)',
      sm: 'min(1200px, 90%)',
      lg: 'min(1200px, 85%)',
    },
  },
}

export default DisclaimerScreen
