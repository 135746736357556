import { useAllArticles } from 'hooks/productApiHooks'
import { useEffect, useState } from 'react'
// import articles from 'screens/Insights/hardcodedArticles'
import { useDispatch, useSelector } from 'react-redux'
import { fetchArticles } from 'store/articlesReducer'
import { defaultArticleFilter } from 'store/selectors/articleSelector'


const nrResultsPerPage = 18


const useFilteredArticles = () => {

  const allAricles = useAllArticles()

  const selectedFilter = useSelector(state => state.articles.selectedFilter)
  const [nrAllowedResults, setNrAllowedResults] = useState(nrResultsPerPage)

  const getFilteredArticles = () => {
    if (!selectedFilter || selectedFilter === defaultArticleFilter)
      return allAricles?.response

    return allAricles?.response?.filter(article =>
      article?.categories?.includes(selectedFilter)
    )
  }

  useEffect(() => {
    // Reset nrAllowedResults to default
    setNrAllowedResults(nrResultsPerPage)
  }, [selectedFilter])


  const filteredArticles = getFilteredArticles()
  const limitedArticles = filteredArticles?.slice(0, nrAllowedResults)
  const isThereMore = limitedArticles?.length < filteredArticles?.length

  const handleSeeMore = () => setNrAllowedResults(nrAllowedResults + nrResultsPerPage)

  return {
    limitedArticles,
    isThereMore,
    handleSeeMore
  }
}

export default useFilteredArticles
