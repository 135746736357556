
import axios from 'axios'
import appConfig from 'appConfig'
import { myAxios, printError } from 'utils/apiUtils'
import { Templates, isValidTemplate, defaultTemplate } from 'utils/productUtils'


const apiBaseUrl = appConfig.apiBaseUrl

const SET_VALUES = 'market/SET_VALUES'
const SET_BASIC_RESTRICTIONS = 'market/SET_BASIC_RESTRICTIONS'
const SET_PERFORMANCE = 'market/SET_PERFORMANCE'
const SET_RUNNING_COMPONENTS = 'market/SET_RUNNING_COMPONENTS'
const SET_DRAWDOWNS = 'market/SET_DRAWDOWNS'
const SET_PERF_MONTHLY_SUMMARY = 'market/SET_PERF_MONTHLY_SUMMARY'
const SET_COMPARATIVES = 'market/SET_COMPARATIVES'
const SET_SIMULATIONS = 'market/SET_SIMULATIONS'
const SET_BASIC_DETAILS = 'market/SET_BASIC_DETAILS'
const SET_REBALANCE_INFO = 'market/SET_REBALANCE_INFO'
const SET_MARKET_PRODUCTS = 'market/SET_MARKET_PRODUCTS'
const SET_SIMULATION_PRODUCTS = 'market/SET_SIMULATION_PRODUCTS'
const SET_MY_PRODUCTS = 'market/SET_MY_PRODUCTS'

const LOAD_VALUES = 'market/LOAD_VALUES'
const LOAD_BASIC_RESTRICTIONS = 'market/LOAD_BASIC_RESTRICTIONS'
const LOAD_PERFORMANCE = 'market/LOAD_PERFORMANCE'
const LOAD_RUNNING_COMPONENTS = 'market/LOAD_RUNNING_COMPONENTS'
const LOAD_DRAWDOWNS = 'market/LOAD_DRAWDOWNS'
const LOAD_PERF_MONTHLY_SUMMARY = 'market/LOAD_PERF_MONTHLY_SUMMARY'
const LOAD_COMPARATIVES = 'market/LOAD_COMPARATIVES'
const LOAD_SIMULATIONS = 'market/LOAD_SIMULATIONS'
const LOAD_BASIC_DETAILS = 'market/LOAD_BASIC_DETAILS'
const LOAD_REBALANCE_INFO = 'market/LOAD_REBALANCE_INFO'
const LOAD_MARKET_PRODUCTS = 'market/LOAD_MARKET_PRODUCTS'
const LOAD_SIMULATION_PRODUCTS = 'market/LOAD_SIMULATION_PRODUCTS'
const LOAD_MY_PRODUCTS = 'market/LOAD_MY_PRODUCTS'

const SET_SELECTED_TEMPLATE = 'market/SET_SELECTED_TEMPLATE'
const SET_ACTIVE_SCREEN = 'market/SET_ACTIVE_SCREEN'
const SET_SIMULATIONS_FILTER = 'market/SET_SIMULATIONS_FILTER'

const CLEAR_MY_PORTFOLIOS = 'market/CLEAR_MY_PORTFOLIOS'
const CLEAR_USER_SPECIFIC_MARKET_DATA = 'market/CLEAR_USER_SPECIFIC_MARKET_DATA'

const fieldsToLoad = {
  marketProducts: null,
  simulationProducts: null,
  myPortfolios: null,

  values: null,
  runningComponents: null,
  drawdowns: null,
  perfMonthlySummary: null,
  comparatives: null,
  simulations: null,
  basicDetails: null,
  rebalanceInfo: null,
  basicRestrictions: null,
}

const initialState = {
  selectedTemplate: defaultTemplate,
  activeScreen: null,

  ...fieldsToLoad,

  isLoading: { ...fieldsToLoad },

  simulationsFilter: {
    fromDate: new Date('2012-01-01T00:00:00.400Z'),
    toDate: new Date(),
  },

}


const marketReducer = (state = initialState, action) => {
  const payload = action?.payload

  /** Inserts payload.data under 
   * state.[fieldName].[payload.productGuid] 
   * or
   * state.[fieldName].[payload.template]
   * */
  const insertInState = fieldName => {
    const subfieldName = payload?.productGuid || payload?.template
    if (!fieldName || !subfieldName) return state

    return {
      ...state,
      [fieldName]: {
        ...state?.[fieldName],
        [subfieldName]: payload.data
      },
      isLoading: {
        ...state.isLoading,
        [fieldName]: {
          ...state?.isLoading?.[fieldName],
          [subfieldName]: false,
        }
      }
    }
  }

  const setLoadingInState = fieldName => {
    const subfieldName = payload?.productGuid || payload?.template
    if (!fieldName || !subfieldName) return state

    return {
      ...state,
      isLoading: {
        ...state.isLoading,
        [fieldName]: {
          ...state?.isLoading?.[fieldName],
          [subfieldName]: true,
        }
      }
    }

  }

  switch (action?.type) {
    case SET_SELECTED_TEMPLATE: return { ...state, selectedTemplate: payload }
    case SET_ACTIVE_SCREEN: return { ...state, activeScreen: payload }
    case SET_SIMULATIONS_FILTER: return {
      ...state,
      simulationsFilter: {
        fromDate: payload.fromDate,
        toDate: payload.toDate,
      },
    }

    case SET_SIMULATION_PRODUCTS: return handleSimulationProducts(state, payload)
    case SET_MARKET_PRODUCTS: return insertInState('marketProducts')
    case SET_MY_PRODUCTS: return insertInState('myPortfolios')
    case SET_VALUES: return insertInState('values')
    case SET_PERFORMANCE: return insertInState('performance')
    case SET_RUNNING_COMPONENTS: return insertInState('runningComponents')
    case SET_DRAWDOWNS: return insertInState('drawdowns')
    case SET_PERF_MONTHLY_SUMMARY: return insertInState('perfMonthlySummary')
    case SET_COMPARATIVES: return insertInState('comparatives')
    case SET_SIMULATIONS: return insertInState('simulations')
    case SET_BASIC_DETAILS: return insertInState('basicDetails')
    case SET_REBALANCE_INFO: return insertInState('rebalanceInfo')
    case SET_BASIC_RESTRICTIONS: return insertInState('basicRestrictions')

    case LOAD_SIMULATION_PRODUCTS: return setLoadingSimulationProducts(state, payload)
    case LOAD_MARKET_PRODUCTS: return setLoadingInState('marketProducts')
    case LOAD_MY_PRODUCTS: return setLoadingInState('myPortfolios')
    case LOAD_VALUES: return setLoadingInState('values')
    case LOAD_PERFORMANCE: return setLoadingInState('performance')
    case LOAD_RUNNING_COMPONENTS: return setLoadingInState('runningComponents')
    case LOAD_DRAWDOWNS: return setLoadingInState('drawdowns')
    case LOAD_PERF_MONTHLY_SUMMARY: return setLoadingInState('perfMonthlySummary')
    case LOAD_COMPARATIVES: return setLoadingInState('comparatives')
    case LOAD_SIMULATIONS: return setLoadingInState('simulations')
    case LOAD_BASIC_DETAILS: return setLoadingInState('basicDetails')
    case LOAD_REBALANCE_INFO: return setLoadingInState('rebalanceInfo')
    case LOAD_BASIC_RESTRICTIONS: return setLoadingInState('basicRestrictions')


    case CLEAR_MY_PORTFOLIOS: return {
      ...state,
      myPortfolios: null,
      isLoading: {
        ...state.isLoading,
        myPortfolios: null,
      }
    }


    case CLEAR_USER_SPECIFIC_MARKET_DATA: return {
      ...state,
      basicRestrictions: null,
      marketProducts: null,
      simulationProducts: null,
      myPortfolios: null,

      isLoading: {
        ...state.isLoading,
        basicRestrictions: false,
        marketProducts: false,
        simulationProducts: false,
        myPortfolios: false,
      }
    }

    default:
      return state
  }
}


export const fetchBasicRestrictions = (productGuid, token) => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchBasicRestrictions(): productGuid cannot be empty. ')
    return
  }
  if (!token) {
    console.log('Error at fetchBasicRestrictions(): token cannot be empty. ')
    return
  }

  dispatch({ type: LOAD_BASIC_RESTRICTIONS, payload: { productGuid } })

  myAxios({
    axiosCallerFn: axiosToken => axios.get(apiBaseUrl + '/portfolio/portfolio-basic-details-restrictions', {
      headers: { "Authorization": `Bearer ${axiosToken}` },
      params: {
        productGuid: productGuid || '',
      },
    }),
    onSuccess: response => dispatch({
      type: SET_BASIC_RESTRICTIONS,
      payload: {
        data: response.data,
        productGuid,
      },
    }),
    onError: error => printError('fetchBasicRestrictions()', error, dispatch),
    token,
    dispatch,
  })
}



export const ScreenTypes = Object.freeze({
  market: 'market',
  simulations: 'simulations',
  myPortfolios: 'myPortfolios',
})


export const setActiveScreen = activeScreen => dispatch => {
  const isValidScreenType = Object.values(ScreenTypes).includes(activeScreen)

  if (!isValidScreenType) {
    console.log(`Error at setActiveScreen(): '${activeScreen}' is not a valid ScreenType. `)
    return
  }

  dispatch({ type: SET_ACTIVE_SCREEN, payload: activeScreen })
}


const setLoadingSimulationProducts = (state, payload) => {
  if (!payload?.productGuid || !payload?.template) {
    console.log(`Error at setLoadingSimulationProducts(): invalid payload '${JSON.stringify(payload)}'.`)
    return state
  }
  return {
    ...state,
    isLoading: {
      simulationProducts: {
        ...state.isLoading.simulationProducts,
        [payload.template]: {
          ...state.isLoading.simulationProducts?.[payload.template],
          [payload.productGuid]: true,
        }
      }
    }
  }
}

/** Inserts payload.data under 
 * state.simulationProducts.[payload.template].[payload.productGuid] */
const handleSimulationProducts = (state, payload) => {
  if (!payload?.productGuid || !payload?.template) {
    console.log(`Error at handleSimulationProducts(): invalid payload '${JSON.stringify(payload)}'.`)
    return state
  }

  return {
    ...state,
    simulationProducts: {
      ...state.simulationProducts,
      [payload.template]: {
        ...state.simulationProducts?.[payload.template],
        [payload.productGuid]: payload.data,
      }
    },
    isLoading: {
      simulationProducts: {
        ...state.isLoading.simulationProducts,
        [payload.template]: {
          ...state.isLoading.simulationProducts?.[payload.template],
          [payload.productGuid]: false,
        }
      }
    }
  }
}


export const fetchRebalanceInfo = (productGuid, token) => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchRebalanceInfo(): productGuid cannot be empty. ')
    return
  }
  if (!token) {
    console.log('Error at fetchRebalanceInfo(): token cannot be empty. ')
    return
  }

  dispatch({ type: LOAD_REBALANCE_INFO, payload: { productGuid } })

  myAxios({
    axiosCallerFn: axiosToken => axios.get(apiBaseUrl + '/rebalance/last', {
      headers: { "Authorization": `Bearer ${axiosToken}` },
      params: {
        portfolioGuid: productGuid || '',
        count: '2',
        order: 'desc',
      },
    }),
    onSuccess: response => dispatch({
      type: SET_REBALANCE_INFO,
      payload: {
        data: response.data,
        productGuid,
      },
    }),
    onError: error => printError('fetchRebalanceInfo()', error, dispatch),
    token,
    dispatch,
  })
}


export const clearUserSpecificMarketData = () =>
  ({ type: CLEAR_USER_SPECIFIC_MARKET_DATA })



export const fetchMarketProducts = (selectedTemplate, token) => dispatch => {

  // Choose endpoints based on wether token is empty or not: 
  const endpoints = token ? {
    [Templates.returns]: '/landing/portfolio-returns-freeproducts-token',
    [Templates.returnsBenchmarks]: '/landing/portfolio-returns-benchmarks-freeproducts-token',
    [Templates.risks]: '/landing/portfolio-risk-freeproducts-token',
    [Templates.riskBenchmarks]: '/landing/portfolio-risk-benchmarks-freeproducts-token',
    [Templates.monthlyReturnPerformance]: '/landing/monthly-portfolio-returns-freeproducts-token',
    [Templates.monthlyReturnPerformanceBenchmark]: '/landing/monthly-portfolio-benchmark-returns-freeproducts-token',
    [Templates.monthlyExcessReturnPerformance]: '/landing/monthly-excess-return-performance-freeproducts-token',
  } : {
    [Templates.returns]: '/landing/portfolio-returns-freeproducts',
    [Templates.returnsBenchmarks]: '/landing/portfolio-returns-benchmarks-freeproducts',
    [Templates.risks]: '/landing/portfolio-risks-freeproducts',
    [Templates.riskBenchmarks]: '/landing/portfolio-risks-benchmarks-freeproducts',
    [Templates.monthlyReturnPerformance]: '/landing/monthly-portfolio-returns-freeproducts',
    [Templates.monthlyReturnPerformanceBenchmark]: '/landing/monthly-portfolio-benchmark-returns-freeproducts',
    [Templates.monthlyExcessReturnPerformance]: '/landing/monthly-excess-return-performance-freeproducts',
  }

  // Choose endpoint based on the selectedTemplate: 
  const endpoint = endpoints[selectedTemplate]

  if (!endpoint) {
    console.log(`Error at fetchMarketProducts(): '${selectedTemplate}' is not a valid template. `)
    return
  }

  const callerFnWithToken = axiosToken =>
    axios.post(apiBaseUrl + endpoint, null, {
      params: { productGuid: '', },
      headers: { "Authorization": `Bearer ${axiosToken}` },
    })

  const callerFnWithoutToken = axiosToken =>
    axios.post(apiBaseUrl + endpoint, null, { params: { productGuid: '', } })

  myAxios({
    axiosCallerFn: token ? callerFnWithToken : callerFnWithoutToken,
    onSuccess: response => {
      dispatch({
        type: SET_MARKET_PRODUCTS,
        payload: { template: selectedTemplate, data: response.data },
      })
    },
    onError: error => {
      printError('fetchMarketProducts()', error, dispatch)
    },
    token,
    dispatch,
  })
}



export const fetchSimulationProducts = (productGuid, selectedTemplate, token) => dispatch => {


  const endpoint = { // Choose endpoint based on the selectedTemplate: 
    [Templates.returns]: '/landing/portfolio-returns-freeproducts-token',
    [Templates.returnsBenchmarks]: '/landing/portfolio-returns-benchmarks-freeproducts-token',
    [Templates.risks]: '/landing/portfolio-risk-freeproducts-token',
    [Templates.riskBenchmarks]: '/landing/portfolio-risk-benchmarks-freeproducts-token',
    [Templates.monthlyReturnPerformance]: '/landing/monthly-portfolio-returns-freeproducts-token',
    [Templates.monthlyReturnPerformanceBenchmark]: '/landing/monthly-portfolio-benchmark-returns-freeproducts-token',
    [Templates.monthlyExcessReturnPerformance]: '/landing/monthly-excess-return-performance-freeproducts-token',
  }[selectedTemplate]

  if (!endpoint) {
    console.log(`Error at fetchSimulationProducts(): '${selectedTemplate}' is not a valid template. `)
    return
  }

  if (!token) {
    console.log('Error at fetchSimulationProducts(): token cannot be empty. ')
    return
  }

  dispatch({ type: LOAD_SIMULATION_PRODUCTS, payload: { productGuid, template: selectedTemplate } })

  myAxios({
    axiosCallerFn: axiosToken =>
      axios.post(apiBaseUrl + endpoint, null, {
        params: { productGuid },
        headers: { "Authorization": `Bearer ${axiosToken}` },
      }),
    onSuccess: response => {
      dispatch({
        type: SET_SIMULATION_PRODUCTS,
        payload: { template: selectedTemplate, productGuid, data: response.data },
      })
    },
    onError: error => {
      printError('fetchSimulationProducts()', error, dispatch)
    },
    token,
    dispatch,
  })
}




export const fetchMyPortfolios = (selectedTemplate, token) => dispatch => {
  const endpoint = { // Choose endpoint based on the selectedTemplate: 
    [Templates.returns]: '/portfolio/portfolio-returns-userproducts',
    [Templates.returnsBenchmarks]: '/portfolio/portfolio-returns-benchmarks-userproducts',
    [Templates.risks]: '/portfolio/portfolio-risk-userproducts',
    [Templates.riskBenchmarks]: '/portfolio/portfolio-risk-benchmarks-userproducts',
    [Templates.monthlyReturnPerformance]: '/portfolio/monthly-portfolio-returns-userproducts',
    [Templates.monthlyReturnPerformanceBenchmark]: '/portfolio/monthly-portfolio-benchmark-returns-userproducts',
    [Templates.monthlyExcessReturnPerformance]: '/portfolio/monthly-excess-return-performance-userproducts',
  }[selectedTemplate]

  if (!endpoint) {
    console.log(`Error at fetchMyPortfolios(): '${selectedTemplate}' is not a valid template. `)
    return
  }

  if (!token) {
    console.log('Error at fetchMyPortfolios(): token cannot be empty. ')
    return
  }

  dispatch({ type: LOAD_MY_PRODUCTS, payload: { template: selectedTemplate } })

  myAxios({
    axiosCallerFn: axiosToken =>
      axios.get(apiBaseUrl + endpoint, { headers: { "Authorization": `Bearer ${axiosToken}` } }),
    onSuccess: response => dispatch({
      type: SET_MY_PRODUCTS,
      payload: { template: selectedTemplate, data: response.data },
    }),
    onError: error => printError('fetchMyPortfolios()', error, dispatch),
    token,
    dispatch,
    maxNrRetries: 10,
  })
}



export const clearMyPortfolios = () => dispatch => {
  dispatch({ type: CLEAR_MY_PORTFOLIOS })
}


export const setSelectedTemplate = selectedTemplate => dispatch => {
  if (!isValidTemplate(selectedTemplate)) {
    console.log(`Error at setSelectedTemplate(): '${selectedTemplate}' is not a valid template. `)
    return
  }
  dispatch({ type: SET_SELECTED_TEMPLATE, payload: selectedTemplate })
}



export const fetchBasicDetails = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchBasicDetails(): productGuid cannot be empty. ')
    return
  }
  dispatch({ type: LOAD_BASIC_DETAILS, payload: { productGuid } })
  axios.get(apiBaseUrl + '/portfolio/portfolio-basic-details',
    { params: { productGuid: productGuid || '', } }
  )
    .then(response => dispatch({
      type: SET_BASIC_DETAILS, payload: {
        data: response.data,
        productGuid,
      }
    }))
    .catch(error => printError('fetchBasicDetails()', error, dispatch))
}


export const setSimulationsFilter = ({ fromDate, toDate, productGuid = null }) => dispatch => {
  dispatch({
    type: SET_SIMULATIONS_FILTER,
    payload: { fromDate, toDate, productGuid },
  })
  // TODO: decide if a setSimulations(null) is needed here
}




export const fetchValues = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchValues(): productGuid cannot be empty. ')
    return
  }
  dispatch({ type: LOAD_VALUES, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio/values', null, { params: { productGuid, } })
    .then(response => dispatch({
      type: SET_VALUES, payload: {
        data: response.data,
        productGuid
      }
    }))
    .catch(error => printError('fetchValues()', error, dispatch))
}




export const fetchPerformance = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchPerformance(): productGuid cannot be empty. ')
    return
  }
  dispatch({ type: LOAD_PERFORMANCE, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio/performance', null, { params: { productGuid, } })
    .then(response => dispatch({
      type: SET_PERFORMANCE, payload: {
        data: response.data,
        productGuid,
      }
    }))
    .catch(error => printError('fetchPerformance()', error, dispatch))
}




export const fetchRunningComponents = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchRunningComponents(): productGuid cannot be empty. ')
    return
  }
  dispatch({ type: LOAD_RUNNING_COMPONENTS, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio-runningcomponents', [productGuid])
    .then(response => dispatch({
      type: SET_RUNNING_COMPONENTS, payload: {
        data: response.data,
        productGuid,
      }
    }))
    .catch(error => printError('fetchRunningComponents()', error, dispatch))
}



export const fetchDrawdowns = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchDrawdowns(): productGuid cannot be empty. ')
    return
  }

  dispatch({ type: LOAD_DRAWDOWNS, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio-drawdowns', [productGuid])
    .then(response => dispatch({
      type: SET_DRAWDOWNS, payload: {
        data: response.data,
        productGuid,
      }
    }))
    .catch(error => printError('fetchDrawdowns()', error, dispatch))
}




export const fetchPerfMonthlySummary = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchPerfMonthlySummary(): productGuid cannot be empty. ')
    return
  }
  dispatch({ type: LOAD_PERF_MONTHLY_SUMMARY, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio/performances-monthly-summary', null, { params: { productGuid, } })
    .then(response => dispatch({
      type: SET_PERF_MONTHLY_SUMMARY, payload: {
        data: response.data,
        productGuid,
      }
    }))
    .catch(error => printError('fetchPerfMonthlySummary()', error, dispatch))
}





export const fetchComparatives = productGuid => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchComparatives(): productGuid cannot be empty. ')
    return
  }
  dispatch({ type: LOAD_COMPARATIVES, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio-comparatives', [productGuid])
    .then(response =>
      dispatch({
        type: SET_COMPARATIVES, payload: {
          data: response.data,
          productGuid,
        }
      })
    )
    .catch(error => printError('fetchComparatives()', error, dispatch))
}




export const fetchSimulations = ({ productGuid, fromDate, toDate }) => dispatch => {
  if (!productGuid) {
    console.log('Error at fetchSimulations(): productGuid cannot be empty. ')
    return
  }

  const fromDateString = fromDate?.toISOString?.()
  const toDateString = toDate?.toISOString?.()

  if (!fromDateString || !toDateString) {
    printError('fetchSimulations()', `Invalid 
      fromDate '${fromDate}' or
      toDate '${toDate}'.
    `)
    return
  }

  dispatch({ // Clear state.simulations[productGuid] while loading.
    type: SET_SIMULATIONS, payload: {
      data: null,
      productGuid,
    }
  })

  dispatch({ type: LOAD_SIMULATIONS, payload: { productGuid } })
  axios.post(apiBaseUrl + '/landing/portfolio-simulations', null, {
    params: {
      productGuid,
      fromDate: fromDateString,
      toDate: toDateString,
    }
  })
    .then(response =>
      dispatch({
        type: SET_SIMULATIONS, payload: {
          data: response.data,
          productGuid,
        }
      })
    )
    .catch(error => printError('fetchSimulations()', error))
}



export default marketReducer
