import { Grid } from '@mui/material'
import React from 'react'

const MyImage = ({ src, sx, ...rest }) => <Grid
  item
  sx={{
    ...styles.backgroundImage,
    backgroundImage: `url(${src})`,
    ...sx,

  }}
  {...rest}
/>


const styles = {
  backgroundImage: {

    minWidth: '50px',
    minHeight: '50px',

    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '100%',
  }
}


export default MyImage