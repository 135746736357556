import React from 'react'
import { Grid } from '@mui/material'
import styles from 'screens/GetStarted/components/ResearchCard/ResearchCard.module.css'
import Text from 'components/Text'
import MyLink from 'components/MyLink'

export default function ResearchCard({ imageUrl, title, href }) {

  const content = <Grid className={styles.card} style={{ backgroundImage: `url(${imageUrl})` }} >
    <Grid className={styles.cardDescriptionContainer} >
      <Text size='18px' fontWeight='medium'>
        {title}
      </Text>
    </Grid>
  </Grid>

  return href
    ? <MyLink to={href}>{content}</MyLink>
    : content

}
