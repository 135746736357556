import { Button, Grid, Paper, Stack } from '@mui/material'
import MyImage from 'components/MyImage'
import Text from 'components/Text'
import React from 'react'
import CheckedAttribute from 'screens/Pricing/CheckedAttribute'
import { colors } from 'theme'
import { useSelector } from 'react-redux'
import useRedicectToStripe from 'utils/payment/useRedicectToStripe'
import { calculatePrices } from 'utils/payment/calculatePrices'
import { billingFrequencies } from 'utils/payment/billingFrequencies'
import { subscriptionTypes } from 'utils/payment/subscriptionTypes'
import { NavLink } from 'react-router-dom'

const breakpoint = 'md'

const PriceColumns = () => {

  const redirectToStripe = useRedicectToStripe()

  const calculatedPrices = useSelector(calculatePrices)
  const selectedBillingFrequency = useSelector(state => state.payment.selectedBillingFrequency)
  const selectedNrModels = useSelector(state => state.payment.selectedNrModels)

  // const redirectToCheckoutStatus = useSelector(state => state.payment?.redirectToCheckout)
  const isRedirectToCheckoutLoading = useSelector(state =>
    Boolean(state.payment?.redirectToCheckout?.isLoading)
  )


  const frequencyLabel = {
    [billingFrequencies.monthly]: 'a Month',
    [billingFrequencies.yearly]: 'a Year',
  }[selectedBillingFrequency] || ''


  return <Grid sx={styles.priceColumns}>


    <Stack
      sx={{ ...styles.priceColumn, ...styles.recommendedColumn, }}
      component={Paper}
      elevation={15}
    >
      <Grid
        container
        sx={styles.iconAndRecommendRow}
      >
        <MyImage sx={styles.priceIcon} />
        <Grid sx={styles.weRecommendLabel} >We recommend</Grid>
      </Grid>
      <Text sx={styles.title}>Individual</Text>
      <Text sx={styles.description}>
        For beginners with limited understanding of alpha and drawdowns.
      </Text>
      <Stack sx={styles.checkedAttributes}>
        <CheckedAttribute text={selectedNrModels} sx={styles.checkedAttribute} />
        <CheckedAttribute text='Exceptional & Rich Indices' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Exceptional & Rich Styles' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Standard Portfolios' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Simulations' sx={styles.checkedAttribute} />
      </Stack>
      <Text sx={styles.price}>
        {`$${calculatedPrices?.[subscriptionTypes.type2]} USD ${frequencyLabel}`}
      </Text>
      <Button
        sx={{ ...styles.buyButton, ...styles.recommendedBuyButton }}
        variant='outlined'
        disabled={isRedirectToCheckoutLoading}
        onClick={() => redirectToStripe(subscriptionTypes.type2)}
      >
        {isRedirectToCheckoutLoading ? 'Loading...' : 'Subscribe'}
      </Button>
      <Text sx={styles.creditCardLabel}>
        Credit card required
      </Text>
    </Stack>

    <Stack sx={styles.priceColumn} component={Paper} elevation={5}>

      <MyImage sx={styles.priceIcon} />
      <Text sx={styles.title}>Institutional</Text>
      <Text sx={styles.description}>
        For Asset owners and Asset Managers.
      </Text>
      <Stack sx={styles.checkedAttributes}>
        <CheckedAttribute text={selectedNrModels} sx={styles.checkedAttribute} />

        <CheckedAttribute text='Exceptional & Rich Strategies' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Portfolio Creation' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Exceptional & Rich Research' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Sandbox Support' sx={styles.checkedAttribute} />
      </Stack>
      <Text sx={styles.price}>
        {`$${calculatedPrices?.[subscriptionTypes.type3]} USD a Month`}
      </Text>
      <Button
        sx={styles.buyButton}
        variant='contained'
        disabled={isRedirectToCheckoutLoading}
        onClick={() => redirectToStripe(subscriptionTypes.type3)}
      >
        {isRedirectToCheckoutLoading ? 'Loading...' : 'Subscribe'}
      </Button>
      <Text sx={styles.creditCardLabel}>
        Credit card required
      </Text>
    </Stack>

    <Stack sx={styles.priceColumn} component={Paper} elevation={5}>
      <MyImage sx={styles.priceIcon} />
      <Text sx={styles.title}>Advisors</Text>
      <Text sx={styles.description}>
        For Robo-Advisors, fintech platforms and applications.
      </Text>
      <Stack sx={styles.checkedAttributes}>
        <CheckedAttribute text={selectedNrModels} sx={styles.checkedAttribute} />
        <CheckedAttribute text='Mandate Customization' sx={styles.checkedAttribute} />
        <CheckedAttribute text='Advanced Analytics' sx={styles.checkedAttribute} />
        <CheckedAttribute text='White Labeling' sx={styles.checkedAttribute} />
        <CheckedAttribute text='APIs' sx={styles.checkedAttribute} />
      </Stack>
      <Text sx={styles.price}>Custom price</Text>
      <Button
        sx={styles.buyButton}
        variant='contained'
        disabled={isRedirectToCheckoutLoading}
        component={NavLink}
        to='/contact'
      >
        {isRedirectToCheckoutLoading ? 'Loading...' : 'Contact us'}
      </Button>
      <Text sx={styles.creditCardLabel}>
        No credit card required
      </Text>
    </Stack>

  </Grid>
}


const styles = {
  priceColumns: {
    display: 'grid',
    margin: 'auto',
    rowGap: {
      xs: '2rem',
      md: '2rem',
      lg: '3rem',
    },
    width: {
      xs: 'min(269px, 100%)',
      sm: '100%',
    },
    gridTemplateColumns: {
      xs: '1fr',
      sm: '1fr 1fr',
      [breakpoint]: '1fr 1fr 1fr',
    },
    columnGap: {
      xs: '2%',
      sm: '3%',
      md: '2%',
      lg: '3%',
    },
  },
  priceColumn: {
    borderRadius: '10px',
    backgroundImage: 'none',
    backgroundColor: 'colors.darkGrey7',
    gap: '18px',
    padding: '9% 8%',
    padding: {
      xs: '7% 6%',
      md: '8% 7%',
      lg: '9% 8%',
    },
    margin: {
      xs: '0 0 0 0',
      [breakpoint]: '0px 0 0 0',
    },
  },
  recommendedColumn: {
    gridRow: {
      xs: '1',
      sm: 'auto',
    },
    margin: {
      xs: '0 0 0 0',
      [breakpoint]: '0 0 0px 0 ',
    },
    boxShadow: `0px -6px 25px 3px ${colors.almostPrimary2}`,
    border: 'solid 2px',
    borderColor: 'colors.almostPrimary3',
    zIndex: 2,
  },
  priceIcon: {
    backgroundColor: 'colors.opposite',
    width: '72px',
    height: '72px',
    borderRadius: '12px',
  },
  iconAndRecommendRow: {
    columnGap: '8%',
    rowGap: '16px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  weRecommendLabel: {
    borderRadius: '999px',
    padding: '0.5em 1.1em',
    boxShadow: `0px 0px 5px 3px ${colors.almostPrimary2}`,
    border: 'solid 1px',
    borderColor: 'colors.almostPrimary4',
    fontSize: '0.875rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    backgroundColor: 'colors.darkGrey11',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
  },
  description: {
    fontSize: '1.125rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey26',
  },
  checkedAttributes: {
    gap: '16px',
    marginTop: '16px',
  },
  checkedAttribute: {
    fontSize: '1.125rem',
  },
  checkbox: {},
  checkboxLabel: {},
  price: {
    fontSize: '1.75rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
  },
  buyButton: {
    textTransform: 'none',
    fontSize: '1.125rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    marginTop: '32px',
    padding: '0.8em',
    color: 'colors.opposite',
    borderRadius: '10px',
  },
  recommendedBuyButton: {
    border: 'solid 2px',
    borderColor: 'colors.primaryMain',
    color: 'colors.main',
    backgroundColor: 'colors.darkGrey6',
    '&:hover': {
      border: 'solid 2px',
      borderColor: 'colors.primaryMain',
    },
  },
  creditCardLabel: {
    fontSize: '0.9375rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey26',
    textAlign: 'center',
    marginTop: '-2px',
  },
}

export default PriceColumns
