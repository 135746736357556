import { Button, FormControl, Grid, InputAdornment, MenuItem, Select } from '@mui/material'
import React from 'react'
import StarIcon from '@mui/icons-material/Star'
import BarChartIcon from '@mui/icons-material/BarChart'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import { Templates } from 'utils/productUtils'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import Text from 'components/Text'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedTemplate } from 'store/marketReducer'
import { colors } from 'theme'


const templateNames = {
  [Templates.returns]: 'Returns',
  [Templates.returnsBenchmarks]: 'Returns + Benchmark',
  [Templates.risks]: 'Risk',
  [Templates.riskBenchmarks]: 'Risk + Benchmark',
  [Templates.monthlyReturnPerformance]: 'Monthly Return Performance',
  [Templates.monthlyReturnPerformanceBenchmark]: 'Monthly Return Performance + Benchmark',
  [Templates.monthlyExcessReturnPerformance]: 'Monthly Excess Return Performance',
  // THESE TEMPLATES ARE COMMENTED BECAUSE 
  // THEY ARE NOT READY YET:
  // [Templates.mptStats]: 'MPT Stats',
}


const ActionRowAboveProductsTable = () => {

  const dispatch = useDispatch()
  const selectedTemplate = useSelector(state => state.market.selectedTemplate)

  const selectProps = {
    autoWidth: true,
    displayEmpty: true,
    disableUnderline: true,
    value: selectedTemplate,
    onChange: event => dispatch(setSelectedTemplate(event.target.value)),
    sx: styles.select,
    MenuProps: { sx: styles.selectDropdown, },
    inputProps: { 'aria-label': 'Without label', },
    startAdornment: < InputAdornment position="start" >
      <BarChartIcon sx={styles.selectStartIcon} />
    </InputAdornment >,
  }

  return <Grid sx={styles.masterGrid} container>
    <Text sx={{ ...styles.actionGroupingLabel, gridColumn: '1 /  span 2', }}>Select table</Text>
    <Text sx={{ ...styles.actionGroupingLabel, gridColumn: '3 /  span 8', }}>Filters</Text>
    <Text sx={{ ...styles.actionGroupingLabel, gridColumn: '12 /  span 2', }}>Custom</Text>
    <Grid sx={{ ...styles.horizontalSeparator, gridColumn: '1 /  span 13', }} />
    <Button sx={styles.actionItem} variant='contained' disabled startIcon={<StarIcon />}>Favorite</Button>
    <Button sx={styles.actionItem} variant='contained' disabled startIcon={<BarChartIcon />}>Market</Button>
    <Grid sx={styles.verticalSeparator} />
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>We Recommend</Button>
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>Assets</Button>
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>Region</Button>
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>Size</Button>
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>Indices</Button>
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>Styles</Button>
    <Button sx={styles.actionItem} variant='contained' disabled endIcon={<ArrowDropDownIcon />}>Strategies</Button>
    <Grid sx={styles.verticalSeparator} />
    <FormControl variant="filled" sx={styles.selectFormControl}>
      <Select {...selectProps}>
        {Object.keys(templateNames)?.map(templateCode =>
          <MenuItem value={templateCode} key={templateCode}>
            {templateNames[templateCode]}
          </MenuItem>
        )}
      </Select>
    </FormControl>
    <Button
      sx={{ ...styles.actionItem, ...styles.layoutSelectorButton, }}
      variant='contained'
      disabled
      startIcon={<ListSharpIcon />}
      endIcon={<GridViewRoundedIcon />}
    />
  </Grid>
}

const textStyle = {
  textTransform: 'none',
  fontSize: '10.13px',
  fontFamily: 'Montserrat',
  fontWeight: '500',
}

const styles = {
  masterGrid: {
    gridTemplateColumns: 'repeat(13, auto)',
    columnGap: '0.5%',
    overflowX: 'hidden',
    rowGap: '14px',
    padding: {
      xs: '1.5% 1%',
      xl: '1% 1%',
    },
    flexDirection: {
      xs: 'row',
      xl: 'initial',
    },
    justifyContent: {
      xs: 'space-around',
      xl: 'initial',
    },
    alignItems: {
      xs: 'center',
      xl: 'initial',
    },
    display: {
      xs: 'flex',
      xl: 'grid',
    },
    borderTop: {
      xs: `solid 1px ${colors.lightGrey9}`,
      xl: 'none',
    },
  },
  actionItem: {
    borderRadius: '8px',
    padding: '6px 7px',
    ...textStyle,
    minWidth: 'unset',
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '& .MuiButton-endIcon': {
      marginLeft: '2px',
      '& .MuiSvgIcon-root': {
        fontSize: '20px',
      },
    },
  },
  verticalSeparator: {
    backgroundColor: 'colors.lightGrey9',
    width: '1px',
    justifySelf: 'center',
    display: {
      xs: 'none',
      xl: 'block',
    },
  },
  horizontalSeparator: {
    margin: '0 -2%',
    overflow: 'hidden',
    backgroundColor: 'colors.lightGrey9',
    height: '1px',
    display: {
      xs: 'none',
      xl: 'block',
    },
  },
  select: {
    minHeight: '32px',
    paddingLeft: '7px',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: 'colors.main',
    '& .MuiInputBase-input': {
      paddingTop: '0',
      paddingBottom: '0',
    },
    '&:hover': { backgroundColor: 'colors.main', },
    '&.Mui-focused': { backgroundColor: 'colors.main', },
    '& .MuiSvgIcon-root': { color: 'colors.opposite', },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-filled.MuiInputAdornment-sizeMedium': { marginTop: '1px', },
    '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconFilled': { right: '3px', },
    '& .MuiSelect-select.MuiSelect-filled.MuiFilledInput-input.MuiInputBase-input.MuiInputBase-inputAdornedStart': { paddingRight: '24px', },
  },
  selectDropdown: {
    '& .MuiMenu-list': {
      borderRadius: '10px',
      backgroundColor: 'colors.main',
      color: 'colors.opposite',
      '& .MuiMenuItem-root': {
        borderTop: 'solid 1px',
        borderColor: 'colors.lightGrey30',
        fontSize: '18px',
        '&:last-child': {
          borderBottom: 'solid 1px',
          borderColor: 'colors.lightGrey30',
        },
        padding: '0.63em 1.5em 0.63em 1.5em',
        '&.Mui-selected': {
          fontWeight: '600',
          borderColor: 'colors.primaryPale3',
        },
      },
    },
  },
  selectFormControl: {
    '& .MuiSelect-select': {
      paddingTop: '0',
      paddingBottom: '0',
      ...textStyle,
      color: 'colors.opposite',
    },
  },
  selectStartIcon: {
    fontSize: '16px',
  },
  layoutSelectorButton: {
    justifySelf: 'center',
    width: '64px',
    '& .MuiButton-endIcon': {
      marginLeft: '4px'
    },
    '& .MuiButton-startIcon': {
      marginRight: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '20px',
      },
    },
  },
  actionGroupingLabel: {
    textAlign: 'center',
    fontSize: '10.1px',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginBottom: '-1px',
    display: {
      xs: 'none',
      xl: 'block',
    },
  },
}


export default ActionRowAboveProductsTable
