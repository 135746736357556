import React from 'react'
import { Grid } from '@mui/material'
import Text from 'components/Text'
import ReactMarkdown from 'react-markdown'
import cloudAndSunIcon from 'assets/img/cloudAndSunIcon.png'
import MyLink from 'components/MyLink'

export default function WebsiteShortcutCard(props) {
  const { title, content, href } = props.cardData
  return <MyLink to={href}>
    <Grid
      sx={styles.cardWrapper}
      container
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
    >
      <img alt='tralio logo' src={cloudAndSunIcon} style={styles.cardImage} />
      <Text sx={styles.cardTitle} variant={2}>
        <span dangerouslySetInnerHTML={{ __html: title }}>
        </span>
      </Text>
      <Grid sx={styles.cardDescription}>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Grid>
    </Grid>
  </MyLink>


}

const styles = {
  cardWrapper: {
    backgroundColor: 'colors.darkGrey7',
    padding: '8.5%',
    border: '1px solid',
    borderColor: 'colors.darkGrey5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  cardImage: {
    maxWidth: '100%',
    textAlign: 'center',
    height: 'auto',
  },
  cardTitle: {
    fontSize: '2rem',
    marginTop: '24px',
    marginBottom: '9px',
    fontWeight: 'medium',
    textAlign: 'center',
  },
  cardDescription: {
    color: 'colors.lightGrey15',
    fontSize: '1.125rem',
    fontFamily: 'Montserrat',
    textAlign: 'center',
  },
}
