import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Grid, } from '@mui/material'
import { colors } from 'theme'
import { isValidDateObject, prettyDate } from 'utils/dateUtils'
import { prettyCurrency } from 'utils/numberUtils'
import { toUnix } from 'utils/dateUtils'
import Loading from 'components/Loading'
import { useEffect } from 'react'
import { useState } from 'react'
import { useValues } from 'hooks/productApiHooks'
import useChartWidth from 'hooks/useChartWidth'


const ProductValuesChart = ({ productGuid, startDate }) => {

  const values = useValues(productGuid)
  const [chartData, setChartData] = useState(null)
  const chartWidth = useChartWidth(chartData)


  useEffect(() => {
    setChartData(values ? getChartData(values, startDate) : null)
  }, [values, startDate, productGuid])

  if (!chartData) return <Loading sx={styles.chartWrapper} />

  return <Grid sx={{
    ...styles.chartWrapper, width: chartWidth,
  }}>
    <ResponsiveContainer>
      <LineChart data={chartData} width='100%'>
        <CartesianGrid stroke={colors.primaryPale2} />
        <XAxis
          dataKey='time'
          domain={['dataMin', 'dataMax']}
          name='Time'
          tickFormatter={prettyDate}
          type='number'

        />
        <YAxis
          domain={['auto', 'auto']}
          tickFormatter={prettyCurrency}
        />
        <Tooltip
          formatter={(value, _, props) =>
            // Format the numbers to have 2 decimals.
            // Try using the chartData because it already
            // has the formatted number. Avoid doing the
            // actual formatting right before rendering.
            props.payload?.pretty?.[props?.dataKey]
            || prettyCurrency(value)
          }
          labelFormatter={(value, name) =>
            name?.[0]?.payload?.pretty?.time
            || prettyDate(value)
          }
        />
        <Legend />
        <Line
          type='linear'
          name='Portfolio'
          dataKey='portfolioValue'
          stroke={colors.chartLine1}
          dot={false}
        />
        <Line
          type='linear'
          name='Benchmark'
          dataKey='benchmarkValue'
          stroke={colors.chartLine2}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  </Grid>
}


const styles = {
  chartWrapper: {
    width: '100%',
    height: {
      xs: '320px',
      sm: '300px',
      md: '300px',
      lg: '405px',
    },
    backgroundColor: 'colors.darkGrey6',
    padding: '4% 4% 3% 3%',
    borderRadius: '14px',
    '& .recharts-tooltip-label': { color: 'colors.lightGrey8', },
    '& .recharts-default-tooltip': { textAlign: 'right', },
  },
}


const reCalculateValue = (currentValue, firstValue) =>
  (currentValue * 100) / firstValue


const getIsFilterNeeded = startDate => {
  const startDateObject = new Date(startDate)
  const isFilterNeeded = Boolean(isValidDateObject(startDateObject))
  const startDateTimestamp = isFilterNeeded && startDateObject.getTime()

  return {
    isFilterNeeded,
    startDateTimestamp
  }
}

/**
 * If startDate is defined, this function not only filters 
 * the dataPoints, but also recalculates the values such that 
 * the first value is always 100 and the rest of the progress 
 * starts from there.
 */
const getChartData = (values, startDate) => {

  const withTimestampsSorted = values?.Content?.PortfolioValues?.map(dataPoint => ({
    time: toUnix(dataPoint.Index),
    portfolioValue: dataPoint.Value,
    benchmarkValue: dataPoint.BenchmarkValue,
  }))

    ?.sort((dataPoint1, dataPoint2) => dataPoint1.time - dataPoint2.time)


  const {
    isFilterNeeded,
    startDateTimestamp
  } = getIsFilterNeeded(startDate)


  const filteredData = isFilterNeeded

    ? withTimestampsSorted?.filter(dataPoint =>
      dataPoint.time >= startDateTimestamp
    )
    : withTimestampsSorted

  const isRecalcNeeded = isFilterNeeded


  const firstPortfolioValue = filteredData?.[0]?.portfolioValue
  const firstBenchmarkValue = filteredData?.[0]?.benchmarkValue


  const chartData = filteredData?.map((dataPoint, index) => {
    const portfolioValue = isRecalcNeeded

      ? reCalculateValue(dataPoint.portfolioValue, firstPortfolioValue)
      : dataPoint.portfolioValue

    const benchmarkValue = isRecalcNeeded
      ? reCalculateValue(dataPoint.benchmarkValue, firstBenchmarkValue)
      : dataPoint.benchmarkValue

    return {
      time: dataPoint.time,
      portfolioValue,
      benchmarkValue,
      pretty: {
        time: prettyDate(dataPoint.time),
        portfolioValue: prettyCurrency(portfolioValue),
        benchmarkValue: prettyCurrency(benchmarkValue),
      }
    }
  })

  return chartData

}


export default ProductValuesChart
