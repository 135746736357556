import { Button, Stack } from '@mui/material'
import Text from 'components/Text'
import React from 'react'
import { NavLink } from 'react-router-dom'

const ContactUsSection = () => <Stack sx={styles.sectionWrapper}>
  <Text sx={styles.title}>Need to get in touch?</Text>
  <Text sx={styles.description}>
    Need more assistance? <br />
    Send us a message and our dedicated
    team will be in touch.
  </Text>
  <Button
    sx={styles.button}
    variant='contained'
    component={NavLink}
    to='/contact'
  >Contact Us</Button>
</Stack>

const styles = {
  title: {
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    fontFamily: 'Montserrat',
    textTransform: 'uppercase',
    marginBottom: '0',
  },
  description: {
    textAlign: 'center',
    fontSize: '1.125rem',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: 'colors.lightGrey26',

  },
  button: {
    width: 'min(290px, 100%)',
    margin: 'auto',

    fontSize: '1.5rem',
    fontWeight: '400',
    color: 'colors.opposite',
    textTransform: 'none',
    borderRadius: '10px',
    marginTop: '16px',

  },
  sectionWrapper: {
    width: 'min(100%, 550px)',
    margin: 'auto',
    justifyItems: 'center',
    gap: '24px',
  },
}

export default ContactUsSection
