
import { Stack } from '@mui/material'
import React from 'react'
import Header from 'components/Header'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import PricingIntroSection from 'screens/Pricing/PricingIntroSection'
import PricingStepsSection from 'screens/Pricing/PricingStepsSection'
import PricingFreeToolsSection from 'screens/Pricing/PricingFreeToolsSection'
import PriceSelector from 'screens/Pricing/PriceSelector'
import PriceColumns from 'screens/Pricing/PriceColumns'
import SeeAllPricesLink from 'screens/Pricing/SeeAllPricesLink'
import PricingAllFeatures from 'screens/Pricing/PricingAllFeatures'
import FaqSection from 'screens/Pricing/FaqSection'
import ContactUsSection from 'screens/Pricing/ContactUsSection'
import { useSelector } from 'react-redux'
import { useSubscriptionPrices } from 'hooks/productApiHooks'
import Loading from 'components/Loading'
import LoginScreen from 'screens/Authentication/LoginScreen'


const PricingScreen = () => {

  const subscriptionPrices = useSubscriptionPrices()
  const isLogged = useSelector(state => Boolean(state.user.token))

  if (!isLogged) return <LoginScreen />


  if (!subscriptionPrices?.response) return <Stack sx={styles.pageWrapper}>
    <Header /><Loading /><LandingFooter />
  </Stack>


  return <Stack sx={styles.pageWrapper}>
    <Header />
    <Stack sx={styles.sectionsWrapper}>
      <PricingIntroSection />
      <PricingStepsSection />
      <PricingFreeToolsSection />
      <Stack gap='1rem'>
        <PriceSelector />
        <PriceColumns />
        <SeeAllPricesLink mt='1rem' />
      </Stack>
      <Stack gap='2rem'>
        <PricingAllFeatures />
        <SeeAllPricesLink />
      </Stack>
      <FaqSection />
      <ContactUsSection />
    </Stack>
    <LandingFooter />
  </Stack>
}


const styles = {
  pageWrapper: {
    backgroundColor: 'colors.darkGrey9',
  },
  sectionsWrapper: {
    margin: 'auto',
    gap: '10.6rem',
    marginBottom: '5rem',
    padding: {
      'xs': '60px 0',
      'md': '80px 0',
    },
    width: {
      xs: 'min(90%, 1210px)',
      md: 'min(94%, 1210px)',
      lg: 'min(84%, 1210px)',
    },
  },
}


export default PricingScreen
