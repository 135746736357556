import React from 'react'
import Text from 'components/Text'
import { NavLink } from 'react-router-dom'
import { Grid } from '@mui/material'
import styles from 'screens/GetStarted/components/ReadMoreButton/ReadMoreButton.module.css'



export default function ReadMoreButton({ href = '/' }) {
  return <Grid component={NavLink} style={{ textDecoration: 'none' }} to={href} container justifyContent='flex-start' >
    <div className={styles.container}>
      <div className={styles.arrow} ></div>
      <div className={styles.arrowHead} >
      </div>
      <Text color='white' size='18px' ml='12px'>
        Read More
      </Text>
    </div>
  </Grid>
}
