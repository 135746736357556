
export const prettyNumber = number => {
  const twoDecimals = number?.toFixed?.(2)
  return isNaN(twoDecimals) ? '' : twoDecimals
}

export const prettyPercent = number => {
  const pretty = prettyNumber(number)
  return pretty === '' ? '' : `${pretty}%`
}


export const prettyCurrency = number => {
  if (isNaN(number) || number === null) return ''

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)
}

