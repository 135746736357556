import { Button, Grid, Stack } from '@mui/material'
import MyImage from 'components/MyImage'
import Text from 'components/Text'
import React from 'react'
import insightsLibraryBooks from 'assets/img/insightsLibraryBooks.jpg'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { useDispatch } from 'react-redux'
import { setSelectedFilter } from 'store/articlesReducer'
import { useSelector } from 'react-redux'
import { filtersSelector } from 'store/selectors/articleSelector'
import Loading from 'components/Loading'


const InsightsTopSection = () => {

  const dispatch = useDispatch()

  const filters = useSelector(filtersSelector)
  const allArticles = useSelector(state => state.articles.allArticles)
  const selectedFilter = useSelector(state => state.articles.selectedFilter)


  const renderFilters = () => filters.map((filter, index) =>
    <Grid
      onClick={() => dispatch(setSelectedFilter(filter))}
      key={index}
    >
      <CategoryFilterItem
        text={filter}
        isActive={filter === selectedFilter}
      />
    </Grid>
  )

  return <Stack sx={styles.sectionStack}>
    <Grid sx={styles.categoriesAndImageRow}>
      <Stack sx={styles.titleAndCategoriesStack}>
        <Text sx={styles.title}>Insights</Text>
        {
          allArticles?.isSuccess
            ? <Grid sx={styles.categories}>{renderFilters()}</Grid>
            : <Loading />
        }

      </Stack>
      <MyImage src={insightsLibraryBooks} sx={styles.heroImage} />
    </Grid>
  </Stack>
}


const CategoryFilterItem = ({ text, isActive = false }) => {
  const className = isActive ? 'active' : ''
  return <Grid
    sx={styles.categoryFilterIconAndLabel}
    container
    component={Button}
    {...{ className }}
  >
    <ArrowRightAltIcon sx={styles.arrowIcon} {...{ className }} />
    <Text sx={styles.categoryFilterLabel}
      {...{ className }}
    >{text}</Text>
  </Grid>
}


const styles = {
  sectionStack: {
    width: {
      xs: 'min(1218px, 95%)',
      sm: 'min(1218px, 90%)',
      lg: 'min(1218px, 85%)',
    },
    margin: 'auto',
    paddingBottom: '4rem',
    borderBottom: '0.1px solid',
    borderColor: 'colors.darkGrey12',
  },
  categoriesAndImageRow: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '10fr',
      sm: '10fr',
      md: '10fr 9fr',
      lg: '10fr 10fr',
    },
    columnGap: '4%',
    rowGap: '2rem',
  },
  titleAndCategoriesStack: {
    rowGap: '3rem',
  },
  title: {
    fontSize: '3.5rem',
    marginTop: '0',
  },
  categories: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr 1fr',
      md: '1fr 1fr',
    },
    columnGap: '4%',
    rowGap: '0.3rem',
  },
  arrowIcon: {
    color: 'colors.primaryMain',
    fontSize: '2rem',
    '&.active': {
      color: 'colors.opposite',
    },
  },
  categoryFilterIconAndLabel: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    width: 'auto',
    textTransform: 'none',
    color: 'colors.main',
    columnGap: '1rem',
    backgroundColor: 'transparent',
    padding: '0.1em 1.2em 0.1em 0.6em',
    boxShadow: 'none',
    '&.active': {
      backgroundColor: 'colors.primaryMain',
    },
  },
  heroImage: {
    height: 'auto',
    display: {
      xs: 'none',
      md: 'inherit',
    },
  },
  categoryFilterLabel: {
    fontSize: '1.5rem',
    wordBreak: 'break-word',
    '&.active': {
      color: 'colors.opposite',
    },
  },
}

export default InsightsTopSection
