import React, { useEffect, useState } from 'react'
import Header from 'components/Header'
import LandingFooter from 'screens/Landing/components/LandingFooter'
import Text from 'components/Text'
import { Grid, TextField, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FacebookIcon from '@mui/icons-material/Facebook'
import GoogleIcon from '@mui/icons-material/Google'
import Stack from '@mui/material/Stack'
import { useDispatch, useSelector } from 'react-redux'
import { login } from 'store/userReducer'
import { NavLink, useNavigate } from 'react-router-dom'
import MyLink from 'components/MyLink'
import TermsCheckbox from 'screens/Authentication/TermsCheckbox'

const LoginScreen = () => <>
  <Grid>
    <Header />
    <LoginForm />
  </Grid>
  <LandingFooter />
</>


export const LoginForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const token = useSelector(state => state.user.token)
  const [wasSubmitAttempted, setWasSubmitAttempted] = useState(false)
  const [acceptsTerms, setAcceptsTerms] = useState(false)
  const {
    isLoading,
    isError,
    errorCode,
  } = useSelector(state => state.user.loginStatus)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (token) navigate('/market')
  }, [token])


  const getEmailErrorMessage = () => {
    if (errorCode === 'incorrect-email-or-password')
      return 'Incorrect email or password. Please try again'

    if (errorCode === 'email-address-has-yet-to-be-confirmed')
      return 'You must confirm your email address first. Please check your inbox.'

    if (wasSubmitAttempted && !email)
      return 'Please type in your email address.'

    if (!isError) return null

    return 'An error occurred, please try again later.'
  }

  const passwordErrorMessage =
    wasSubmitAttempted &&
    !password &&
    'Please type in your password.'

  const termsErrorMessage =
    wasSubmitAttempted &&
    !acceptsTerms &&
    'You must accept the the Terms Of Service and Privacy Policy to continue. '

  const canSubmit = email && password && acceptsTerms

  const handleSubmit = () => {
    setWasSubmitAttempted(true)
    if (canSubmit) dispatch(login(email, password))
  }


  return <Grid container sx={styles.loginWrapper}>
    <Grid
      container
      sx={styles.loginInner}
      onKeyPress={event => event.key === 'Enter' && handleSubmit()}
    >
      <Grid sx={styles.loginImage}></Grid>
      <Text sx={styles.title}>Log in</Text>
      <Text sx={styles.welcomeText}>
        We are so happy to see you back. You can login to manage
        your Exceptional & Rich Indices, Strategies and Styles.
      </Text>
      <TextField
        label='Email'
        variant='filled'
        type='email'
        value={email}
        error={Boolean(getEmailErrorMessage())}
        helperText={getEmailErrorMessage()}
        onChange={event => setEmail(event.target?.value)}
        sx={styles.input}
        required
      />
      <TextField
        label='Password'
        variant='filled'
        type='password'
        sx={styles.input}
        value={password}
        onChange={event => setPassword(event.target?.value)}
        error={Boolean(passwordErrorMessage)}
        helperText={passwordErrorMessage}

      />
      <Button
        variant='contained'
        sx={styles.button}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Log In'}
      </Button>

      <Grid container sx={styles.checkboxesAndLinks}>
        <Stack >
          <TermsCheckbox
            checked={acceptsTerms}
            onChange={event => setAcceptsTerms(event.target.checked)}
            termsErrorMessage={termsErrorMessage}
          />
          <FormControlLabel
            control={<Checkbox />}
            label={<Text sx={styles.loginSetting}>Remember me</Text>}
          />
        </Stack>
        <MyLink to='/forgot-password'>
          <Text sx={styles.loginSetting} mt='9px'>
            Forgot password?
          </Text>
        </MyLink>
      </Grid>

      <Grid sx={styles.horizontalSeparatorWrapper}>
        <Grid sx={styles.horizontalSeparatorLine}></Grid>
        <Text size='2.5rem'>OR</Text>
        <Grid sx={styles.horizontalSeparatorLine}></Grid>
      </Grid>

      <Button
        variant='outlined'
        sx={styles.externalLoginBtn}
        startIcon={<GoogleIcon />}
        disabled
      >
        Sign up with Google
      </Button>
      <Button
        variant='outlined'
        sx={styles.externalLoginBtn}
        startIcon={<FacebookIcon />}
        disabled
      >
        Continue with Facebook
      </Button>
      <hr width='100%' />
      <Text sx={styles.title}>Dont have an account?</Text>
      <Text sx={styles.welcomeText}>
        Create an account so you can learn about machine investing.
      </Text>

      <Button
        variant='contained'
        sx={styles.button}
        to='/registration'
        component={NavLink}
      >
        Create Account
      </Button>

    </Grid>
  </Grid>
}



const styles = {
  loginWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '5%',
  },
  loginInner: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '33px',
    width: 'min(100%, 650px)',
  },
  loginImage: {
    height: '200px',
    width: '200px',
    backgroundColor: 'colors.lightGrey5',
  },
  title: {
    fontSize: '2rem',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    width: '100%',
    textAlign: 'center',
  },
  welcomeText: {
    fontSize: '1.5rem',
    fontFamily: 'Montserrat',
    textAlign: 'center',
    color: 'colors.lightGrey11',
  },
  input: {
    width: '100%',
  },
  button: {
    fontSize: '1.5rem',
    width: '100%',
    textTransform: 'none',
  },
  checkboxesAndLinks: {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 'inherit',
  },
  loginSetting: {
    color: 'colors.lightGrey12',
    fontSize: '1.125rem',
  },
  horizontalSeparatorWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    width: '80%',
    margin: 'auto',
    gap: '2.1em',
    opacity: '0.2',
  },
  horizontalSeparatorLine: {
    height: 0,
    alignSelf: 'center',
    borderBottom: '2px solid',
    borderBottomColor: 'colors.lightGrey13',
  },
  externalLoginBtn: {
    color: 'colors.lightGrey12',
    borderColor: 'colors.lightGrey12',
    textTransform: 'none',
    fontSize: '2rem',
    width: '100%',
    textAlign: 'left',
    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: '2.2rem',
    },
  },
}



export default LoginScreen

