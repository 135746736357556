import React from 'react'
import researchSection3_1 from 'assets/img/researchSection3_1.jpg'
import researchSection3_2 from 'assets/img/researchSection3_2.jpg'
import HorizontalSlide from 'components/HorizontalSlide'
import MyImage from 'components/MyImage'
import { Grid, Stack } from '@mui/material'
import Text from 'components/Text'


const Section3 = () => <HorizontalSlide sx={{ height: 'auto', }}>
  <Grid item>
    <Grid sx={styles.imageAndText}>
      <MyImage src={researchSection3_1} />
      <Stack>
        <Text size='2.5rem' mb='1em'>Is this a generational problem?</Text>
        <Text size='1.5rem'>
          Yes, it is a generational problem and it is worsening because humans are living longer,
          and interest rates don&rsquo;t have much lower to go. We have reached peak credit and there
          is a limit to credit-induced growth. The asset owners who rely on asset managers to do a
          good job feel the pressure and the shift to alternative assets to seek illiquidity premiums,
          and search for negatively correlated assets is forcing asset owners to orchestrate their
          business and renegotiate fees and demand that they simply pay for alpha.
        </Text>
      </Stack>
    </Grid>
    <Grid sx={styles.textAndImage}>
      <Stack>
        <Text size='2.5rem' mb='1em'>Why did you not hear about it?</Text>
        <Text size='1.5rem'>
          If as an investor you are not aware of the statistic, now you know it and now you also
          know why there is a rush to crypto investing and generally investors taking charge of
          their investments, the whole rush to Robo-advisors is primarily driven by investors
          refusing to pay a fee for lack of skill. Not everybody is young, capable, competent, and
          has the luxury of time to manage his/her investments, and a relationship with an advisor
          may seem to prevail despite odds, but this could partially be also a function of the 14-
          year bull market. In the era of intelligence, the search for alpha is critical for survival.
        </Text>
      </Stack>
      <MyImage src={researchSection3_2} />
    </Grid>
  </Grid>
</HorizontalSlide>


const styles = {
  imageAndText: {
    display: 'grid',
    gridTemplateColumns: '10fr 33fr',
    gap: '2%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textAndImage: {

    display: 'grid',
    gridTemplateColumns: '33fr 10fr',
    gap: '2%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    pt: '5%',
  },
}


export default Section3

